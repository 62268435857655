export const USER_ME_INIT = '[USER_ME] USER_ME_INIT';
export const USER_ME_SUCCESS = '[USER_ME] USER_ME_SUCCESS';
export const USER_ME_ERROR = '[USER_ME] USER_ME_ERROR';
export const USER_ME_UPDATE_INIT = '[USER_ME] USER_ME_UPDATE_INIT';
export const USER_ME_UPDATE_SUCCESS = '[USER_ME] USER_ME_UPDATE_SUCCESS';
export const USER_ME_UPDATE_ERROR = '[USER_ME] USER_ME_UPDATE_ERROR';
export const USERS_LIST_INIT = '[USERS] USERS_LIST_INIT';
export const USERS_LIST_SUCCESS = '[USERS] USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = '[USERS] USERS_LIST_ERROR';
export const USERS_CREATE_INIT = '[USERS] USERS_CREATE_INIT';
export const USERS_CREATE_SUCCESS = '[USERS] USERS_CREATE_SUCCESS';
export const USERS_CREATE_ERROR = '[USERS] USERS_CREATE_ERROR';
export const USERS_UPDATE_INIT = '[USERS] USERS_UPDATE_INIT';
export const USERS_UPDATE_SUCCESS = '[USERS] USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_ERROR = '[USERS] USERS_UPDATE_ERROR';
export const USERS_DELETE_INIT = '[USERS] USERS_DELETE_INIT';
export const USERS_DELETE_SUCCESS = '[USERS] USERS_DELETE_SUCCESS';
export const USERS_DELETE_ERROR = '[USERS] USERS_DELETE_ERROR';
export const USER_SELECTED = '[USERS] USER_SELECTED';
export const USER_SELECTED_CLEAR = '[USERS] USER_SELECTED_CLEAR';
export const DOMAINS_BY_TYPE = '[DOMAINS] DOMAINS_BY_TYPE';
export const ROLES_LIST_INIT = '[ROLES] ROLES_LIST_INIT';
export const ROLES_LIST_SUCCESS = '[ROLES] ROLES_LIST_SUCCESS';
export const ROLES_LIST_ERROR = '[ROLES] ROLES_LIST_ERROR';
export const TIPOS_ENTIDAD_LIST_INIT = '[TIPOS_ENTIDAD] TIPOS_ENTIDAD_LIST_INIT';
export const TIPOS_ENTIDAD_SUCCESS = '[TIPOS_ENTIDAD] TIPOS_ENTIDAD_SUCCESS';
export const TIPOS_ENTIDAD_ERROR = '[TIPOS_ENTIDAD] TIPOS_ENTIDAD_ERROR';
export const TIPOS_DOCUMENTO_LIST_INIT = '[TIPOS_DOCUMENTO] TIPOS_DOCUMENTO_LIST_INIT';
export const TIPOS_DOCUMENTO_SUCCESS = '[TIPOS_DOCUMENTO] TIPOS_DOCUMENTO_SUCCESS';
export const TIPOS_DOCUMENTO_ERROR = '[TIPOS_DOCUMENTO] TIPOS_DOCUMENTO_ERROR';
export const USER_LOAD_SELECTED_INIT = '[USERS] USER_LOAD_SELECTED_INIT';
export const USER_LOAD_SELECTED_SUCCESS = '[USERS] USER_LOAD_SELECTED_SUCCESS';
export const USER_LOAD_SELECTED_ERROR = '[USERS] USER_LOAD_SELECTED_ERROR';
