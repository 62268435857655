/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import useForm from "react-hook-form";
import {withRouter} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as yup from "yup";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import Page from "../components/Page";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {PageHeader} from "../components/PageHeader";
import PaginationTableRowSelect from "../components/Table/PaginationTableRowSelect";
import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import ErrorBoundary from '../components/ErrorBoundary';
import {Button} from "../components/form-ui-components/Button";
// import {ButtonLight} from "../components/form-ui-components/ButtonLight";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";

import {
    useAutoridadAmbiental,
    useEntidadDisposicion,
    useDomains,
    useConstants,
    useNotification,
    useLogin
} from "../hooks";
/* Style Components */
import { SpanTextInfo, SpanDivide, SpanView } from './styled';
import {MapWithGeocoderControl} from "../components/form-ui-components/MapWithGeocoderControl";
import {Col, Col1, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";

import { removeEmpty } from "../utils";

dayjs.locale('es');

const EntidadDisposicionForm = ({history, match}) => {
    const [loaded, setLoaded] = useState(false);
    const [ municipiosFiltrados, setMunicipiosFiltrados ] = useState([]);
    const [reverseGeocodingResult, setResult] = useState();
    const [ loadedInfo, setLoadedInfo ] = useState(false);
    const [ files, setFiles ] = useState([]);
    const [ filesAux, setFilesAux ] = useState([]);
    const { domainsByKey, getOptionsByDomainTypes } = useDomains();
    const { constantsByKey, getConstantOptionsByTypes } = useConstants();
    const { isLoggedIn, token } = useLogin();
    const {
        selected,
        mode,
        createEntidadDisposicion,
        updateEntidadDisposicion,
        loadSelected,
        clearSelected
    } = useEntidadDisposicion();
    const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();
    const { createNotification } = useNotification();

    const schema = yup.object().shape({
        departamento: yup.string()
    });

    const searchOption = (val, datalist) => {
        if (!datalist) {
            return null;
        }
        for (let j = 0; j < datalist.length; j++) {
            if (val === datalist[j].constantName) {
                return datalist[j];
            }
        }
    };

    const {setValue, register, handleSubmit, errors, reset} = useForm();

    const handleChangeDpto = e => {
      e.preventDefault();
      const { value } = e.target;
      const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
      setMunicipiosFiltrados(newData);
    };

    const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');

    const isPresent = (rows, index) => {
      let esta = false;
      Object.keys(rows).map(key => {
        if(key === index.toString()){
          esta = true;
        }
      });
      return esta;
    };

    const handleDeleteFiles = (e, selectedRowIds, selectedFlatRows) => {
      e.preventDefault();
      confirmAlert({
        title: `¿Esta seguro de eliminar los ${Object.keys(selectedRowIds).length} archivos del listado?`,
        message: `Al borrar los archivos del listado quedan desvinculados del expediente`,
        buttons: [
          {
            label: "VOLVER AL FORMULARIO",
            onClick: () => {}
          },
          {
            label: "ACEPTAR",
            onClick: () => {
              console.log(selectedFlatRows);
              const newFilesAux = filesAux.map((file, index) => (isPresent(selectedRowIds, index)) ? {...file, activo: false } : file);
              const newFiles = filesAux.filter((_, index) => !isPresent(selectedRowIds, index));
              console.log('newFilesAux',newFilesAux);
              setFiles(newFilesAux);
              setFilesAux(newFiles);
            }
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
    };

    const handleClose = async e => {
      e.preventDefault();
      reset();
      await clearSelected();
      history.push('/entidad-disposicion-search');
      e.stopPropagation();
    };

    const columnsFiles = [
      {
        Header: 'Tipo de documento',
        accessor:  d => {
          return ((d.tipo) && (d.tipo !== '') && (d.tipo !== ' ')) ? `${d.tipo}` : '(empty)';
        }
      },
      {
        Header: 'Nombre del documento',
        accessor:  d => {
          return ((d.archivo) && (d.archivo !== '') && (d.archivo !== ' ')) ? (((d.nombre !== undefined) && (d.nombre !== '')) ? `${d.nombre}` : `${d.archivo}`) : '(empty)';
        }
      },
      {
        Header: 'Fecha cargue',
        accessor:  d => {
          return ((d.fecha_creacion) && (d.fecha_creacion !== '') && (d.fecha_creacion !== ' ')) ? `${parseDate(d.fecha_creacion)}` : '(empty)';
        }
      },
      {
        Header: 'Usuario',
        accessor:  d => {
          return ((d.usuario_creacion) && (d.usuario_creacion !== '') && (d.usuario_creacion !== ' ')) ? `${d.usuario_creacion}` : (((d.usuario !== undefined) && (d.usuario !== '')) ? `${d.usuario}` : '(empty)');
        }
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => {
          const { original } = row;
          const buttons = [
            {
              id: original._id,
              iconName: 'remove_red_eye',
              title: 'Ver Archivo',
              visible: true,
              handleAction: () => {
                // await getByIdArchivoRequest(original, 'view');
                // handleOpenModalFiles(this);
              }
            },
            {
              id: original._id,
              iconName: 'edit',
              title: 'Actualizar Archivo',
              visible: true,
              handleAction: () => {
                // await getByIdArchivoRequest(original, 'edit');
                // handleOpenModalFiles(this);
              }
            },
          ];
          return <ButtonsActionRow buttons={buttons} />;
        },
      }
    ];

    const gotoLogin = useCallback(() => {
      history.push('/');
    }, [history]);

    const clearEntidad = useCallback(async () => {
      window.scrollTo(0, 0);
      await clearSelected();
      reset();
      setLoadedInfo(true);
    }, [history]);

    const gotoEntidad = useCallback(async () => {
      await clearSelected();
      history.push('/entidad-disposicion-search');
    }, [history]);

    const gotoEntidadCrear = useCallback(async () => {
      await clearSelected();
      history.push('/entidad-disposicion-form');
    }, [history]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        try {
          if((Number(data.tipo_disposicion_provisional) === 0 && Number(data.tipo_disposicion_final) === 0) || (Number(data.tipo_disposicion_provisional) !== 0 && Number(data.tipo_disposicion_final) !== 0)) {
            const opcselected = (Number(data.tipo_disposicion_provisional) === 0 && Number(data.tipo_disposicion_final) === 0) ? 'no' : ((Number(data.tipo_disposicion_provisional) !== 0 && Number(data.tipo_disposicion_final) !== 0) ? 'si' : 'n/a' );
            Swal.fire({
              title: 'CAMPOS TIPO DE DISPOSICIÓN!',
              icon: 'info',
              html: `¿aplica disposición provisional? y ¿aplica disposición final? No se puede seleccionar dos tipos de entidad de disposición con la opción: <b>${opcselected}</b>, debe indicar el tipo de disposición en cual aplica!`,
              confirmButtonText: 'ACEPTAR'
            })
          } else {
            const newData = {
              ...data,
              observaciones: data.observaciones.length > 0 ? data.observaciones : "no hay observaciones",
              tipo: data.tipo ? Number(data.tipo) : null,
              tipo_disposicion: (Number(data.tipo_disposicion_provisional) !== 0 && Number(data.tipo_disposicion_final) === 0) ? Number(data.tipo_disposicion_provisional): ((Number(data.tipo_disposicion_provisional) === 0 && Number(data.tipo_disposicion_final) !== 0) ? Number(data.tipo_disposicion_final) : null),
              tipo_naturaleza: data.tipo_naturaleza ? Number(data.tipo_naturaleza) : null,
              pais: data.pais ? Number(data.pais) : null,
              departamento: data.departamento ? Number(data.departamento) : null,
              municipio: data.municipio ? Number(data.municipio) : null,
              coordenadas: (data.coordenadas.latitud.decimal && data.coordenadas.longitud.decimal) ? ({
                latitud: {
                  decimal: data.coordenadas.latitud.decimal ? Number(data.coordenadas.latitud.decimal) : null,
                },
                longitud: {
                  decimal: data.coordenadas.longitud.decimal ? Number(data.coordenadas.longitud.decimal) : null,
                }
              }) : null,
            };

            let isError = false;
            if (selected !== null) {
              const {msg, err} = await updateEntidadDisposicion(removeEmpty(newData), selected._id, token);
              isError = err;
              createNotification({
                message: msg,
                error: err,
                visible: true
              });
            } else {
              const {msg, err} = await createEntidadDisposicion(removeEmpty(newData), token);
              isError = err;
              createNotification({
                message: msg,
                error: err,
                visible: true
              });
            }

            if(!isError) {
              reset();
              await gotoEntidad();
            }
          }
        } catch (err) {
            console.log(err);
        }
        e.stopPropagation();
    };

    const loadMunicipioSelected = useCallback(() => {
      if(selected && municipiosFiltrados.length > 0) {
        setValue("municipio", selected.municipio._id);
      }
    }, [municipiosFiltrados, selected]);

    const loadEntidadDisposicionSelected = useCallback(() => {
      if (selected) {
        setValue("autoridad_ambiental", selected.autoridad_ambiental._id);
        setValue("nombre", selected.nombre);
        setValue("tipo", `${selected.tipo._id}`);
        setValue("tipo_disposicion_provisional", (Number(selected.tipo_entidad_disposicion._id) === 15000001) ? `${selected.tipo_entidad_disposicion._id}` : '0');
        setValue("tipo_disposicion_final", (Number(selected.tipo_entidad_disposicion._id) === 15000002) ? `${selected.tipo_entidad_disposicion._id}` : '0');
        setValue("tipo_naturaleza", `${selected.tipo_naturaleza._id}`);
        setValue("pais", selected.pais._id);
        setValue("departamento", selected.departamento._id);
        setValue("direccion", selected.direccion);
        setValue("telefono_movil", selected.telefono_movil);
        setValue("telefono_fijo", selected.telefono_fijo);
        setValue("sitio_web", selected.sitio_web);
        setValue("responsable", selected.responsable);
        setValue("ano_fundacion", selected.ano_fundacion);
        setValue("estado", selected.estado);
        setValue("email", selected.email);
        setValue("observaciones", selected.observaciones);
        if (selected.coordenadas && selected.coordenadas.longitud) {
          setValue(
              "coordenadas.longitud.decimal",
              selected.coordenadas.longitud.decimal
          );
        }
        if (selected.coordenadas && selected.coordenadas.latitud) {
            setValue(
                "coordenadas.latitud.decimal",
                selected.coordenadas.latitud.decimal
            );
        }
        const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(selected.departamento._id));
        setMunicipiosFiltrados(newData);
      }
    }, [setValue, selected]);

    const load = useCallback(async () => {
      window.scrollTo(0, 0);
      await Promise.all([clearSelected(), searchAutoridadAmbiental({}, token), getOptionsByDomainTypes(["tiposEntidadDisposicion"]), getConstantOptionsByTypes(["paises", "departamentos", "municipios"])]);
      if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
        await loadSelected(match.params.id, match.params.mode, token);
        if (match.params.mode === 'edit') loadEntidadDisposicionSelected();
      } else {
        setValue("pais", 3000001);
      }
      setLoadedInfo(true);
    }, [
        searchAutoridadAmbiental,
        getOptionsByDomainTypes,
        getConstantOptionsByTypes,
        loadSelected,
        loadEntidadDisposicionSelected,
        clearSelected,
        match.params.id,
        match.params.mode
    ]);

    useEffect(() => {
      if(!isLoggedIn) {
        gotoLogin();
      } else if(isLoggedIn && !loaded) {
        load();
        setLoaded(true);
      }
    }, [isLoggedIn, loaded, load, gotoLogin]);

    useEffect(() => {
      loadEntidadDisposicionSelected();
    }, [selected]);

    useEffect(() => {
      loadMunicipioSelected();
    }, [municipiosFiltrados]);

    useEffect(() => {
      if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
        clearEntidad();
      }
    }, [clearEntidad, match.params.id, match.params.mode]);

    const setSuggestedValues = useCallback(() => {
        setValue("longitud", reverseGeocodingResult.center.lng);
        setValue("latitud", reverseGeocodingResult.center.lat);
        setValue("direccion", reverseGeocodingResult.properties.display_name);
        const pais = searchOption(
            reverseGeocodingResult.properties.address.country,
            constantsByKey.paises
        );
        if (pais) {
            setValue("pais", pais._id);
        }
        const departamento = searchOption(
            reverseGeocodingResult.properties.address.state,
            constantsByKey.departamentos
        );
        if (departamento) {
            setValue("departamento", departamento._id);
        }
        const municipio = searchOption(
            reverseGeocodingResult.properties.address.county,
            constantsByKey.municipios
        );
        if (municipio) {
            setValue("municipio", municipio._id);
        }
    }, [setValue, constantsByKey, reverseGeocodingResult]);

    useEffect(() => {
        if (reverseGeocodingResult) {
            setSuggestedValues();
        }
    }, [reverseGeocodingResult, setSuggestedValues]);

    return (
      <ErrorBoundary>
        <Page useSideBarSpace>
          {isLoggedIn && (
            <>
              {!loadedInfo && (
                <>
                  <br />
                  <div>Cargando información por favor espere un momento...</div>
                  <div>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                        <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                </>
              )}
              <PageHeader>
                <H1>{mode === 'view' ? ('Ver Entidad de Disposición') : (mode === 'edit' ? ('Modificar Entidad de Disposición') : ('Crear Entidad de Disposición'))}</H1>
              </PageHeader>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Card title=" ">
                  <CardTitleBox>
                    <H5>Datos Generales</H5>
                  </CardTitleBox>
                  <GridContainer>
                    <Row>
                      <Col3>
                        <Label>
                          AUTORIDAD AMBIENTAL <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Select name="autoridad_ambiental" ref={register({ required: true })}>
                                <SelectOptions selectOptions={autoridadesAmbientales}/>
                              </Select>
                              {errors.autoridad_ambiental && errors.autoridad_ambiental.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La autoridad ambiental es requerida!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.autoridad_ambiental.nombre}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          NOMBRE DE LA ENTIDAD <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="nombre"
                                type="text"
                                ref={register({ required: true, maxLength: 100 })}
                                placeholder="Escriba el nombre de la entidad"
                              />
                              {errors.nombre && errors.nombre.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El nombre de la entidad es requerida!
                                </p>
                              )}
                              {errors.nombre && errors.nombre.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  Al nombre le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.nombre}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          TIPO DE ENTIDAD DE DISPOSICIÓN <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Select name="tipo" ref={register({ required: true })}>
                                <SelectOptions selectOptions={domainsByKey.tiposEntidadDisposicion} />
                              </Select>
                              {errors.tipo && errors.tipo.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El tipo de entidad de disposición es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.tipo.domainName}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                    </Row>
                    <Row>
                      <Col3>
                        <Label>
                          NATURALEZA JURÍDICA
                          {mode !== 'view' ? (
                            <>
                              <br />
                              <input
                                name="tipo_naturaleza"
                                value={2000001}
                                type="radio"
                                ref={register}
                              /> Privada
                              <input
                                name="tipo_naturaleza"
                                value={2000002}
                                type="radio"
                                ref={register}
                              /> Pública
                              {errors.tipo_naturaleza && errors.tipo_naturaleza.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La naturaleza jurídica es requerida!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.tipo_naturaleza.domainName}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          ¿APLICA DISPOSICIÓN PROVISIONAL? <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <br />
                              <input
                                name="tipo_disposicion_provisional"
                                value={15000001}
                                type="radio"
                                ref={register({ required: true })}
                              /> Sí
                              <input
                                name="tipo_disposicion_provisional"
                                value={0}
                                type="radio"
                                ref={register({ required: true })}
                                defaultChecked
                              /> No
                              {errors.tipo_disposicion_provisional && errors.tipo_disposicion_provisional.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  ¿aplica disposición provisional? es requerida!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{(Number(selected.tipo_entidad_disposicion._id) === 15000001) ? 'Si' : 'No'}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          ¿APLICA DISPOSICIÓN FINAL? <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <br />
                              <input
                                name="tipo_disposicion_final"
                                value={15000002}
                                type="radio"
                                ref={register({ required: true })}
                              /> Sí
                              <input
                                name="tipo_disposicion_final"
                                value={0}
                                type="radio"
                                ref={register({ required: true })}
                                defaultChecked
                              /> No
                              {errors.tipo_disposicion_final && errors.tipo_disposicion_final.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  ¿aplica disposición final? es requerida!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{(Number(selected.tipo_entidad_disposicion._id) === 15000002) ? 'Si' : 'No'}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                    </Row>
                    <Row>
                      <Col3>
                        <Label>
                          RESPONSABLE DE LA ENTIDAD <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="responsable"
                                type="text"
                                ref={register({ required: true, maxLength: 50 })}
                                placeholder="Escriba el nombre del responsable"
                              />
                              {errors.responsable && errors.responsable.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El nombre de la autoridad ambiental es requerida!
                                </p>
                              )}
                              {errors.responsable && errors.responsable.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre del responsable le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.responsable}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          AÑO DE FUNDACIÓN
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="ano_fundacion"
                                type="number"
                                ref={register({ minLength: 4, maxLength: 4 })}
                                placeholder="Solo números"
                              />
                              {errors.ano_fundacion && errors.ano_fundacion.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  El año de fundación le falta dígitos mínimo (4)!
                                </p>
                              )}
                              {errors.ano_fundacion && errors.ano_fundacion.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El año de fundación le sobran dígitos máximo (4)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.ano_fundacion}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          ESTADO <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <br />
                              Activa: <Input name="estado" type="checkbox" ref={register} />
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.estado ? 'Activa' : 'Inactiva'}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                    </Row>
                    <Row>
                      <Col1>
                        <Label>
                          OBSERVACIONES GENERALES
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="observaciones"
                                type="text"
                                ref={register({minLength: 5, maxLength: 200})}
                                placeholder="Ingrese las observaciones sobre la entidad"
                              />
                              {errors.observaciones && errors.observaciones.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  Las observaciones de la entidad le falta caracteres mínimo (5)!
                                </p>
                              )}
                              {errors.observaciones && errors.observaciones.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  Las observaciones de la entidad le sobran caracteres máximo (200)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.observaciones}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col1>
                    </Row>
                    <Row>
                      {/*<Col1>*/}
                      {/*  <Label>*/}
                      {/*    ADJUNTAR SOPORTES <span className="obligatory">*</span><br />*/}
                      {/*    <PaginationTableRowSelect columns={columnsFiles} data={filesAux} onClick={handleDeleteFiles} eraser textEraser="QUITAR" /><br />*/}
                      {/*    {filesAux.length === 0 && (<SpanTextInfo style={{ fontStyle: 'italic' }}>No se ha asociado ningún documento</SpanTextInfo>)}*/}
                      {/*  </Label>*/}
                      {/*</Col1>*/}
                    </Row>
                    <Row>
                      <Col1>
                        <SpanDivide>
                          <br />
                          Datos de ubicación de la entidad.
                        </SpanDivide>
                      </Col1>
                    </Row>
                    <Row>
                      <Col3>
                        <Label>
                          PAÍS <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Select name="pais" ref={register({ required: true })}>
                                <SelectOptions selectOptions={constantsByKey.paises}/>
                              </Select>
                              {errors.pais && errors.pais.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El país de ubicación de la entidad es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.pais.constantName}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          DEPARTAMENTO <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Select name="departamento" onChange={handleChangeDpto} ref={register({ required: true })}>
                                <SelectOptions selectOptions={constantsByKey.departamentos}/>
                              </Select>
                              {errors.departamento && errors.departamento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El departamento de ubicación de la entidad es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.departamento.constantName}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          CIUDAD O MUNICIPIO <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Select name="municipio" ref={register({ required: true })}>
                                <SelectOptions selectOptions={municipiosFiltrados}/>
                              </Select>
                              {errors.municipio && errors.municipio.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La ciudad o municipio de ubicación de la entidad es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.municipio.constantName}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                    </Row>
                    <Row>
                      <Col3>
                        <Label>
                          DIRECCIÓN <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="direccion"
                                type="text"
                                ref={register({ required: true, minLength: 5, maxLength: 100 })}
                                placeholder="Ingrese dirección de la autoridad."
                              />
                              {errors.direccion && errors.direccion.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La dirección es requerida!
                                </p>
                              )}
                              {errors.direccion && errors.direccion.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  La dirección le falta caracteres mínimo (5)!
                                </p>
                              )}
                              {errors.direccion && errors.direccion.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La dirección le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.direccion}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          TELÉFONO FIJO
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="telefono_fijo"
                                type="tel"
                                ref={register({ minLength: 7, maxLength: 20 })}
                                placeholder="ex.: +57 1 123 7777"
                              />
                              {errors.telefono_fijo && errors.telefono_fijo.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  EL teléfono fijo le falta dígitos mínimo (7)!
                                </p>
                              )}
                              {errors.telefono_fijo && errors.telefono_fijo.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  EL teléfono fijo le dígitos máximo (20)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.telefono_fijo}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          TELÉFONO MÓVIL
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="telefono_movil"
                                type="tel"
                                ref={register}
                                placeholder="ex.: 310 777 1234"
                              />
                              {errors.telefono_movil && errors.telefono_movil.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  EL teléfono móvil le falta dígitos mínimo (7)!
                                </p>
                              )}
                              {errors.telefono_movil && errors.telefono_movil.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  EL teléfono móvil le sobran dígitos máximo (20)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.telefono_movil}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col3>
                    </Row>
                    <Row>
                      <Col4>
                        <Label>
                          LATITUD
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="coordenadas.latitud.decimal"
                                type="number"
                                step="any"
                                ref={register}
                                placeholder="Decimales"
                              />
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.coordenadas != null ? selected.coordenadas.latitud.decimal : null}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col4>
                      <Col4>
                        <Label>
                          LOGNITUD
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="coordenadas.longitud.decimal"
                                type="number"
                                step="any"
                                ref={register}
                                placeholder="Decimales"
                              />
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.coordenadas != null ? selected.coordenadas.longitud.decimal : null}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col4>
                      <Col4>
                        <Label>
                          EMAIL <span className="obligatory">*</span>
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="email"
                                type="text"
                                ref={register({ required: true, maxLength: 50 })}
                                placeholder="Correo de la entidad"
                              />
                              {errors.email && errors.email.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El correo es requerido!
                                </p>
                              )}
                              {errors.email && errors.email.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El correo de la entidad le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.email}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col4>
                      <Col4>
                        <Label>
                          SITIO WEB
                          {mode !== 'view' ? (
                            <>
                              <Input
                                name="sitio_web"
                                type="text"
                                ref={register({
                                  minLength: 10,
                                  maxLength: 100,
                                  pattern: {
                                    value: /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                    message: 'El sitio web indicado no es valido!'
                                  }
                                })}
                                placeholder="www.example.gov.co"
                              />
                              {errors.sitio_web && errors.sitio_web.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  El sitio web le falta caracteres mínimo (10)!
                                </p>
                              )}
                              {errors.sitio_web && errors.sitio_web.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El sitio web le sobran caracteres máximo (100)!
                                </p>
                              )}
                              {errors.sitio_web && errors.sitio_web.type === "pattern" && (
                                <p style={{color: 'red'}}>
                                  El sitio web indicado no es valido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.sitio_web}</SpanView>
                            </>
                          )}
                        </Label>
                      </Col4>
                    </Row>
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        {mode !== 'view' ? (
                          <>
                            <Label style={{ width: 'auto' }}>
                              <Button type="button" onClick={(e) => handleClose(e)}>
                                CANCELAR
                              </Button>
                            </Label>
                            <Label style={{ width: 'auto' }}>
                              <Button type="submit">
                                {mode === 'edit' ? ('ACTUALIZAR') : ('CREAR')}
                              </Button>
                            </Label>
                          </>
                        ) : (
                          <>
                            <Label style={{ width: 'auto' }}>
                              <Button type="button" onClick={(e) => handleClose(e)}>
                                CANCELAR
                              </Button>
                            </Label>
                          </>
                        )}
                      </Col>
                    </Row>
                  </GridContainer>
                </Card>
              </Form>
            </>
          )}
        </Page>
      </ErrorBoundary>
    );
};

EntidadDisposicionForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

EntidadDisposicionForm.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(EntidadDisposicionForm);
