/* eslint-disable no-underscore-dangle */
import {
  createInit,
  createSuccess,
  createError,
  trasladoEspecimenesInit,
  trasladoEspecimenesSuccess,
  trasladoEspecimenesError,
  trasladoEntidadDestinoInit,
  trasladoEntidadDestinoSuccess,
  trasladoEntidadDestinoError,
  getSolicitanteSuccess,
  getSolicitanteError,
  trasladoLoadSoporteSuccess,
  trasladoLoadSoporteError,
  searchInit,
  searchSuccess,
  searchError,
  trasladoCancelarInit,
  trasladoCancelarSuccess,
  trasladoCancelarError,
  clearSelectedInit,
  trasladoContactAddSuccess,
  trasladoContactAddError,
  trasladoContactDeleteSuccess,
  trasladoContactDeleteError,
  clearSelectedFileInit,
  trasladoEspecimenSelectedSuccess,
  trasladoEspecimenSelectedError,
  trasladoEspecimenAddSuccess,
  trasladoEspecimenAddError,
  trasladoEspecimenDeleteSuccess,
  trasladoEspecimenDeleteError,
  trasladoRespuestaSuccess,
  trasladoRespuestaError,
  cleanContactTrasladoSuccess,
  cleanContactTrasladoError,
  trasladoSoportesAddSuccess,
  trasladoSoportesAddError,
  trasladoSoportesUpdateSuccess,
  trasladoSoportesUpdateError,
  trasladoSoportesDeleteSuccess,
  trasladoSoportesDeleteError,
  trasladoSoportesDownloadInit,
  trasladoSoportesDownloadSuccess,
  trasladoSoportesDownloadError,
  selectedFileSuccess,
  selectedFileError,
  searchEspecimenesSinTrasladoInit,
  searchEspecimenesSinTrasladoSuccess,
  searchEspecimenesSinTrasladoError,
  searchEspecimenesConTrasladoInit,
  searchEspecimenesConTrasladoSuccess,
  searchEspecimenesConTrasladoError,
  loadSelectedInit,
  loadSelectedSuccess,
  loadSelectedFailed
} from "./actions";
import * as TrasladoServices from "../../services";
import { domainTypesByKey, constantTypesByKey } from '../../config/const';

export const createTrasladoRequest = (traslado, files, contacts, especimenes, token) => {
  return async dispatch => {
    dispatch(createInit());
    try {
      const data = await TrasladoServices.apiTraslados.create(traslado, token);
      if(typeof data === 'object' && typeof data.data === 'object') {

        if(files.length > 0) {
          const anAsyncFunctionFile = async file => {
            let info = {};
            if(file.nuevo) {
              const { nuevo, _id, activo, memory, ...fileInfo } = file;
              const newFileInfo = { ...fileInfo, traslado: data.data._id, tipo: Number(fileInfo.tipo._id) };
              console.log('archivo crear', newFileInfo, nuevo, activo);
              info = await TrasladoServices.apiArchivos.uploadFileCreateToTraslado(newFileInfo, token);
            }
            return info;
          };

          const getDataFile = async () => {
            return Promise.all(files.map(file => anAsyncFunctionFile(file)))
          };

          await getDataFile().then(newData => {
            console.log(newData);
          });
        }

        if(especimenes.length > 0) {
          const newEspecimenes = especimenes.map(({_id}) => { return { especimen: _id }; });
          const dataEspecimenes = await TrasladoServices.apiEspecimenes.putTraslado(data.data._id, newEspecimenes, token);
          console.log(dataEspecimenes);
        }

        dispatch(createSuccess(data.data));
        return { msg: `${data.message} `, err: false };
      }

      if(typeof data === 'string') {
        dispatch(createError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
    } catch (error) {
      dispatch(createError('Se genero un error al guardar el traslado por favor consulte al administrador!'));
      return { msg: 'Se genero un error al guardar el traslado por favor consulte al administrador!', err: true };
    }
  };
};

export const getUsuarioEntidad = (username, token) => {
  return async dispatch => {
    try {
      const data = await TrasladoServices.apiUser.getUsers(username, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const autoridad = data.data.autoridad_ambiental ? await TrasladoServices.apiAutoridadAmbiental.get(data.data.autoridad_ambiental, token) : '';
          const entidad = data.data.entidad_disposicion ? await TrasladoServices.apiEntidadDisposicion.get(data.data.entidad_disposicion, token) : '';
          const autoridadNombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const entidadNombre = (typeof entidad === 'object' && typeof entidad.data === 'object') ? entidad.data.nombre : '';
          const entidadAutoridad = (typeof entidad === 'object' && typeof entidad.data === 'object') ? entidad.data.autoridad_ambiental.nombre : '';
          return {
            _id: data.data.autoridad_ambiental,
            nombre: autoridadNombre || entidadAutoridad,
            entidad: entidadNombre,
            tipo: (autoridadNombre && 'autoridad') || (entidadAutoridad && 'entidad')
          };
        };

        const newData = await anAsyncFunction();
        dispatch(getSolicitanteSuccess(newData));
      }
    } catch (error) {
      dispatch(getSolicitanteError(error));
    }
  };
};

export const searchEntidadDisposicion = (search, type, token) => {
  return async dispatch => {
    dispatch(trasladoEntidadDestinoInit());
    try {
      const data = await TrasladoServices.apiEntidadDisposicion.search(search, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        dispatch(trasladoEntidadDestinoSuccess(data.data, type));
        return { msg: '', err: false };
      }
      if(typeof data === 'string') {
        dispatch(trasladoEntidadDestinoError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
    } catch (error) {
      dispatch(trasladoEntidadDestinoError(error));
      return { msg: "no se puedo cargar la información de las entidades asociadas", err: true };
    }
  };
};

export const searchTraslados = (values, from, to, pageSize, token) => {
  return async dispatch => {
    dispatch(searchInit());
    try {
      const data = await TrasladoServices.apiTraslados.search(values, from, to, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        // const anAsyncFunction = traslado => {
        //   // const autoridad = entidad.autoridad_ambiental ? await TrasladoServices.apiAutoridadAmbiental.get(entidad.autoridad_ambiental, token) : '';
        //   // const tipoData = await TrasladoServices.apiUser.getByDomainIdsTypes(traslado.tipo._id, domainTypesByKey.tiposDisposicion, token);
        //   // const estadoData = await TrasladoServices.apiUser.getByDomainIdsTypes(traslado.estado._id, domainTypesByKey.tiposEstadoTraslado, token);
        //   // const tipoNombre = (typeof tipoData === 'object' && Array.isArray(tipoData.data) && tipoData.data.length > 0) ? tipoData.data[0].domainName : '';
        //   // const estadoNombre = (typeof estadoData === 'object' && Array.isArray(estadoData.data) && estadoData.data.length > 0) ? estadoData.data[0].domainName : '';

        //   return {
        //     ...traslado,
        //     // tipo: { _id: traslado.tipo._id, domainName: tipoNombre },
        //     // estado: { _id: traslado.estado._id, domainName: estadoNombre },
        //   };
        // };

        // const getData = async () => {
        //   return Promise.all(data.data.map(traslado => anAsyncFunction(traslado)))
        // };

        // await getData().then(newData => {
        //   const total = (data.metadata && data.metadata.total) || 10;
        //   const pageCount = total / pageSize;
        //   dispatch(searchSuccess(newData, pageCount));
        // });
        const countValues = Object.keys(values).length;
        const total = ((data.metadata && countValues === 0) && data.metadata.total) || data.data.length;
        const pageCount = Math.round(total / to) || Math.ceil(total / to);
        dispatch(searchSuccess(data.data, pageCount));
      } else if(typeof data === 'string') {
        dispatch(searchError(data));
      }
    } catch (error) {
      dispatch(searchError(error));
    }
  };
};

export const trasladoCancelarRequest = (id, token) => {
  return async dispatch => {
    dispatch(trasladoCancelarInit());
    try {
      const data = await TrasladoServices.apiTraslados.cancelar(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = () => {
          return {
            ...data.data
          };
        };

        const newData = anAsyncFunction();
        dispatch(trasladoCancelarSuccess(newData));
        return { msg: data.message, err: true };
      }
      if(typeof data === 'string') {
        dispatch(trasladoCancelarError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
    } catch (error) {
      dispatch(trasladoCancelarError(error));
      return { msg: "no se puedo cancelar el traslado", err: true };
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(clearSelectedInit());
  };
};

export const trasladoContactAdd = contact => {
  return async dispatch => {
    try {
      dispatch(trasladoContactAddSuccess(contact));
    } catch (error) {
      dispatch(trasladoContactAddError(error));
    }
  };
};

export const trasladoContactDelete = id => {
  return async dispatch => {
    try {
      dispatch(trasladoContactDeleteSuccess(id));
    } catch (error) {
      dispatch(trasladoContactDeleteError(error));
    }
  };
};

export const clearSelectedFile = () => {
  return async dispatch => {
    dispatch(clearSelectedFileInit());
  };
};

export const trasladoEspecimenSelected = (id, mode) => {
  return async dispatch => {
    try {
      dispatch(trasladoEspecimenSelectedSuccess(id, mode));
    } catch (error) {
      dispatch(trasladoEspecimenSelectedError('Se genero un error al obtener el espécimen por favor consulte al administrador!'));
    }
  };
};

export const trasladoEspecimenAdd = (selectedRowIds, selectedFlatRows, traslado) => {
  return async dispatch => {
    try {
      if (Object.keys(selectedRowIds).length > 0) {
        dispatch(trasladoEspecimenAddSuccess(selectedRowIds, selectedFlatRows, traslado));
      } else {
        dispatch(trasladoEspecimenAddError('Se genero un error no hay especímenes seleccionados'));
      }
    } catch (error) {
      dispatch(trasladoEspecimenAddError(error));
    }
  };
};

export const trasladoEspecimenDelete = (selectedRowIds, selectedFlatRows) => {
  return async dispatch => {
    try {
      if (Object.keys(selectedRowIds).length > 0) {
        dispatch(trasladoEspecimenDeleteSuccess(selectedRowIds, selectedFlatRows));
      } else {
        dispatch(trasladoEspecimenDeleteError('Se genero un error no hay especímenes seleccionados'));
      }
    } catch (error) {
      dispatch(trasladoEspecimenDeleteError(error));
    }
  };
};

export const trasladoRespuestaRequest = (id, respuesta, token) => {
  return async dispatch => {
    try {
      const data = await TrasladoServices.apiTraslados.aprobar(id, respuesta, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(trasladoRespuestaSuccess(data.data));
        return { msg: data.message, err: false };
      }
      if(typeof data === 'string') {
        dispatch(trasladoRespuestaError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
    } catch (error) {
      dispatch(trasladoRespuestaError(error));
      return { msg: "Se genero un error al responder el traslado por favor consulte al administrador!", err: true };
    }
  };
};

export const cleanContactTraslado = () => {
	return (dispatch) => {
		try {
      dispatch(cleanContactTrasladoSuccess());
		} catch (error) {
			dispatch(cleanContactTrasladoError('Se genero un error al limpiar los contactos del traslado por favor consulte al administrador!'));
		}
	};
};

// Dispatchers sobre soportes
export const trasladoSoportesAdd = file => {
  return async (dispatch) => {
    try {
      dispatch(trasladoSoportesAddSuccess(file));
    } catch (error) {
      dispatch(trasladoSoportesAddError(error));
    }
  };
};

export const trasladoSoportesUpdate = file => {
  return async (dispatch) => {
    try {
      dispatch(trasladoSoportesUpdateSuccess(file));
    } catch (error) {
      dispatch(trasladoSoportesUpdateError(error));
    }
  };
};

export const trasladoSoportesDelete = (selectedRowIds, selectedFlatRows) => {
  return async (dispatch) => {
    try {
      dispatch(trasladoSoportesDeleteSuccess(selectedRowIds, selectedFlatRows));
    } catch (error) {
      dispatch(trasladoSoportesDeleteError(error));
    }
  };
};

export const getByIdSoporteRequest = (id, mode) => {
  return async (dispatch) => {
		try {
      dispatch(selectedFileSuccess(id, mode));
		} catch (error) {
			dispatch(selectedFileError('Se genero un error al cargar el archivo seleccionado por favor consulte al administrador!'));
		}
	};
};

export const trasladoSoportesDownload = (file, token) => {
  return async (dispatch) => {
    dispatch(trasladoSoportesDownloadInit());
		try {
      const fileBlob = await TrasladoServices.apiArchivos.downloadFileToTraslado(file._id, token);
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${file.nombre}`;
      // a.target = '_blank'; // Optional config
      a.click();
      dispatch(trasladoSoportesDownloadSuccess());
      a.remove();
      return true;
    } catch (error) {
      dispatch(trasladoSoportesDownloadError('Se genero un error al descargar el archivo seleccionado por favor consulte al administrador!'));
      return false;
		}
	};
};

export const searchEspecimenesSinTraslado = (values, from, to, token) => {
  return async dispatch => {
    dispatch(searchEspecimenesSinTrasladoInit());
    try {
      const data = await TrasladoServices.apiTraslados.sintraslado(values, from, to, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async especimen => {
          const entidadDisposicionInicial = await TrasladoServices.apiEntidadDisposicion.get(especimen.entidad_disposicion_inicial, token);
          const estadoPosesionData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.estado_posesion_expediente._id, domainTypesByKey.tiposEstadoEspecimenExpediente, token);
          const unidadMedidanData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.unidad_medida._id, domainTypesByKey.unidadesMedida, token);
          const taxonomiaData = await TrasladoServices.apiExpedientes.taxonGet(especimen.taxonomia._id, token);
          const clasificacionTaxonomiaData = await TrasladoServices.apiUser.getByConstantIdsTypes('', constantTypesByKey.clasificacionTaxonomia, token);
          const nombreDisposicionInicial = (typeof entidadDisposicionInicial === 'object' && typeof entidadDisposicionInicial.data === 'object') ? entidadDisposicionInicial.data.nombre : '';
          const estadoPosesionNombre = (typeof estadoPosesionData === 'object' && Array.isArray(estadoPosesionData.data) && estadoPosesionData.data.length > 0) ? estadoPosesionData.data[0].domainName : '';
          const unidadMedidaNombre = (typeof unidadMedidanData === 'object' && Array.isArray(unidadMedidanData.data) && unidadMedidanData.data.length > 0) ? unidadMedidanData.data[0].domainName : '';
          const taxonomiaGrupoBio = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].PIFS_grupoBio : '';
          const taxonomiaTaxonRank = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].taxonRank : '';
          const categoriaId = (taxonomiaTaxonRank && typeof clasificacionTaxonomiaData === 'object' && Array.isArray(clasificacionTaxonomiaData.data) && clasificacionTaxonomiaData.data.length > 0) ? clasificacionTaxonomiaData.data.filter(({ constantName }) => constantName === taxonomiaTaxonRank)[0]._id : '';

          return {
            ...especimen,
            entidad_disposicion_inicial: { _id: especimen.entidad_disposicion_inicial, nombre: nombreDisposicionInicial },
            expediente: { _id: especimen.expediente[0]._id, acta: especimen.expediente[0].acta, codigo_expediente: (especimen.expediente[0].codigo_expediente || '---') },
            traslado: null, // { _id: especimen.traslado[0]._id, codigo: especimen.traslado[0].codigo },
            estado_posesion_expediente: { _id: especimen.estado_posesion_expediente._id, domainName: estadoPosesionNombre },
            unidad_medida: { _id: especimen.unidad_medida._id, domainName: unidadMedidaNombre },
            categoria_taxonomica: { _id: categoriaId, domainName: taxonomiaTaxonRank },
            taxonomia: { _id: especimen.taxonomia._id, canonicalName: especimen.taxonomia.canonicalName, PIFS_grupoBio: taxonomiaGrupoBio },
            marcador_genetico: especimen.marcador_genetico ? especimen.marcador_genetico : '',
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(especimen => anAsyncFunction(especimen)))
        };

        await getData().then(newData => {
          const countValues = Object.keys(values).length;
          const total = ((data.metadata && (countValues === 0 || countValues === 3)) && data.metadata.total) || data.data.length;
          const pageCount = Math.round(total / to) || Math.ceil(total / to);
          dispatch(searchEspecimenesSinTrasladoSuccess(newData, pageCount));
        });
      } else if(typeof data === 'string') {
        dispatch(searchEspecimenesSinTrasladoError(data));
      }
    } catch (error) {
      dispatch(searchEspecimenesSinTrasladoError('Se genero un error al consultar los especímenes sin traslado por favor consulte al administrador!'));
    }
  };
};

export const searchEspecimenesConTraslado = (values, from, to, token) => {
  return async dispatch => {
    dispatch(searchEspecimenesConTrasladoInit());
    try {
      const data = await TrasladoServices.apiTraslados.contraslado(values, from, to, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async especimen => {
          const entidadDisposicionInicial = await TrasladoServices.apiEntidadDisposicion.get(especimen.especimen[0].entidad_disposicion_inicial, token);
          const estadoPosesionData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.estado_posesion_traslado._id, domainTypesByKey.tiposEstadoEspecimenTraslado, token);
          const unidadMedidanData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.especimen[0].unidad_medida._id, domainTypesByKey.unidadesMedida, token);
          const taxonomiaData = await TrasladoServices.apiExpedientes.taxonGet(especimen.especimen[0].taxonomia._id, token);
          const clasificacionTaxonomiaData = await TrasladoServices.apiUser.getByConstantIdsTypes('', constantTypesByKey.clasificacionTaxonomia, token);
          const nombreDisposicionInicial = (typeof entidadDisposicionInicial === 'object' && typeof entidadDisposicionInicial.data === 'object') ? entidadDisposicionInicial.data.nombre : '';
          const estadoPosesionNombre = (typeof estadoPosesionData === 'object' && Array.isArray(estadoPosesionData.data) && estadoPosesionData.data.length > 0) ? estadoPosesionData.data[0].domainName : '';
          const unidadMedidaNombre = (typeof unidadMedidanData === 'object' && Array.isArray(unidadMedidanData.data) && unidadMedidanData.data.length > 0) ? unidadMedidanData.data[0].domainName : '';
          const taxonomiaGrupoBio = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].PIFS_grupoBio : '';
          const taxonomiaTaxonRank = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].taxonRank : '';
          const categoriaId = (taxonomiaTaxonRank && typeof clasificacionTaxonomiaData === 'object' && Array.isArray(clasificacionTaxonomiaData.data) && clasificacionTaxonomiaData.data.length > 0) ? clasificacionTaxonomiaData.data.filter(({ constantName }) => constantName === taxonomiaTaxonRank)[0]._id : '';

          return {
            ...especimen.especimen[0],
            entidad_disposicion_inicial: { _id: especimen.especimen[0].entidad_disposicion_inicial, nombre: nombreDisposicionInicial },
            expediente: { _id: especimen.expediente[0]._id, acta: especimen.expediente[0].acta, codigo_expediente: (especimen.expediente[0].codigo_expediente || '---') },
            traslado: { _id: especimen.traslado[0]._id, codigo: especimen.traslado[0].codigo },
            estado_posesion_expediente: { _id: especimen.estado_posesion_traslado._id, domainName: estadoPosesionNombre },
            unidad_medida: { _id: especimen.especimen[0].unidad_medida._id, domainName: unidadMedidaNombre },
            categoria_taxonomica: { _id: categoriaId, domainName: taxonomiaTaxonRank },
            taxonomia: { _id: especimen.especimen[0].taxonomia._id, canonicalName: especimen.especimen[0].taxonomia.canonicalName, PIFS_grupoBio: taxonomiaGrupoBio },
            marcador_genetico: especimen.especimen[0].marcador_genetico ? especimen.especimen[0].marcador_genetico : '',
            // clase_recurso: { _id: especimen.especimen[0].clase_recurso._id, domainName: especimen.especimen[0].clase_recurso.domainName },
            // estado: { _id: especimen.especimen[0].estado._id, domainName: especimen.especimen[0].estado.domainName },
            // parte_producto: { _id: especimen.especimen[0].parte_producto._id, domainName: especimen.especimen[0].parte_producto.domainName },
            // sexo: { _id: especimen.especimen[0].sexo._id, domainName: especimen.especimen[0].sexo.domainName },
            // condicion: { _id: especimen.especimen[0].condicion._id, domainName: especimen.especimen[0].condicion.domainName },
            // estado_desarrollo: { _id: especimen.especimen[0].estado_desarrollo._id, domainName: especimen.especimen[0].estado_desarrollo.domainName },
            // pais_procedencia_declarado: { _id: especimen.especimen[0].pais_procedencia_declarado._id, domainName: especimen.especimen[0].pais_procedencia_declarado.constantName },
            // departamento_procedencia_declarado: { _id: especimen.especimen[0].departamento_procedencia_declarado._id, domainName: especimen.especimen[0].departamento_procedencia_declarado.constantName },
            // municipio_procedencia_declarado: { _id: especimen.especimen[0].municipio_procedencia_declarado._id, domainName: especimen.especimen[0].municipio_procedencia_declarado.constantName },
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(especimen => anAsyncFunction(especimen)))
        };

        await getData().then(newData => {
          const countValues = Object.keys(values).length;
          const total = ((data.metadata && (countValues === 0 || countValues === 2)) && data.metadata.total) || data.data.length;
          const pageCount = Math.round(total / to) || Math.ceil(total / to);
          dispatch(searchEspecimenesConTrasladoSuccess(newData, pageCount));
        });
      } else if(typeof data === 'string') {
        dispatch(searchEspecimenesConTrasladoError(data));
      }
    } catch (error) {
      dispatch(searchEspecimenesConTrasladoError('Se genero un error al consultar los especímenes con traslado por favor consulte al administrador!'));
    }
  };
};

export const loadSelected = (id, mode, token) => {
  return async dispatch => {
    dispatch(loadSelectedInit());
    try {
      const data = await TrasladoServices.apiTraslados.get(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const funcionario   = await TrasladoServices.apiContacts.get(data.data.funcionario_responsable, token);
          if(typeof funcionario === 'object' && typeof funcionario.data === 'object') {
            const autoridad     = await TrasladoServices.apiAutoridadAmbiental.get(funcionario.data.autoridad_ambiental, token);
            let idAutoridad     = '';
            let nombreAutoridad = '';
            let tipoAutoridad   = '';

            if(typeof autoridad === 'object' && typeof autoridad.data === 'object') {
              const autoridadTipo = await TrasladoServices.apiUser.getByDomainIdsTypes(autoridad.data.tipo._id, domainTypesByKey.tiposAutoridadAmbiental, token);
              const autotidadTipoNombre = (typeof autoridadTipo === 'object' && autoridadTipo.data.length === 1) ? autoridadTipo.data[0].domainName : '';
              idAutoridad = autoridad.data._id;
              nombreAutoridad = autoridad.data.nombre;
              tipoAutoridad = autotidadTipoNombre;
            }

            return {
              ...funcionario.data,
              _id: funcionario.data._id ? funcionario.data._id : '',
              nombre: funcionario.data.nombre ? funcionario.data.nombre : '',
              autoridad_ambiental: { _id: idAutoridad, nombre: nombreAutoridad, tipo: tipoAutoridad},
              numero_documento: funcionario.data.numero_documento ? funcionario.data.numero_documento : '',
            };
          }

          return {
            _id: '',
            nombre: '',
            autoridad_ambiental: { _id: '', nombre: '', tipo: ''},
            numero_documento: '',
          };
        };

        const newFuncionario = await anAsyncFunction();

        const anAsyncFunctionData = async () => {
          const estadoData = await TrasladoServices.apiUser.getByDomainIdsTypes(data.data.estado._id, domainTypesByKey.tiposEstadoTraslado, token);
          const tipoData = await TrasladoServices.apiUser.getByDomainIdsTypes(data.data.tipo._id, domainTypesByKey.tiposDisposicion, token);

          const estadoNombre = (typeof estadoData === 'object' && Array.isArray(estadoData.data) && (estadoData.data.length > 0)) ? estadoData.data[0].domainName : '';
          const tipoDispoNombre = (typeof tipoData === 'object' && Array.isArray(tipoData.data) && (tipoData.data.length > 0)) ? tipoData.data[0].domainName : '';
          // const motivoCierreNombre = (typeof motivoCierreData === 'object' && Array.isArray(motivoCierreData.data) && (motivoCierreData.data.length > 0)) ? motivoCierreData.data[0].domainName : '';

          let respuestaNombre = '';
          if(data.data.respuesta) {
            const respuestaData = await TrasladoServices.apiUser.getByDomainIdsTypes(data.data.respuesta, domainTypesByKey.tiposRespuestaTraslado, token);
            respuestaNombre = (typeof respuestaData === 'object' && Array.isArray(respuestaData.data) && (respuestaData.data.length > 0)) ? respuestaData.data[0].domainName : '';
          }

          let archivos = [];
          if(data.data.archivos_adjuntos.length > 0) {
            const anAsyncFunctionFile = async archivo => {
              console.log('archivo', archivo);
              const archivoInfo = await TrasladoServices.apiArchivos.getFileInfoToTraslado(archivo._id, token);
              const detalles = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoInfo.data.length > 0)) ? (archivoInfo.data[0].detalle || '') : '';
              // const tipoInfo = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoInfo.data.length > 0)) ? (archivoInfo.data[0].tipo || '') : '';
              const archivoTipo = await TrasladoServices.apiUser.getByDomainIdsTypes(archivo.tipo, domainTypesByKey.tiposAdjunto, token);
              const tipoNombre = (typeof archivoTipo === 'object' && Array.isArray(archivoTipo.data) && (archivoTipo.data.length > 0)) ? archivoTipo.data[0].domainName : '';
              // eslint-disable-next-line camelcase
              const usuario_firma = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoInfo.data.length > 0)) ? (archivoInfo.data[0].usuario_firma || '') : '';
              const cargo = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoInfo.data.length > 0)) ? (archivoInfo.data[0].cargo || '') : '';
              console.log('archivoInfo', archivoInfo);

              return {
                ...archivo,
                tipo: { _id: Number(archivo.tipo), domainName: tipoNombre },
                detalles,
                usuario_firma,
                cargo,
                nuevo: false,
                nombre_archivo: `${archivo.nombre}.${archivo.archivo.split('.')[1]}`,
              };
            };

            const getDataFiles = async () => {
              console.log('data.data.archivos_adjuntos', data.data.archivos_adjuntos);
              return Promise.all(data.data.archivos_adjuntos.map(archivo => anAsyncFunctionFile(archivo)))
            }

            archivos = await getDataFiles().then(newDataFile => {
              return newDataFile;
            });
          }

          return {
            ...data.data,
            // motivo_cierre: data.data.motivo_cierre ? { _id: data.data.motivo_cierre._id ? data.data.motivo_cierre._id : '', domainName: motivoCierreNombre } : '',
            respuesta: (data.data.respuesta && Number(data.data.respuesta) !== 0) ? { _id: data.data.respuesta, domainName: respuestaNombre } : '',
            estado: { _id: data.data.estado._id, domainName: estadoNombre },
            tipo: { _id: data.data.tipo._id, domainName: tipoDispoNombre },
            funcionarios: [{...newFuncionario}],
            files: archivos,
          };
        };

        const newObj = await anAsyncFunctionData();
        dispatch(loadSelectedSuccess(newObj, mode));
      }

      if(typeof data === 'string') {
        dispatch(loadSelectedFailed(data));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};

export const getEspecimenesTrasladoRequest = (values, from, to, token) => {
	return async (dispatch) => {
    dispatch(trasladoEspecimenesInit());
		try {
      const data = await TrasladoServices.apiTraslados.contraslado(values, from, to, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async especimen => {
          const entidadDisposicionInicial = await TrasladoServices.apiEntidadDisposicion.get(especimen.especimen[0].entidad_disposicion_inicial, token);
          const estadoPosesionData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.estado_posesion_traslado._id, domainTypesByKey.tiposEstadoEspecimenTraslado, token);
          const unidadMedidanData = await TrasladoServices.apiUser.getByDomainIdsTypes(especimen.especimen[0].unidad_medida._id, domainTypesByKey.unidadesMedida, token);
          const taxonomiaData = await TrasladoServices.apiExpedientes.taxonGet(especimen.especimen[0].taxonomia._id, token);
          const clasificacionTaxonomiaData = await TrasladoServices.apiUser.getByConstantIdsTypes('', constantTypesByKey.clasificacionTaxonomia, token);
          const nombreDisposicionInicial = (typeof entidadDisposicionInicial === 'object' && typeof entidadDisposicionInicial.data === 'object') ? entidadDisposicionInicial.data.nombre : '';
          const estadoPosesionNombre = (typeof estadoPosesionData === 'object' && Array.isArray(estadoPosesionData.data) && estadoPosesionData.data.length > 0) ? estadoPosesionData.data[0].domainName : '';
          const unidadMedidaNombre = (typeof unidadMedidanData === 'object' && Array.isArray(unidadMedidanData.data) && unidadMedidanData.data.length > 0) ? unidadMedidanData.data[0].domainName : '';
          const taxonomiaGrupoBio = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].PIFS_grupoBio : '';
          const taxonomiaTaxonRank = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].taxonRank : '';
          const categoriaId = (taxonomiaTaxonRank && typeof clasificacionTaxonomiaData === 'object' && Array.isArray(clasificacionTaxonomiaData.data) && clasificacionTaxonomiaData.data.length > 0) ? clasificacionTaxonomiaData.data.filter(({ constantName }) => constantName === taxonomiaTaxonRank)[0]._id : '';

          return {
            ...especimen.especimen[0],
            entidad_disposicion_inicial: { _id: especimen.especimen[0].entidad_disposicion_inicial, nombre: nombreDisposicionInicial },
            expediente: { _id: especimen.expediente[0]._id, acta: especimen.expediente[0].acta, codigo_expediente: (especimen.expediente[0].codigo_expediente || '---') },
            traslado: { _id: especimen.traslado[0]._id, codigo: especimen.traslado[0].codigo },
            estado_posesion_expediente: { _id: especimen.estado_posesion_traslado._id, domainName: especimen.estado_posesion_traslado.domainName },
            unidad_medida: { _id: especimen.especimen[0].unidad_medida._id, domainName: unidadMedidaNombre },
            categoria_taxonomica: { _id: categoriaId, domainName: taxonomiaTaxonRank },
            taxonomia: { _id: especimen.especimen[0].taxonomia._id, canonicalName: especimen.especimen[0].taxonomia.canonicalName, PIFS_grupoBio: taxonomiaGrupoBio },
            marcador_genetico: especimen.especimen[0].marcador_genetico ? especimen.especimen[0].marcador_genetico : '',
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(especimen => anAsyncFunction(especimen)))
        };

        await getData().then(newData => {
          const countValues = Object.keys(values).length;
          const total = ((data.metadata && (countValues === 0 || countValues === 1)) && data.metadata.total) || data.data.length;
          const pageCount = Math.round(total / to) || Math.ceil(total / to);
          dispatch(trasladoEspecimenesSuccess(newData, pageCount));
        });
      } else if(typeof data === 'string') {
        dispatch(trasladoEspecimenesError(data));
      }
		} catch (error) {
			dispatch(trasladoEspecimenesError(`Se genero un error al buscar los especimenes por favor consulte al administrador!: ${error.toString()}`));
		}
	};
};
