/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useForm from 'react-hook-form';
import Rodal from 'rodal';
/* Hooks */
import {
  useAutoridadAmbiental,
  useDomains,
  useLogin,
  useContact,
  useTraslado
} from '../../hooks';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import PaginationTable from '../Table/PaginationTable';
import ButtonsActionRow from '../Table/ButtonsActionRow';
import { CardTitleBox } from '../CardTitleBox';
import { Col, Col1, Col2, Col3, Col4, GridContainer, Row } from '../Grid';
import { H5 } from '../H5';
import { Input } from '../form-ui-components/Input';
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Select } from '../form-ui-components/Select';
import { SelectOptions } from '../form-ui-components/SelectOptions';
import { Button } from '../form-ui-components/Button';
// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
/* Style Components */
import { ModalContainerBody, CardContainer } from './styled';
import { CardContainerBody, SpanDivide } from '../../pages/styled';
/* Utils */
import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

const ModalContactsTraslado = ({ history, onClick, modalContacts, animation }) => {
  const [ loaded, setLoaded ] = useState(false);
  const { register, handleSubmit, reset } = useForm({});
  const { token } = useLogin();
  const { domainsByKey } = useDomains();
  const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();
  const {
    isLoading,
    error,
    data: contacts,
    getAllContactsSearch
  } = useContact();
  const {
    contacts: contactsExpediente,
    trasladoContactAdd,
    cleanContactTraslado
  } = useTraslado();

  const arrEstados = [{ _id: true, nombre: 'Activo' }, { _id: false, nombre: 'Inactivo' }];

  const handleAddContact = e => {
    e.preventDefault();

    confirmAlert({
      title: 'IR AL FORMULARIO DE AGREGAR CONTACTO',
      message: `¿Esta seguro que desea ir al formulario de crear el contacto?`,
      buttons: [
        {
          label: "IR A AGREGAR CONTACTO",
          onClick: async () => {
            await cleanContactTraslado();
            history.push('/contacts-form');
          }
        },
        {
          label: "VOLVER AL LISTADO DE CONTACTOS",
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
    document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
  };

  const handleClearSearchFields = async e => {
    e.preventDefault();
    reset();
    await getAllContactsSearch({ estado: true }, token);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data.estado = data.estado ? (String(true) === data.estado) : true;
      getAllContactsSearch(removeEmpty(data), token)
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const load = useCallback(async () => {
    await Promise.all([getAllContactsSearch({ estado: true }, token), searchAutoridadAmbiental({}, token)]);
  }, [
    getAllContactsSearch,
    searchAutoridadAmbiental
  ]);

  const columns = [
    {
      Header: 'Autoridad Ambiental',
      accessor: d => {
        return ((d.autoridad_ambiental) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ')) ? `${d.autoridad_ambiental.nombre}` : '---';
      }
    },
    {
      Header: 'Tipo de entidad',
      accessor: d => {
        return ((d.tipo_entidad_disposicion) && (d.tipo_entidad_disposicion.domainName !== '') && (d.tipo_entidad_disposicion.domainName !== ' ')) ? d.tipo_entidad_disposicion.domainName : '---';
      }
    },
    {
      Header: 'Nombre',
      accessor: d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? d.nombre : '---';
      }
    },
    {
      Header: 'Correo',
      accessor: d => {
        return ((d.correo_electronico) && (d.correo_electronico !== '') && (d.correo_electronico !== ' ')) ? d.correo_electronico : '---';
      }
    },
    {
      Header: 'Estado',
      accessor: d => {
          return ((d.estado !== '') && (d.estado !== ' ') && (d.estado !== undefined) && (d.estado)) ? 'Activo' : 'Inactivo';
      }
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'add_circle',
            title: 'Agregar Contacto',
            handleAction: async () => {
              confirmAlert({
                title: 'AGREGAR CONTACTO AL TRASLADO',
                message: `¿Esta seguro que desea agregar el contacto: ${((original.nombre) && (original.nombre !== undefined)) ? original.nombre : '---'}`,
                buttons: [
                  {
                    label: "AGREGAR CONTACTO",
                    onClick: async () => {
                      await trasladoContactAdd(original);
                      onClick();
                    }
                  },
                  {
                    label: "VOLVER AL LISTADO",
                    onClick: () => {}
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true
              });
              document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
            }
          },
        ];
        const isAddedExpediente = contactsExpediente.filter((item) => item._id === original._id)
        return (isAddedExpediente.length === 1) ? <span style={{ color: 'red' }}>Adicionado</span> : <ButtonsActionRow buttons={buttons} />;
      }
    }
  ];

  useEffect(() => {
    if (!loaded) {
      load();
      setLoaded(true);
    }
  }, [loaded, load, contacts]);

  return ReactDOM.createPortal(
    <Rodal visible={modalContacts}
      onClose={onClick}
      animation={animation}
      customStyles={customStyles}
      className="modal-rodal-container"
      width={80}
      height={90}
      measure="%"
      closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <br />Asociar contacto responsable del traslado.
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    NOMBRE
                    <Input
                      style={{ width: '100%' }}
                      name="nombre"
                      type="text"
                      placeholder="Ingrese el nombre"
                      ref={register}
                    />
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    ESTADO
                    <Select style={{ width: '100%' }} name="estado" ref={register}>
                      <SelectOptions selectOptions={arrEstados} />
                    </Select>
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    CORREO
                    <Input
                      style={{ width: '100%' }}
                      name="correo_electronico"
                      type="text"
                      placeholder="Ingrese el correo"
                      ref={register}
                    />
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col4>
                  <Label>
                    AUTORIDAD AMBIENTAL
                    <Select style={{ width: '100%' }} name="autoridad_ambiental" ref={register}>
                      <SelectOptions selectOptions={autoridadesAmbientales} />
                    </Select>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    TIPO DE ENTIDAD
                    <Select style={{ width: '100%' }} name="tipo_entidad_disposicion" ref={register}>
                      <SelectOptions selectOptions={domainsByKey.tiposEntidadDisposicion} />
                    </Select>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                      LIMPIAR FORMULARIO
                    </Button>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    <Button type="submit">
                      BUSCAR
                    </Button>
                  </Label>
                </Col4>
              </Row>
            </GridContainer>
          </Form>
          <GridContainer>
            <Row>
              <Col1>
                {isLoading ? (
                  <div style={{justifyContent: 'flex-end', width: '100%'}}>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                ) : (
                  <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                    <CardContainerBody>
                      <div className="card-body-row">
                        <PaginationTable columns={columns} data={contacts}/>
                      </div>
                    </CardContainerBody>
                    {error && (
                      <CardContainerBody>
                        <CardTitleBox className="error" error>
                          <H5>Ups, hubo un error: {error}</H5>
                        </CardTitleBox>
                      </CardContainerBody>
                    )}
                  </div>
                )}
              </Col1>
            </Row>
          </GridContainer>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
};

ModalContactsTraslado.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  modalContacts: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalContactsTraslado.propTypes = {
  history: {},
  modalContacts: false,
  animation: '',
  onClick: () => {},
};

export default withRouter(ModalContactsTraslado);
