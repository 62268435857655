import React from "react";
import styled from "styled-components";
import LogoMADS from "../assets/logos/logo-mads.png";
import LogoSib from "../assets/logos/logo-sib.png";
import LogoSIAC from "../assets/logos/logo-siac.png";

import {pxToRem} from "../utils";
import {GridContainer, Row} from "./Grid";

const StyledImage = styled.img`
  height: ${props => props.height};
  width: auto;
  margin: 0 ${pxToRem(15)} 0;
  margin-top: ${props => props.marginTop};
`;

const StyledFooter = styled.footer`
  display: flex;
  padding: ${pxToRem(8)};
  background-color: ${props => props.theme.footerBackground};
  z-index: 1;
  @media (min-width: 600px) {
    flex-direction: row;
    padding: ${pxToRem(40)} 10%;
    justify-content: center;
  }
  p {
    font-size: .8em;
  }
`;

const VersionBar = styled.div`
  background-color: ${props => props.theme.black};
  color: ${props => props.theme.white};
  text-align: center;
  width: 100vw;
  padding: ${pxToRem(5)};
  a {
    color: white;
    font-size: ${pxToRem(10)}; 
    margin: 0;
  }
  
`;


const FooterText = styled.p`
  text-align: center;
  color: white;
  font-size: ${pxToRem(14)}; 
`;

const Footer = () => (
  <>
    <StyledFooter>
      <GridContainer>
        <Row center>
          <FooterText>Con el apoyo de:</FooterText>
        </Row>
        <Row center>
          <StyledImage src={LogoSIAC} height={"70px"} marginTop={"10px"}/>
          <StyledImage src={LogoSib} height={"50px"} marginTop={"25px"}/>
        </Row>
      </GridContainer>
    </StyledFooter>
    <VersionBar>
      <a href="https://gitlab.com/sib-colombia/pifs-web/-/blob/master/CHANGELOG.md" target="_blank">Versión
        RC-0.2.1</a>
    </VersionBar>
  </>
);
export default Footer;
