import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Rodal from 'rodal';
/* Hooks */
import {
  useTraslado
} from '../../hooks';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import { CardTitleBox } from '../CardTitleBox';
import { Col1, Col2, Col3, GridContainer, Row } from '../Grid';
import { H5 } from '../H5';
import { Label } from '../form-ui-components/Label';
import { Icon } from '../Icon';
// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
/* Style Components */
import { ContainerHeader, ModalContainerBody } from './styled';
import { SpanDivide, SpanView } from '../../pages/styled';

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

const ModalEspecimenTraslado = ({ onClick, modalEspecimen, animation }) => {
  const { selectedEspecimen, modeEspecimen } = useTraslado();

  return ReactDOM.createPortal(
    <Rodal visible={modalEspecimen}
      onClose={onClick}
      animation={animation}
      customStyles={customStyles}
      className="modal-rodal-container"
      width={80}
      height={90}
      measure="%"
      closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <GridContainer>
            <Row>
              <Col1>
                <SpanDivide>
                  <ContainerHeader>
                    <div className="header-col">
                      <H5>Información del espécimen.</H5>
                    </div>
                    <div className="header-col">
                      <span className="text-info">NÚMERO DE ACTA</span>
                      <H5>{modeEspecimen === 'view' && (selectedEspecimen.expediente.acta || '---')}</H5>
                    </div>
                    <div className="header-col">
                      <span className="text-info">CÓD. EXPEDIENTE</span>
                      <H5>{modeEspecimen === 'view' && (selectedEspecimen.expediente.codigo_expediente || '---')}</H5>
                    </div>
                  </ContainerHeader>
                </SpanDivide>
              </Col1>
            </Row>
            <Row>
              <Col3>
                <Label>
                  CLASE DE RECURSO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.clase_recurso.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  PARTE, PRODUCTO O SUBPRODUCTO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.parte_producto.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  ESTADO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.estado.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col3>
                <Label>
                  CATEGORÍA TAXONÓMICA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.categoria_taxonomica.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  NOMBRE CIENTÍFICO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.taxonomia.canonicalName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  GRUPO BIOLÓGICO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.taxonomia.PIFS_grupoBio || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col3>
                <Label>
                  NOMBRE COMÚN
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.nombre_comun || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  SEXO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.sexo.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3 />
            </Row>
            <Row>
              <Col3>
                <Label>
                  CONDICIÓN
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.condicion.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  ESTADO DE DESARROLLO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.estado_desarrollo.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  HONGOS O ENFERMEDADES
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{(selectedEspecimen.hongos_enfermedades) ? 'Si.' : 'No'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col3>
                <Label>
                  MARCADOR GENÉTICO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.marcador_genetico || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  UNIDAD DE MEDIDA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.unidad_medida.domainName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  CANTIDAD DE DÍAS EN CAUTIVERIO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.tiempo_cautiverio || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col3>
                <Label>
                  CASTRADO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{(selectedEspecimen.castrado) ? 'Si.' : 'No'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  PREÑADO
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{(selectedEspecimen.prenado) ? 'Si.' : 'No'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  DIETA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.dieta || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col1>
                <Label>
                  DESCRIPCIÓN
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.descripcion || '---'}</SpanView>
                  </>
                )}
              </Col1>
            </Row>
            <Row>
              <Col1>
                <SpanDivide>
                  <br />Información de procedencia del espécimen.
                </SpanDivide>
              </Col1>
            </Row>
            <Row>
              <Col3>
                <Label>
                  PAÍS DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.pais_procedencia_declarado.constantName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  DEPARTAMENTO DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.departamento_procedencia_declarado.constantName || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  CIUDAD O MUNICIPIO DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.municipio_procedencia_declarado.constantName || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col3>
                <Label>
                  VEREDA DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.vereda_procedencia_declarado || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  CORREGIMIENTO DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.corregimiento_procedencia_declarado || '---'}</SpanView>
                  </>
                )}
              </Col3>
              <Col3>
                <Label>
                  BARRIO DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.barrio_procedencia_declarado || '---'}</SpanView>
                  </>
                )}
              </Col3>
            </Row>
            <Row>
              <Col2>
                <Label>
                  PREDIO DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.predio_procedencia_declarado || '---'}</SpanView>
                  </>
                )}
              </Col2>
              <Col2>
                <Label>
                  LUGAR DE PROCEDENCIA
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.lugar_procedencia_declarado || '---'}</SpanView>
                  </>
                )}
              </Col2>
            </Row>
            <Row>
              <Col1>
                <Label>
                  ENTIDAD DE DISPOSICIÓN ACTUAL <span className="obligatory">*</span>
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{(selectedEspecimen.entidad_disposicion_inicial) ? selectedEspecimen.entidad_disposicion_inicial.nombre : '---'}</SpanView>
                  </>
                )}
              </Col1>
            </Row>
            <Row>
              <Col1>
                <Label>
                  OBSERVACIONES GENERALES DEL EXPEDIENTE
                </Label>
                {modeEspecimen === 'view' && (
                  <>
                    <br /><SpanView>{selectedEspecimen.observaciones || '---'}</SpanView>
                  </>
                )}
              </Col1>
            </Row>
          </GridContainer>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
};

ModalEspecimenTraslado.propTypes = {
  modalEspecimen: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalEspecimenTraslado.propTypes = {
  modalEspecimen: false,
  animation: '',
  onClick: () => {},
};

export default withRouter(ModalEspecimenTraslado);
