/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback }  from 'react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Rodal from 'rodal';
import { confirmAlert } from 'react-confirm-alert';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import PaginationTableRowSelect from "../Table/PaginationTableRowSelect";
import ButtonsActionRow from "../Table/ButtonsActionRow";
import { CardTitleBox } from "../CardTitleBox";
import { Col, Col1, Col2, Col3, GridContainer, Row } from "../Grid";
import { H5 } from "../H5";
import { Input } from "../form-ui-components/Input";
import { Form } from "../form-ui-components/Form";
import { Label } from "../form-ui-components/Label";
import { Select } from "../form-ui-components/Select";
import { SelectOptions } from "../form-ui-components/SelectOptions";
import { Button } from '../form-ui-components/Button';
import Loader from '../Loader';
import ModalFilesClose from '../ModalFilesClose';
// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

/* Hooks */
import {
  useDomains,
  useExpedientes,
  useLogin,
  useNotification
} from '../../hooks';

/* Style Components */
import { ModalContainerBody, CardContainer, ContainerHeader } from './styled';
import { CardContainerBody, SpanTextInfo, SpanDivide, SpanView } from '../../pages/styled';

/* Defined Constants */
import { wait } from "../../config/const";
import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

dayjs.locale('es');

const ModalClose = ({ history, onClick, modalClose, animation }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ modalFile, setModalFile ] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({});
  const { isLoggedIn, token } = useLogin();
  const {
    filesClose,
    filesAuxClose,
    mode,
    selected,
    isLoadingCerrar,
    especimenesExpediente,
    closeExpedienteRequest,
    getByIdArchivoCloseRequest,
    expedienteCerrarFilesDelete,
    clearSelectedFileClose,
    searchExpedienteRequest,
    getEspecimenesExpedienteRequest
  } = useExpedientes();
  const { createNotification } = useNotification();
  const { domainsByKey } = useDomains();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');

  const handleOpenModalFiles = async () => {
    await clearSelectedFileClose();
    setModalFile(!modalFile);
  };

  const handleCancelar = e => {
    e.preventDefault();
    reset();
    onClick(e);
  };

  const columnsFiles = [
    {
      Header: 'Tipo de documento',
      accessor:  d => {
        return ((d.tipo) && (d.tipo.domainName !== '') && (d.tipo.domainName !== ' ')) ? `${d.tipo.domainName}` : '---';
      }
    },
    {
      Header: 'Nombre del documento',
      accessor:  d => {
        return ((d.archivo) && (d.archivo !== '') && (d.archivo !== ' ')) ? (((d.nombre) && (d.nombre !== '')) ? `${d.nombre}` : `${d.archivo}`) : '---';
      }
    },
    {
      Header: 'Fecha cargue',
      accessor:  d => {
        return ((d.fecha_creacion) && (d.fecha_creacion !== '') && (d.fecha_creacion !== ' ')) ? `${parseDate(d.fecha_creacion)}` : '---';
      }
    },
    {
      Header: 'Usuario',
      accessor:  d => {
        return ((d.usuario_modificacion) && (d.usuario_modificacion !== '') && (d.usuario_modificacion !== ' ')) ? `${d.usuario_modificacion}` : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Archivo',
            visible: true,
            handleAction: async () => {
              await getByIdArchivoCloseRequest(original._id, 'view');
              setModalFile(!modalFile);
            }
          },
          {
            id: original._id,
            iconName: 'edit',
            title: 'Actualizar Archivo',
            visible: original.nuevo || (mode !== 'view'),
            handleAction: async () => {
              await getByIdArchivoCloseRequest(original._id, 'edit');
              setModalFile(!modalFile);
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const handleDeleteFiles = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: 'BORRAR SOPORTES',
      message: `¿Esta seguro de eliminar los ${Object.keys(selectedRowIds).length} archivos del listado?`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            await expedienteCerrarFilesDelete(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
    document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const estanDispoFinal = especimenesExpediente.filter(({ disposicion_final }) => disposicion_final);
      console.log(especimenesExpediente, estanDispoFinal);
      if(especimenesExpediente.length === estanDispoFinal.length) {
        data._id = selected._id;
        data.motivo_cierre = Number(data.motivo_cierre);
        data.fecha_cierre = data.fecha_cierre ? parseDateTime(data.fecha_cierre) : null;
        const { msg, err } = await closeExpedienteRequest(removeEmpty(data), filesClose, token);
        console.log('lo que devolvio', msg, err);
        createNotification({message: msg, visible: true, error: err});
        if(!err) {
          reset();
          onClick(e);
          await searchExpedienteRequest({}, 0, 50, token);
        } else {
          Swal.fire({
            title: 'FALLO AL CERRAR EXPEDIENTE',
            html: `<b>Error:</b> ${msg}`,
            icon: 'error',
            confirmButtonText: 'ACEPTAR'
          })
          // await wait(1000);
          document.querySelector('.swal2-backdrop-show').style.zIndex = 10000;
        }
      } else {
        const diferencia = especimenesExpediente.length - estanDispoFinal.length;
        Swal.fire({
          title: 'FALLO AL CERRAR EXPEDIENTE',
          html: `No se puede cerrar el expediente ya que al menos ${diferencia} espécimen(es) que no están en disposición final`,
          icon: 'info',
          confirmButtonText: 'ACEPTAR'
        })
        // await wait(1000);
        document.querySelector('.swal2-backdrop-show').style.zIndex = 10000;
      }
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const loadExpedienteSelected = useCallback(async () => {
      if(selected) {
        console.log('selected', selected);
        await getEspecimenesExpedienteRequest(({ expediente: selected._id }), 0, 10, token);
      }
  }, [selected]);

  useEffect(() => {
    if ((isLoggedIn && !loaded)) {
      loadExpedienteSelected();
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, loadExpedienteSelected, gotoLogin]);

  useEffect(() => {
    loadExpedienteSelected();
  }, [selected]);

  return ReactDOM.createPortal(
    <Rodal visible={modalClose}
      onClose={onClick}
      animation={animation}
      customStyles={customStyles}
      className="modal-rodal-container"
      width={80}
      height={90}
      measure="%"
      closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <ContainerHeader>
                      <div className="header-col">
                        <H5>{mode === 'view' ? ('Ver Cerrar expediente') : ('Cerrar expediente')}</H5>
                      </div>
                      <div className="header-col">
                        <span className="text-info"> </span>
                        <H5> </H5>
                      </div>
                      <div className="header-col">
                        <span className="text-info">Número de acta</span>
                        <H5>{selected.acta ? selected.acta : '---'}</H5>
                      </div>
                    </ContainerHeader>
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                <Col2>
                  <Label>
                    MOTIVO DE CIERRE <span className="obligatory">*</span>
                    {mode !== 'view' ? (
                      <>
                        <Select name="motivo_cierre" ref={register({required: true})}>
                          <SelectOptions selectOptions={domainsByKey.cierreMotivo} />
                        </Select>
                        {errors.motivo_cierre && errors.motivo_cierre.type === "required" && (
                          <p style={{color: 'red'}}>
                            El motivo de cierre es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br/><SpanView>{selected.motivo_cierre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col2>
                <Col2>
                  <Label>
                    FECHA DE CIERRE <span className="obligatory">*</span>
                    {mode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="fecha_cierre"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register({ required: true })}
                        />
                        {errors.fecha_cierre && errors.fecha_cierre.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La fecha de cierre es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{selected.fecha_cierre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col2>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    OBSERVACIONES GENERALES <span className="obligatory">*</span>
                    {mode !== 'view' ? (
                      <>
                        <Input
                          name="observaciones_cierre"
                          type="text"
                          ref={register({ required: true, minLength: 5, maxLength: 500 })}
                          placeholder="Escribe el nombre de la imagen"
                        />
                        {errors.observaciones_cierre && errors.observaciones_cierre.type === "required" && (
                          <p style={{ color: 'red'}}>
                            Las observaciones de cierre del expediente son requeridas!
                          </p>
                        )}
                        {errors.observaciones_cierre && errors.observaciones_cierre.type === "minLength" && (
                          <p style={{color: 'red'}}>
                            Las observaciones de cierre del expediente le falta caracteres mínimo (5)!
                          </p>
                        )}
                        {errors.observaciones_cierre && errors.observaciones_cierre.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            Las observaciones de cierre del expediente le sobran caracteres máximo (500)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br/><SpanView>{selected.observaciones_cierre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    ADJUNTAR SOPORTES <br />
                    <PaginationTableRowSelect columns={columnsFiles} data={filesAuxClose} onClick={handleDeleteFiles} eraser textEraser="QUITAR" />
                  </Label>
                </Col1>
              </Row>
              <Row style={{justifyContent: 'flex-end'}}>
                <Col right>
                  <Label>
                    <Button type="button" onClick={(e) => handleOpenModalFiles(e)}>
                      ADJUNTAR
                    </Button>
                  </Label>
                </Col>
              </Row>
              <Row style={{justifyContent: 'flex-end'}}>
                <Col right>
                  {isLoadingCerrar ? (
                    <Label style={{justifyContent: 'flex-end', width: '25vw'}}>
                      <Loader message="Cerrando el expediente por favor espere un momento... No recargue la página ya que se puede alterar el proceso de cerrar el expediente"/>
                    </Label>
                  ) : (
                    <>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={handleCancelar}>
                          CANCELAR
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="submit">
                          ACEPTAR
                        </Button>
                      </Label>
                    </>
                  )}
                </Col>
              </Row>
            </GridContainer>
          </Form>
          {modalFile && <ModalFilesClose onClick={(e) => handleOpenModalFiles(e)} modalFile={modalFile} animation={animation} />}
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
};

ModalClose.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  modalClose: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalClose.defaultProps = {
  history: {},
  modalClose: false,
  animation: '',
  onClick: () => {},
};

export default withRouter(ModalClose);
