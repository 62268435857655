/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import { useExpedientes, useLogin } from '../../hooks';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import {Col, Col1, Col2, GridContainer, Row} from "../Grid";
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Input } from '../form-ui-components/Input';
import { Button } from '../form-ui-components/Button';
import { H5 } from "../H5";
import Loader from '../Loader';

/* Style Components */
import { ModalContainerBody } from './styled';
import { SpanDivide, SpanView } from '../../pages/styled';

/* Utils */
import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

dayjs.locale('es');

const ModalImages = ({ onClick, animation, modalImage }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ tmpImage, setTmpImage ] = useState(null);

  const { register, setValue, handleSubmit, errors, reset } = useForm();

  const { userLogin, token } = useLogin();
  const {
    isLoadingImage,
    imageMode,
    imageSelected,
    expedienteImagesAdd,
    expedienteImagesUpdate,
    expedienteImagesDownload
  } = useExpedientes();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const splitDate = timestamp => timestamp.split('T')[0];

  const handleDownloadImage = async () => {
    if(imageSelected) {
      await expedienteImagesDownload(imageSelected, token);
    }
  };

  const handleAttachImages = e => {
    e.preventDefault();
    document.getElementById('imagenes').click();
    e.stopPropagation();
  };

  const handleCancelar = e => {
    e.preventDefault();
    reset();
    onClick(e);
  };

  const handleChangeImages = e => {
    Object.keys(e.target.files).forEach(key => {
      setTmpImage(e.target.files[key]);
      console.log('image', e.target.files[key].name);
      setValue('nombre_archivo', e.target.files[key].name);
    });
  };

  const loadImageSelected = useCallback(() => {
    if(imageSelected) {
      setValue('nombre_archivo', imageSelected.nombre_archivo);
      setValue('fecha_captura', splitDate(imageSelected.fecha_captura));
      setValue('fecha_creacion', splitDate(imageSelected.fecha_creacion));
      setValue('nombre', imageSelected.nombre);
      setValue('detalle', imageSelected.detalle);
      setValue('usuario_modificacion', imageSelected.usuario_modificacion);
      if(typeof imageSelected.archivo !== 'string') {
        setTmpImage(imageSelected.archivo);
      }
    } else {
      setValue('fecha_creacion', getActualDate());
      setValue('usuario_modificacion', userLogin);
    }
  }, [setValue, imageSelected]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data._id            = imageSelected ? imageSelected._id : nanoid();
      data.nuevo          = !imageSelected;
      data.activo         = true;
      data.archivo        = tmpImage;
      data.fecha_creacion = parseDateTime(data.fecha_creacion);
      if(imageSelected) {
        data.nuevo = false;
        data.memory = true;
        await expedienteImagesUpdate(removeEmpty(data));
      } else {
        data.nuevo = true;
        data.memory = true;
        await expedienteImagesAdd(removeEmpty(data));
      }
      reset();
      onClick(e);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if(!loaded) {
      loadImageSelected();
      setLoaded(true);
    }
  }, [loaded, loadImageSelected]);

  useEffect(() => {
    loadImageSelected();
  }, [imageSelected]);

  return ReactDOM.createPortal(
    <Rodal visible={modalImage}
          onClose={onClick}
          animation={animation}
          customStyles={customStyles}
          className="modal-rodal-container"
          width={35}
          height={90}
          measure="%"
          closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <H5>Agregar imagen al expediente.</H5>
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                {imageMode !== 'view' ? (
                  <>
                    <Col2>
                      <Label>
                        SELECCIONE ARCHIVO <span className="obligatory">*</span>
                        <Input
                          style={{ width: '100%' }}
                          name="nombre_archivo"
                          type="text"
                          placeholder="Buscar imagen..."
                          ref={register({ required: true })}
                        />
                        {errors.nombre_archivo && errors.nombre_archivo.type === "required" && (
                          <p style={{ color: 'red'}}>
                            Debe adjuntar un archivo!
                          </p>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                          <Button type="button" onClick={handleAttachImages}>
                            ADJUNTAR
                          </Button>
                        </Label>
                    </Col2>
                  </>
                ) : (
                  <Col1>
                    <Label>
                      {(imageMode === 'view' && imageSelected.nuevo) ? (
                        <>
                          <br /><SpanView>{imageSelected.nombre_archivo || '---'}</SpanView>
                        </>
                      ) : (
                        !isLoadingImage ? (
                          <Button type="button" onClick={handleDownloadImage}>
                            DESCARGAR ARCHIVO
                          </Button>
                        ) : (
                          <Loader message="Descargando imagen por favor espere un momento..."/>
                        )
                      )}
                    </Label>
                  </Col1>
                )}
              </Row>
              <Row>
                <Col1>
                  <Label>
                    NOMBRE DE LA IMAGEN <span className="obligatory">*</span>
                    {imageMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="nombre"
                          type="text"
                          placeholder="Escriba el nombre del documento"
                          ref={register({ required: true })}
                        />
                        {errors.nombre && errors.nombre.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El nombre de la imagen es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{imageSelected.nombre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    DETALLES DE LA IMAGEN
                    {imageMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="detalle"
                          type="text"
                          placeholder="Agregue detalles de la imagen"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{imageSelected.detalle || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FECHA DE CAPTURA DE LA IMAGEN <span className="obligatory">*</span>
                    {imageMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="fecha_captura"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register({ required: true })}
                        />
                        {errors.fecha_captura && errors.fecha_captura.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La fecha de captura es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{splitDate(imageSelected.fecha_captura)}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FECHA DE CARGUE <span className="obligatory">*</span>
                    {imageMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="fecha_creacion"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.fecha_creacion && errors.fecha_creacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La fecha de captura es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{splitDate(imageSelected.fecha_creacion)}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    USUARIO <span className="obligatory">*</span>
                    {imageMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="usuario_modificacion"
                          type="text"
                          placeholder="Coordinador del expediente"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.usuario_modificacion && errors.usuario_modificacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El usuario es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{splitDate(imageSelected.usuario_modificacion)}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              {imageMode !== 'view' && (
                <>
                  <Row>
                    <Col1>
                      <Input
                        style={{ display: 'none' }}
                        name="imagenes"
                        id="imagenes"
                        type="file"
                        onChange={e => handleChangeImages(e) }
                        accept="image/*"
                      />
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={handleCancelar}>
                          CANCELAR
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="submit">
                          GUARDAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </>
              )}
            </GridContainer>
          </Form>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
}

ModalImages.propTypes = {
  modalFile: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalImages.defaultProps = {
  modalFile: false,
  animation: '',
  onClick: () => {},
};

export default ModalImages;
