export const CONTACTS_SEARCH_INIT = '[CONTACT] CONTACT_SEARCH_INIT';
export const CONTACTS_SEARCH_SUCCESS = '[CONTACT] CONTACT_SEARCH_SUCCESS';
export const CONTACTS_SEARCH_ERROR = '[CONTACT] CONTACT_SEARCH_ERROR';
export const CONTACT_CREATE_INIT = '[CONTACT] CONTACT_CREATE_INIT';
export const CONTACT_CREATE_SUCCESS = '[CONTACT] CONTACT_CREATE_SUCCESS';
export const CONTACT_CREATE_ERROR = '[CONTACT] CONTACT_CREATE_ERROR';
export const CONTACT_UPDATE_INIT = '[CONTACT] CONTACT_UPDATE_INIT';
export const CONTACT_UPDATE_SUCCESS = '[CONTACT] CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_ERROR = '[CONTACT] CONTACT_UPDATE_ERROR';
export const CONTACT_DELETE_INIT = '[CONTACT] CONTACT_DELETE_INIT';
export const CONTACT_DELETE_SUCCESS = '[CONTACT] CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_ERROR = '[CONTACT] CONTACT_DELETE_ERROR';
export const CONTACTS_CLEAR_SELECTED = '[CONTACT] CONTACT_CLEAR_SELECTED';
export const CONTACTS_LOAD_SELECTED_INIT = '[CONTACT] CONTACT_LOAD_SELECTED_INIT';
export const CONTACTS_LOAD_SELECTED_SUCCESS = '[CONTACT] CONTACT_LOAD_SELECTED_SUCCESS';
export const CONTACTS_LOAD_SELECTED_ERROR = '[CONTACT] CONTACT_LOAD_SELECTED_ERROR';
export const CONTACTS_CHANGE_MODE_SUCCESS = '[CONTACT] CONTACTS_CHANGE_MODE_SUCCESS';
