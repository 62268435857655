/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../form-ui-components/Input';
/* Style Components */
import { GlobalStyleAutoComplete } from './styled';
import { Icon } from '../Icon';

const Autocomplete = ({ name, placeholder, suggestions, loading, activeSuggestion, showSuggestions, onChange, onKeyDown, onClick, userInput }) => {
  return (
    <>
      <span className="input-item">
        <Input
          id={name}
          name={name}
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        />
        <Icon>search</Icon>
      </span>
      {loading && (
        <>
          <div className="no-suggestions">
            <em>buscando información...</em>
          </div>
        </>
      )}
      {(showSuggestions && userInput) && (
        (suggestions.length) ? (
          <>
            <ul className="suggestions" style={{ textTransform: 'none' }}>
              {suggestions.map(({ _id, canonicalName, family, PIFS_grupoBio: grupoBio }, index) => {
                return (
                  <li
                    className={(index === activeSuggestion) ? 'suggestion-active' : ''}
                    id={`${_id}-${grupoBio}`}
                    family={family}
                    name={grupoBio}
                    key={_id}
                    onClick={onClick}
                  >
                    {canonicalName}
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <>
            <div className="no-suggestions">
              <em>No hay sugerencias para los valores de búsqueda!</em>
            </div>
          </>
        ))
      }
      <GlobalStyleAutoComplete />
    </>
  )
}

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
  activeSuggestion: PropTypes.number,
  showSuggestions: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  userInput: PropTypes.string,
};

Autocomplete.defaultProps = {
  suggestions: [],
  activeSuggestion: 0,
  showSuggestions: false,
  loading: false,
  name: '',
  placeholder: 'Ingrese valor a buscar...',
  onClick: () => {},
  onChange: () => {},
  onKeyDown: () => {},
  userInput: '',
};

export default Autocomplete;
