import {
  USER_ME_INIT,
  USER_ME_SUCCESS,
  USER_ME_ERROR,
  USER_ME_UPDATE_INIT,
  USER_ME_UPDATE_SUCCESS,
  USER_ME_UPDATE_ERROR,
  USERS_LIST_INIT,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USERS_CREATE_INIT,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_ERROR,
  USERS_UPDATE_INIT,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_ERROR,
  USERS_DELETE_INIT,
  USERS_DELETE_SUCCESS,
  USERS_DELETE_ERROR,
  USER_SELECTED,
  USER_SELECTED_CLEAR,
  ROLES_LIST_INIT,
  ROLES_LIST_SUCCESS,
  ROLES_LIST_ERROR,
  TIPOS_ENTIDAD_LIST_INIT,
  TIPOS_ENTIDAD_SUCCESS,
  TIPOS_ENTIDAD_ERROR,
  TIPOS_DOCUMENTO_LIST_INIT,
  TIPOS_DOCUMENTO_SUCCESS,
  TIPOS_DOCUMENTO_ERROR,
  DOMAINS_BY_TYPE,
  USER_LOAD_SELECTED_INIT,
  USER_LOAD_SELECTED_SUCCESS,
  USER_LOAD_SELECTED_ERROR
} from './types';

export const userMe = () => {
	return {
		type: USER_ME_INIT
	};
};

export const userMeSuccess = data => {
	return {
		type: USER_ME_SUCCESS,
		payload: { data }
	};
};

export const userMeFailed = error => {
	return {
		type: USER_ME_ERROR,
		payload: error
	};
};

export const updateUserMe = () => {
	return {
		type: USER_ME_UPDATE_INIT
	};
};

export const updateMeSuccess = update => {
	return {
		type: USER_ME_UPDATE_SUCCESS,
		payload: update
	};
};

export const updateMeFailed = error => {
	return {
		type: USER_ME_UPDATE_ERROR,
		payload: error
	};
};

export const listUsers = () => {
	return {
		type: USERS_LIST_INIT
	};
};

export const listSuccess = (data, values) => {
	return {
		type: USERS_LIST_SUCCESS,
		payload: { data, values }
	};
};

export const listFailed = error => {
	return {
		type: USERS_LIST_ERROR,
		payload: error
	};
};

export const createUser = () => {
	return {
		type: USERS_CREATE_INIT
	};
};

export const createSuccess = data => {
	return {
		type: USERS_CREATE_SUCCESS,
		payload: { data }
	};
};

export const createFailed = error => {
	return {
		type: USERS_CREATE_ERROR,
		payload: error
	};
};

export const updateUser = () => {
	return {
		type: USERS_UPDATE_INIT
	};
};

export const updateSuccess = data => {
	return {
		type: USERS_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const updateFailed = error => {
	return {
		type: USERS_UPDATE_ERROR,
		payload: error
	};
};

export const deleteUser = () => {
	return {
		type: USERS_DELETE_INIT
	};
};

export const deleteSuccess = data => {
	return {
		type: USERS_DELETE_SUCCESS,
		payload: { data }
	};
};

export const deleteFailed = error => {
	return {
		type: USERS_DELETE_ERROR,
		payload: error
	};
};

export const userSelected = id => {
	return {
		type: USER_SELECTED,
		payload: id
	};
};

export const userClear = () => {
	return {
		type: USER_SELECTED_CLEAR,
	};
};

export const rolesList = () => {
	return {
		type: ROLES_LIST_INIT
	};
};

export const rolesSuccess = data => {
	return {
		type: ROLES_LIST_SUCCESS,
		payload: { data }
	};
};

export const rolesFailed = error => {
	return {
		type: ROLES_LIST_ERROR,
		payload: error
	};
};

export const tiposEntidadList = () => {
	return {
		type: TIPOS_ENTIDAD_LIST_INIT
	};
};

export const tiposEntidadSuccess = data => {
	return {
		type: TIPOS_ENTIDAD_SUCCESS,
		payload: { data }
	};
};

export const tiposEntidadFailed = error => {
	return {
		type: TIPOS_ENTIDAD_ERROR,
		payload: error
	};
};

export const tiposDocumentoList = () => {
	return {
		type: TIPOS_DOCUMENTO_LIST_INIT
	};
};

export const tiposDocumentoSuccess = data => {
	return {
		type: TIPOS_DOCUMENTO_SUCCESS,
		payload: { data }
	};
};

export const tiposDocumentoFailed = error => {
	return {
		type: TIPOS_DOCUMENTO_ERROR,
		payload: error
	};
};

export const domainsByType = data => {
	return {
    type: DOMAINS_BY_TYPE,
    payload: { data }
	};
};

export const loadSelectedInit = () => {
  return {
    type: USER_LOAD_SELECTED_INIT
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: USER_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: USER_LOAD_SELECTED_ERROR,
    payload: error
  };
};
