/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* Defined Constants */
import { USERS_URL_API_PIFS } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '', token = '') => {
  console.log('body', (data instanceof FormData) );
  if(data instanceof FormData) {
    const newApiHeaders = new Headers();
    newApiHeaders.append("Accept", "application/json");
    newApiHeaders.append("Authorization", token);
    return {
      method,
      headers: newApiHeaders,
      credentials: 'same-origin',
      body: data,
    };
  }
  const body = data ? { body: JSON.stringify(data) } : {};
  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiImagenes = {
  downloadImageToExpediente: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.imagen.download}/${id}/expediente/descargar`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.blob();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  uploadImageCreateToExpediente: async (image, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const formData = new FormData();
      Object.keys(image).map(key => (formData.append(key, image[key])) );
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.imagen.upload}`, fetchParams('POST', formData, token));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  uploadImageUpdateToExpediente: async (image, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const formData = new FormData();
      Object.keys(image).map(key => (formData.append(key, image[key])) );
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.imagen.get}/${image._id}/expediente`, fetchParams('PUT', formData, token));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  getImageInfoToExpediente: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.imagen.get}/${id}/expediente`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
};

export default apiImagenes;
