import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import reportesReducer from '../../store/reportes';

const { dispatchers } = reportesReducer;

const useConsultaTraficoEspecies = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { reportes } = state;

  // List of Dispatchers
	const {
    searchTablaRequest,
    searchGeovisorRequest,
    descargarReporteRequest,
    searchEstadisticasRequest
  } = dispatchers;

  // Bind Actions
	const consultaTraficoEspeciesActions = bindActions({
    searchTablaRequest,
    searchGeovisorRequest,
    descargarReporteRequest,
    searchEstadisticasRequest
  }, dispatch);

  return { ...reportes, ...consultaTraficoEspeciesActions };
};

export default useConsultaTraficoEspecies;
