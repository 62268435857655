import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import useForm from 'react-hook-form';
import styled from "styled-components";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

import {pxToRem} from "../utils";
import {useLogin} from '../hooks';

/* Components */
import Page from "../components/Page";
import {CardTitleBox} from "../components/CardTitleBox";
import {Form} from "../components/form-ui-components/Form";
import {Input} from "../components/form-ui-components/Input";
import {Label} from "../components/form-ui-components/Label";
import {Button} from "../components/form-ui-components/Button";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {StyledLink} from '../components/StyledLink';
import {SmallCard} from "../components/SmallCard";
import {FormMessage} from "../components/form-ui-components/Messages";
import {GridContainer, Row, Col, Col2, Col3} from "../components/Grid";

/* Defined Constants */
import {wait} from '../config/const';

const Recover = ({history}) => {
    const {isLoading, error, handleRecover, response} = useLogin();
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            handleRecover(data);
        } catch (err) {
            console.log(err);
        }
        e.stopPropagation();
    };

    const gotoLogin = useCallback(async () => {
        await wait(3000);
        history.push('/oauth/sign-in');
    }, [history]);

    useEffect(() => {
        if (typeof response.response !== 'undefined') gotoLogin();
    }, [response, gotoLogin]);

    return (
        <Page>
            <SmallCard title>
                <CardTitleBox>
                    <H1>Recuperar contaseña</H1>
                </CardTitleBox>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <GridContainer>
                        <Label>
                            Correo electrónico:
                            <Input
                                name="email"
                                type="email"
                                placeholder="Escriba su correo electrónico"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'El email es requerido o no es válido!'
                                    }
                                })}
                            />
                            {errors.email && errors.email.type === "required" && (
                                <FormMessage error>
                                    El email es requerido
                                </FormMessage>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <FormMessage error>
                                    El email no es válido!
                                </FormMessage>
                            )}
                        </Label>
                        {error && (
                            <>
                                <FormMessage error>
                                    <H5> {error} </H5>
                                </FormMessage>
                                <br/>
                            </>
                        )}
                        {response && (
                            <>
                                <FormMessage>
                                    <H5>{response.message}</H5>
                                </FormMessage>
                                <br/>
                            </>
                        )}
                        {isLoading ? (
                            <SkeletonTheme color="#f42f6391" highlightColor="#444">
                                <Skeleton height={34}/>
                            </SkeletonTheme>
                        ) : (
                            <Button type="submit">
                                Enviar e-mail
                            </Button>
                        )}
                        <StyledLink center to="/oauth/sign-in">Volver al inicio de sesión</StyledLink>
                    </GridContainer>
                </Form>
            </SmallCard>
        </Page>
    )
}

Recover.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object]),
};

Recover.defaultProps = {
    history: {},
};

export default withRouter(Recover);
