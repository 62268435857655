/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable spaced-comment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Cookies from "js-cookie";
import styled from "styled-components";
import { useLogin } from "../hooks";
import { Icon } from "./Icon";
import SideBar from "./SideBar";
import LogoPIFS from "../assets/logos/logo-pifs.png";
import LogoMADS from "../assets/logos/logo-mads.png";
/* Defined Constants */
import { wait} from "../config/const";
import { pxToRem } from "../utils";

const TopBar = styled.div`
  position: fixed;
  top: 0;
  background-color: ${props => props.theme.primaryColor};
  height: ${pxToRem(15)};
  left: 0;
  right: 0;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.headerBackgrond};
  box-shadow: 0 -2px 7px -1px ${props => props.theme.colors.black};
  height: ${pxToRem(70)};
  justify-content: space-between;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const StyledLogo = styled.img`
  max-height: 50px;
  margin: 0  ${pxToRem(10)} 0;
  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

const Logo = () => <StyledLogo src={LogoPIFS} alt="Portal de información sobre tráfico ilegal de fauna silvestre" />;

const NavItem = styled(Link)`
  border: none;
  color: ${props => props.theme.headerTextColor};
  font-weight: 300;
  padding: 0 ${pxToRem(16)};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
`;

const User = styled.div`
  display: flex;
  color: ${props => props.theme.headerTextColor};
  height: ${pxToRem(70)};
  padding: ${pxToRem(16)};

`;

const UserName = styled.div`
  display: flex;
  padding: 0 ${pxToRem(8)};
  margin: auto;
`;

const Widget = styled.div`
  display: flex;
  width: ${pxToRem(158)};
  justify-content: space-around;
  cursor: pointer;
  border: none;
  margin-left: ${pxToRem(48)};
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${pxToRem(66)};
  right: ${pxToRem(36)};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.dropdownMenuShadow};
`;

const MenuItem = styled(Link)`
  display: flex;
  justify-content: start;
  color: ${props => props.theme.colors.darkTeal};
  padding: ${pxToRem(8)};
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    background-color: ${props => props.theme.colors.darkTeal};
    color: ${props => props.theme.colors.white};
  }
`;

const UserWidget = ({ toogleMenu }) => {
  const { isLoggedIn, userLogin } = useLogin();
  return (
    <Widget onMouseOver={toogleMenu}>
      {isLoggedIn ? (
        <>
          <User >
            <Icon> arrow_drop_down </Icon>
            <UserName>{userLogin}</UserName>
            <Icon> account_circle </Icon>
          </User>
        </>
      ) : (
        <NavItem to="/oauth/sign-in"> Login </NavItem>
      )}
    </Widget>
  );
};

const Text = styled.span`
  display: none;
  @media (min-width: 600px) {
    display: inline-flex;
    padding: 0 ${pxToRem(12)};
    text-align: left;
    margin: auto 0;
  }
`;


const UserMenu = ({ logout, toogleMenu }) => (
  <DropdownMenu onMouseLeave={toogleMenu}>
    <MenuItem to="/update-profile">
      <Icon> settings </Icon>
      <Text>actualizar datos</Text>
    </MenuItem>
    <MenuItem to={window.location} onClick={logout}>
      <Icon> exit_to_app </Icon>
      <Text>cerrar sesión</Text>
    </MenuItem>
  </DropdownMenu>
);

const Header = ({ history, location }) => {
  const { pathname } = location;
  const { menuUserLogin, isLoggedIn, handleLogout, token, getUserSession } = useLogin();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      getUserSession(token)
    }
  }, [isLoggedIn]);

  const logout = () => {
    confirmAlert({
      title: "¿Esta seguro que desea cerrar sesión?",
      message: "los cambios que no hayan sido guardados se perderán",
      buttons: [
        {
          label: "Cerrar sesión",
          onClick: async () => {
            Cookies.remove('pifs');
            await handleLogout();
            if (token === "") {
              history.push("/");
            }
          }
        },
        {
          label: "Cancelar",
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const gotoLogin = useCallback(async () => {
    console.log("gotoLogin", pathname);
    const url = pathname.split("/");
    console.log("url", url);
    if (
      url[2] !== "reset" &&
      url[1] !== "about" &&
      url[2] !== "sign-in" &&
      url[2] !== "recover"
    ) {
      await wait(1000);
      history.push("/");
    }
  }, [history, pathname]);

  // useEffect(() => {
  //   if (!isLoggedIn) gotoLogin();
  // }, [isLoggedIn, gotoLogin]);

  const toogleMenu = () => setMenuVisible(!menuVisible);
  return (
    <>
      <TopBar/>
    <StyledHeader>
      {isLoggedIn && <SideBar items={menuUserLogin} />}
      <NavItem to="/">
        <Logo />
        <StyledLogo src={LogoMADS} alt="Ministerio de ambiente y desarrollo sostenible"/>
      </NavItem>
      <Navigation>
        {/*<NavItem to="/sobre-el-portal"> Sobre el portal </NavItem>*/}
        {/*<NavItem to="/consultas"> Consultas </NavItem>*/}
        {/*<NavItem to="/bibliografia"> Bibliografia </NavItem>*/}
        {<NavItem to="/contacto"> Contacto </NavItem>}
        <UserWidget toogleMenu={toogleMenu} />
        {menuVisible && isLoggedIn && (
          <UserMenu logout={logout} toogleMenu={toogleMenu} />
        )}
      </Navigation>
    </StyledHeader>
      </>
  );
};

Header.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.oneOfType([PropTypes.object])
};

Header.defaultProps = {
  history: {},
  location: {}
};

export default withRouter(Header);
