export const CONSULTA_POR_TABLA_SEARCH_INIT = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_SEARCH_INIT';
export const CONSULTA_POR_TABLA_SEARCH_SUCCESS = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_SEARCH_SUCCESS';
export const CONSULTA_POR_TABLA_SEARCH_ERROR = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_SEARCH_ERROR';
export const CONSULTA_POR_TABLA_VER_GRAFICA_INIT = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_VER_GRAFICA_INIT';
export const CONSULTA_POR_TABLA_VER_GRAFICA_SUCCESS = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_VER_GRAFICA_SUCCESS';
export const CONSULTA_POR_TABLA_VER_GRAFICA_ERROR = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_VER_GRAFICA_ERROR';
export const CONSULTA_POR_TABLA_VER_GRAFICA_PROCEDIMIENTO_SUCCESS = '[MODAL_INFORMACION_PROCEDIMIENTO] CONSULTA_POR_TABLA_VER_GRAFICA_PROCEDIMIENTO_SUCCESS';
export const CONSULTA_POR_TABLA_VER_GRAFICA_PROCEDIMIENTO_ERROR = '[MODAL_INFORMACION_PROCEDIMIENTO] CONSULTA_POR_TABLA_VER_GRAFICA_PROCEDIMIENTO_ERROR';
export const CONSULTA_POR_TABLA_VER_GRAFICA_DISPOSICION_SUCCESS = '[MODAL_DISPOSICION_FINAL] CONSULTA_POR_TABLA_VER_GRAFICA_DISPOSICION_SUCCESS';
export const CONSULTA_POR_TABLA_VER_GRAFICA_DISPOSICION_ERROR = '[MODAL_DISPOSICION_FINAL] CONSULTA_POR_TABLA_VER_GRAFICA_DISPOSICION_ERROR';
export const CONSULTA_POR_TABLA_VER_GRAFICA_EVENTO_SUCCESS = '[MODAL_EVENTO_FINAL] CONSULTA_POR_TABLA_VER_GRAFICA_EVENTO_SUCCESS';
export const CONSULTA_POR_TABLA_VER_GRAFICA_EVENTO_ERROR = '[MODAL_EVENTO_FINAL] CONSULTA_POR_TABLA_VER_GRAFICA_EVENTO_ERROR';
export const CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT';
export const CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS';
export const CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR = '[CONSULTA_POR_TABLA] CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR';
export const CONSULTA_POR_GEOVISOR_SEARCH_INIT = '[CONSULTA_POR_GEOVISOR] CONSULTA_POR_GEOVISOR_SEARCH_INIT';
export const CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS = '[CONSULTA_POR_GEOVISOR] CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS';
export const CONSULTA_POR_GEOVISOR_SEARCH_ERROR = '[CONSULTA_POR_GEOVISOR] CONSULTA_POR_GEOVISOR_SEARCH_ERROR';
export const CONSULTA_POR_GEOVISOR_DESCARGAR_IMAGEN_SUCCESS = '[CONSULTA_POR_GEOVISOR] CONSULTA_POR_GEOVISOR_DESCARGAR_IMAGEN_SUCCESS';
export const CONSULTA_POR_GEOVISOR_DESCARGAR_IMAGEN_ERROR = '[CONSULTA_POR_GEOVISOR] CONSULTA_POR_GEOVISOR_DESCARGAR_IMAGEN_ERROR';
export const CONSULTA_POR_ESTADISTICAS_SEARCH_INIT = '[CONSULTA_POR_ESTADISTICAS] CONSULTA_POR_ESTADISTICAS_SEARCH_INIT';
export const CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS = '[CONSULTA_POR_ESTADISTICAS] CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS';
export const CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR = '[CONSULTA_POR_ESTADISTICAS] CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR';
