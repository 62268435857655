import styled from 'styled-components';
import { pxToRem } from "../utils";

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.center ? "center" : "flex-start"};
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  aling-items: center;
  width: 100%;

  .header-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .text-info {
      color: ${props => props.theme.colors.dargraygold};
      font-weight: lighter;
    }
  }
`;

export const SpanDivide = styled.span`
  align-items: flex-start;
  font-size: ${pxToRem(18)};
  border-bottom: ${pxToRem(1)} solid ${props => props.theme.primaryColor};
  width: 100%;
`;

export const SpanTextInfo = styled.span`
  font-size: 14px;
  color: ${props => props.light ? props.theme.colors.lightGrey : props.theme.colors.dargraygold};
`;

export const SpanContact = styled.span`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .button-item {
    color: ${props => props.theme.colors.lightGrey};
    &:hover {
      color: ${props => props.theme.colors.darkGreyDarken};
    }

    i {
      vertical-align: bottom;
    }
  }
`;

export const CardContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  .card-body-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .obligatory {
      color: ${props => props.theme.colors.fuchsia};
    }

    button {
      cursor: pointer;
    }
  }

  .card-body-row-only {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: 100%;

    .obligatory {
      color: ${props => props.theme.colors.fuchsia};
    }

    .text-info {
      font-size: 14px;
      color: ${props => props.theme.colors.dargraygold};
    }
  }

  .card-body-row-only-right {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width: auto;

    button {
      cursor: pointer;
    }
  }

  .card-body-row-only-left {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: auto;

    span {
      color: ${props => props.theme.colors.darkGrey};
      font-weight: bold;
    }

    span:hover {
      color: ${props => props.theme.colors.darkGreyDarken};
    }

    button {
      cursor: pointer;
    }
  }
`;

export const SpanView = styled.span`
  color: ${props => props.theme.colors.darkGrey};
  font-weight: normal;
  text-transform: none;
  &::first-letter {
    text-transform: uppercase;
  }
`;
