/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useLogin,
  useReportes
} from '../hooks';
/* Components */
import ErrorBoundary from '../components/ErrorBoundary';
import Page from '../components/Page';
import {PageHeader} from '../components/PageHeader';
import {CardTitleBox} from '../components/CardTitleBox';
import {Card} from '../components/Card';
import {H1} from '../components/H1';
import {H5} from '../components/H5';
/* Style Components */
import {CardContainerBody} from './styled';
import Estadisticas from "../components/Charts/Estadisticas";

dayjs.locale('es');

const ConsultaEstadisticas = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const [loadingEst, setLoadingEst] = useState(true);
  const {token} = useLogin();

  const {
    searchEstadisticasRequest,
    est
  } = useReportes();
  const {
    isLoading,
    error
  } = useReportes();

  const load = useCallback(async () => {
    setLoadingEst(true)
    await searchEstadisticasRequest(token)
    setLoadingEst(false)
  }, []);

  useEffect(() => {
    if (!loaded) {
      load();
      setLoaded(true);
    }
  }, [loaded, load]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
          <>
            <PageHeader>
              <H1>Estadísticas del portal.</H1>
            </PageHeader>
            <Card container>
              {loadingEst ? (
                <div style={{justifyContent: 'flex-end', width: '100%'}}>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              ) : (
                <CardContainerBody>
                  {console.log("estadisticas antes de enviar params--->", est)}
                  <Estadisticas data={est}/>
                </CardContainerBody>
              )}
              {error && (
                <CardContainerBody>
                  <CardTitleBox className="error">
                    <H5> Ups, hubo un error {error}</H5>
                  </CardTitleBox>
                </CardContainerBody>
              )}
            </Card>
          </>
      </Page>
    </ErrorBoundary>
  )
};

ConsultaEstadisticas.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConsultaEstadisticas.defaultProps = {
  history: {},
};

export default withRouter(ConsultaEstadisticas);
