/* eslint-disable no-underscore-dangle */
import {
  listInit,
  listSuccess,
  listError
} from "./actions";
import * as TaxonomyServices from "../../services";

export const getAllTaxonomy = (from, to, token) => {
  return async dispatch => {
    dispatch(listInit());
    try {
      const data = await TaxonomyServices.apiTaxonomy.search(from, to, token);
      dispatch(listSuccess(data.data));
    } catch (error) {
      dispatch(listError(error));
    }
  };
};

export default getAllTaxonomy;
