import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import usersReducer from '../../store/users';

const { dispatchers } = usersReducer;

const useUsers = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { users } = state;

  // List of Dispatchers
	const {
    getUsersRequest,
    userCreateRequest,
    userUpdateRequest,
    userDeleteRequest,
    userSelectedChange,
    userSelectedClear,
    getUserMeRequest,
    updateUserMeRequest,
    getRolesRequest,
    getTiposEntidadRequest,
    getTiposDocumentoRequest,
    getDomainsByTypeRequest,
    getAutoridadAmbientalRequest,
    clearSelected,
    loadSelected
  } = dispatchers;

  // Bind Actions
	const usersActions = bindActions({
    getUsersRequest,
    userCreateRequest,
    userUpdateRequest,
    userDeleteRequest,
    userSelectedChange,
    userSelectedClear,
    getUserMeRequest,
    updateUserMeRequest,
    getRolesRequest,
    getTiposEntidadRequest,
    getTiposDocumentoRequest,
    getDomainsByTypeRequest,
    getAutoridadAmbientalRequest,
    clearSelected,
    loadSelected
  }, dispatch);

  return { ...users, ...usersActions };
};

export default useUsers;
