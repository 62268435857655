import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import domainsReducer from '../../store/domains';

const { dispatchers } = domainsReducer;

const useDomains = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { domains } = state;

  // List of Dispatchers
	const {
    getAllDomains,
    getOptionsByDomainTypes
  } = dispatchers;

  // Bind Actions
	const domainsActions = bindActions({
    getAllDomains,
    getOptionsByDomainTypes
  }, dispatch);

  return { ...domains, ...domainsActions };
};

export default useDomains;
