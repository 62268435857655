/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* Defined Constants */
import { USERS_URL_API_PIFS } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};

  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiAutoridadAmbiental = {
  search: async (values, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.search}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  delete: async (autoridad, id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.delete}/${id}`, fetchParams('PUT', { ...autoridad }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  get: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.get}/${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  create: async (autoridad, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.create}`, fetchParams('POST', { ...autoridad }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  update: async (autoridad, id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.update}/${id}`, fetchParams('PUT', { ...autoridad }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
};

export default apiAutoridadAmbiental;
