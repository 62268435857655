/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* Defined Constants */
import nanoid from 'nanoid';
import { USERS_URL_API as URL_API, DOMAINS_URL_API, USERS_URL_API_PIFS, wait } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};

  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiUser = {
  getUserMe: async (username, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${URL_API}${endpoints.user.me}/${username}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  updateUserMe: async (user, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${URL_API}${endpoints.user.updateMe}`, fetchParams('PUT', { ...user }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getUserGeneral: async (usuario, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${URL_API}${endpoints.user.getUserGeneral}/${usuario}`, fetchParams('GET'));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  create: async (user, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.user.create}`, fetchParams('POST', { ...user }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  update: async (user, id, username, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.user.update}/${username}`, fetchParams('PUT', { ...user }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  delete: async (user, username, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.user.delete}/${username}`, fetchParams('PUT', { ...user }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getUsers: async (usuario = null, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const path = usuario ? `/${usuario}` : '';
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.user.getUsers}${path}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getUsersBySystem: async (system, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${URL_API}${endpoints.user.getUsersBySystem}`, fetchParams('POST', { ...system }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getRoles: async () => {
    try {
      const response = await fetch(`${URL_API}${endpoints.user.getRoles}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        console.log(data.error);
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getEntidades: async () => {
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.user.getEntidades}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        // throw new Error(data.error);
        console.log(data.error);
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getDocumentos: async () => {
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.user.getDocumentos}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getDomainsByType: async type => {
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.domains.getOptionsByDomainTypes}=${type}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  searchAutoridad: async values => {
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.autoridadAmbiental.search}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  searchEntidad: async () => {
    try {
      await wait(200);
      // const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.entidadDisposicion.search}`, fetchParams('GET'));
      // if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
      //   return response.statusText;
      // }
      // const data = await response.json();
      // if (typeof data.error !== 'undefined') {
      //   return data.error;
      // }
      return [
        {_id: nanoid(), Nombre_disposicion: 'Entidad Disposición 1'},
        {_id: nanoid(), Nombre_disposicion: 'Entidad Disposición 2'},
        {_id: nanoid(), Nombre_disposicion: 'Entidad Disposición 3'},
        {_id: nanoid(), Nombre_disposicion: 'Entidad Disposición 4'},
      ]; // data;
    } catch (error) {
      return error.toString();
    }
  },
  getByDomainIdsTypes: async (id, type, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.domains.getByDomainIdsTypes}=${id}&domainTypes=${type}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  getByConstantIdsTypes: async (id, type, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.constants.getByConstantIdsTypes}=${id}&constantTypes=${type}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
};

export default apiUser;
