/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useMemo, useRef }  from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useLogin,
  useTraslado,
  useNotification,
  useDomains,
  useConstants,
  useExpedientes
} from '../hooks';
/* Components */
import ErrorBoundary from "../components/ErrorBoundary";
import Page from "../components/Page";
import { PageHeader } from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";
import PaginationTableRowSelect from "../components/Table/PaginationTableRowSelect";
import PaginationTableRowSelectServerSide from "../components/Table/PaginationTableRowSelectServerSide";
import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import { CardTitleBox } from "../components/CardTitleBox";
import { Col, Col1, Col2, Col3, GridContainer, Row } from "../components/Grid";
import { Card } from "../components/Card";
import { H1 } from "../components/H1";
import { H5 } from "../components/H5";
import { Button } from '../components/form-ui-components/Button';
import { Input } from '../components/form-ui-components/Input';
import { Form } from '../components/form-ui-components/Form';
import { Label } from '../components/form-ui-components/Label';
import { Select } from '../components/form-ui-components/Select';
import { SelectOptions } from '../components/form-ui-components/SelectOptions';
import ModalContactsTraslado from '../components/ModalContactsTraslado';
import ModalSoporteTraslado from '../components/ModalSoporteTraslado';
import ModalEspecimenTraslado from '../components/ModalEspecimenTraslado';
import Autocomplete from '../components/Autocomplete';
import { Icon } from '../components/Icon';
import Loader from '../components/Loader';
/* Style Components */
import { ContainerHeader, SpanTextInfo, SpanContact, SpanDivide, CardContainerBody, SpanView } from './styled';
/* Utils */
import { removeEmpty } from '../utils';

dayjs.locale('es');

const TrasladoForm = ({ history, match }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ loadedInfo, setLoadedInfo ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ modalFile, setModalFile ] = useState(false);
  const [ codigoExpediente ] = useState('-- -- --');
  const [ modalEspecimenes, setModalEspecimenes ] = useState(false);
  const [ modalContacts, setModalContacts ] = useState(false);
  const [ animation, setAnimation  ] = useState('zoom');
  const fetchIdRef = useRef(0);
  // const [ files, setFiles ] = useState([]);
  // const [ filesAux, setFilesAux ] = useState([]);
  // Auto Complete
  const [ suggestions, setSuggestions ] = useState([]);
  const [ loadingAutoComplete, setLoadingAutoComplete ] = useState(false);
  const [ activeSuggestion, setActiveSuggestion ] = useState(0);
  const [ showSuggestions, setShowSuggestions ] = useState(false);
  const [ userInput, setUserInput ] = useState('');
  // Fin Auto Complete

  const { isLoggedIn, userLogin, token } = useLogin();
  const { createNotification } = useNotification();
  const { constantsByKey, getConstantOptionsByTypes } = useConstants();
  const { domainsByKey, getOptionsByDomainTypes } = useDomains();
  const { taxonomyInformation, especimenTaxonomyInfoRequest } = useExpedientes();
  const {
    isLoading,
    files,
    filesAux,
    isLoadingEspecimenes,
    isLoadingEspecimenesAddaded,
    soporteSelected,
    solicitante,
    entidadesProvisional,
    entidadesFinal,
    selected,
    pageCountEspecimenes,
    mode,
    contacts,
    especimenes,
    especimenesAddaded,
    loadSelected,
    clearSelected,
    createTrasladoRequest,
    trasladoContactDelete,
    trasladoEspecimenSelected,
    trasladoEspecimenAdd,
    trasladoEspecimenDelete,
    getUsuarioEntidad,
    searchEntidadDisposicion,
    trasladoRespuestaRequest,
    trasladoSoportesDelete,
    getByIdSoporteRequest,
    clearSelectedFile,
    searchEspecimenesSinTraslado,
    searchEspecimenesConTraslado,
    getEspecimenesTrasladoRequest
  } = useTraslado();
  const { setValue, getValues, register, handleSubmit, errors, reset, watch, clearError, setError } = useForm();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const getActualDateFormat = () => dayjs().format('DD/MM/YYYY');
  const parseDateFormat = date => dayjs(date).format('DD/MM/YYYY');
  const getActualDateTime = () => dayjs().format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const splitDate = timestamp => timestamp.split('T')[0];

  // Auto Complete
  // const loadTaxonomyInfo = useCallback(() => {
  //   setSuggestions(taxonomyInformation);
  // }, [taxonomyInformation]);

  const handleAutoCompleteChange = async e => {
    const newUserInput = e.currentTarget.value;
    if(document.querySelector("select[name='taxonRank']").value !== '') {
      const search = {
        taxonRank: document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const { taxonomyInfo, msg, err } = await especimenTaxonomyInfoRequest(search, token);
      console.log(taxonomyInfo, msg, err);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInfo);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId  = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalName').value = newUserInput;
    setValue('taxonomia', taxonomiaId);
  };
  // Fin Auto Complete

  const handleChangeType = e => {
    const { value } = e.target;
  };

  const loadSolicitante = useCallback(async () => {
    if(solicitante) {
      console.log('entro', solicitante);
      setValue('solicitante_autoridad', solicitante.nombre);
      const searchProvisional = {
        autoridad_ambiental: solicitante._id,
        tipo_disposicion: 15000001,
        estado: true,
      };
      const searchFinal = {
        autoridad_ambiental: solicitante._id,
        tipo_disposicion: 15000002,
        estado: true,
      };
      await Promise.all([searchEntidadDisposicion(searchProvisional, 'provisional', token), searchEntidadDisposicion(searchFinal, 'final', token)]);
      // setLoadedInfo(true);
    }
  }, [setValue, solicitante, searchEntidadDisposicion]);

  const loadEspecimenesSelected = useCallback(async () => {
    if (selected) {
      console.log('entro en loadEspecimenesSelected', selected._id);
      await getEspecimenesTrasladoRequest(({ traslado: selected._id }), 0, 100, token);
    }
  }, [selected]);

  const loadTrasladoSelected = useCallback(() => {
    if (selected) {
      console.log('entro en selected', selected);

      if(selected.respuesta) setValue('respuesta', selected.respuesta._id);
      if(selected.comentario) setValue('comentario', selected.comentario);
      if(selected.fecha_recepción) setValue('fecha_recepcion', parseDateFormat(splitDate(selected.fecha_recepción)));
      if(selected.fecha_recepcion) setValue('fecha_recepcion', parseDateFormat(splitDate(selected.fecha_recepcion)));
    } else {
      setValue('fecha_envio', getActualDate());
    }
  }, [setValue, selected, searchEntidadDisposicion]);

  const load = useCallback(async () => {
    setLoadedInfo(false);
    window.scrollTo(0, 0);
    await Promise.all([clearSelected(), getUsuarioEntidad(userLogin, token), getOptionsByDomainTypes(['tiposRecurso', 'tiposParte', 'tiposSexo', 'estadosDesarrollo', 'tiposEstadoEspecimen', 'tiposRespuestaTraslado', 'motivosCierre', 'tiposEntidadDisposicion', 'tiposAdjunto']), getConstantOptionsByTypes(['clasificacionTaxonomia'])]);
    if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
      console.log("modo de vista---->",match.params.mode)
      await loadSelected(match.params.id, match.params.mode, token);
    } else {
      setValue('fecha_envio', getActualDate());
      // await searchEspecimenesSinTraslado({ estado_posesion_expediente: 25000002, disposicion_final: false, estado_expediente: 22000002 }, 0, 50, token);
    }
    setLoadedInfo(true);
  }, [
      loadSelected,
      getUsuarioEntidad,
      getOptionsByDomainTypes,
      clearSelected,
      match.params.id,
      match.params.mode
  ]);

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const clearTraslado = useCallback(async () => {
    window.scrollTo(0, 0);
    await clearSelected();
    reset();
    setLoadedInfo(true);
  }, [history]);

  const gotoTraslados = useCallback(async () => {
    await clearSelected();
    history.push('/traslados-search');
  }, [history]);

  const handleDeleteFiles = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: `¿Esta seguro de eliminar los ${Object.keys(selectedRowIds).length} soportes del listado?`,
      message: `Al borrar los soportes del listado quedan desvinculados del traslado`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            await trasladoSoportesDelete(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const handleOpenModalContacts = () => {
    setAnimation('zoom');
    setModalContacts(!modalContacts);
  };

  // ------------------> SOPORTES
  const handleOpenModalFiles = async () => {
    await clearSelectedFile();
    setAnimation('zoom');
    setModalFile(!modalFile);
  };
  // ------------------> FIN SOPORTES

  const handleOpenModalEspecimenes = () => {
    setAnimation('zoom');
    setModalEspecimenes(!modalEspecimenes);
  };

  const AlertThenConfirm = async original => {
    confirmAlert({
      title: 'CREAR TRASLADO',
      message: `¿Esta seguro que desea generar el traslado: ${original.nombre}`,
      buttons: [
        {
          label: "CONFIRMAR",
          onClick: async () => {
            const newData = {
              ...original,
              tipo: Number(original.tipo),
              fecha_solicitud: getActualDateTime(original.fecha_solicitud),
              fecha_envio: parseDateTime(original.fecha_envio),
              // estado: 0,
              // respuesta: 0
            };
            const {msg, err} = await createTrasladoRequest(newData, token);
            createNotification({
              message: msg,
              error: err,
              visible: true
            });
            if(!err) {
              reset();
              if(mode === 'edit') await gotoTraslados();
            }
          }
        },
        {
          label: "CANCELAR",
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const handleClose = async e => {
    e.preventDefault();
    reset();
    await clearSelected();
    history.push('/traslados-search');
    e.stopPropagation();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      let isError = false;
      if(contacts.length === 0 && !isError) {
        isError = true;
        Swal.fire({
          title: 'REGISTRAR CONTACTO!',
          text: 'Debe asociar al menos un contacto al traslado!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      } else if(especimenesAddaded.length === 0 && !isError) {
        isError = true;
        Swal.fire({
          title: 'REGISTRAR ESPÉCIMEN!',
          text: 'Debe ingresar al menos un espécimen en el traslado!',
          icon: 'info',
          confirmButtonText: 'ACEPTAR'
        })
      }  else {
        Swal.fire({
          title: 'CREAR TRASLADO',
          html: `¿Esta seguro que desea generar el traslado en la fecha de envío: <b>${parseDate(splitDate(parseDateTime(data.fecha_envio)))}</b>?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'CONFIRMAR'
        }).then(async (result) => {
          if (result.value) {
            const newData = {
              tipo: Number(data.tipo),
              entidad_disposicion_destino: data.entidad_disposicion_destino,
              fecha_solicitud: getActualDateTime(data.fecha_solicitud),
              fecha_envio: parseDateTime(data.fecha_envio),
              funcionario_responsable: contacts.map(({ _id }) => _id)[0]
              // respuesta: 0,
            };
            const {msg, err} = await createTrasladoRequest(newData, files, contacts, especimenesAddaded, token);
            createNotification({message: msg, visible: true, error: err});
            if(!err) {
              reset();
              await gotoTraslados();
            }
          }
        })
      }
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  // Responder Traslado
  const handleCancelarResponderTraslado = async e => {
    e.preventDefault();
    setValue('respuesta', '');
    setValue('fecha_recepcion', '');
    setValue('comentario', '');
    await clearSelected();
    history.push('/traslados-search');
  };

  const handleAceptarResponderTraslado = async e => {
    e.preventDefault();
    const values = getValues();
    const responder = {
      respuesta: values.respuesta ? Number(values.respuesta) : null,
      fecha_recepcion: values.fecha_recepcion ? parseDateTime(values.fecha_recepcion) : null,
      comentario: values.comentario ? values.comentario : null,
    };

    let countEmpty = 0;
    let fieldsWithError = [];
    clearError(['respuesta', 'comentario', 'fecha_recepcion'])
    Object.keys(responder).map(key => {
      if((mode === 'responder') && (key !== 'fecha_recepcion')) {
        if((typeof responder[key] === 'object' ) && responder[key] === null) {
          countEmpty+=1;
          fieldsWithError = [...fieldsWithError, { type: 'required', name: key }];
        };
      }  else if((mode === 'recepcion') && (key === 'fecha_recepcion')) {
        if((typeof responder[key] === 'object' ) && responder[key] === null) {
          countEmpty+=1;
          fieldsWithError = [...fieldsWithError, { type: 'required', name: key }];
        };
      }
    });

    if(countEmpty > 0) {
      Swal.fire({
        title: 'CAMPOS OBLIGATORIOS!',
        icon: 'info',
        html: `Debe diligenciar los campos obligatorios para ${(mode === 'responder') ? 'responder el traslado.' : 'la recepción del traslado'}`,
        confirmButtonText: 'ACEPTAR'
      })
      setError(fieldsWithError);
    } else {
      const {msg, err} = await trasladoRespuestaRequest(selected._id, removeEmpty(responder), token);
      createNotification({message: msg, visible: true, error: err});
      if(!err) {
        reset();
        await gotoTraslados();
      }
    }
  };

  const notFetchData = useCallback(async ({ pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch }) => {
    console.log('notFetchData', fetchIdRef.current, pageSize, pageIndex, loaderFecth, loarderSearch, loading, isLoading);
  }, []);

  const fetchData = useCallback(async ({ pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch }) => {
    setLoading(true);
    console.log('fetchData 1', fetchIdRef.current, pageSize, pageIndex, loaderFecth, loarderSearch, loading, isLoading);
    const fetchId = ++fetchIdRef.current;
    if ((fetchId === fetchIdRef.current) && !loading) {
      console.log('fetchData 2');
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;

      const values = getValues();
      const search = {
        clase_recurso: Number(values.clase_recurso) || null,
        estado: Number(values.estado) || null,
        parte_producto: Number(values.parte_producto) || null,
        taxonomia: Number(values.taxonomia) || null,
        nombre_comun: values.nombre_comun || null,
        sexo: Number(values.sexo) || null,
        estado_desarrollo: Number(values.estado_desarrollo) || null,
        codigo_expediente: values.codigo_expediente || null,
        acta: values.acta || null,
        codigo_traslado: values.codigo_traslado || null,
      };

      if(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') {
        if(String(true) === values.traslado) {
          console.log('sin traslado entro');
          await searchEspecimenesSinTraslado(removeEmpty({ ...search, estado_posesion_expediente: 25000002, disposicion_final: false, estado_expediente: 22000002 }), startRow, pageSize, token);
        } else {
          console.log('con traslado entro');
          await searchEspecimenesConTraslado(removeEmpty({ ...search, estado_posesion_traslado: 24000002, disposicion_final: false }), startRow, pageSize, token);
        }
      } else {
        await getEspecimenesTrasladoRequest(({ ...search, traslado: selected._id }), startRow, pageSize, token);
      }

      setLoading(false);
      console.log('fetchData 3', loaderFecth, loarderSearch);
    }
  }, [mode]);

  const handleChangeTraslado = async () => {
    // const { value } = e.target;
    // if(String(true) === value) {
    //   console.log('sin traslado entro');
    //   await searchEspecimenesSinTraslado({ estado_posesion_expediente: 25000002, disposicion_final: false, estado_expediente: 22000002 }, 0, 100, token);
    // } else {
    //   console.log('con traslado entro');
    //   await searchEspecimenesConTraslado({ estado_posesion_traslado: 24000002, disposicion_final: false }, 0, 100, token);
    // }
    await fetchData({ pageSize: 10, pageIndex: 0, loading, isLoading });
  };

  // Especimes
  const handleClearSearchFieldsEspecimen = async e => {
    e.preventDefault();
    setValue('clase_recurso', '');
    setValue('estado', '');
    setValue('parte_producto', '');
    setValue('taxonRank', '');
    document.getElementById('canonicalName').value = '';
    setValue('taxonomia', '');
    setValue('nombre_comun', '');
    setValue('sexo', '');
    setValue('estado_desarrollo', '');
    const values = getValues();
    if(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') {
      if(String(true) === values.traslado) {
        console.log('sin traslado entro');
        setValue('codigo_expediente', '');
        setValue('acta', '');
        // await searchEspecimenesSinTraslado({ estado_posesion_expediente: 25000002, disposicion_final: false, estado_expediente: 22000002 }, 0, 100, token);
      } else {
        console.log('con traslado entro');
        setValue('codigo_traslado', '');
        // await searchEspecimenesConTraslado({ estado_posesion_traslado: 24000002, disposicion_final: false }, 0, 100, token);
      }
    } else {
      setValue('codigo_traslado', '');
      // await getEspecimenesTrasladoRequest(({ traslado: selected._id }), 0, 100, token);
    }
    await fetchData({ pageSize: 10, pageIndex: 0, loading, isLoading });
  };

  const handleSearchFieldsEspecimen = async e => {
    e.preventDefault();
    // const values = getValues();
    // const search = {
    //   clase_recurso: values.clase_recurso? Number(values.clase_recurso) : null,
    //   estado: values.estado ? Number(values.estado) : null,
    //   parte_producto: values.parte_producto ? Number(values.parte_producto) : null,
    //   taxonomia: values.taxonomia ? Number(values.taxonomia) : null,
    //   nombre_comun: values.nombre_comun ? values.nombre_comun : null,
    //   sexo: values.sexo ? Number(values.sexo) : null,
    //   estado_desarrollo: values.estado_desarrollo ? Number(values.estado_desarrollo) : null,
    //   codigo_expediente: values.codigo_expediente ? values.codigo_expediente : null,
    //   acta: values.acta ? values.acta : null,
    //   codigo_traslado: values.codigo_traslado ? values.codigo_traslado : null,
    // };

    // if(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') {
    //   if(String(true) === values.traslado) {
    //     console.log('sin traslado entro');
    //     // await searchEspecimenesSinTraslado(removeEmpty({ ...search, estado_posesion_expediente: 25000002, disposicion_final: false, estado_expediente: 22000002 }), 0, 100, token);
    //   } else {
    //     console.log('con traslado entro');
    //     // await searchEspecimenesConTraslado(removeEmpty({ ...search, estado_posesion_traslado: 24000002, disposicion_final: false }), 0, 100, token);
    //   }
    // } else {
    //   // await getEspecimenesTrasladoRequest(({ ...search, traslado: selected._id }), 0, 100, token);
    // }
    await fetchData({ pageSize: 10, pageIndex: 0, loading, isLoading });
  };

  const handleAddEspecimenes = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: 'AGREGAR ESPECÍMENES AL LISTADO',
      message: `¿Esta seguro de agregar los ${Object.keys(selectedRowIds).length} especímenes del listado?`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {
            // const newSelectedFlatRows = { ...selectedFlatRows.map(({ original }) => original._id)};
            const newSelectedFlatRows = selectedFlatRows.some(({ original }) => original._id === "5e9b25a392b9d44f65d5ff2f");
            console.log(selectedRowIds, selectedFlatRows, newSelectedFlatRows);
          }
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            const values = getValues();
            await trasladoEspecimenAdd(selectedRowIds, selectedFlatRows, values.traslado);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const handleDeleteEspecimenes = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: 'BORRAR ESPECÍMENES ADICIONADOS AL LISTADO',
      message: `¿Esta seguro de borrar los ${Object.keys(selectedRowIds).length} especímenes del listado?`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            await trasladoEspecimenDelete(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  // Fin Especimenes
  const columnsFiles = [
    {
      Header: 'Tipo de documento',
      accessor:  d => {
        return ((d.tipo) && (d.tipo.domainName !== '') && (d.tipo.domainName !== ' ')) ? `${d.tipo.domainName}` : '---';
      }
    },
    {
      Header: 'Nombre del documento',
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? `${d.nombre}` : '---';
      }
    },
    {
      Header: 'Fecha cargue',
      accessor:  d => {
        return ((d.fecha_creacion) && (d.fecha_creacion !== '') && (d.fecha_creacion !== ' ')) ? `${splitDate(d.fecha_creacion)}` : '---';
      }
    },
    {
      Header: 'Usuario',
      accessor:  d => {
        return ((d.usuario_modificacion) && (d.usuario_modificacion !== '') && (d.usuario_modificacion !== ' ')) ? `${d.usuario_modificacion}` : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Soporte',
            visible: true,
            handleAction: async () => {
              await getByIdSoporteRequest(original._id, 'view');
              setAnimation('zoom');
              setModalFile(!modalFile);
            }
          },
          {
            id: original._id,
            iconName: 'edit',
            title: 'Actualizar Soporte',
            visible: original.nuevo || (mode !== 'view' && mode !== 'responder' && mode !== 'recepcion'),
            handleAction: async () => {
              await getByIdSoporteRequest(original._id, 'edit');
              setAnimation('zoom');
              setModalFile(!modalFile);
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsContacts = [
    {
      Header: 'Nombre del Funcionario',
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? `${d.nombre}` : '---';
      }
    },
    {
      Header: 'Autoridad Ambiental',
      accessor:  d => {
        return ((d.autoridad_ambiental) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ')) ? d.autoridad_ambiental.nombre : '---';
      }
    },
    {
      Header: "Tipo",
      accessor:  d => {
        return ((d.tipo_entidad_disposicion) && (d.tipo_entidad_disposicion.domainName !== '') && (d.tipo_entidad_disposicion.domainName !== ' ')) ? d.tipo_entidad_disposicion.domainName : '---';
      }
    },
    {
      Header: "Identificación",
      accessor: d => {
        return ((d.numero_documento) && (d.numero_documento !== '') && (d.numero_documento !== ' ')) ? d.numero_documento : '---';
      }
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'cancel',
            title: 'Desactivar Responsable',
            visible: (mode !== 'view' && mode !== 'responder' && mode !== 'recepcion'),
            handleAction: async () => {
              confirmAlert({
                title: 'DESACTIVAR FUNCIONARIO',
                message: `¿Esta seguro que desea desactivar al funcionario: ${((original.nombre !== '') && (original.nombre !== undefined)) ? original.nombre : '---'}`,
                buttons: [
                  {
                    label: "DESACTIVAR FUNCIONARIO",
                    onClick: async () => {
                      await trasladoContactDelete(original._id);
                    }
                  },
                  {
                    label: "VOLVER AL LISTADO",
                    onClick: () => {}
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true
              });
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsEspecimenes = [
    {
      Header: 'Cod. de expediente',
      accessor: d => {
        return ((d.expediente) && (d.expediente.codigo_expediente !== '') && (d.expediente.codigo_expediente  !== ' ')) ? d.expediente.codigo_expediente : '---';
      }
    },
    {
      Header: 'No. Acta única',
      accessor: d => {
        return ((d.expediente) && (d.expediente.acta !== '') && (d.expediente.acta  !== ' ')) ? d.expediente.acta : '---';
      }
    },
    {
      Header: 'Cod. de traslado',
      accessor: d => {
        return ((d.traslado) && (d.traslado.codigo !== '') && (d.traslado.codigo  !== ' ')) ? d.traslado.codigo : '---';
      }
    },
    {
      Header: 'Clase de recurso',
      accessor:  d => {
        return ((d.clase_recurso) && (d.clase_recurso.domainName !== '') && (d.clase_recurso.domainName !== ' ')) ? d.clase_recurso.domainName : '---';
      }
    },
    {
      Header: 'Estado',
      accessor:  d => {
        return ((d.estado) && (d.estado.domainName !== '') && (d.estado.domainName !== ' ')) ? d.estado.domainName : '---';
      }
    },
    {
      Header: 'Parte, producto o subproducto',
      accessor: d => {
        return ((d.parte_producto) && (d.parte_producto.domainName !== '') && (d.parte_producto.domainName !== ' ')) ? d.parte_producto.domainName : '---';
      }
    },
    {
      Header: 'Nombre Científico',
      accessor: d => {
        return ((d.taxonomia) && (d.taxonomia.canonicalName !== '') && (d.taxonomia.canonicalName !== ' ')) ? d.taxonomia.canonicalName : '---';
      }
    },
    {
      Header: 'Nombre Común',
      accessor: d => {
        return ((d.nombre_comun) && (d.nombre_comun !== '') && (d.nombre_comun !== ' ')) ? d.nombre_comun : '---';
      }
    },
    {
      Header: 'Sexo',
      accessor: d => {
        return ((d.sexo) && (d.sexo.domainName !== '') && (d.sexo.domainName !== ' ')) ? d.sexo.domainName : '---';
      }
    },
    {
      Header: 'Estado de desarrollo',
      accessor: d => {
        return ((d.estado_desarrollo) && (d.estado_desarrollo.domainName !== '') && (d.estado_desarrollo.domainName !== ' ')) ? d.estado_desarrollo.domainName : '---';
      }
    },
    {
      Header: 'Estado de posesión',
      accessor: d => {
        return ((d.estado_posesion_expediente) && (d.estado_posesion_expediente.domainName !== '') && (d.estado_posesion_expediente.domainName !== ' ')) ? d.estado_posesion_expediente.domainName : '---';
      }
    },
    {
      Header: 'Entidad actual',
      accessor: d => {
        return ((d.entidad_disposicion_inicial) && (d.entidad_disposicion_inicial !== '') && (d.entidad_disposicion_inicial !== ' ')) ? d.entidad_disposicion_inicial.nombre : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Especímen',
            visible: true,
            handleAction: async () => {
              await trasladoEspecimenSelected(original._id, 'view');
              handleOpenModalEspecimenes();
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsEspecimenesAddaded = columnsEspecimenes.filter((_, index) => index !== 11);

  useEffect(() => {
    if(match.params.mode !== 'view') {
      loadTrasladoSelected();
    }
  }, [selected, match.params.mode]);

  useEffect(() => {
    if(match.params.mode !== 'view') {
      loadSolicitante();
    }
  }, [solicitante, match.params.mode]);

  useEffect(() => {
    if(match.params.mode === 'view' || match.params.mode === 'responder' || match.params.mode === 'recepcion') {
      loadEspecimenesSelected();
    }
  }, [selected, loadEspecimenesSelected])

  useEffect(() => {
    if(!isLoggedIn) {
      gotoLogin();
    } else if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  useEffect(() => {
    if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
      clearTraslado();
    }
  }, [clearTraslado, match.params.id, match.params.mode]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            {!loadedInfo && (
              <>
                <div>Cargando información por favor espere un momento...</div>
                <div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              </>
            )}
            <PageHeader>
              <ContainerHeader>
                <div className="header-col">
                  <H5>{(mode === null && ('Crear Traslado')) || (mode === 'view' && ('Ver Traslado')) || (mode === 'responder' && ('Responder Traslado')) || (mode === 'recepcion' && ('Recepción Traslado'))}</H5>
                </div>
                <div className="header-col">
                  <span className="text-info">Fecha de solicitud</span>
                  <H5>{selected ? parseDateFormat(splitDate(selected.fecha_solicitud)) : getActualDateFormat()}</H5>
                </div>
                <div className="header-col">
                  <span className="text-info">Estado</span>
                  <H5>{selected ? selected.estado.domainName : '-- -- --'}</H5>
                </div>
                <div className="header-col">
                  <span className="text-info">Cod. del traslado</span>
                  <H5>{selected ? selected.codigo : '-- -- --'}</H5>
                </div>
              </ContainerHeader>
            </PageHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card title=" ">
                <CardTitleBox>
                  <H5>Datos Generales</H5>
                </CardTitleBox>
                <GridContainer>
                  <Row>
                    <Col3>
                      <Label>
                        TIPO DE DISPOSICIÓN <span className="obligatory">*</span><br />
                        {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                          <>
                            <input
                              name="tipo"
                              value={15000001}
                              type="radio"
                              ref={register({ required: true })}
                            /> Provisional
                            <input
                              name="tipo"
                              value={15000002}
                              type="radio"
                              ref={register({ required: true })}
                              defaultChecked
                            /> Final
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.tipo.domainName || '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ENTIDAD QUE SOLICITA EL TRASLADO <span className="obligatory">*</span>
                        {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                          <>
                            <Input
                              name="solicitante_autoridad"
                              type="text"
                              ref={register({ required: true })}
                              readOnly
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.solicitante_autoridad.nombre || '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ENTIDAD DE DESTINO <span className="obligatory">*</span>
                        {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                          <>
                            <Select name="entidad_disposicion_destino" ref={register({ required: true })}>
                              {Number(watch('tipo')) === 15000001 ? (
                                <SelectOptions selectOptions={entidadesProvisional}/>
                              ) : (
                                <SelectOptions selectOptions={entidadesFinal}/>
                              )}
                            </Select>
                            {errors.entidad_disposicion_destino && errors.entidad_disposicion_destino.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La entidad de destino es requerida!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.entidad_disposicion_destino.nombre || '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        FECHA DE ENVÍO <span className="obligatory">*</span>
                        {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="fecha_envio"
                              type="date"
                              placeholder="mm/dd/yyyy"
                              ref={register({ required: true })}
                            />
                            {errors.fecha_envio && errors.fecha_envio.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La fecha de envío es requerida!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{parseDateFormat(splitDate(selected.fecha_envio)) || '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    {(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') && (
                      <Col3>
                        <SpanContact style={{ alignSelf: 'center' }} onClick={(e) => handleOpenModalContacts(e)}>
                          <span className="button-item"><Icon>add_circle</Icon> AGREGAR RESPONSABLE</span>
                        </SpanContact>
                      </Col3>
                    )}
                    <Col3>
                      <Label />
                    </Col3>
                  </Row>
                  <Row>
                    <Col1>
                      <SpanTextInfo>Lista de funcionarios responsables del traslado. Debe existir al menos uno.</SpanTextInfo>
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      <PaginationTable columns={columnsContacts} data={contacts} />
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        ADJUNTAR SOPORTES <br />
                        <PaginationTableRowSelect columns={columnsFiles} data={filesAux} onClick={handleDeleteFiles} eraser={(!selected)} textEraser="QUITAR" /><br />
                      </Label>
                    </Col1>
                  </Row>
                  {(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') && (
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        <Label>
                          <Button type="button" onClick={(e) => handleOpenModalFiles(e)}>
                            ADJUNTAR
                          </Button>
                        </Label>
                      </Col>
                    </Row>
                  )}
                </GridContainer>
              </Card>
              <Card title=" ">
                <CardTitleBox>
                  <H5>Especímenes</H5>
                </CardTitleBox>
                <GridContainer>
                  <Row>
                    <Col1>
                      {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                        <SpanDivide>
                          <br />Lista de especímenes asociados al traslado.
                        </SpanDivide>
                      ) : (
                        <SpanDivide>
                          <br />Lista de especímenes asociados al traslado.
                        </SpanDivide>
                      )}
                    </Col1>
                  </Row>
                  {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' && (
                    <Row>
                      <Col1>
                        <Label style={{ textTransform: 'none' }}>
                          Estado del espécimen <span className="obligatory">*</span><br />
                          <>
                            <input
                              name="traslado"
                              value
                              type="radio"
                              ref={register({ required: true })}
                              onChange={(e) => handleChangeTraslado(e)}
                              defaultChecked
                            /> Sin traslado
                            <input
                              name="traslado"
                              value={false}
                              type="radio"
                              ref={register({ required: true })}
                              onChange={(e) => handleChangeTraslado(e)}
                            /> Trasladado
                          </>
                        </Label>
                      </Col1>
                    </Row>
                  )}
                  <Row>
                    <Col3>
                      <Label>
                        CLASE DE RECURSO
                        <Select style={{ width: '100%' }} name="clase_recurso" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposRecurso} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ESTADO
                        <Select style={{ width: '100%' }} name="estado" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimen} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        PARTE, PRODUCTO O SUBPRODUCTO
                        <Select style={{ width: '100%' }} name="parte_producto" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposParte} />
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        CATEGORÍA TAXONÓMICA
                        <Select style={{ width: '100%' }} name="taxonRank" ref={register}>
                          <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NOMBRE CIENTÍFICO
                        <Autocomplete
                          name="canonicalName"
                          placeholder="Indique nombre científico"
                          suggestions={suggestions}
                          loading={loadingAutoComplete}
                          activeSuggestion={activeSuggestion}
                          showSuggestions={showSuggestions}
                          onChange={handleAutoCompleteChange}
                          onKeyDown={handleAutoCompleteKeyDown}
                          onClick={handleAutoCompleteClick}
                          userInput={userInput}
                        />
                        <Input
                          style={{ width: '100%' }}
                          name="taxonomia"
                          type="hidden"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NOMBRE COMÚN
                        <Input
                          style={{ width: '100%' }}
                          name="nombre_comun"
                          type="text"
                          placeholder="Ingrese nombre común"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        SEXO
                        <Select style={{ width: '100%' }} name="sexo" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposSexo} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ESTADO DE DESARROLLO
                        <Select style={{ width: '100%' }} name="estado_desarrollo" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.estadosDesarrollo} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        {(String(true) === watch('traslado') || (mode === 'view' || mode === 'responder' || mode === 'recepcion')) && (
                          <>
                            CÓDIGO DEL EXPEDIENTE
                            <Input
                              style={{ width: '100%' }}
                              name="codigo_expediente"
                              type="text"
                              placeholder="Ingrese código del expediente"
                              ref={register}
                            />
                          </>
                        )}
                      </Label>
                      <Label>
                        {(String(false) === watch('traslado') || (mode === 'view' || mode === 'responder' || mode === 'recepcion')) && (
                          <>
                            CÓDIGO DEL TRASLADO
                            <Input
                              style={{ width: '100%' }}
                              name="codigo_traslado"
                              type="text"
                              placeholder="Ingrese código del traslado"
                              ref={register}
                            />
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col3>
                      <Label>
                        {(String(true) === watch('traslado') || (mode === 'view' || mode === 'responder' || mode === 'recepcion')) && (
                          <>
                            NÚMERO DE ACTA ÚNICA
                            <Input
                              style={{ width: '100%' }}
                              name="acta"
                              type="text"
                              placeholder="Ingrese acta del expediente"
                              ref={register}
                            />
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={(e) => handleClearSearchFieldsEspecimen(e)}>
                          LIMPIAR FORMULARIO
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={(e) => handleSearchFieldsEspecimen(e)}>
                          BUSCAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                  {(mode !== 'view' && mode !== 'responder' && mode !== 'recepcion') && (
                    <>
                      <Row>
                        <Col1>
                          <SpanTextInfo>Seleccione los especímenes que quiere asociar al traslado y luego de click en agregar</SpanTextInfo>
                        </Col1>
                      </Row>
                      <Row>
                        <Col1>
                          {/* {isLoadingEspecimenes ? (
                            <div style={{justifyContent: 'flex-end', width: '100%'}}>
                              Cargando información por favor espere un momento...
                              <SkeletonTheme color="#f42f6391" highlightColor="#444">
                                <Skeleton height={34}/>
                              </SkeletonTheme>
                            </div>
                          ) : ( */}
                          {(loadedInfo) && (
                            <CardContainerBody>
                              <div className="card-body-row">
                                <PaginationTableRowSelectServerSide columns={columnsEspecimenes} data={especimenes} fetchData={fetchData} loading={loading} isLoading={isLoadingEspecimenes} pageCount={pageCountEspecimenes} onClick={handleAddEspecimenes} eraser={(!selected)} textEraser="AGREGAR" />
                              </div>
                            </CardContainerBody>
                          )}
                          {/* )} */}
                        </Col1>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col1>
                      {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' ? (
                        <SpanTextInfo>Lista de especímenes asociados al traslado. Sí requiere remover uno o mas espécimen de la lista de traslado, selecciones y de click en borrar</SpanTextInfo>
                      ) : (
                        <SpanTextInfo>Lista de especímenes asociados al traslado.</SpanTextInfo>
                      )}
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      {/* {isLoadingEspecimenesAddaded ? (
                        <div style={{justifyContent: 'flex-end', width: '100%'}}>
                          Cargando información por favor espere un momento...
                          <SkeletonTheme color="#f42f6391" highlightColor="#444">
                            <Skeleton height={34}/>
                          </SkeletonTheme>
                        </div>
                      ) : ( */}
                      {(mode === 'view' || mode === 'responder' || mode === 'recepcion') ? (
                        <PaginationTableRowSelectServerSide columns={(((mode === 'view' || mode === 'responder' || mode === 'recepcion') && (columnsEspecimenesAddaded)) || (columnsEspecimenes))} data={especimenesAddaded} fetchData={fetchData} loading={loading} isLoading={isLoadingEspecimenes} pageCount={pageCountEspecimenes} onClick={handleDeleteEspecimenes} eraser={(!selected)} textEraser="BORRAR" />
                      ) : (
                        <PaginationTableRowSelect columns={(((mode === 'view' || mode === 'responder' || mode === 'recepcion') && (columnsEspecimenesAddaded)) || (columnsEspecimenes))} data={especimenesAddaded} onClick={handleDeleteEspecimenes} eraser={(!selected)} textEraser="BORRAR" />
                      )}
                      {/* )} */}
                    </Col1>
                  </Row>
                  {mode !== 'view' && mode !== 'responder' && mode !== 'recepcion' && (
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        <Label style={{ width: 'auto' }}>
                          <Button type="button" onClick={(e) => handleClose(e)}>
                            CANCELAR
                          </Button>
                        </Label>
                        {isLoading ? (
                          <Label style={{justifyContent: 'flex-end', width: '25vw'}}>
                            <Loader message="Creando el traslado por favor espere un momento... No recargue la página ya que de acuerdo a los archivos adjuntos, es el tiempo de procesamiento"/>
                          </Label>
                        ) : (
                          <Label style={{ width: 'auto' }}>
                            <Button type="submit">
                              Crear Traslado
                            </Button>
                          </Label>
                        )}
                      </Col>
                    </Row>
                  )}
                </GridContainer>
              </Card>
              {/* // TODO: Aca de acuerdo al rol del usuario realizar la validación para que solo muestre esta sección para el rol traslados coordinador de entidades  */}
              {(mode === 'view' || mode === 'responder' || mode === 'recepcion') && (
                <Card title=" ">
                  <CardTitleBox>
                    <H5>Responder traslado</H5>
                  </CardTitleBox>
                  <GridContainer>
                    <Row>
                      <Col3 style={{ justifyContent: 'flex-start' }}>
                        {mode !== 'view' ? (
                          <Label>
                            RESPUESTA A TRASLADO <span className="obligatory">*</span>
                            <Select style={{ width: '100%' }} name="respuesta" ref={register} readOnly={((mode === 'recepcion' && (selected.respuesta && selected.respuesta !== 0)))}>
                              <SelectOptions selectOptions={domainsByKey.tiposRespuestaTraslado} />
                            </Select>
                            {errors.respuesta && errors.respuesta.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La respuesta es requerida!
                              </p>
                            )}
                          </Label>
                        ) : (
                          <>
                            <Label>RESPUESTA A TRASLADO <span className="obligatory">*</span></Label>
                            <br /><br /><SpanView>{selected.respuesta.domainName || '---'}</SpanView>
                          </>
                        )}
                      </Col3>
                      <Col3 style={{ justifyContent: 'flex-start' }}>
                        {mode !== 'view' ? (
                          <Label>
                            FECHA DE RECEPCIÓN <br />
                            <Input
                              style={{ width: '100%' }}
                              name="fecha_recepcion"
                              type="date"
                              placeholder="dd/mm/yyyy"
                              ref={register}
                              readOnly={(mode === 'responder')}
                            />
                            {errors.fecha_recepcion && errors.fecha_recepcion.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La fecha de recepción es requerida!
                              </p>
                            )}
                          </Label>
                        ) : (
                          <>
                            <Label>FECHA DE RECEPCIÓN</Label>
                            <br /><br /><SpanView>{(selected.fecha_recepción && splitDate(selected.fecha_recepción)) || (selected.fecha_recepcion && splitDate(selected.fecha_recepcion)) || ('---')}</SpanView>
                          </>
                        )}
                      </Col3>
                      <Col3 style={{ justifyContent: 'flex-start' }}>
                        <SpanTextInfo style={{ alignSelf: 'center' }}>
                          Fecha en la que se recibieron los especímenes. Cuando se documente este campo se finaliza el traslado
                        </SpanTextInfo>
                      </Col3>
                    </Row>
                    <Row>
                      <Col1 style={{ justifyContent: 'flex-start' }}>
                        {mode !== 'view' ? (
                          <Label>
                            OBSERVACIONES
                            <Input
                              name="comentario"
                              type="text"
                              ref={register}
                              placeholder="Ingrese las observaciones aquí"
                              readOnly={(mode === 'recepcion')}
                            />
                            {errors.comentario && errors.comentario.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El comentario es requerido!
                              </p>
                            )}
                          </Label>
                        ) : (
                          <>
                            <Label>OBSERVACIONES</Label>
                            <br /><br /><SpanView>{selected.comentario || '---'}</SpanView>
                          </>
                        )}
                      </Col1>
                    </Row>
                    {mode !== 'view' ? (
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Col right>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleCancelarResponderTraslado(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleAceptarResponderTraslado(e)}>
                              ACEPTAR
                            </Button>
                          </Label>
                        </Col>
                      </Row>
                    ) : (
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Col right>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleCancelarResponderTraslado(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                        </Col>
                      </Row>
                    )}
                  </GridContainer>
                </Card>
              )}
            </Form>
            {modalContacts && <ModalContactsTraslado onClick={handleOpenModalContacts} modalContacts={modalContacts} animation={animation} />}
            {modalFile && <ModalSoporteTraslado onClick={(e) => handleOpenModalFiles(e)} modalFile={modalFile} animation={animation} />}
            {modalEspecimenes && <ModalEspecimenTraslado onClick={handleOpenModalEspecimenes} modalEspecimen={modalEspecimenes} animation={animation} />}
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
}

TrasladoForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

TrasladoForm.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(TrasladoForm);
