import styled from "styled-components";
import { pxToRem } from "../utils";

export const P = styled.p`
  font-weight: 100;
  padding:  0 ${pxToRem(8)};
  margin: 0 auto ${pxToRem(8)};
  text-align: ${props => props.center ? "center" : "left"};
  width: 100%;
  b {
    font-weight: 400;
  }
`;
