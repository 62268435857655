/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import ReactTooltip from 'react-tooltip';
import Rodal from 'rodal';
import { confirmAlert } from 'react-confirm-alert';
import useForm from 'react-hook-form';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import nanoid from 'nanoid';
// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Input } from '../form-ui-components/Input';
import { Select } from '../form-ui-components/Select';
import { SelectOptions } from '../form-ui-components/SelectOptions';
import { Button } from '../form-ui-components/Button';
import { ButtonLight } from '../form-ui-components/ButtonLight';
import PaginationTable from '../Table/PaginationTable';
import ButtonsActionRow from '../Table/ButtonsActionRow';
import { CardTitleBox } from '../CardTitleBox';
import { H5 } from '../H5';
import { Col, Col1, Col2, Col3, Col4, GridContainer, Row } from '../Grid';

/* Hooks */
import {
  useExpedientes,
  useDomains,
  useAutoridadAmbiental,
  useContact,
  useLogin
} from '../../hooks';
/* Style Components */
import { ModalContainerBody, CardContainer } from './styled';
import { CardContainerBody, SpanTextInfo, SpanDivide } from '../../pages/styled';

import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

const ModalContact = ({ history, onClick, modalContacts, animation }) => {
  const [ loaded, setLoaded ] = useState(false);
  const { token } = useLogin();
  const { domainsByKey } = useDomains();
  const {
    isLoading,
    error,
    data: contacts,
    getAllContactsSearch
  } = useContact();
  const {
    contacts: contactsExpediente,
    addContactToExpedienteRequest,
    cleanContactExpediente,
  } = useExpedientes();
  const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();

  const { register, reset, handleSubmit } = useForm();

  const arrEstados = [{ _id: true, nombre: 'Activo' }, { _id: false, nombre: 'Inactivo' }];

  const handleAddContact = e => {
    e.preventDefault();

    confirmAlert({
      title: 'IR AL FORMULARIO DE AGREGAR CONTACTO',
      message: `¿Esta seguro que desea ir al formulario de crear el contacto?`,
      buttons: [
        {
          label: "IR A AGREGAR CONTACTO",
          onClick: async () => {
            await cleanContactExpediente();
            history.push('/contacts-form');
          }
        },
        {
          label: "VOLVER AL LISTADO DE CONTACTOS",
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
    document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
  };

  const handleClearFields = async e => {
    e.preventDefault();
    reset();
    await getAllContactsSearch({ estado: true }, token);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data.estado = data.estado ? (String(true) === data.estado) : null;
      await getAllContactsSearch(removeEmpty(data), token);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const load = useCallback(async () => {
    await Promise.all([getAllContactsSearch({ estado: true }, token), searchAutoridadAmbiental({}, token)]);
  }, [
    getAllContactsSearch,
    searchAutoridadAmbiental
  ]);

  const columns = [
    {
      Header: 'Autoridad Ambiental',
      accessor:  d => {
        return ((d.autoridad_ambiental) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ')) ? `${d.autoridad_ambiental.nombre}` : '---';
      }
    },
    {
      Header: 'Tipo Entidad',
      accessor:  d => {
        return ((d.tipo_entidad_disposicion) && (d.tipo_entidad_disposicion.domainName !== '') && (d.tipo_entidad_disposicion.domainName !== ' ')) ? d.tipo_entidad_disposicion.domainName : '---';
      }
    },
    {
      Header: "Nombre",
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? d.nombre : '---';
      }
    },
    {
      Header: "Correo",
      accessor: d => {
        return ((d.correo_electronico) && (d.correo_electronico !== '') && (d.correo_electronico !== ' ')) ? d.correo_electronico : '---';
      }
    },
    {
      Header: "Estado",
      accessor: d => {
        return ((d.estado) && (d.estado !== '') && (d.estado !== ' ')) ? 'Activo' : 'Inactivo';
      }
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'add_circle',
            title: 'Agregar Contacto',
            visible: original.estado,
            handleAction: async () => {
              confirmAlert({
                title: 'AGREGAR CONTACTO AL EXPEDIENTE',
                message: `¿Esta seguro que desea agregar el contacto: ${((original.nombre) && (original.nombre !== undefined)) ? original.nombre : '---'}`,
                buttons: [
                  {
                    label: "AGREGAR CONTACTO",
                    onClick: async () => {
                      await addContactToExpedienteRequest(original);
                      onClick();
                    }
                  },
                  {
                    label: "VOLVER AL LISTADO",
                    onClick: () => {}
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true
              });
              document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
            }
          },
        ];
        const isAddedExpediente = contactsExpediente.filter((item) => item._id === original._id)
        return (isAddedExpediente.length === 1) ? <span style={{ color: 'red' }}>Adicionado</span> : <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  useEffect(() => {
    if(!loaded) {
      load();
      setLoaded(true);
    }
  }, [loaded, load, contacts]);

  return ReactDOM.createPortal(
    <Rodal visible={modalContacts}
          onClose={onClick}
          animation={animation}
          customStyles={customStyles}
          className="modal-rodal-container"
          width={80}
          height={90}
          measure="%"
          closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <br />Asociar contacto responsable del expediente.
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <SpanTextInfo light>
                    Ingrese los valores de búsqueda para seleccionar al contacto responsable. Si el contacto no está en la lista, comuníquese con a la Autoridad Ambiental para que cree el contacto dentro del sistema
                  </SpanTextInfo>
                </Col1>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    NOMBRE
                    <Input
                      style={{ width: '100%' }}
                      name="nombre"
                      type="text"
                      placeholder="Escriba el nombre"
                      ref={register}
                    />
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    ESTADO
                    <Select style={{ width: '100%' }} name="estado" ref={register}>
                      <SelectOptions selectOptions={arrEstados} />
                    </Select>
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    CORREO
                    <Input
                      style={{ width: '100%' }}
                      name="correo_electronico"
                      type="text"
                      placeholder="Escriba el correo"
                      ref={register}
                    />
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col4>
                  <Label>
                    AUTORIDAD AMBIENTAL
                    <Select style={{ width: '100%' }} name="autoridad_ambiental" ref={register}>
                      <SelectOptions selectOptions={autoridadesAmbientales} />
                    </Select>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    TIPO DE ENTIDAD
                    <Select style={{ width: '100%' }} name="tipo_entidad_disposicion" ref={register}>
                      <SelectOptions selectOptions={domainsByKey.tiposEntidadDisposicion} />
                    </Select>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    <Button type="button" onClick={(e) => handleClearFields(e)}>
                      LIMPIAR FORMULARIO
                    </Button>
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    <Button type="submit">
                      BUSCAR
                    </Button>
                  </Label>
                </Col4>
              </Row>
            </GridContainer>
          </Form>
          <GridContainer>
            <Row>
              <Col1>
                {isLoading ? (
                  <div style={{justifyContent: 'flex-end', width: '100%'}}>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                ) : (
                  <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                    <CardContainerBody>
                      <div className="card-body-row">
                        <PaginationTable columns={columns} data={contacts}/>
                      </div>
                    </CardContainerBody>
                    {error && (
                      <CardContainerBody>
                        <CardTitleBox className="error" error>
                          <H5>Ups, hubo un error: {error}</H5>
                        </CardTitleBox>
                      </CardContainerBody>
                    )}
                  </div>
                )}
              </Col1>
            </Row>
            {/* <Row>
              <Col1>
                <Label>
                  <Button
                    type="button"
                    onClick={(e) => handleAddContact(e)}
                    data-for="main-contact"
                    data-tip="Debe crear el contacto antes de continuar con el diligenciamiento del expediente.<br />
                    De ser necesario contacte a la Autoridad Ambiental del funcionario responsable para que cree el contacto"
                    data-iscapture="true"
                    data-multiline="true"
                    data-place="right"
                    data-class="tooltipfield"
                    style={{ cursor: 'pointer' }}
                  >
                    Crear Contacto
                  </Button>
                </Label>
              </Col1>
            </Row> */}
          </GridContainer>
        </ModalContainerBody>
        {/* <ReactTooltip
          id="main-contact"
          type="light"
          effect="float"
          wrapper="span"
          multiline
        /> */}
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
}

ModalContact.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  modalContacts: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalContact.defaultProps = {
  history: {},
  modalContacts: false,
  animation: '',
  onClick: () => {},
};

export default withRouter(ModalContact);
