import { BASE_FONT_SIZE } from "./config/const";

export function pxToRem(target) {
  return `${(target / BASE_FONT_SIZE) * 1}rem`;
}

export const removeEmpty = obj =>
  Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => ((v !== null && v !== "") || (v === false)))
  );

export const decimalDegreesToSexagesimalDegrees = latLng => {
  const zeroPad = n => {
    n = Math.round(n);
    return n < 10 ? `0${  n}` : n;
  };
  const sexagesimal = (p, pos, neg) => {
    const n = Math.abs(p);
    const degs = Math.floor(n);
    const mins = (n - degs) * 60;
    const secs = (mins - Math.floor(mins)) * 60;
    const frac = Math.round((secs - Math.floor(secs)) * 100);
    return {
      grados: degs,
      minutos: mins,
      segundos: secs,
      hemisferio: p >= 0 ? pos : neg
    };
  };

  return {
    latitud: sexagesimal(latLng.lat, "N", "S"),
    longitud: sexagesimal(latLng.lng, "E", "W")
  };
};

export const isPresent = (rows, index) => {
  let esta = false;
  Object.keys(rows).map(key => {
    if(key === index.toString()){
      esta = true;
    }
  });
  return esta;
};
