import {
  TRASLADO_SEARCH_INIT,
  TRASLADO_SEARCH_SUCCESS,
  TRASLADO_SEARCH_ERROR,
  TRASLADO_CREATE_INIT,
  TRASLADO_CREATE_SUCCESS,
  TRASLADO_CREATE_ERROR,
  TRASLADO_ESPECIMENES_SEARCH_INIT,
  TRASLADO_ESPECIMENES_SEARCH_SUCCESS,
  TRASLADO_ESPECIMENES_SEARCH_ERROR,
  TRASLADO_USUARIO_ENTIDAD_SUCCESS,
  TRASLADO_USUARIO_ENTIDAD_ERROR,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR,
  TRASLADO_LOAD_SOPORTE_SUCCESS,
  TRASLADO_LOAD_SOPORTE_ERROR,
  TRASLADO_CANCELAR_INIT,
  TRASLADO_CANCELAR_SUCCESS,
  TRASLADO_CANCELAR_ERROR,
  TRASLADO_CLEAR_SELECTED,
  TRASLADO_CONTACT_ADD_SUCCESS,
  TRASLADO_CONTACT_ADD_ERROR,
  TRASLADO_CONTACT_DELETE_SUCCESS,
  TRASLADO_CONTACT_DELETE_ERROR,
  TRASLADO_ESPECIMEN_SELECTED_SUCCESS,
  TRASLADO_ESPECIMEN_SELECTED_ERROR,
  TRASLADO_ESPECIMENES_ADD_SUCCESS,
  TRASLADO_ESPECIMENES_ADD_ERROR,
  TRASLADO_ESPECIMENES_DELETE_SUCCESS,
  TRASLADO_ESPECIMENES_DELETE_ERROR,
  TRASLADO_RESPUESTA_SUCCESS,
  TRASLADO_RESPUESTA_ERROR,
  TRASLADO_SOPORTES_ADD_SUCCESS,
  TRASLADO_SOPORTES_ADD_ERROR,
  TRASLADO_SOPORTES_UPDATE_SUCCESS,
  TRASLADO_SOPORTES_UPDATE_ERROR,
  TRASLADO_SOPORTES_DELETE_SUCCESS,
  TRASLADO_SOPORTES_DELETE_ERROR,
  FILE_CLEAR_SELECTED,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_ERROR,
  TRASLADO_SOPORTES_DOWNLOAD_INIT,
  TRASLADO_SOPORTES_DOWNLOAD_SUCCESS,
  TRASLADO_SOPORTES_DOWNLOAD_ERROR,
  CLEAN_CONTACTS_TO_TRASLADO_SUCCESS,
  CLEAN_CONTACTS_TO_TRASLADO_ERROR,
  SEARCH_ESPECIMENES_SIN_TRASLADO_INIT,
  SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS,
  SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR,
  SEARCH_ESPECIMENES_CON_TRASLADO_INIT,
  SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS,
  SEARCH_ESPECIMENES_CON_TRASLADO_ERROR,
  TRASLADO_LOAD_SELECTED_INIT,
  TRASLADO_LOAD_SELECTED_SUCCESS,
  TRASLADO_LOAD_SELECTED_ERROR
} from "./types";

export const searchInit = () => {
	return {
		type: TRASLADO_SEARCH_INIT
	};
};

export const searchSuccess = (data, pageCount) => {
	return {
		type: TRASLADO_SEARCH_SUCCESS,
		payload: { data, pageCount }
	};
};

export const searchError = error => {
	return {
		type: TRASLADO_SEARCH_ERROR,
		payload: error
	};
};

export const createInit = () => {
	return {
		type: TRASLADO_CREATE_INIT
	};
};

export const createSuccess = data => {
	return {
		type: TRASLADO_CREATE_SUCCESS,
		payload: { data }
	};
};

export const createError = error => {
	return {
		type: TRASLADO_CREATE_ERROR,
		payload: error
	};
};

export const trasladoEspecimenesInit = () => {
	return {
		type: TRASLADO_ESPECIMENES_SEARCH_INIT
	};
};

export const trasladoEspecimenesSuccess = (data, pageCount) => {
	return {
		type: TRASLADO_ESPECIMENES_SEARCH_SUCCESS,
		payload: { data, pageCount }
	};
};

export const trasladoEspecimenesError = error => {
	return {
		type: TRASLADO_ESPECIMENES_SEARCH_ERROR,
		payload: error
	};
};

export const getSolicitanteSuccess = data => {
	return {
		type: TRASLADO_USUARIO_ENTIDAD_SUCCESS,
		payload: { data }
	};
};

export const getSolicitanteError = error => {
	return {
		type: TRASLADO_USUARIO_ENTIDAD_ERROR,
		payload: error
	};
};

export const trasladoEntidadDestinoInit = () => {
	return {
		type: TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT
	};
};

export const trasladoEntidadDestinoSuccess = (data, type) => {
	return {
		type: TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS,
		payload: { data, type }
	};
};

export const trasladoEntidadDestinoError = error => {
	return {
		type: TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR,
		payload: error
	};
};

export const trasladoLoadSoporteSuccess = data => {
	return {
		type: TRASLADO_LOAD_SOPORTE_SUCCESS,
		payload: { data }
	};
};

export const trasladoLoadSoporteError = error => {
	return {
		type: TRASLADO_LOAD_SOPORTE_ERROR,
		payload: error
	};
};

export const trasladoCancelarInit = () => {
	return {
		type: TRASLADO_CANCELAR_INIT
	};
};

export const trasladoCancelarSuccess = data => {
	return {
		type: TRASLADO_CANCELAR_SUCCESS,
		payload: { data }
	};
};

export const trasladoCancelarError = error => {
	return {
		type: TRASLADO_CANCELAR_ERROR,
		payload: error
	};
};

export const clearSelectedInit = () => {
  return {
		type: TRASLADO_CLEAR_SELECTED
	};
};

export const trasladoContactAddSuccess = data => {
	return {
		type: TRASLADO_CONTACT_ADD_SUCCESS,
		payload: { data }
	};
};

export const trasladoContactAddError = error => {
	return {
		type: TRASLADO_CONTACT_ADD_ERROR,
		payload: error
	};
};

export const trasladoContactDeleteSuccess = id => {
	return {
		type: TRASLADO_CONTACT_DELETE_SUCCESS,
		payload: id
	};
};

export const trasladoContactDeleteError = error => {
	return {
		type: TRASLADO_CONTACT_DELETE_ERROR,
		payload: error
	};
};

export const trasladoEspecimenSelectedSuccess = (id, mode) => {
	return {
		type: TRASLADO_ESPECIMEN_SELECTED_SUCCESS,
		payload: { id, mode }
	};
};

export const trasladoEspecimenSelectedError = error => {
	return {
		type: TRASLADO_ESPECIMEN_SELECTED_ERROR,
		payload: error
	};
};

export const trasladoEspecimenAddSuccess = (selectedRowIds, selectedFlatRows, traslado) => {
	return {
		type: TRASLADO_ESPECIMENES_ADD_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows, traslado }
	};
};

export const trasladoEspecimenAddError = error => {
	return {
		type: TRASLADO_ESPECIMENES_ADD_ERROR,
		payload: error
	};
};

export const trasladoEspecimenDeleteSuccess = (selectedRowIds, selectedFlatRows) => {
	return {
		type: TRASLADO_ESPECIMENES_DELETE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const trasladoEspecimenDeleteError = error => {
	return {
		type: TRASLADO_ESPECIMENES_DELETE_ERROR,
		payload: error
	};
};

export const trasladoRespuestaSuccess = traslado => {
	return {
		type: TRASLADO_RESPUESTA_SUCCESS,
		payload: { traslado }
	};
};

export const trasladoRespuestaError = error => {
	return {
		type: TRASLADO_RESPUESTA_ERROR,
		payload: error
	};
};

// Acciones sobre los soportes
export const trasladoSoportesAddSuccess = data => {
  return {
		type: TRASLADO_SOPORTES_ADD_SUCCESS,
		payload: { data }
	};
};

export const trasladoSoportesAddError = error => {
  return {
		type: TRASLADO_SOPORTES_ADD_ERROR,
		payload: error
	};
};

export const trasladoSoportesUpdateSuccess = data => {
  return {
		type: TRASLADO_SOPORTES_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const trasladoSoportesUpdateError = error => {
  return {
		type: TRASLADO_SOPORTES_UPDATE_ERROR,
		payload: error
	};
};

export const trasladoSoportesDeleteSuccess = (selectedRowIds, selectedFlatRows) => {
  return {
		type: TRASLADO_SOPORTES_DELETE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const trasladoSoportesDeleteError = error => {
  return {
		type: TRASLADO_SOPORTES_DELETE_ERROR,
		payload: error
	};
};

export const clearSelectedFileInit = () => {
  return {
    type: FILE_CLEAR_SELECTED
  }
};

export const selectedFileSuccess = (id, mode) => {
	return {
		type: SELECTED_FILE_SUCCESS,
		payload: { id, mode }
	};
};

export const selectedFileError = error => {
	return {
		type: SELECTED_FILE_ERROR,
		payload: error
	};
};

export const trasladoSoportesDownloadInit = () => {
  return {
		type: TRASLADO_SOPORTES_DOWNLOAD_INIT,
	};
};

export const trasladoSoportesDownloadSuccess = () => {
  return {
		type: TRASLADO_SOPORTES_DOWNLOAD_SUCCESS,
	};
};

export const trasladoSoportesDownloadError = error => {
  return {
		type: TRASLADO_SOPORTES_DOWNLOAD_ERROR,
		payload: error
	};
};

export const cleanContactTrasladoSuccess = () => {
	return {
		type: CLEAN_CONTACTS_TO_TRASLADO_SUCCESS
	};
};

export const cleanContactTrasladoError = error => {
	return {
    type: CLEAN_CONTACTS_TO_TRASLADO_ERROR,
    payload: error
	};
};

export const searchEspecimenesSinTrasladoInit = () => {
  return {
    type: SEARCH_ESPECIMENES_SIN_TRASLADO_INIT
  }
};

export const searchEspecimenesSinTrasladoSuccess = (data, pageCount) => {
  return {
		type: SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS,
		payload: { data, pageCount }
	};
};

export const searchEspecimenesSinTrasladoError = error => {
	return {
    type: SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR,
    payload: error
	};
};

export const searchEspecimenesConTrasladoInit = () => {
  return {
    type: SEARCH_ESPECIMENES_CON_TRASLADO_INIT
  }
};

export const searchEspecimenesConTrasladoSuccess = (data, pageCount) => {
  return {
		type: SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS,
		payload: { data, pageCount }
	};
};

export const searchEspecimenesConTrasladoError = error => {
	return {
    type: SEARCH_ESPECIMENES_CON_TRASLADO_ERROR,
    payload: error
	};
};

export const loadSelectedInit = data => {
  return {
    type: TRASLADO_LOAD_SELECTED_INIT,
    payload: { data }
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: TRASLADO_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: TRASLADO_LOAD_SELECTED_ERROR,
    payload: error
  };
};
