import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import trasladoReducer from '../../store/traslado';

const { dispatchers } = trasladoReducer;

const useTraslado = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { traslado } = state;

  // List of Dispatchers
	const {
    createTrasladoRequest,
    trasladoEspecimenesSearch,
    trasladoEspecimenSelected,
    trasladoEspecimenAdd,
    trasladoEspecimenDelete,
    getUsuarioEntidad,
    searchEntidadDisposicion,
    searchTraslados,
    trasladoCancelarRequest,
    clearSelected,
    trasladoContactAdd,
    trasladoContactDelete,
    trasladoRespuestaRequest,
    cleanContactTraslado,
    trasladoSoportesAdd,
    trasladoSoportesUpdate,
    trasladoSoportesDelete,
    trasladoSoportesDownload,
    getByIdSoporteRequest,
    clearSelectedFile,
    searchEspecimenesSinTraslado,
    searchEspecimenesConTraslado,
    loadSelected,
    getEspecimenesTrasladoRequest
  } = dispatchers;

  // Bind Actions
	const trasladosActions = bindActions({
    createTrasladoRequest,
    trasladoEspecimenesSearch,
    trasladoEspecimenSelected,
    trasladoEspecimenAdd,
    trasladoEspecimenDelete,
    getUsuarioEntidad,
    searchEntidadDisposicion,
    searchTraslados,
    trasladoCancelarRequest,
    clearSelected,
    trasladoContactAdd,
    trasladoContactDelete,
    trasladoRespuestaRequest,
    cleanContactTraslado,
    trasladoSoportesAdd,
    trasladoSoportesUpdate,
    trasladoSoportesDelete,
    trasladoSoportesDownload,
    getByIdSoporteRequest,
    clearSelectedFile,
    searchEspecimenesSinTraslado,
    searchEspecimenesConTraslado,
    loadSelected,
    getEspecimenesTrasladoRequest
  }, dispatch);

  return { ...traslado, ...trasladosActions };
};

export default useTraslado;
