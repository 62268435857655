import styled from "styled-components";
import {pxToRem} from "../../utils";

export const Select = styled.select`
    display: inline-block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: ${pxToRem(8)} ${pxToRem(16)};
    margin-top: ${pxToRem(5)};
    outline: none;
    border: 1px solid transparent;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    pointer-events: ${props => props.readOnly ? 'none' : 'initial'};
    background-color: ${props => props.readOnly ? props.theme.colors.lightGrey : props.theme.colors.white};
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
`;

export default Select;
