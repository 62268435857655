/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import Rodal from 'rodal';
import dayjs from 'dayjs';
/* Hooks */
import {
  useLogin,
  useTraslado,
  useDomains
} from '../../hooks';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import PaginationTable from '../Table/PaginationTable';
import ButtonsActionRow from '../Table/ButtonsActionRow';
import { CardTitleBox } from '../CardTitleBox';
import { Col, Col1, Col2, Col3, GridContainer, Row } from '../Grid';
import { H5 } from '../H5';
import { Input } from '../form-ui-components/Input';
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Select } from '../form-ui-components/Select';
import { SelectOptions } from '../form-ui-components/SelectOptions';
import { Button } from '../form-ui-components/Button';
import Loader from '../Loader';

// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

/* Style Components */
import { ModalContainerBody } from './styled';
import { SpanDivide, SpanView } from '../../pages/styled';

/* Utils */
import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

const ModalSoporteTraslado = ({ onClick, animation, modalFile }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ tmpFile, setTmpFile ] = useState(null);

  const { register, setValue, handleSubmit, errors, reset } = useForm();

  const { userLogin, token } = useLogin();
  const {
    isLoadingFile,
    soporteMode,
    soporteSelected,
    trasladoSoportesAdd,
    trasladoSoportesUpdate,
    trasladoSoportesDownload
  } = useTraslado();
  const { domainsByKey } = useDomains();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const splitDate = timestamp => timestamp.split('T')[0];

  const handleDownloadFile = async () => {
    if(soporteSelected) {
      await trasladoSoportesDownload(soporteSelected, token);
    }
  };

  const handleAttachFiles = e => {
    e.preventDefault();
    document.getElementById('archivos').click();
    e.stopPropagation();
  };

  const handleCancelar = e => {
    e.preventDefault();
    reset();
    onClick(e);
  };

  const handleChangeFiles = e => {
    Object.keys(e.target.files).forEach(key => {
      setTmpFile(e.target.files[key]);
      console.log('name', e.target.files[key].name);
      setValue('nombre_archivo', e.target.files[key].name);
    });
  };

  const loadSoporteSelected = useCallback(() => {
    if(soporteSelected) {
      setValue('nombre_archivo', soporteSelected.nombre_archivo);
      setValue('tipo', soporteSelected.tipo._id);
      setValue('fecha_creacion', splitDate(soporteSelected.fecha_creacion));
      setValue('nombre', soporteSelected.nombre);
      setValue('detalle', soporteSelected.detalle);
      setValue('usuario_modificacion', soporteSelected.usuario_modificacion);
      setValue('usuario_firma', soporteSelected.usuario_firma);
      setValue('cargo', soporteSelected.cargo);
      if(typeof soporteSelected.archivo !== 'string') {
        setTmpFile(soporteSelected.archivo);
      }
    } else {
      setValue('fecha_creacion', getActualDate());
      setValue('usuario_modificacion', userLogin);
    }
  }, [setValue, soporteSelected]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data._id   = soporteSelected ? soporteSelected._id : nanoid();
      data.tipo  = {
        _id: data.tipo,
        domainName: document.getElementById("tipo")[document.getElementById("tipo").selectedIndex].innerHTML
      };
      data.activo         = true;
      data.archivo        = tmpFile;
      data.fecha_creacion = parseDateTime(data.fecha_creacion);
      if(soporteSelected) {
        data.nuevo = false;
        data.memory = true;
        await trasladoSoportesUpdate(removeEmpty(data));
      } else {
        data.nuevo = true;
        data.memory = true;
        await trasladoSoportesAdd(removeEmpty(data));
      }
      reset();
      onClick(e);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if(!loaded) {
      loadSoporteSelected();
      setLoaded(true);
    }
  }, [loaded, loadSoporteSelected]);

  useEffect(() => {
    loadSoporteSelected();
  }, [soporteSelected]);

  return ReactDOM.createPortal(
    <Rodal visible={modalFile}
      onClose={onClick}
      animation={animation}
      customStyles={customStyles}
      className="modal-rodal-container"
      width={35}
      height={90}
      measure="%"
      closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
            <Row>
              <Col1>
                <SpanDivide>
                  <H5>Agregar soportes al traslado.</H5>
                </SpanDivide>
              </Col1>
            </Row>
            <Row>
              {soporteMode !== 'view' ? (
                <>
                  <Col2>
                    <Label>
                      SELECCIONE ARCHIVO <span className="obligatory">*</span>
                      <Input
                        style={{ width: '100%' }}
                        name="nombre_archivo"
                        type="text"
                        placeholder="Buscar archivo..."
                        ref={register({ required: true })}
                      />
                      {errors.nombre_archivo && errors.nombre_archivo.type === "required" && (
                        <p style={{ color: 'red'}}>
                          Debe adjuntar un archivo!
                        </p>
                      )}
                    </Label>
                  </Col2>
                  <Col2>
                    <Label>
                        <Button type="button" onClick={handleAttachFiles}>
                          ADJUNTAR
                        </Button>
                      </Label>
                  </Col2>
                </>
              ) : (
                <Col1>
                  <Label>
                  {(soporteMode === 'view' && soporteSelected.nuevo) ? (
                    <>
                      <br /><SpanView>{soporteSelected.nombre_archivo || '---'}</SpanView>
                    </>
                  ) : (
                    !isLoadingFile ? (
                      <Button type="button" onClick={handleDownloadFile}>
                        DESCARGAR ARCHIVO
                      </Button>
                    ) : (
                      <Loader message="Descargando archivo por favor espere un momento..."/>
                    )
                  )}
                  </Label>
                </Col1>
              )}
              </Row>
              <Row>
                <Col1>
                  <Label>
                    TIPO DE DOCUMENTO <span className="obligatory">*</span>
                    {soporteMode !== 'view' ? (
                      <>
                        <Select id="tipo" style={{ width: '100%' }} name="tipo" ref={register({ required: true })}>
                          <SelectOptions selectOptions={domainsByKey.tiposAdjunto} />
                        </Select>
                        {errors.tipo && errors.tipo.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El tipo de documento es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.tipo.domainName || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    NOMBRE DEL DOCUMENTO <span className="obligatory">*</span>
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="nombre"
                          type="text"
                          placeholder="Escriba el nombre del documento"
                          ref={register({ required: true })}
                        />
                        {errors.nombre && errors.nombre.type === "required" && (
                          <p style={{ color: 'red'}}>
                            Debe adjuntar un archivo!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.nombre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    DETALLES DEL DOCUMENTO
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="detalle"
                          type="text"
                          placeholder="Agregue detalles del documento"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.detalle || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FECHA DE CARGUE <span className="obligatory">*</span>
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="fecha_creacion"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.fecha_creacion && errors.fecha_creacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La fecha de cargue es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{splitDate(soporteSelected.fecha_creacion || '---')}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    USUARIO <span className="obligatory">*</span>
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="usuario_modificacion"
                          type="text"
                          placeholder="Coordinador del expediente"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.usuario_modificacion && errors.usuario_modificacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El usuario es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.usuario_modificacion || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FUNCIONARIO QUE FIRMA EL DOCUMENTO
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="usuario_firma"
                          type="text"
                          placeholder="Escriba el nombre del funcionario"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.usuario_firma || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    CARGO DEL FUNCIONARIO
                    {soporteMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="cargo"
                          type="text"
                          placeholder="Escriba el cargo del funcionario"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{soporteSelected.cargo || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              {soporteMode !== 'view' && (
                <>
                  <Row>
                    <Col1>
                      <Input
                          style={{ display: 'none' }}
                          name="archivos"
                          id="archivos"
                          type="file"
                          onChange={e => handleChangeFiles(e) }
                          accept=".doc,.docx,.odt,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={handleCancelar}>
                          CANCELAR
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="submit">
                          GUARDAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </>
              )}
            </GridContainer>
          </Form>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
};

ModalSoporteTraslado.propTypes = {
  modalFile: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalSoporteTraslado.propTypes = {
  modalFile: false,
  animation: '',
  onClick: () => {},
};

export default ModalSoporteTraslado;
