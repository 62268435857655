import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import notificationReducer from '../../store/notification';

const { dispatchers } = notificationReducer;

const useNotification = () => {
  const { state, dispatch } = useGlobalStore();
  const { notification } = state;
	const {
    createNotification,
    deleteNotification,
  } = dispatchers;

	const actions = bindActions({
    createNotification,
    deleteNotification,
  }, dispatch);

  return { ...notification, ...actions };
};

export default useNotification;
