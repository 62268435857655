/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import {pxToRem} from "../../utils";

export const Input = styled.input`
    display: inline-block;
    padding: ${pxToRem(8)} ${pxToRem(16)};
    margin-top: ${pxToRem(5)};
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    background-color: ${props => props.readOnly ? props.theme.colors.lightGrey : props.theme.colors.white};
    // &::-webkit-file-upload-button {
    //   visibility: ${props => props.uploader ? "hidden" : "visible"};
    // }
`;



