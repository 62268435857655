import styled from 'styled-components';
import { pxToRem } from "../../utils";

export const ModalContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  .card-body-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .text-info {
      font-size: 14px;
      color: ${props => props.theme.colors.dargraygold};
    }

    button {
      cursor: pointer;
    }
  }

  .card-body-row-only {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: 100%;

    .title-divide {
      align-items: flex-start;
      font-size: ${pxToRem(18)};
      border-bottom: ${pxToRem(1)} solid ${props => props.theme.primaryColor};
      width: 100%;
    }

    button {
      cursor: pointer;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  aling-items: center;
  width: 100%;

  .header-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .text-info {
      color: ${props => props.theme.colors.dargraygold};
      font-weight: lighter;
    }
  }
`;
