/* eslint-disable import/no-cycle */
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import {BASE_FONT_SIZE} from './config/const';
import themes from './themes';
import Header from './components/Header';
import Footer from './components/Footer';
/* Pages */
import AboutPage from './pages/AboutPage';
import AutoridadesAmbientales from "./pages/AutoridadesAmbientales";
import AutoridadAmbientalForm from './pages/AutoridadAmbientalForm';
import Contacts from './pages/Contacts';
import ContactForm from './pages/ContactForm';
import EntidadDisposicionForm from './pages/EntidadDisposicionForm';
import EntidadesDisposicion from './pages/EntidadesDisposicion';
import HomePage from './pages/HomePage';
import SignIn from './pages/SignIn';
import Recover from './pages/Recover';
import Reset from './pages/Reset';
import DomainsPage from './pages/DomainsPage';
import ConstantsPage from './pages/ConstantsPage';
import TaxonomyPage from './pages/TaxonomyPage';
import Users from './pages/Users';
import UserForm from './pages/UserForm';
import UpdateProfile from './pages/UpdateProfile';
import ExpedienteForm from './pages/ExpedienteForm';
import ExpedientesSearch from './pages/ExpedientesSearch';
import ContactPage from './pages/ContactPage';
import Traslados from './pages/Traslados';
import TrasladoForm from './pages/TrasladoForm';
import ConsultaTraficoEspecies from './pages/ConsultaTraficoEspecies';
import ConsultaPorTabla from './pages/ConsultaPorTabla';
import ConsultaPorGeovisor from './pages/ConsultaPorGeovisor';
import ConsultaPorEstadisticas from './pages/ConsultaPorEstadisticas';
import ConsultaEstadisticasHook from './pages/ConsultaEstadisticasHook';
import Dashboard from "./pages/Dashboard";
/* Hooks */
import {useLogin} from "./hooks";

import {permissionValidation} from "./routesValidator";

dayjs.locale('es');

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700&display=swap');
  @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
  @import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
  * { box-sizing: border-box }
  .App {
    align-items: center;
    font-size: ${props => props.fontSize};
    font-family: 'Roboto', sans-serif;
    width: 100vw;
    height: 100vh;
  }

  .modal-rodal-container {
    z-index: 9999 !important;
  }

  .modal-rodal-container-aux {
    z-index: 10000 !important;
  }

  .tooltipfield {
    z-index: 10000 !important;
  }

  .swal2-container {
    z-index: 10000 !important;
  }
`;

const themeContext = {
  theme: themes.light,
  toogleTheme: () => {}
};

export const UserContext = React.createContext(null);

export const ThemeContext = React.createContext(themeContext);

const App = () => {
  //Initial validation for user permissions
  const {isLoggedIn, permissions} = useLogin();
  const [ validRoutes, setValidRoutes ] = useState({});
  useEffect(() => {
     if (isLoggedIn) {
    //   console.log("permisos----->",permissions)
    //   console.log("Rutas Validas: ",permissionValidation(permissions))
      setValidRoutes(permissionValidation(permissions))
    } else {
      setValidRoutes({});
    }
  }, [isLoggedIn, permissions]);
  //{isLoggedIn ? Dashboard : HomePage}


// TODO: eliminar validación de login en cada vista y dejar la configuración de login y ruteo en app.js reemplazar Route por private para las vistas que deben ser privadas
//   const PrivateRoute = ({ component: Component, ...rest }) => (
//       <Route {...rest} render={(props) => (
//           isLoggedIn === true
//               ? <Component {...props} />
//               : <Redirect to={{
//                 pathname: '/oauth/sign-in',
//                 state: { from: props.location }
//               }} />
//       )} />
//   );

  return (
    <ThemeProvider theme={themeContext.theme}>
      <BrowserRouter>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={isLoggedIn ? Dashboard : HomePage}  />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/sobre-el-portal" component={AboutPage} />
            <Route exact path="/contacto" component={ContactPage} />
            <Route exact path="/oauth/sign-in" component={SignIn} />
            <Route exact path="/oauth/recover" component={Recover} />
            <Route exact path="/oauth/reset/:id" component={Reset} />
            <Route exact path="/domains" component={DomainsPage} />
            <Route exact path="/constants" component={ConstantsPage} />
            <Route exact path="/contacts-search" component={Contacts}/>
            <Route exact path="/contacts-form" component={ContactForm}/>
            <Route exact path="/contacts-form/:mode/:id" component={ContactForm}/>
            <Route exact path="/taxonomy" component={TaxonomyPage} />
            <Route exact path="/autoridad-ambiental-search" component={AutoridadesAmbientales}/>
            <Route exact path="/autoridad-ambiental-form" component={AutoridadAmbientalForm}/>
            <Route exact path="/autoridad-ambiental-form/:mode/:id" component={AutoridadAmbientalForm}/>
            <Route exact path="/entidad-disposicion-search" component={EntidadesDisposicion}/>
            <Route exact path="/entidad-disposicion-form" component={EntidadDisposicionForm}/>
            <Route exact path="/entidad-disposicion-form/:mode/:id" component={EntidadDisposicionForm}/>
            <Route exact path="/users" component={Users} />
            <Route exact path="/user-form" component={UserForm} />
            <Route exact path="/user-form/:mode/:id" component={UserForm} />
            <Route exact path="/update-profile" component={UpdateProfile} />
            <Route exact path="/expediente-form" component={ExpedienteForm} />
            <Route exact path="/expediente-form/:mode/:id" component={ExpedienteForm} />
            <Route exact path="/expedientes-search" component={ExpedientesSearch} />
            <Route exact path="/traslados-search" component={Traslados}/>
            <Route exact path="/traslados-form" component={TrasladoForm}/>
            <Route exact path="/traslados-form/:mode/:id" component={TrasladoForm}/>
            <Route exact path="/consulta-trafico-especies" component={ConsultaTraficoEspecies}/>
            <Route exact path="/consulta-por-tabla-search" component={ConsultaPorTabla}/>
            <Route exact path="/consulta-por-geovisor-search" component={ConsultaPorGeovisor}/>
            <Route exact path="/consulta-por-estadisticas-search" component={ConsultaEstadisticasHook}/>
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
      <GlobalStyle fontSize={BASE_FONT_SIZE} />
    </ThemeProvider>
  );
};

export default App;
