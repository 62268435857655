import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useConstants, useLogin } from "../hooks";

import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import {H1} from "../components/H1";
import {Card} from "../components/Card";
import PaginationTable from "../components/Table/PaginationTable";
import {FileWidget} from "../components/FileWidget";
import { CardTitleBox } from "../components/CardTitleBox";
import { H5 } from "../components/H5";
import {GridContainer, Row} from "../components/Grid";
import ErrorBoundary from "../components/ErrorBoundary";

/* Style Components */
import { CardContainerBody } from './styled';

const ConstantsPage = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const { isLoggedIn, token } = useLogin();
  const { allconstants, getAllConstants, error, isLoading } = useConstants();

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const load = useCallback(async () => {
    await getAllConstants(token);
  }, [
    getAllConstants
  ]);

  useEffect(() => {
    if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if(!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, gotoLogin, load]);


  const columns = [
    {Header: "Id", accessor: "_id"},
    {Header: "Constante padre", accessor: "constantParent"},
    {Header: "Tipo", accessor: "constantType"},
    {Header: "Nombre", accessor: "constantName"},
    {Header: "Estado", accessor: d => (d.state ? "activo" : "inactivo")}
  ];

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        <PageHeader>
          <H1>Constantes</H1>
        </PageHeader>
        <Card>
          <GridContainer>
            <Row>
              <FileWidget fileName="constant_file" downloadFileName="constantes"/>
            </Row>
            <Row>
              {isLoading ? (
                <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
                  <div>Cargando lista, un momento por favor...</div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              ) : (
                <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                  <CardContainerBody>
                    <div className="card-body-row">
                      <PaginationTable columns={columns} data={allconstants}/>
                    </div>
                  </CardContainerBody>
                  {error && (
                    <CardContainerBody>
                      <CardTitleBox className="error" error>
                        <H5>Ups, hubo un error: {error}</H5>
                      </CardTitleBox>
                    </CardContainerBody>
                  )}
                </div>
              )}
            </Row>
          </GridContainer>
        </Card>
      </Page>
    </ErrorBoundary>
  );
};

ConstantsPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConstantsPage.defaultProps = {
  history: {},
};

export default withRouter(ConstantsPage);
