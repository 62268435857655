/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-cycle */
import React, {useEffect, useCallback} from "react";
import {withRouter} from 'react-router';

import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import Cookies from "js-cookie";
import {pxToRem} from "../utils";
// import { UserContext } from "../App";
import {useLogin} from '../hooks';
/* Components */
import Page from "../components/Page";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {Form} from "../components/form-ui-components/Form";
import {Input} from "../components/form-ui-components/Input";
import {Label} from "../components/form-ui-components/Label";
import {Button} from "../components/form-ui-components/Button";
import {H5} from "../components/H5";
import {H1} from "../components/H1";
import {StyledLink} from "../components/StyledLink";
import {SmallCard} from "../components/SmallCard";
import {FormMessage} from "../components/form-ui-components/Messages";
import {GridContainer, Row} from "../components/Grid";
import styled from "styled-components";
import IguanaJPEG from "../assets/images/ph-iguana.jpg";

export const SignInCard = styled.div`
  display: flex;
  padding: ${pxToRem(16)};
  padding-top: ${props => props.title ? pxToRem(56) : pxToRem(16)};
  background-color: ${props => props.theme.colors.white};
  margin-top: 8vh;
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;

const SlideCont = styled.div`
    height: 80vh;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const SlideImg = styled.div`
    background: url(${props => props.bgImg}) center center/cover no-repeat;
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    @media (min-width: 600px) {
        right: 29%;

    }
`;

const TitleCont = styled.div`
    //background-color: ${props => props.theme.secondaryColorLight};
    background-color: white;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    bottom: 0;
    padding: 5%;
    h2 {
      font-size: ${pxToRem(18)};
      line-height: 1;
      color: white;
      font-weight: 400;
    }
    @media (min-width: 600px) {
        top: 0;
        left: 70%;
        padding: 5% 5% 0;
        h2 {
          font-size: ${pxToRem(36)};
          line-height: 1.2;
        }
    }
`;

// const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
const in30Minutes = 1 / 48;

const SignIn = ({history}) => {
  const {isLoading, error, handleLogin, token, userLogin} = useLogin();
  const {register, errors, handleSubmit} = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    try {
      handleLogin(data);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const gotoDomains = useCallback((tokens, userLogins) => {
    const newPifs = {
      token: tokens,
      userLogin: userLogins,
    };
    // const newPifsJson = JSON.stringify(newPifs);
    // console.log('token gotoDomains', newPifsJson);
    // setPifs(newPifs, { expires: inFifteenMinutes });
    Cookies.set('pifs', newPifs, {
      expires: 1
    });

    history.push('/dashboard');
  }, [history]);

  useEffect(() => {
    console.log('token useEffect', token, userLogin);
    if (token !== '') gotoDomains(token, userLogin);
  }, [token, userLogin, gotoDomains]);

  return (

    <Page fullWidth>
      <SlideCont>
        <SlideImg bgImg={IguanaJPEG}/>
        <TitleCont>

    <SignInCard title>
      <CardTitleBox>
        <H1> Ingreso al sistema </H1>
      </CardTitleBox>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Label full>
            Usuario:
            <Input
              name="username"
              placeholder="Escriba usuario o correo electrónico"
              type="text"
              ref={register({required: true})}
            />
            {errors.username && (
              <FormMessage error>
                El nombre de usuario o email es requerido!
              </FormMessage>
            )}
          </Label>
          <Label half>
            Contraseña:
            <Input
              name="password"
              placeholder="password"
              type="password"
              ref={register({required: true})}
            />
            {errors.password && (
              <FormMessage error>
                El password de usuario es requerido!
              </FormMessage>
            )}
          </Label>

          {error && (
            <Row>
              <FormMessage error>
                <H5>{error}</H5>
              </FormMessage>
            </Row>
          )}
          {isLoading ? (
            <SkeletonTheme color="#f42f6391" highlightColor="#444">
              <Skeleton height={34}/>
            </SkeletonTheme>
          ) : (
            <Button type="submit">
              Iniciar sesión
            </Button>
          )}
          <StyledLink center to="/oauth/recover">Recuperar contraseña</StyledLink>
        </GridContainer>
      </Form>
    </SignInCard>
        </TitleCont>
      </SlideCont>
    </Page>
  );
};

SignIn.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

SignIn.defaultProps = {
  history: {},
};

export default withRouter(SignIn);
