import styled from "styled-components";
import {pxToRem} from "../utils";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: ${props => props.center ? "center" : "flex-start"};
  overflow-x: ${props => props.scroll ? "auto" : "hidden"};
  width: 100%;
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.center ? "center" : "flex-start"};
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.right ? "flex-end" : "space-between"};
  justify-content: ${props => props.center ? "center" : "space-between"};
  width: ${props => props.right ? "auto" : "100%"};
  padding: ${pxToRem(8)};
`;

export const Col1 = styled(Col)`
  width: 100%;
`;

export const Col2 = styled(Col)`
  width: 100%;
  @media (min-width: 600px) {
    width: 50%;
  }
`;

export const Col3 = styled(Col)`
  width: 100%;
  @media (min-width: 600px) {
    width: 33%;
  }
`;

export const Col4 = styled(Col)`
  width: 100%;
  @media (min-width: 600px) {
    width: 25%;
  }
`;

export const Col5 = styled(Col)`
  width: 100%;
  @media (min-width: 600px) {
    width: 20%;
  }
`;

export const Col12 = styled(Col)`
  width: 100%;
`;
