export const ALL_PERMISSIONS = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  },
];
const ADMINISTRADOR_SISTEMA = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
const COORDINADOR_AUTORIDAD = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
const COORDINADOR_EXPEDIENTE = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
const COORDINADOR_ENTIDADES = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
const USUARIO_CONSULTA = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]


export const permissionValidation = async (permissions) => {
  let validRoutes = []
  // permissions.forEach(permission => {
  //   console.log("name dle permission",permission.name)
    // switch (permission.name) {
    //   case "ADMINISTRADOR DEL SISTEMA":
    //     return ADMINISTRADOR_SISTEMA
    //     break
    //   case "COORDINADOR DE AUTORIDAD AMBIENTAL":
    //     return COORDINADOR_AUTORIDAD
    //     break
    //   case "COORDINADOR DE EXPEDIENTES":
    //     return COORDINADOR_EXPEDIENTE
    //     break
    //   case "COORDINADOR DE ENTIDADES DE DISPOSICIÓN":
    //     return COORDINADOR_ENTIDADES
    //     break
    //   case "USUARIO_CONSULTA":
    //     return USUARIO_CONSULTA
    //     break
    //   default:
    //     return "NO_LOGUEADO"
    // }

  // })
// console.log([...new Set(validRoutes)])
  // return validRoutes;
  return USUARIO_CONSULTA;
}

export default permissionValidation;
