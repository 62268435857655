/* eslint-disable react/prop-types */
import React from "react";

import styled from "styled-components";
import {useNotification} from "../hooks";

import {pxToRem} from "../utils";

import {Notification} from "./Notification";

const StyledPage = styled.div`
  display: flex;
  background-color: ${props => props.theme.pageBackground};
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  align-items: center;
  padding-left: ${props => (props.useSideBarSpace ? pxToRem(60) : 0 )};
  padding-top: ${pxToRem(70)};
  @media (min-width: 600px) {
    padding-left: ${props => (props.useSideBarSpace ? pxToRem(270) : 0)};
  }
`;

export const PageContainer = styled.div`
  margin: 0;
  width: ${props => (props.fullWidth ? "100%" : "95%")};
  @media (min-width: 600px) {
    width: ${props => (props.fullWidth ? "100%" : "85%")};
    max-width: ${props => (props.fullWidth ? "100%" : pxToRem(1000))};
  }
`;

const Page = ({children, useSideBarSpace, fullWidth}) => {
    const {notification} = useNotification();
    const {type, message, visible} = notification;

    return (
        <StyledPage useSideBarSpace={useSideBarSpace}>
            <Notification message={message} visible={visible}/>
            <PageContainer fullWidth={fullWidth}>
                {children}
            </PageContainer>
        </StyledPage>
    );
};

export default Page;
