import styled from "styled-components";
import { pxToRem } from "../utils";

import { Card } from "../components/Card";

export const SmallCard = styled(Card)`
  @media (min-width: 600px) {
    max-width: ${pxToRem(400)};
  }
`;
