import React from "react";

import { CREATE_NOTIFICATION, DELETE_NOTIFICATION } from "./types";

export const initialState = {
  notification: { message: "", error: "", visible: false }
};

const notificationReducer = (state, { type, payload }) => {
  switch (type) {
    case CREATE_NOTIFICATION: {
      return {
        ...state,
        notification: payload.data
      };
    }

    case DELETE_NOTIFICATION: {
      return {
        ...state,
        notification: initialState.notification
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
