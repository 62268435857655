/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, {useCallback, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {withRouter} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";

import {removeEmpty} from "../utils";

import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import {Button} from "../components/form-ui-components/Button";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";

import {
  useAutoridadAmbiental,
  useConstants,
  useDomains,
  useEntidadDisposicion,
  useNotification,
  useLogin
} from "../hooks";
/* Style Components */
import {CardContainerBody} from './styled';
import {Col, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";
import ErrorBoundary from "../components/ErrorBoundary";

const EntidadesDisposicion = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const {register, handleSubmit, errors, reset} = useForm({});
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {
    constants,
    constantsByKey,
    getConstantOptionsByTypes
  } = useConstants();
  const {createNotification} = useNotification();
  const {isLoggedIn, token, isRolCoordinadorAutoridades} = useLogin();
  const {
    isLoading,
    error,
    searchEntidadDisposicion,
    data: entidadesDisposicion,
    deleteEntidadDisposicion
  } = useEntidadDisposicion();
  const {data: autoridadesAmbientales, searchAutoridadAmbiental} = useAutoridadAmbiental();

  const entidadesEstados = [{_id: true, nombre: 'Activa'}, {_id: false, nombre: 'Inactiva'}];

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const load = useCallback(async () => {
    await Promise.all([searchEntidadDisposicion({}, token), searchAutoridadAmbiental({}, token), getOptionsByDomainTypes(["tiposEntidadDisposicion"])]);
  }, [searchEntidadDisposicion, searchAutoridadAmbiental, getOptionsByDomainTypes]);

  const AlertThenDelete = async original => {
    confirmAlert({
      title: 'DESACTIVAR ENTIDAD DE DISPOSICIÓN',
      message: `¿Esta seguro que desea desactivar la entidad de disposición: ${original.nombre}`,
      buttons: [
        {
          label: "ACEPTAR",
          onClick: async () => {
            let coordenadasOriginales = null
            if('coordenadas' in original){
              coordenadasOriginales = {
                latitud: {
                  decimal: original.coordenadas.latitud.decimal ? Number(original.coordenadas.latitud.decimal) : 0,
                },
                longitud: {
                  decimal: original.coordenadas.longitud.decimal ? Number(original.coordenadas.longitud.decimal) : 0,
                }
              }
            }
            console.log("originallllllll",original)
            const newData = {
              ...original,
              observaciones: original.observaciones ? original.observaciones : "no hay observaciones",
              tipo: original.tipo ? Number(original.tipo._id._id) : null,
              autoridad_ambiental: original.autoridad_ambiental ? original.autoridad_ambiental._id : null,
              tipo_disposicion: original.tipo_disposicion ? Number(original.tipo_disposicion._id) : null,
              tipo_naturaleza: original.tipo_naturaleza ? Number(original.tipo_naturaleza._id) : 15000001,
              pais: original.pais ? Number(original.pais._id) : null,
              departamento: original.departamento ? Number(original.departamento._id) : null,
              municipio: original.municipio ? Number(original.municipio._id) : null,
              coordenadas: coordenadasOriginales,
              estado: false,
            };
            const {msg, err} = await deleteEntidadDisposicion(newData, original._id, token);
            createNotification({message: msg, visible: true, error: err});
            load();
          }
        },
        {
          label: "CANCELAR",
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const columns = [
    {
      Header: 'Autoridad Ambiental',
      accessor: d => {
        return ((d.autoridad_ambiental.nombre) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ')) ? d.autoridad_ambiental.nombre : '---';
      }
    },
    {
      Header: 'Nombre de la Entidad',
      accessor: d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? d.nombre : '---';
      }
    },
    {
      Header: 'Tipo Entidad Disposición',
      accessor: d => {
        return ((d.tipo_entidad_disposicion) && (d.tipo_entidad_disposicion.domainName !== '') && (d.tipo_entidad_disposicion.domainName !== ' ')) ? d.tipo_entidad_disposicion.domainName : '---';
      }
    },
    {
      Header: 'Naturaleza jurídica',
      accessor: d => {
        return ((d.tipo_naturaleza.domainName) && (d.tipo_naturaleza.domainName !== '') && (d.tipo_naturaleza.domainName !== ' ')) ? d.tipo_naturaleza.domainName : '---';
      }
    },
    {
      Header: 'Estado',
      accessor: d => {
        return ((d.estado) && (d.estado !== '') && (d.estado !== ' ')) ? 'Activo' : 'Inactivo';
      }
    },
    {
      Header: "Acciones",
      Cell: ({row}) => {
        const {original} = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Entidad Disposición',
            visible: true,
            handleAction: () => {
              history.push(`/entidad-disposicion-form/view/${original._id}`);
            }
          },
          {
            id: original._id,
            iconName: "edit",
            title: "Actualizar Entidad Disposición",
            visible: isRolCoordinadorAutoridades,
            handleAction: () => {
              history.push(`/entidad-disposicion-form/edit/${original._id}`);
            }
          },
          {
            id: original._id,
            iconName: 'visibility_off',
            title: 'Desactivar Entidad Disposición',
            visible: isRolCoordinadorAutoridades,
            handleAction: async () => AlertThenDelete(original)
          },
        ];
        return <ButtonsActionRow buttons={buttons}/>;
      }
    }
  ];

  const handleClearSearchFields = async e => {
    e.preventDefault();
    reset();
    await searchEntidadDisposicion({}, token);
  };

  useEffect(() => {
    if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data.autoridad_ambiental = data.autoridad_ambiental ? data.autoridad_ambiental : null;
      data.tipo = data.tipo ? Number(data.tipo) : null;
      data.tipo_disposicion = data.tipo_disposicion ? Number(data.tipo_disposicion) : null;
      data.tipo_naturaleza = data.tipo_naturaleza ? Number(data.tipo_naturaleza) : null;
      data.estado = data.estado ? (String(true) === data.estado) : null;
      await searchEntidadDisposicion(removeEmpty(data), token);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Ver entidades de disposición.</H1>
            </PageHeader>
            <Card title=" ">
              <CardTitleBox>
                <H5>Datos de búsqueda</H5>
              </CardTitleBox>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                  <Row>
                    <Col2>
                      <Label>
                        AUTORIDAD AMBIENTAL
                        <Select name="autoridad_ambiental" ref={register}>
                          <SelectOptions selectOptions={autoridadesAmbientales}/>
                        </Select>
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        NOMBRE DE LA ENTIDAD
                        <Input
                          name="nombre"
                          type="text"
                          ref={register}
                          placeholder="Escriba el nombre de la entidad"
                        />
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col4>
                      <Label>
                        TIPO DE ENTIDAD DE DISPOSICIÓN
                        <Select name="tipo" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEntidadDisposicion}/>
                        </Select>
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        NATURALEZA JURÍDICA<br/>
                        <>
                          <input
                            name="tipo_naturaleza"
                            value={2000001}
                            type="radio"
                            ref={register}
                          /> Privada
                          <input
                            name="tipo_naturaleza"
                            value={2000002}
                            type="radio"
                            ref={register}
                          /> Pública
                        </>
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        TIPO DE DISPOSICIÓN<br/>
                        <>
                          <input
                            name="tipo_disposicion"
                            value={15000001}
                            type="radio"
                            ref={register}
                          /> Provisional
                          <input
                            name="tipo_disposicion"
                            value={15000002}
                            type="radio"
                            ref={register}
                          /> Final
                        </>
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        ESTADO <br/>
                        <Select name="estado" ref={register}>
                          <SelectOptions selectOptions={entidadesEstados}/>
                        </Select>
                      </Label>
                    </Col4>
                  </Row>
                  <Row style={{justifyContent: 'flex-end'}}>
                    <Col right>
                      <Label style={{width: 'auto'}}>
                        <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                          LIMPIAR FORMULARIO
                        </Button>
                      </Label>
                      <Label style={{width: 'auto'}}>
                        <Button type="submit">
                          BUSCAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </GridContainer>
              </Form>
            </Card>
            <Card container>
              {isLoading ? (
                <div style={{justifyContent: 'flex-end', width: '100%'}}>
                  <div>Cargando información, un momento por favor...</div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              ) : (
                <CardContainerBody>
                  <div className="card-body-row">
                    <PaginationTable columns={columns} data={entidadesDisposicion}/>
                  </div>
                </CardContainerBody>
              )}
              {error && (
                <CardContainerBody>
                  <CardTitleBox className="error">
                    <H5> Ups, hubo un error {error}</H5>
                  </CardTitleBox>
                </CardContainerBody>
              )}
            </Card>
          </>
        )}
      </Page>
    </ErrorBoundary>
  );
};

EntidadesDisposicion.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

EntidadesDisposicion.defaultProps = {
  history: {},
};

export default withRouter(EntidadesDisposicion);
