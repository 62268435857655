export default {
  autoridadAmbiental: {
    create: "/api/autoridadAmbiental",
    search: "/api/autoridadAmbiental/buscar",
    delete: "/api/autoridadAmbiental",
    patch: "/api/autoridadAmbiental",
    update: "/api/autoridadAmbiental",
    get: "/api/autoridadAmbiental"
  },
  domains: {
    bulkUpdate: "/api/v1/domain/bulk",
    create: "/api/v1/domain/create",
    list: "/api/v1/domain/domainList",
    search: "/api/v1/domain",
    download: "/api/v1/domain/download",
    getOptionsByDomainTypes: "/api/v1/domain/search/domainIds=&domainTypes=",
    getByDomainIdsTypes: '/api/v1/domain/search/domainIds',
  },
  contacts: {
    create: '/api/contacto',
    list: '/api/contacto',
    get: '/api/contacto',
    delete: '/api/contacto',
    update: '/api/contacto',
    search: '/api/contacto/buscar',
  },
  constants: {
    bulkUpdate: "/api/v1/constant/bulk",
    create: "/api/v1/constant/create",
    list: "/api/v1/constant/constantList",
    search: "/api/v1/constant/search",
    download: "/api/v1/constant/download",
    getConstantOptionsByTypes: "/api/v1/constant/search/constantIds=&constantTypes=",
    getByConstantIdsTypes: '/api/v1/constant/search/constantIds',
  },
  entidadesDisposicion: {
    create: "/api/entidad",
    list: "/api/entidad/buscar",
    search: "/api/entidad/buscar",
    delete: "/api/entidad",
    update: "/api/entidad",
    get: "/api/entidad"
  },
  login: {
    signIn: "/auth/local",
    logout: "",
    recovery: "/api/user/password/recovery",
    reset: "/api/user/password/token",
    me: '/api/user/me',
  },
  taxonInfo: {
    bulkUpdate: "/api/v1/taxonomyInformation/bulk",
    list: "/api/v1/taxonomyInformation/list",
    search: "/api/v1/taxonomyInformation/search",
    download: "/api/v1/taxonomyInformation/download",
    get: "/api/v1/taxonomyInformation/searchByIds",
  },
  user: {
    create: "/api/usuario/crear",
    createCatalogo: "/api/user",
    update: "/api/usuario/actualizar",
    updateCatalogo: "/api/user",
    delete: "/api/usuario/actualizar",
    deleteCatalogo: "/api/user",
    me: "/api/user",
    updateMe: "/api/user",
    updateUserById: "/api/user",
    getUserGeneral: "/api/user",
    getUsers: "/api/usuario/listar",
    getUsersBySystem: "/api/user/find/system",
    getRoles: "/api/rol",
    getEntidades: "/api/v1/domain/search/domainIds=&domainTypes=ENTIDAD.TIPO",
    getDocumentos: "/api/v1/constant/search/constantIds=&constantTypes=DOCUMENTO.TIPO"
  },
  expediente: {
    create: "/api/expediente",
    update: "/api/expediente",
    close: "/api/expediente/cerrar",
    search: "/api/expediente/buscar",
    delete: "/api/expediente",
    get: "/api/expediente",
    radicar: "/api/expediente/radicar",
  },
  especimenes: {
    put: '/api/expediente',
    putTraslado: '/api/traslado',
    search: '/api/especimen/expediente/buscar',
    delete: '/api/especimenes',
    get: '/api/especimenes',
    searchEspecimenTraslado: '/api/especimen/traslado/buscar',
    getEspecimenTraslado: '/api/especimen/traslado',
    getEspecimenExpediente: '/api/especimen/expediente',
  },
  archivo: {
    download: '/api/files/archivo',
    upload: '/api/files/archivo/expediente',
    uploadTraslado: '/api/files/archivo/traslado',
    get: '/api/files/archivo',
  },
  imagen: {
    download: '/api/files/imagen',
    upload: '/api/files/imagen/expediente',
    get: '/api/files/imagen',
  },
  traslado: {
    create: '/api/traslado',
    search: '/api/traslado/buscar',
    cancel: '/api/traslado/cancelar',
    aprobar: '/api/traslado/aprobar',
    sintraslado: '/api/especimen/expediente/buscar',
    contraslado: '/api/especimen/traslado/buscar',
    get: '/api/traslado',
  },
  reportes: {
    search: '/api/reporte/especimen/buscar',
    generar: '/api/reporte/especimen/generar',
    descargar: '/api/reporte/especimen/descargar',
    estadistica: '/api/reporte/especimen/estadistica',
  },
};
