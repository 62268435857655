/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Rodal from 'rodal';
import { confirmAlert } from 'react-confirm-alert';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// include styles
import 'rodal/lib/rodal.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Input } from '../form-ui-components/Input';
import { Textarea } from '../form-ui-components/Textarea';
import { Select } from '../form-ui-components/Select';
import { SelectOptions } from '../form-ui-components/SelectOptions';
import { Button } from '../form-ui-components/Button';
import { ButtonLight } from '../form-ui-components/ButtonLight';
import PaginationTable from '../Table/PaginationTable';
import ButtonsActionRow from '../Table/ButtonsActionRow';
import Autocomplete from '../Autocomplete';
import { H5 } from "../H5";
import { Col, Col1, Col2, Col3, Col4, GridContainer, Row } from '../Grid';
/* Hooks */
import {
  useExpedientes,
  useConstants,
  useDomains,
  useEntidadDisposicion,
  useLogin
} from '../../hooks';
/* Style Components */
import { ModalContainerBody, ContainerHeader } from './styled';
import { CardContainerBody, SpanTextInfo, SpanDivide, SpanView } from '../../pages/styled';

import { wait } from '../../config/const';

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

const ModalEspecimenes = ({ onClick, modalEspecimen, animation }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ enableParteProducto, setEnableParteProducto ] = useState(false);
  const [ municipiosFiltradosProceEspe, setMunicipiosFiltradosProceEspe ] = useState([]);
  // Auto Complete
  const [ suggestions, setSuggestions ] = useState([]);
  const [ loadingAutoComplete, setLoadingAutoComplete ] = useState(false);
  const [ activeSuggestion, setActiveSuggestion ] = useState(0);
  const [ showSuggestions, setShowSuggestions ] = useState(false);
  const [ userInput, setUserInput ] = useState('');
  // Fin Auto Complete

  const { token } = useLogin();
  const { domainsByKey } = useDomains();
  const { constantsByKey } = useConstants();
  const { data: entidadesDisposicion } = useEntidadDisposicion();
  const {
    isLoadingEspecimenes,
    especimenSelected,
    selected,
    especimenMode,
    especimenTaxonomyInfoRequest,
    taxonomyInformation,
    expedienteEspecimenUpdateRequest
  } = useExpedientes();

  const { register, setValue, handleSubmit, reset, errors } = useForm();

  const handleChangeEstadoEspecimen = e => {
    const { value } = e.target;
    setEnableParteProducto((Number(value) === 8000002));
  };

  const handleChangeDptoProceEspe = e => {
    const { value } = e.target;
    const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
    setMunicipiosFiltradosProceEspe(newData);
  };

  // Auto Complete
  const loadTaxonomyInfo = useCallback(() => {
    setSuggestions(taxonomyInformation);
  }, [taxonomyInformation]);

  const handleAutoCompleteChange = async e => {
    const newUserInput = e.currentTarget.value;
    if(document.querySelector("select[name='taxonRankEspecimen']").value !== '') {
      const search = {
        taxonRank: document.querySelector("select[name='taxonRankEspecimen']")[document.querySelector("select[name='taxonRankEspecimen']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const { taxonomyInfo, msg, err } = await especimenTaxonomyInfoRequest(search, token);
      console.log(taxonomyInfo, msg, err);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInfo);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      confirmAlert({
        title: 'CATEGORÍA TAXONÓMICA!',
        message: `Debe seleccionar una categoría taxonómica!`,
        buttons: [
          {
            label: "ACEPTAR",
            onClick: () => {}
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
      document.querySelector('.react-confirm-alert-overlay').style.zIndex = 10000;
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId  = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalNameEspecimen').value = newUserInput;
    setValue('taxonomiaEspecimen', taxonomiaId);
    setValue('PIFS_grupoBioEspecimen', info[1]);
  };
  // Fin Auto Complete

  const handleClearFields = async e => {
    e.preventDefault();
    reset();
    onClick();
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    try {
      const especimen = {
        clase_recurso: { _id: data.clase_recurso_especimen, domainName: data.clase_recurso_especimen ? document.querySelector("select[name='clase_recurso_especimen']")[document.querySelector("select[name='clase_recurso_especimen']").selectedIndex].innerHTML : '' },
        estado: { _id: data.estado_especimen, domainName: (Number(data.estado_especimen) === 8000001) ? 'Vivo' : 'Muerto' },
        parte_producto: { _id: data.parte_producto_especimen, domainName: data.parte_producto_especimen ? document.querySelector("select[name='parte_producto_especimen']")[document.querySelector("select[name='parte_producto_especimen']").selectedIndex].innerHTML : '' },
        categoria_taxonomica: { _id: data.taxonRankEspecimen, domainName: data.taxonRankEspecimen ? document.querySelector("select[name='taxonRankEspecimen']")[document.querySelector("select[name='taxonRankEspecimen']").selectedIndex].innerHTML : '' },
        taxonomia: { _id: data.taxonomiaEspecimen, canonicalName: document.getElementById('canonicalNameEspecimen').value, PIFS_grupoBio: data.PIFS_grupoBioEspecimen },
        nombre_comun: data.nombre_comun_especimen,
        descripcion: data.descripcion_especimen,
        hongos_enfermedades: (String(true) === data.hongos_enfermedades_especimen),
        marcador_genetico: data.marcador_genetico_especimen,
        sexo: { _id: data.sexo_especimen, domainName: data.sexo_especimen ? document.querySelector("select[name='sexo_especimen']")[document.querySelector("select[name='sexo_especimen']").selectedIndex].innerHTML : '' },
        tiempo_cautiverio: data.cantidad_especimen,
        unidad_medida: { _id: data.unidad_medida_especimen, domainName: data.unidad_medida_especimen ? document.querySelector("select[name='unidad_medida_especimen']")[document.querySelector("select[name='unidad_medida_especimen']").selectedIndex].innerHTML : '' },
        condicion: { _id: data.condicion_especimen, domainName: data.condicion_especimen ? document.querySelector("select[name='condicion_especimen']")[document.querySelector("select[name='condicion_especimen']").selectedIndex].innerHTML : '' },
        estado_desarrollo: { _id: data.estado_desarrollo_especimen, domainName: data.estado_desarrollo_especimen ? document.querySelector("select[name='estado_desarrollo_especimen']")[document.querySelector("select[name='estado_desarrollo_especimen']").selectedIndex].innerHTML : '' },
        pais_procedencia_declarado: { _id: data.pais_procedencia_declarado_especimen, constantName: data.pais_procedencia_declarado_especimen ? document.querySelector("select[name='pais_procedencia_declarado_especimen']")[document.querySelector("select[name='pais_procedencia_declarado_especimen']").selectedIndex].innerHTML : '' },
        departamento_procedencia_declarado: { _id: data.departamento_procedencia_declarado_especimen, constantName: data.departamento_procedencia_declarado_especimen ? document.querySelector("select[name='departamento_procedencia_declarado_especimen']")[document.querySelector("select[name='departamento_procedencia_declarado_especimen']").selectedIndex].innerHTML : '' },
        municipio_procedencia_declarado: { _id: data.municipio_procedencia_declarado_especimen, constantName: data.municipio_procedencia_declarado_especimen ? document.querySelector("select[name='municipio_procedencia_declarado_especimen']")[document.querySelector("select[name='municipio_procedencia_declarado_especimen']").selectedIndex].innerHTML : '' },
        corregimiento_procedencia_declarado: data.corregimiento_procedencia_declarado_especimen,
        vereda_procedencia_declarado: data.vereda_procedencia_declarado_especimen,
        barrio_procedencia_declarado: data.barrio_procedencia_declarado_especimen,
        predio_procedencia_declarado: data.predio_procedencia_declarado_especimen,
        lugar_procedencia_declarado: data.lugar_procedencia_declarado_especimen,
        entidad_disposicion_inicial: { _id: data.entidad_disposicion_inicial_especimen, domainName: data.entidad_disposicion_inicial_especimen ? document.querySelector("select[name='entidad_disposicion_inicial_especimen']")[document.querySelector("select[name='entidad_disposicion_inicial_especimen']").selectedIndex].innerHTML : '' },
        observaciones: data.observaciones_generales_especimen,
        castrado: (String(true) === data.castrado_especimen),
        prenado: (String(true) === data.prenado_especimen),
        dieta: data.dieta_especimen,
        estado_posesion_expediente: { _id: especimenSelected.estado_posesion_expediente._id, domainName: especimenSelected.estado_posesion_expediente.domainName },
        nuevo: especimenSelected.nuevo,
        cantidad: data.cantidad_unidad ? Number(data.cantidad_unidad) : '',
        disposicion_final: especimenSelected.disposicion_final,
        _id: especimenSelected._id,
      };
      expedienteEspecimenUpdateRequest(especimenSelected._id, especimen);
      onClick();
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  // const loadExpedienteSelectedCanonical = useCallback(() => {
  //   console.log(document.getElementById('canonicalNameEspecimen'), document.getElementById('canonicalNameEspecimen').value);
  //   if(document.getElementById('canonicalNameEspecimen').value) {
  //     document.getElementById('canonicalNameEspecimen').value = especimenSelected.taxonomia.canonicalName;
  //   }
  // }, [document.getElementById('canonicalNameEspecimen').value]);

  const loadMunicipioSelected = useCallback(() => {
    if(especimenSelected && municipiosFiltradosProceEspe.length > 0) {
      setValue('municipio_procedencia_declarado_especimen', especimenSelected.municipio_procedencia_declarado._id);
    }
  }, [municipiosFiltradosProceEspe, especimenSelected]);

  const loadExpedienteSelected = useCallback(async () => {
    if (especimenSelected) {
      setValue('clase_recurso_especimen', especimenSelected.clase_recurso._id);
      setValue('estado_especimen', `${especimenSelected.estado._id}`);
      setValue('taxonRankEspecimen', especimenSelected.categoria_taxonomica._id);
      setValue('taxonomiaEspecimen', especimenSelected.taxonomia._id);
      setValue('nombre_comun_especimen', especimenSelected.nombre_comun);
      setValue('PIFS_grupoBioEspecimen', especimenSelected.taxonomia.PIFS_grupoBio);
      setValue('descripcion_especimen', especimenSelected.descripcion);
      setValue('marcador_genetico_especimen', especimenSelected.marcador_genetico);
      setValue('sexo_especimen', especimenSelected.sexo._id);
      setValue('cantidad_especimen', especimenSelected.tiempo_cautiverio);
      setValue('unidad_medida_especimen', especimenSelected.unidad_medida._id);
      setValue('condicion_especimen', especimenSelected.condicion._id);
      setValue('estado_desarrollo_especimen', especimenSelected.estado_desarrollo._id);
      setValue('pais_procedencia_declarado_especimen', especimenSelected.pais_procedencia_declarado._id);
      setValue('departamento_procedencia_declarado_especimen', especimenSelected.departamento_procedencia_declarado._id);
      setValue('corregimiento_procedencia_declarado_especimen', especimenSelected.corregimiento_procedencia_declarado);
      setValue('vereda_procedencia_declarado_especimen', especimenSelected.vereda_procedencia_declarado);
      setValue('barrio_procedencia_declarado_especimen', especimenSelected.barrio_procedencia_declarado);
      setValue('predio_procedencia_declarado_especimen', especimenSelected.predio_procedencia_declarado);
      setValue('lugar_procedencia_declarado_especimen', especimenSelected.lugar_procedencia_declarado);
      setValue('hongos_enfermedades_especimen', `${especimenSelected.hongos_enfermedades}`);
      setValue('castrado_especimen', `${especimenSelected.castrado}`);
      setValue('prenado_especimen', `${especimenSelected.prenado}`);
      setValue('dieta_especimen', especimenSelected.dieta);
      setValue('observaciones_generales_especimen', especimenSelected.observaciones);
      setValue('entidad_disposicion_inicial_especimen', especimenSelected.entidad_disposicion_inicial._id);
      setValue('cantidad_unidad', especimenSelected.cantidad);
      const newDataProce = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(especimenSelected.departamento_procedencia_declarado._id));
      setMunicipiosFiltradosProceEspe(newDataProce);
      setEnableParteProducto((Number(especimenSelected.estado._id) === 8000002));
      if(especimenMode !== 'view') {
        await wait(1000);
        document.getElementById('canonicalNameEspecimen').value = especimenSelected.taxonomia.canonicalName;
        setValue('parte_producto_especimen', especimenSelected.parte_producto._id);
      }
    }
  }, [especimenSelected]);

  const hongosEnfermedafes = [
    {
      _id: true,
      domainName: 'Si'
    },
    {
      _id: false,
      domainName: 'No'
    },
  ];

  useEffect(() => {
    if(!loaded) {
      loadExpedienteSelected();
      setLoaded(true);
    }
  }, [loaded, loadExpedienteSelected]);

  useEffect(() => {
    loadExpedienteSelected();
  }, [especimenSelected]);

  useEffect(() => {
    loadMunicipioSelected();
  }, [municipiosFiltradosProceEspe]);

  // useEffect(() => {
  //   loadExpedienteSelectedCanonical();
  // }, [document.getElementById('canonicalNameEspecimen').value]);

  useEffect(() => {
    loadTaxonomyInfo();
  }, [taxonomyInformation]);

  return ReactDOM.createPortal(
    <Rodal visible={modalEspecimen}
          onClose={onClick}
          animation={animation}
          customStyles={customStyles}
          className="modal-rodal-container"
          width={80}
          height={90}
          measure="%"
          closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <ContainerHeader>
                      <div className="header-col">
                        <H5>{especimenMode !== 'view' ? 'Modificar espécimen.' : 'Información del especimen'}</H5>
                      </div>
                      <div className="header-col">
                        <span className="text-info">ESTADO DE POSESIÓN</span>
                        <H5>{especimenSelected ? especimenSelected.estado_posesion_expediente.domainName : 'Posesión'}</H5>
                      </div>
                      <div className="header-col">
                        <span className="text-info">Número de acta</span>
                        <H5>{especimenSelected.nuevo ? '---' : (selected && (selected.acta || '---'))}</H5>
                      </div>
                    </ContainerHeader>
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    CLASE DE RECURSO <span className="obligatory">*</span>
                    {especimenMode !== 'view' ? (
                      <Select style={{ width: '100%' }} name="clase_recurso_especimen" ref={register({ required: true })}>
                        <SelectOptions selectOptions={domainsByKey.tiposRecurso} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.clase_recurso) ? especimenSelected.clase_recurso.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    ESTADO <span className="obligatory">*</span>
                    {especimenMode !== 'view' ? (
                      <>
                        <br />
                        <input
                          name="estado_especimen"
                          value={8000001}
                          type="radio"
                          ref={register({ required: true })}
                          onChange={handleChangeEstadoEspecimen}
                          defaultChecked
                        /> Vivo
                        <input
                          name="estado_especimen"
                          value={8000002}
                          type="radio"
                          ref={register({ required: true })}
                          onChange={handleChangeEstadoEspecimen}
                        /> Muerto
                        {errors.estado_especimen && errors.estado_especimen.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El estado del espécimen es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.estado) ? especimenSelected.estado.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    PARTE, PRODUCTO O SUBPRODUCTO
                    {(especimenMode !== 'view' && enableParteProducto) ? (
                      <Select style={{ width: '100%' }} name="parte_producto_especimen" ref={register}>
                        <SelectOptions selectOptions={domainsByKey.tiposParte} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.parte_producto) ? especimenSelected.parte_producto.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col2>
                  <Label>
                    CATEGORÍA TAXONÓMICA <span className="obligatory">*</span>
                    {especimenMode !== 'view' ? (
                      <>
                        <Select style={{ width: '100%' }} name="taxonRankEspecimen" ref={register({ required: true })}>
                          <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia} />
                        </Select>
                        {errors.taxonRankEspecimen && errors.taxonRankEspecimen.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El nombre cientifico es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.categoria_taxonomica) ? especimenSelected.categoria_taxonomica.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col2>
                <Col2>
                  <Label>
                    NOMBRE CIENTÍFICO <span className="obligatory">*</span>
                    {especimenMode !== 'view' ? (
                      <>
                        <Autocomplete
                          name="canonicalNameEspecimen"
                          placeholder="Indique nombre científico"
                          suggestions={suggestions}
                          loading={loadingAutoComplete}
                          activeSuggestion={activeSuggestion}
                          showSuggestions={showSuggestions}
                          onChange={handleAutoCompleteChange}
                          onKeyDown={handleAutoCompleteKeyDown}
                          onClick={handleAutoCompleteClick}
                          userInput={userInput}
                        />
                        <Input
                          style={{ width: '100%' }}
                          name="taxonomiaEspecimen"
                          type="hidden"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.taxonomia) ? especimenSelected.taxonomia.canonicalName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col2>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    GRUPO BIOLÓGICO
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="PIFS_grupoBioEspecimen"
                        type="text"
                        placeholder="Ingrese grupo biológico"
                        ref={register}
                        readOnly
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.taxonomia) ? especimenSelected.taxonomia.PIFS_grupoBio : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    NOMBRE COMÚN
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="nombre_comun_especimen"
                        type="text"
                        placeholder="Ingrese nombre común"
                        ref={register}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.nombre_comun) ? especimenSelected.nombre_comun : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    SEXO <span className="obligatory">*</span>
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="sexo_especimen" ref={register({ required: true })}>
                        <SelectOptions selectOptions={domainsByKey.tiposSexo} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.sexo) ? especimenSelected.sexo.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    CONDICIÓN <span className="obligatory">*</span>
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="condicion_especimen" ref={register({ required: true })}>
                        <SelectOptions selectOptions={domainsByKey.estadosCondicion} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.condicion) ? especimenSelected.condicion.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    ESTADO DE DESARROLLO <span className="obligatory">*</span>
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="estado_desarrollo_especimen" ref={register({ required: true })}>
                        <SelectOptions selectOptions={domainsByKey.estadosDesarrollo} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.estado_desarrollo) ? especimenSelected.estado_desarrollo.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    NOMBRE DE MARCADOR GENÉTICO
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="marcador_genetico_especimen"
                        type="text"
                        placeholder="Ingrese marcador genético"
                        ref={register}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.marcador_genetico) ? especimenSelected.marcador_genetico : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    HONGOS O ENFERMEDADES
                    {especimenMode === 'edit' ? (
                      <>
                        <br />
                        <input
                          name="hongos_enfermedades_especimen"
                          value
                          type="radio"
                          ref={register}
                        /> Si
                        <input
                          name="hongos_enfermedades_especimen"
                          value={false}
                          type="radio"
                          ref={register}
                          defaultChecked
                        /> No
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.hongos_enfermedades) ? 'Si' : 'No'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    UNIDAD DE MEDIDA <span className="obligatory">*</span>
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="unidad_medida_especimen" ref={register({ required: true })}>
                        <SelectOptions selectOptions={domainsByKey.unidadesMedida} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.unidad_medida) ? especimenSelected.unidad_medida.domainName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    CANTIDAD UNIDAD <span className="obligatory">*</span>
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="cantidad_unidad"
                        type="text"
                        placeholder="Ingrese la cantidad para la unidad"
                        ref={register}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.cantidad) ? especimenSelected.cantidad : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col4>
                  <Label>
                    CASTRADO
                    {especimenMode === 'edit' ? (
                      <>
                        <br />
                        <input
                          name="castrado_especimen"
                          value
                          type="radio"
                          ref={register}
                        /> Si
                        <input
                          name="castrado_especimen"
                          value={false}
                          type="radio"
                          ref={register}
                          defaultChecked
                        /> No
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.castrado) ? 'Si' : 'No'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    PREÑADO
                    {especimenMode === 'edit' ? (
                      <>
                        <br />
                        <input
                          name="prenado_especimen"
                          value
                          type="radio"
                          ref={register}
                        /> Si
                        <input
                          name="prenado_especimen"
                          value={false}
                          type="radio"
                          ref={register}
                          defaultChecked
                        /> No
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.prenado) ? 'Si' : 'No'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    CANTIDAD DE DÍAS EN CAUTIVERIO
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="cantidad_especimen"
                        type="number"
                        placeholder="Ingrese la cantidad"
                        ref={register({
                          pattern: { value: /^[0-9]+$/ },
                          minLength: 1,
                          maxLength: 4
                        })}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.tiempo_cautiverio) ? especimenSelected.tiempo_cautiverio : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col4>
                <Col4>
                  <Label>
                    DIETA
                    {especimenMode === 'edit' ? (
                      <Input
                        style={{ width: '100%' }}
                        name="dieta_especimen"
                        type="text"
                        placeholder="Ingrese la dieta"
                        ref={register}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.dieta) ? especimenSelected.dieta : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col4>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    DESCRIPCIÓN
                    {especimenMode === 'edit' ? (
                      <Textarea
                        rows="6"
                        style={{ width: '100%' }}
                        name="descripcion_especimen"
                        type="text"
                        placeholder="Ingrese la descripción"
                        ref={register}
                      />
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.descripcion) ? especimenSelected.descripcion : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <SpanDivide>
                    <br />Información de procedencia del espécimen.
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    PAÍS DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="pais_procedencia_declarado_especimen" ref={register}>
                        <SelectOptions selectOptions={constantsByKey.paises} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.pais_procedencia_declarado) ? especimenSelected.pais_procedencia_declarado.constantName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    DEPARTAMENTO DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} onChange={handleChangeDptoProceEspe} name="departamento_procedencia_declarado_especimen" ref={register}>
                        <SelectOptions selectOptions={constantsByKey.departamentos} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.departamento_procedencia_declarado) ? especimenSelected.departamento_procedencia_declarado.constantName : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    CIUDAD O MUNICIPIO DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <Select style={{ width: '100%' }} name="municipio_procedencia_declarado_especimen" ref={register}>
                        <SelectOptions selectOptions={municipiosFiltradosProceEspe} />
                      </Select>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.municipio_procedencia_declarado) ? especimenSelected.municipio_procedencia_declarado.constantName : '---' }</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    CORREGIMIENTO DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="corregimiento_procedencia_declarado_especimen"
                          type="text"
                          placeholder="Escriba el nombre del corregimiento"
                          ref={register({maxLength: 50})}
                        />
                        {errors.corregimiento_procedencia_declarado_especimen && errors.corregimiento_procedencia_declarado_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El corregimiento de procedencia le sobran caracteres máximo (50)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.corregimiento_procedencia_declarado) ? especimenSelected.corregimiento_procedencia_declarado : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    VEREDA DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="vereda_procedencia_declarado_especimen"
                          type="text"
                          placeholder="Ingrese el nombre de la vereda"
                          ref={register({maxLength: 50})}
                        />
                        {errors.vereda_procedencia_declarado_especimen && errors.vereda_procedencia_declarado_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El nombre de la vereda de procedencia le sobran caracteres máximo (50)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.vereda_procedencia_declarado) ? especimenSelected.vereda_procedencia_declarado : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    BARRIO DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="barrio_procedencia_declarado_especimen"
                          type="text"
                          placeholder="Ingrese el nombre del barrio"
                          ref={register({maxLength: 50})}
                        />
                        {errors.barrio_procedencia_declarado_especimen && errors.barrio_procedencia_declarado_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El barrio de procedencia le sobran caracteres máximo (50)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.barrio_procedencia_declarado) ? especimenSelected.barrio_procedencia_declarado : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
              </Row>
              <Row>
                <Col3>
                  <Label>
                    PREDIO DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="predio_procedencia_declarado_especimen"
                          type="text"
                          placeholder="Escriba el predio"
                          ref={register({maxLength: 50})}
                        />
                        {errors.predio_procedencia_declarado_especimen && errors.predio_procedencia_declarado_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El predio de procedencia le sobran caracteres máximo (50)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.predio_procedencia_declarado) ? especimenSelected.predio_procedencia_declarado : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label>
                    LUGAR DE PROCEDENCIA
                    {especimenMode === 'edit' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="lugar_procedencia_declarado_especimen"
                          type="text"
                          placeholder="Ingrese el lugar"
                          ref={register({maxLength: 50})}
                        />
                        {errors.lugar_procedencia_declarado_especimen && errors.lugar_procedencia_declarado_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El lugar de procedencia le sobran caracteres máximo (50)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.lugar_procedencia_declarado) ? especimenSelected.lugar_procedencia_declarado : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col3>
                <Col3>
                  <Label />
                </Col3>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    ENTIDAD DE DISPOSICIÓN ACTUAL <span className="obligatory">*</span>
                    {especimenMode !== 'view' ? (
                      <>
                        <Select style={{ width: '100%' }} name="entidad_disposicion_inicial_especimen" ref={register({ required: true })}>
                          <SelectOptions selectOptions={entidadesDisposicion} />
                        </Select>
                        {errors.entidad_disposicion_inicial_especimen && errors.entidad_disposicion_inicial_especimen.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La entidad de disposición actual es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.entidad_disposicion_inicial) ? especimenSelected.entidad_disposicion_inicial.nombre : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    OBSERVACIONES GENERALES
                    {especimenMode !== 'view' ? (
                      <>
                        <Input
                          name="observaciones_generales_especimen"
                          type="text"
                          ref={register({minLength: 5, maxLength: 500})}
                          placeholder="Ingrese las observaciones especiales del espécimen asociado expediente"
                        />
                        {errors.observaciones_generales_especimen && errors.observaciones_generales_especimen.type === "minLength" && (
                          <p style={{color: 'red'}}>
                            Las observaciones del espécimen le falta caracteres mínimo (5)!
                          </p>
                        )}
                        {errors.observaciones_generales_especimen && errors.observaciones_generales_especimen.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            Las observaciones del espécimen le sobran caracteres máximo (500)!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{(especimenSelected.observaciones) ? especimenSelected.observaciones : '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              {especimenMode !== 'view' && (
                <Row style={{ justifyContent: 'flex-end' }}>
                  <Col right>
                    <Label style={{ width: 'auto' }}>
                      <Button type="button" onClick={(e) => handleClearFields(e)}>
                        CANCELAR
                      </Button>
                    </Label>
                    <Label style={{ width: 'auto' }}>
                      <Button type="submit">
                        GUARDAR
                      </Button>
                    </Label>
                  </Col>
                </Row>
              )}
              {isLoadingEspecimenes && (
                <Row>
                  <Col1>
                    <div style={{justifyContent: 'flex-end', width: '100%'}}>
                      Guardando información por favor espere un momento...
                    </div>
                    <div style={{justifyContent: 'flex-end', width: '100%'}}>
                      <SkeletonTheme color="#f42f6391" highlightColor="#444">
                        <Skeleton height={34}/>
                      </SkeletonTheme>
                    </div>
                  </Col1>
                </Row>
              )}
            </GridContainer>
          </Form>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
}

ModalEspecimenes.propTypes = {
  modalEspecimen: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalEspecimenes.defaultProps = {
  modalEspecimen: false,
  animation: '',
  onClick: () => {},
};

export default withRouter(ModalEspecimenes);
