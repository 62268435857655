import { createGlobalStyle } from 'styled-components';

export const GlobalStyleAutoComplete = createGlobalStyle`
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    background-color: ${props => props.theme.colors.white};
    width: auto;
  }

  .suggestions li {
    padding: 0.5rem;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #459d8e;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  .input-item {
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.colors.lightGrey};
    i {
      vertical-align: bottom;
      margin-left: -25px;
      margin-top: 12px;
    }
  }
`;

export default GlobalStyleAutoComplete;
