/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
import {
  DOMAINS_LIST_INIT,
  DOMAINS_LIST_SUCCESS,
  DOMAINS_LIST_ERROR,
  DOMAIN_OPTIONS_BY_TYPE,
  DOMAIN_OPTIONS_BY_TYPE_SUCCESS
} from "./types";

import { domainTypesByKey } from "../../config/const";

const initialState = {
  alldomains: [],
  domainsByKey: [],
  isLoading: false,
  error: ""
};

const domains = (state = initialState, { type, payload }) => {
  switch (type) {
    case DOMAINS_LIST_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case DOMAINS_LIST_SUCCESS: {
      return {
        ...state,
        alldomains: payload.data,
        isLoading: false
      };
    }

    case DOMAINS_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case DOMAIN_OPTIONS_BY_TYPE: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case DOMAIN_OPTIONS_BY_TYPE_SUCCESS: {
      const domainsByKey = {};
      let domainKey;
        let domainType = null;
      const domainKeys = payload.domainTypes;
      for (let i = 0; i < domainKeys.length; i++) {
        domainKey = domainKeys[i];
        domainType = domainTypesByKey[domainKey];
        domainsByKey[domainKey] = payload.data.filter(
          d => d.domainType === domainType
        ).sort((a, b) => {
          return a.domainName.localeCompare(b.domainName);
        });
      }
      return {
        ...state,
        isLoading: false,
        domainsByKey
      };
    }

    default: {
      return state;
    }
  }
};

export { domains as default, initialState };
