/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import {withRouter} from "react-router-dom";
import * as yup from "yup";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import Page from "../components/Page";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {PageHeader} from "../components/PageHeader";

import { decimalDegreesToSexagesimalDegrees } from "../utils";
import ErrorBoundary from '../components/ErrorBoundary';
import {Button} from "../components/form-ui-components/Button";
// import {ButtonLight} from "../components/form-ui-components/ButtonLight";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";

import {
  useAutoridadAmbiental,
  useDomains,
  useConstants,
  useNotification,
  useLogin
} from "../hooks";

/* Style Components */
import {SpanDivide, SpanView} from './styled';
// import {MapWithGeocoderControl} from "../components/form-ui-components/MapWithGeocoderControl";
import {Col, Col1, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";
import { removeEmpty } from '../utils';

const AutoridadAmbientalForm = ({ history, match }) => {
  const [loaded, setLoaded] = useState(false);
  const [loadedInfo, setLoadedInfo] = useState(false);
  const [ municipiosFiltrados, setMunicipiosFiltrados ] = useState([]);
  const [reverseGeocodingResult, setResult] = useState();
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const {
    selected,
    mode,
    createAutoridadAmbiental,
    updateAutoridadAmbiental,
    loadSelected,
    clearSelected
  } = useAutoridadAmbiental();
  const {createNotification} = useNotification();
  const {isLoggedIn, token} = useLogin();

  const schema = yup.object().shape({
    departamento: yup.string()
  });

  const {setValue, register, handleSubmit, errors, reset} = useForm();

  const handleChangeDpto = e => {
    e.preventDefault();
    const { value } = e.target;
    const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
    setMunicipiosFiltrados(newData);
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const clearAutoridad = useCallback(async () => {
    window.scrollTo(0, 0);
    await clearSelected();
    reset();
    setLoadedInfo(true);
  }, [history]);

  const gotoAutoridad = useCallback(async () => {
    await clearSelected();
    history.push('/autoridad-ambiental-search');
  }, [history]);

  const gotoAutoridadCrear = useCallback(async () => {
    await clearSelected();
    history.push('/autoridad-ambiental-form');
  }, [history]);

  const searchOption = (val, datalist) => {
    if (!datalist) {
      return null;
    }
    for (let j = 0; j < datalist.length; j++) {
      if (val === datalist[j].constantName) {
        return datalist[j];
      }
    }
    return null;
  };

  const handleClose = async e => {
    e.preventDefault();
    reset();
    await clearSelected();
    history.push('/autoridad-ambiental-search');
    e.stopPropagation();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const newData = {
        ...data,
        observaciones_responsable: data.observaciones_responsable.length > 0 ? data.observaciones_responsable : "no hay observaciones",
        tipo: data.tipo ? Number(data.tipo) : null,
        pais: data.pais ? Number(data.pais) : null,
        departamento: data.departamento ? Number(data.departamento) : null,
        municipio: data.municipio ? Number(data.municipio) : null,
        coordenadas: (data.coordenadas.latitud.decimal && data.coordenadas.longitud.decimal) ? ({
          latitud: {
            decimal: data.coordenadas.latitud.decimal ? Number(data.coordenadas.latitud.decimal) : null,
          },
          longitud: {
            decimal: data.coordenadas.longitud.decimal ? Number(data.coordenadas.longitud.decimal) : null,
          }
        }): null,
      };

      if (newData.coordenadas === null){
        delete newData.coordenadas
      }

      let isError = false;
      if (selected !== null) {
        const {msg, err} = await updateAutoridadAmbiental(removeEmpty(newData), selected._id, token);
        isError = err;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      } else {
        const {msg, err} = await createAutoridadAmbiental(removeEmpty(newData), token);
        isError = err;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      }

      if(!isError) {
        reset();
        await gotoAutoridad();
      }
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const loadMunicipioSelected = useCallback(() => {
    if(selected && municipiosFiltrados.length > 0) {
      setValue("municipio", selected.municipio._id);
    }
  }, [municipiosFiltrados, selected]);

  const loadAASelected = useCallback(() => {
    console.log("selected", selected)
    if (selected) {
      setValue("nombre", selected.nombre);
      setValue("sigla", selected.sigla);
      setValue("tipo", selected.tipo._id);
      setValue("estado", selected.estado);
      setValue("pais", selected.pais._id);
      setValue("departamento", selected.departamento._id);
      setValue("direccion", selected.direccion);
      setValue("telefono_movil", selected.telefono_movil);
      setValue("telefono_fijo", selected.telefono_fijo);
      setValue("sitio_web", selected.sitio_web);
      setValue("responsable", selected.responsable);
      setValue("email_responsable", selected.email_responsable);
      setValue("cargo_responsable", selected.cargo_responsable);
      setValue("observaciones_responsable", selected.observaciones_responsable);
      if (selected.coordenadas && selected.coordenadas.longitud) {
        setValue(
          "coordenadas.longitud.decimal",
          selected.coordenadas.longitud.decimal
        );
      }
      if (selected.coordenadas && selected.coordenadas.latitud) {
        setValue(
          "coordenadas.latitud.decimal",
          selected.coordenadas.latitud.decimal
        );
      }
      const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(selected.departamento._id));
      setMunicipiosFiltrados(newData);
    }
  }, [setValue, selected]);

  const load = useCallback(async () => {
    window.scrollTo(0, 0);
    await Promise.all([clearSelected(), getOptionsByDomainTypes(["tiposAutoridadAmbiental"]), getConstantOptionsByTypes(["paises", "departamentos", "municipios"])]);
    if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
      await loadSelected(match.params.id, match.params.mode, token);
      if (match.params.mode === 'edit') loadAASelected();
    } else {
      setValue("pais", 3000001);
    }
    setLoadedInfo(true);
  }, [
    getOptionsByDomainTypes,
    getConstantOptionsByTypes,
    loadSelected,
    loadAASelected,
    clearSelected,
    match.params.id,
    match.params.mode
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      gotoLogin();
    } else if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  useEffect(() => {
    loadAASelected();
  }, [selected]);

  useEffect(() => {
    loadMunicipioSelected();
  }, [municipiosFiltrados]);

  useEffect(() => {
    if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
      clearAutoridad();
    }
  }, [clearAutoridad, match.params.id, match.params.mode]);


  const setSuggestedValues = useCallback(() => {
    const dmsLonLat = decimalDegreesToSexagesimalDegrees(
      reverseGeocodingResult.center
    );

    setValue("coordenadas.longitud.decimal", reverseGeocodingResult.center.lng);

    setValue("coordenadas.latitud.decimal", reverseGeocodingResult.center.lat);

    const pais = searchOption(
      reverseGeocodingResult.properties.address.country,
      constantsByKey.paises
    );

    if (pais) {
      setValue("pais", pais._id);
    }
    const departamento = searchOption(
      reverseGeocodingResult.properties.address.state,
      constantsByKey.departamentos
    );
    if (departamento) {
      setValue("departamento", departamento._id);
    }
    const municipio = searchOption(
      reverseGeocodingResult.properties.address.county,
      constantsByKey.municipios
    );
    if (municipio) {
      setValue("municipio", municipio._id);
    }
  }, [setValue, constantsByKey, reverseGeocodingResult]);

  useEffect(() => {
    if (reverseGeocodingResult) {
      setSuggestedValues();
    }
  }, [reverseGeocodingResult, setSuggestedValues]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            {!loadedInfo && (
              <>
                <br />
                <div>Cargando información por favor espere un momento...</div>
                <div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              </>
            )}
            <PageHeader>
              <H1>{mode === 'view' ? ('Ver Autoridad Ambiental') : (mode === 'edit' ? ('Modificar Autoridad Ambiental') : ('Crear Autoridad Ambiental'))}</H1>
            </PageHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card title=" ">
                <CardTitleBox>
                  <H5>Datos Generales</H5>
                </CardTitleBox>
                <GridContainer>
                  <Row>
                    <Col2>
                      <Label>
                        NOMBRE DE LA AUTORIDAD AMBIENTAL <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="nombre"
                              type="text"
                              ref={register({required: true, minLength: 10, maxLength: 200})}
                              placeholder="Nombre de la autoridad ambiental"
                            />
                            {errors.nombre && errors.nombre.type === "required" && (
                              <p style={{color: 'red'}}>
                                ¡El nombre de la autoridad ambiental es requerida!
                              </p>
                            )}
                            {errors.nombre && errors.nombre.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 10 caracteres!
                              </p>
                            )}
                            {errors.nombre && errors.nombre.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 200 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.nombre}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        SIGLA DE LA AUTORIDAD AMBIENTAL
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="sigla"
                              type="text"
                              ref={register({minLength: 2, maxLength: 20})}
                              placeholder="Sigla o acrónimo de la autoridad ambiental"
                            />
                            {errors.sigla && errors.sigla.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 2 caracteres!
                              </p>
                            )}
                            {errors.sigla && errors.sigla.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Máximo 20 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.sigla}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        TIPO DE AUTORIDAD AMBIENTAL <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="tipo" ref={register({required: true})}>
                              <SelectOptions selectOptions={domainsByKey.tiposAutoridadAmbiental}/>
                            </Select>
                            {errors.tipo && errors.tipo.type === "required" && (
                              <p style={{color: 'red'}}>
                                El tipo de autoridad ambiental es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.tipo.domainName}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        ESTADO DE LA AUTORIDAD AMBIENTAL <span className="obligatory">*</span><br/>
                        {mode !== 'view' ? (
                          <>
                            Activa: <Input
                            name="estado"
                            type="checkbox"
                            ref={register}
                          />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.estado ? 'Activo' : 'Inactivo'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        RESPONSABLE <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="responsable"
                              type="text"
                              ref={register({
                                required: true,
                                minLength: 10,
                                maxLength: 150
                              })}
                              placeholder="Nombre del responsable"
                            />
                            {errors.responsable && errors.responsable.type === "required" && (
                              <p style={{color: 'red'}}>
                                El responsable es requerido!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 10 caracteres!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 150 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.responsable}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CARGO DEL RESPONSABLE
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="cargo_responsable"
                              type="text"
                              ref={register({
                                minLength: 5,
                                maxLength: 100
                              })}
                              placeholder="Cargo del responsable"
                            />
                            {errors.cargo_responsable && errors.cargo_responsable.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 5 caracteres!
                              </p>
                            )}
                            {errors.cargo_responsable && errors.cargo_responsable.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 100 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.cargo_responsable}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        EMAIL DEL RESPONSABLE
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="email_responsable"
                              type="email"
                              ref={register({maxLength: 50})}
                              placeholder="Escriba email"
                            />
                            {errors.email_responsable && errors.email_responsable.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 50 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.email_responsable}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        OBSERVACIONES GENERALES
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="observaciones_responsable"
                              type="text"
                              ref={register({minLength: 5, maxLength: 500})}
                              placeholder="Ingrese las observaciones sobre la autoridad"
                            />
                            {errors.observaciones_responsable && errors.observaciones_responsable.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 5 caracteres!
                              </p>
                            )}
                            {errors.observaciones_responsable && errors.observaciones_responsable.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo (500)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.observaciones_responsable}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      <SpanDivide>
                        <br/>
                        Datos de ubicación de la autoridad.
                      </SpanDivide>
                    </Col1>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        PAÍS <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="pais" ref={register({required: true})}>
                              <SelectOptions selectOptions={constantsByKey.paises}/>
                            </Select>
                            {errors.pais && errors.pais.type === "required" && (
                              <p style={{color: 'red'}}>
                                ¡El país de ubicación de la autoridad es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.pais.constantName}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        DEPARTAMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="departamento" onChange={handleChangeDpto} ref={register({required: true})}>
                              <SelectOptions selectOptions={constantsByKey.departamentos}/>
                            </Select>
                            {errors.departamento && errors.departamento.type === "required" && (
                              <p style={{color: 'red'}}>
                                ¡El departamento de ubicación de la autoridad es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.departamento.constantName}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        MUNICIPIO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="municipio" ref={register({required: true})}>
                              <SelectOptions selectOptions={municipiosFiltrados}/>
                            </Select>
                            {errors.municipio && errors.municipio.type === "required" && (
                              <p style={{color: 'red'}}>
                                ¡La ciudad o municipio de ubicación de la autoridad es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.municipio.constantName}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        DIRECCIÓN <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="direccion"
                              type="text"
                              ref={register({required: true, maxLength: 100})}
                              placeholder="Dirección de la autoridad."
                            />
                            {errors.direccion && errors.direccion.type === "required" && (
                              <p style={{color: 'red'}}>
                                ¡La dirección es requerida!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 100 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.direccion}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        TELÉFONO FIJO
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="telefono_fijo"
                              type="tel"
                              ref={register({maxLength: 20})}
                              placeholder="(57-1) 1234567"
                            />
                            {errors.telefono_fijo && errors.telefono_fijo.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Al número de teléfono fijo le sobran dígitos máximo (20)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.telefono_fijo}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        TELÉFONO MÓVIL
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="telefono_movil"
                              type="tel"
                              ref={register({maxLength: 20})}
                              placeholder="Ejemplo: 3001234567"
                            />
                            {errors.telefono_movil && errors.telefono_movil.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Al número de teléfono móvil le sobran dígitos máximo (20)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.telefono_movil}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col4>
                      <Label>
                        LATITUD DECIMAL (OPCIONAL)
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="coordenadas.latitud.decimal"
                              type="number"
                              step="any"
                              ref={register}
                              placeholder="Ejemplo: 4.78457"
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? selected.coordenadas.latitud.decimal : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        LONGITUD DECIMAL (OPCIONAL)
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="coordenadas.longitud.decimal"
                              type="number"
                              step="any"
                              ref={register}
                              placeholder="Ejemplo: -75.45782"
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? selected.coordenadas.longitud.decimal : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        SITIO WEB
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="sitio_web"
                              type="text"
                              ref={register({
                                minLength: 10,
                                maxLength: 100,
                                pattern: {
                                  value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,// /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                  message: 'El sitio web indicado no es valido!'
                                }
                              })}
                              placeholder="www.ejemplo.gov.co"
                            />
                            {errors.sitio_web && errors.sitio_web.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                ¡Mínimo 10 caracteres!
                              </p>
                            )}
                            {errors.sitio_web && errors.sitio_web.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                ¡Máximo 100 caracteres!
                              </p>
                            )}
                            {errors.sitio_web && errors.sitio_web.type === "pattern" && (
                              <p style={{color: 'red'}}>
                                El sitio web indicado no es valido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.sitio_web || '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label />
                    </Col4>
                  </Row>
                  <Row style={{justifyContent: 'flex-end'}}>
                    <Col right>
                      {mode !== 'view' ? (
                        <>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                          <Label style={{ width: 'auto' }}>
                            <Button type="submit">
                              {mode === 'edit' ? ('ACTUALIZAR') : ('CREAR')}
                            </Button>
                          </Label>
                        </>
                      ) : (
                        <>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                        </>
                      )}
                    </Col>
                  </Row>
                </GridContainer>
              </Card>
            </Form>
          </>
        )}
      </Page>
    </ErrorBoundary>
  );
};

AutoridadAmbientalForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

AutoridadAmbientalForm.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(AutoridadAmbientalForm);
