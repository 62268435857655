/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import { pxToRem } from "../../utils";
import { StyledCell } from "./Cell";

const TableContainer = styled.div`
  display: grid;
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  border: ${pxToRem(1)} solid;
  border-color: ${props => props.theme.complementaryColor};
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
`;

const TableHead = styled.thead`
  color: ${props => props.theme.colors.darkGrey};
  background-color: ${props => props.theme.complementaryColor};
`;

const TH = styled.th`
  padding: ${pxToRem(12)} ${pxToRem(8)};
  font-weight: 300;
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;

const StyledPaginationControls = styled.div`
  display: inline-block;
  margin: auto;
  padding:  ${pxToRem(16)};
`;

const PaginationTable = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={`fill-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <TH key={`col-${i}-${j}`} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TH>
              ))}
            </tr>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, k) => {
            prepareRow(row);
            return (
              <tr key={`row-${k}`} {...row.getRowProps()}>
                {row.cells.map((cell, n) => {
                  return (
                    <StyledCell key={`cell-${k}-${n}`} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <StyledPaginationControls>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <span>
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Ver {pageSize}
            </option>
          ))}
        </select>
        <span>
          <b> Total: {data.length}</b>
        </span>
      </StyledPaginationControls>
    </TableContainer>
  );
};

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
};

PaginationTable.defaultProps = {
  columns: [],
  data: []
};

export default PaginationTable;
