import styled from "styled-components";
import { pxToRem } from "../../utils";

export const ButtonLight = styled.button`
  display: inline-block;
  color: ${props => props.theme.primaryColor};
  background-color: ${props => props.theme.colors.white};
  border: solid ${pxToRem(1)} ${props => props.theme.primaryColor};
  border-radius: ${pxToRem(4)};
  font-size: ${pxToRem(14)};
  max-width: ${pxToRem(192)}
  padding: ${pxToRem(8)};
  margin: ${pxToRem(8)} auto;
  text-transform: lowercase;
  &::first-letter {
  	text-transform: uppercase;
  }
  &:hover {
    border: solid ${pxToRem(1)} ${props => props.theme.hoverColor};
    color: ${props => props.theme.hoverColor};
    background-color: ${props => props.theme.colors.grainsboro};
  }
`;


