import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import entidadDisposicionReducer from '../../store/entidadDisposicion';

const { dispatchers } = entidadDisposicionReducer;

const useEntidadDisposicion = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { entidadDisposicion } = state;

  // List of Dispatchers
	const {
    getAllEntidadesDisposicion,
    getEntidadDisposicionById,
    searchEntidadDisposicion,
    deleteEntidadDisposicion,
    createEntidadDisposicion,
    updateEntidadDisposicion,
    clearSelected,
    loadSelected,
    changeMode
  } = dispatchers;

  // Bind Actions
	const entidadDisposicionActions = bindActions({
    getAllEntidadesDisposicion,
    getEntidadDisposicionById,
    searchEntidadDisposicion,
    deleteEntidadDisposicion,
    createEntidadDisposicion,
    updateEntidadDisposicion,
    clearSelected,
    loadSelected,
    changeMode
  }, dispatch);

  return { ...entidadDisposicion, ...entidadDisposicionActions };
};

export default useEntidadDisposicion;
