import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import nanoid from 'nanoid';
/* Hooks */
import {
  useUsers,
  useLogin,
  useConstants,
  useNotification
} from '../hooks';
/* Components */
import {Col, Col1, Col2, Col3, Col4, Col5, Col12, GridContainer, Row} from "../components/Grid";
import Page from "../components/Page";
import { Card } from "../components/Card";
import { CardTitleBox } from "../components/CardTitleBox";
import { PageHeader } from "../components/PageHeader";
// import { StyledLink } from '../components/StyledLink';
import { Form } from "../components/form-ui-components/Form";
import { Input } from "../components/form-ui-components/Input";
import { Label } from "../components/form-ui-components/Label";
import {Button, ButtonSecondary} from "../components/form-ui-components/Button";
import { H1 } from "../components/H1";
import { H5 } from "../components/H5";
import { Select } from "../components/form-ui-components/Select";
import { SelectOptions } from "../components/form-ui-components/SelectOptions";
import { ButtonLight } from "../components/form-ui-components/ButtonLight";
import ErrorBoundary from "../components/ErrorBoundary";


/* Style Components */
import { ContainerRow } from './styled';
/* Defined Constants */
import { wait } from '../config/const';

const UpdateProfile = ({ history }) => {
  const [ message, setMessage ] = useState('');
  const [ submited, setSubmited ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ loadValues, setLoadValues ] = useState(false);
  const { register, setValue, handleSubmit, errors, reset } = useForm();

  const {
    isLoggedIn,
    userLogin,
    token
  } = useLogin();
  const {
    getUserMeRequest,
    updateUserMeRequest,
    isLoading,
    error,
    userMe
  } = useUsers();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const { createNotification } = useNotification();

  const gotoLogin = useCallback(async () => {
    history.push('/');
  }, [history]);

  const gotoHome = useCallback(async () => {
    await wait(3000);
    history.push('/');
  }, [history]);

  const handleResetForm = e => {
    e.preventDefault();
    reset();
    history.push('/');
    e.stopPropagation();
  };

  const loadUserMeSelected = useCallback(() => {
    if (userMe) {
      setValue('nombres', userMe.name);
      setValue('apellidos', userMe.lastname);
      setValue('usuario', userMe.username);
      setValue('numero_documento', userMe.document);
      setValue('correo', userMe.email);
      setValue('telefono', userMe.telephone);
      setValue('direccion', userMe.address);
      setValue('tipo_documento', userMe.documentType);
    }
  }, [setValue, userMe]);

  const load = useCallback(async () => {
    await getConstantOptionsByTypes(['tiposDocumento']);
    if(userLogin) {
      await getUserMeRequest(userLogin, token);
      loadUserMeSelected();
    }
  }, [getConstantOptionsByTypes, loadUserMeSelected, getUserMeRequest, userLogin]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const infoUpdate = {
        username: data.usuario,
        email: data.correo,
        name: data.nombres,
        lastname: data.apellidos,
        document: data.numero_documento,
        documentType: Number(data.tipo_documento),
        telephone: data.telefono,
        address: data.direccion,
      };
      const {msg, err} = await updateUserMeRequest(infoUpdate, token);
      createNotification({
        message: msg,
        error: err,
        visible: true
      });

      reset();
      await gotoHome();
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if(!isLoggedIn) {
      gotoLogin();
    } else if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    }
  }, [isLoggedIn, isLoading, loaded, load, gotoLogin]);

  useEffect(() => {
    loadUserMeSelected();
  }, [userMe]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Actualizar mis datos</H1>
            </PageHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card title=" ">
                <GridContainer>
                  <CardTitleBox>
                    <H1>Datos de usuario</H1>
                  </CardTitleBox>
                  <Row>
                    <Col3>
                      <Label>
                        NOMBRES:
                        <Input
                          style={{ width: '100%' }}
                          name="nombres"
                          type="text"
                          placeholder="Escriba sus nombres completos"
                          ref={register({ required: true })}
                        />
                        {errors.nombres && (
                          <p style={{ color: 'red'}}>
                            Los nombres son requeridos!
                          </p>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        APELLIDOS:
                        <Input
                          style={{ width: '100%' }}
                          name="apellidos"
                          type="text"
                          placeholder="Escriba sus apellidos completos"
                          ref={register({ required: true })}
                        />
                        {errors.apellidos && (
                          <p style={{ color: 'red'}}>
                            Los apellidos son requeridos!
                          </p>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        USUARIO:
                        <Input
                          style={{ width: '100%' }}
                          disabled = {true}
                            name="usuario"
                          type="text"
                          placeholder="Cree un usuario único"
                          ref={register({ required: true })}
                        />
                        {errors.usuario && (
                          <p style={{ color: 'red'}}>
                            El usuario único es requerido!
                          </p>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        TIPO DE DOCUMENTO:
                        <Select style={{ width: '100%' }} name="tipo_documento" ref={register({ required: true })}>
                          <SelectOptions selectOptions={constantsByKey.tiposDocumento}/>
                        </Select>
                        {errors.tipo_documento && (
                          <p style={{ color: 'red'}}>
                            El tipo de documento es requerido!
                          </p>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        NÚMERO DE DOCUMENTO:
                        <Input
                          style={{ width: '100%' }}
                          name="numero_documento"
                          type="text"
                          placeholder="Número sin puntos ni comas"
                          ref={register({
                            required: true,
                            pattern: { value: /^[0-9]+$/ },
                            minLength: 5,
                            maxLength: 12
                          })}
                        />
                        {errors.numero_documento && errors.numero_documento.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El número de documento es requerido!
                          </p>
                        )}
                        {errors.numero_documento && errors.numero_documento.type === "pattern" && (
                          <p style={{ color: 'red'}}>
                            El número de documento no es válido!
                          </p>
                        )}
                        {errors.numero_documento && errors.numero_documento.type === "minLength" && (
                          <p style={{ color: 'red'}}>
                            El número de documento tiene una longitud inferior a 5 dígitos!
                          </p>
                        )}
                        {errors.numero_documento && errors.numero_documento.type === "maxLength" && (
                          <p style={{ color: 'red'}}>
                            El número de documento tiene una longitud superior a 12 dígitos!
                          </p>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        CORREO ELECTRÓNICO
                        <Input
                          style={{ width: '100%' }}
                          name="correo"
                          type="email"
                          placeholder="Correo electrónico completo"
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'El email es requerido o no es válido!'
                            }
                          })}
                        />
                        {errors.correo && errors.correo.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El email es requerido
                          </p>
                        )}
                        {errors.correo && errors.correo.type === "pattern" && (
                          <p style={{ color: 'red'}}>
                            El email no es válido!
                          </p>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        TELÉFONO:
                        <Input
                          style={{ width: '100%' }}
                          name="telefono"
                          type="text"
                          placeholder="Número sin puntos ni comas"
                          ref={register({
                            required: true,
                            minLength: 6,
                            maxLength: 20
                          })}
                        />
                        {errors.telefono && errors.telefono.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El número de teléfono es requerido!
                          </p>
                        )}
                        {errors.telefono && errors.telefono.type === "minLength" && (
                          <p style={{color: 'red'}}>
                            El número de teléfono le falta caracteres mínimo (6)!
                          </p>
                        )}
                        {errors.telefono && errors.telefono.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            El número de teléfono le sobran caracteres máximo (20)!
                          </p>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        DIRECCIÓN:
                        <Input
                          style={{ width: '100%' }}
                          name="direccion"
                          type="text"
                          placeholder="Dirección de lugar de trabajo"
                          ref={register({ required: true })}
                        />
                        {errors.direccion && (
                          <p style={{ color: 'red'}}>
                            La dirección de trabajo es requerida!
                          </p>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={(e) => handleResetForm(e)}>
                          CANCELAR
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="submit">
                          GUARDAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col1>
                        <CardTitleBox>
                          <H5> Ups, hubo un error {error}</H5>
                        </CardTitleBox>
                      </Col1>
                    </Row>
                  )}
                </GridContainer>
              </Card>
            </Form>
          </>
        )}
      </Page>
    </ErrorBoundary>
  );
};

UpdateProfile.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

UpdateProfile.defaultProps = {
  history: {},
};

export default withRouter(UpdateProfile);
