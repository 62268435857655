import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import expedienteReducer from '../../store/expediente';

const { dispatchers } = expedienteReducer;

const useExpedientes = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { expediente } = state;

  // List of Dispatchers
	const {
    createExpedienteRequest,
    updateExpedienteRequest,
    closeExpedienteRequest,
    searchExpedienteRequest,
    deleteExpedienteRequest,
    getbyidExpedienteRequest,
    loadSelected,
    clearSelected,
    contactDeleteExpediente,
    getContactsRequest,
    addContactToExpedienteRequest,
    cleanContactExpediente,
    getEspecimenesExpedienteRequest,
    getByIdEspecimenRequest,
    radicarExpedienteRequest,
    addSameEspecimeneByCantidad,
    deleteEspecimenesExpediente,
    getByIdArchivoRequest,
    getByIdImagenRequest,
    expedienteFilesDownload,
    expedienteImagesDownload,
    getByIdArchivoCloseRequest,
    downloadFileCloseRequest,
    especimenTaxonomyInfoRequest,
    expedienteFilesAdd,
    expedienteFilesUpdate,
    expedienteFilesDelete,
    expedienteImagesAdd,
    expedienteImagesUpdate,
    expedienteImagesDelete,
    expedienteCerrarFilesDelete,
    expedienteFilesCloseAdd,
    expedienteFilesCloseUpdate,
    expedienteFilesCloseDownload,
    expedienteEspecimenUpdateRequest,
    clearSelectedFile,
    clearSelectedImage,
    clearSelectedFileClose
  } = dispatchers;

  // Bind Actions
	const expedientesActions = bindActions({
    createExpedienteRequest,
    updateExpedienteRequest,
    closeExpedienteRequest,
    searchExpedienteRequest,
    deleteExpedienteRequest,
    getbyidExpedienteRequest,
    loadSelected,
    clearSelected,
    contactDeleteExpediente,
    getContactsRequest,
    addContactToExpedienteRequest,
    cleanContactExpediente,
    getEspecimenesExpedienteRequest,
    getByIdEspecimenRequest,
    radicarExpedienteRequest,
    addSameEspecimeneByCantidad,
    deleteEspecimenesExpediente,
    getByIdArchivoRequest,
    getByIdImagenRequest,
    expedienteFilesDownload,
    expedienteImagesDownload,
    getByIdArchivoCloseRequest,
    downloadFileCloseRequest,
    especimenTaxonomyInfoRequest,
    expedienteFilesAdd,
    expedienteFilesUpdate,
    expedienteFilesDelete,
    expedienteImagesAdd,
    expedienteImagesUpdate,
    expedienteImagesDelete,
    expedienteCerrarFilesDelete,
    expedienteFilesCloseAdd,
    expedienteFilesCloseUpdate,
    expedienteFilesCloseDownload,
    expedienteEspecimenUpdateRequest,
    clearSelectedFile,
    clearSelectedImage,
    clearSelectedFileClose
  }, dispatch);

  return { ...expediente, ...expedientesActions };
};

export default useExpedientes;
