import styled from "styled-components";
import { pxToRem } from "../../utils";
import {Card} from "../Card";

export const Form = styled.form`
  padding: ${pxToRem(0)};
  width: 100%;
`;

export default Form;
