/* eslint-disable no-underscore-dangle */
import {
  optionsByTypes,
  optionsByTypesSuccess,
  optionsByTypesFailed,
  listInit,
  listSuccess,
  listError
} from "./actions";
import * as ConstantsServices from "../../services";

export const getConstantOptionsByTypes = constantTypes => {
  return async dispatch => {
    dispatch(optionsByTypes());
    try {
      const data = await ConstantsServices.constantsApi.getConstantOptionsByTypes(constantTypes);
      if (typeof data.status !== "undefined") {
        dispatch(optionsByTypesSuccess(data.data, constantTypes));
      } else if (typeof data.error !== "undefined") {
        dispatch(optionsByTypesFailed(data.error, constantTypes));
      }
    } catch (error) {
      dispatch(optionsByTypesFailed(error));
    }
  };
};

export const getAllConstants = token => {
  return async dispatch => {
    dispatch(listInit());
    try {
      const data = await ConstantsServices.constantsApi.getAllConstants(token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        dispatch(listSuccess(data.data));
      }
      if(typeof data === 'string') {
        dispatch(listError(data));
      }
    } catch (error) {
      dispatch(listError(error));
    }
  };
};
