/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import useForm from 'react-hook-form';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
/* Hooks */
import {
  useUsers,
  useLogin,
  useConstants,
  useNotification,
  useAutoridadAmbiental,
  useEntidadDisposicion
} from '../hooks';
/* Components */
import Page from "../components/Page";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {PageHeader} from "../components/PageHeader";
import {Form} from "../components/form-ui-components/Form";
import {Input} from "../components/form-ui-components/Input";
import {Label} from "../components/form-ui-components/Label";
import {Button, ButtonSecondary} from "../components/form-ui-components/Button";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import {ButtonLight} from "../components/form-ui-components/ButtonLight";
import ErrorBoundary from "../components/ErrorBoundary";

/* Style Components */
import {ContainerRow, SpanView} from './styled';
import {Col, Col1, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";
import { wait } from '../config/const';

const UserForm = ({history, match}) => {
  // const [submited, setSubmited] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [ loadedInfo, setLoadedInfo ] = useState(false);
  const [rolesEntidad, setRolesEntidad] = useState([]);
  const { isLoggedIn, token } = useLogin();
  const {
    rolesData,
    clearSelected,
    loadSelected,
    userCreateRequest,
    userUpdateRequest,
    getUsersRequest,
    selected,
    mode,
    data: users,
    getRolesRequest
  } = useUsers();
  const { createNotification } = useNotification();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();
  const { data: entidadesDisposicion, searchEntidadDisposicion } = useEntidadDisposicion();
  const {register, setValue, getValues, handleSubmit, errors, watch, reset} = useForm();

  const handleChangeType = e => {
    const {value} = e.target;
    const newRolesData = (value === 'disposicion') ? rolesData.filter(({ name }) => (name.includes('COORDINADOR DE EXPEDIENTES')) || (name.includes('COORDINADOR DE ENTIDADES DE DISPOSICIÓN'))) : rolesData;
    setRolesEntidad(newRolesData);
  };

  const gotoLogin = useCallback(async () => {
    history.push('/');
  }, [history]);

  const clearUser = useCallback(async () => {
    window.scrollTo(0, 0);
    await clearSelected();
    reset();
    setLoadedInfo(true);
  }, [history]);

  const gotoUsers = useCallback(async () => {
    await clearSelected();
    history.push('/users');
  }, [history]);

  const gotoUserCrear = useCallback(async () => {
    await clearSelected();
    history.push('/user-form');
  }, [history]);

  const handleClose = async e => {
    e.preventDefault();
    reset();
    await clearSelected();
    history.push('/users');
    e.stopPropagation();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      let newData = {
        ...data,
        estado: true,
        tipo_documento: Number(data.tipo_documento),
        autoridad_ambiental: (data.tipo === 'autoridad') ? data.entidad : '',
        roles: [data.roles]
      }

      if (data.tipo === 'disposicion') {
        newData = {
          ...newData,
          entidad_disposicion: (data.tipo === 'disposicion') ? data.entidad : '',
        }
      }

      const { entidad, ...infoData } = newData;
      let isError = false;
      if (selected) {
        const { usuario, ...infoUpdate } = infoData;
        const {msg, err} = await userUpdateRequest(infoUpdate, selected._id, selected.usuario, token);
        isError = err;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      } else {
        const {msg, err} = await userCreateRequest(infoData, token);
        isError = err;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      }

      if(!isError) {
        reset();
        await gotoUsers();
      }
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const loadUserEntidad = useCallback(async () => {
    if (selected) {
      setValue('entidad', (selected.autoridad_ambiental._id !== '') ? selected.autoridad_ambiental._id : ((selected.entidad_disposicion._id !== '') ? selected.entidad_disposicion._id : ''));
    }
  }, [setValue, selected]);

  const loadUserSelected = useCallback(async () => {
    if (selected) {
      console.log("selected", selected)
      setValue('tipo', (selected.autoridad_ambiental.nombre !== '') ? 'autoridad' : ((selected.entidad_disposicion.nombre !== '') ? 'disposicion' : ''));
      setValue('nombres', selected.nombres);
      setValue('apellidos', selected.apellidos);
      setValue('usuario', selected.usuario);
      setValue('numero_documento', selected.numeroDocumento);
      setValue('correo', selected.correo);
      setValue('telefono', selected.telefono);
      setValue('direccion', selected.direccion);
      // setValue('entidad', (selected.autoridad_ambiental._id !== '') ? selected.autoridad_ambiental._id : ((selected.entidad_disposicion._id !== '') ? selected.entidad_disposicion._id : ''));
      setValue('roles', selected.rol._id);
      setValue('tipo_documento', selected.tipo_documento._id);
    }
  }, [setValue, selected]);

  const load = useCallback(async () => {
    console.log("load")
    window.scrollTo(0, 0);
    await Promise.all([clearSelected(), searchAutoridadAmbiental({}, token), searchEntidadDisposicion({}, token), getRolesRequest(), getConstantOptionsByTypes(['tiposDocumento'])]);
    if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
      console.log("params--->",match.params)
      await loadSelected(match.params.id, match.params.mode, token);
      if (match.params.mode === 'edit') {
        await loadUserSelected();
      }
    }
    setLoadedInfo(true);
  }, [
    getRolesRequest,
    searchAutoridadAmbiental,
    searchEntidadDisposicion,
    getConstantOptionsByTypes,
    clearSelected,
    match.params.id,
    match.params.mode,
    token,
    users,
    loadSelected,
    loadUserSelected
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      gotoLogin();
    } else if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  useEffect(() => {
    loadUserSelected();
  }, [selected, loadUserSelected]);

  useEffect(() => {
    const values = getValues();
    if(values.entidad === '') {
      loadUserEntidad();
    }
  }, [selected, getValues, loadUserEntidad]);

  useEffect(() => {
    if (rolesData.length > 0 && rolesEntidad.length === 0) {
      setRolesEntidad(rolesData);
    }
  }, [rolesData, rolesEntidad]);

  useEffect(() => {
    if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
      clearUser();
    }
  }, [clearUser, match.params.id, match.params.mode]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            {!loadedInfo && (
              <>
                <br />
                <div>Cargando información por favor espere un momento...</div>
                <div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              </>
            )}
            <PageHeader>
              <H1>{mode === 'view' ? ('Ver Usuario') : (mode === 'edit' ? ('Modificar Usuario') : ('Crear Usuario'))}</H1>
            </PageHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card title=" ">
                <CardTitleBox>
                  <H1>Datos Generales</H1>
                </CardTitleBox>
                <GridContainer>
                  <Row>
                    <Col1>
                      <Label>
                        USUARIO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="usuario"
                              type="text"
                              placeholder="Usuario"
                              ref={register({required: true, maxLength: 30})}
                            />
                            {errors.usuario && errors.usuario.type === "required" && (
                              <p style={{color: 'red'}}>
                                El correo electrónico es requerido!
                              </p>
                            )}
                            {errors.usuario && errors.usuario.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El correo electrónico le sobran caracteres máximo (30)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.usuario}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  {(mode !== 'view' && mode !== 'edit') &&
                  <Row>
                    <Col2>
                      <Label>
                        CONTRASEÑA <span className="obligatory">*</span>
                        <Input
                          name="contrasena"
                          type="password"
                          placeholder="Digite la contraseña"
                          ref={register({
                            required: true,
                            minLength: 5,
                            maxLength: 30,
                            pattern: {
                              value: /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,30})$/, // /^[A-Za-z0-9]+$/g,
                              message: 'La contraseña debe contener números y letras!'
                            }
                          })}
                        />
                        {errors.contrasena && errors.contrasena.type === "required" && (
                          <p style={{color: 'red'}}>
                            La contraseña es requerida
                          </p>
                        )}
                        {errors.contrasena && errors.contrasena.type === "pattern" && (
                          <p style={{color: 'red'}}>
                            La contraseña debe contener números y letras!
                          </p>
                        )}
                        {errors.contrasena && errors.contrasena.type === "minLength" && (
                          <p style={{color: 'red'}}>
                            Mínimo seis (6) caracteres!
                          </p>
                        )}
                        {errors.contrasena && errors.contrasena.type === "maxLength" && (
                          <p style={{color: 'red'}}>
                            Máximo treinta (30) caracteres!
                          </p>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        CONFIRMAR <span className="obligatory">*</span>
                        <Input
                          name="confirmar"
                          type="password"
                          placeholder="Digite nuevamente la contraseña"
                          ref={register({
                            required: true,
                            validate: value => value === watch('contrasena')
                          })}
                        />
                        {errors.confirmar && errors.confirmar.type === "required" && (
                          <p style={{color: 'red'}}>
                            La confirmación de contraseña es requerida!
                          </p>
                        )}
                        {errors.confirmar && errors.confirmar.type === "validate" && (
                          <p style={{color: 'red'}}>
                            La contraseña no coincide!
                          </p>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  }
                  <Row>
                    <Col4>
                      <Label>
                        NOMBRES <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="nombres"
                              type="text"
                              placeholder="Escriba su nombre completo"
                              ref={register({required: true, minLength: 3, maxLength: 30})}
                            />
                            {errors.nombres && errors.nombres.type === "required" && (
                              <p style={{color: 'red'}}>
                                Nombre completo requerido!
                              </p>
                            )}
                            {errors.nombres && errors.nombres.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                Mínimo 3 caracteres!
                              </p>
                            )}
                            {errors.nombres && errors.nombres.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Máximo 30 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.nombres}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        APELLIDOS
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="apellidos"
                              type="text"
                              placeholder="Escriba sus apellidos"
                              ref={register({ minLength: 3, maxLength: 30 })}
                            />
                            {errors.apellidos && errors.apellidos.type === "required" && (
                              <p style={{color: 'red'}}>
                                Los apellidos son requeridos!
                              </p>
                            )}
                            {errors.apellidos && errors.apellidos.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                Mínimo 3 caracteres!
                              </p>
                            )}
                            {errors.apellidos && errors.apellidos.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Máximo 30 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.apellidos}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        TIPO DE DOCUMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{width: '100%'}} name="tipo_documento"
                                    ref={register({required: true})}>
                              <SelectOptions selectOptions={constantsByKey.tiposDocumento}/>
                            </Select>
                            {errors.tipo_documento && errors.tipo_documento.type === "required" && (
                              <p style={{color: 'red'}}>
                                El tipo de documento es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.tipo_documento.nombre}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        NÚMERO DE DOCUMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="numero_documento"
                              type="text"
                              placeholder="Número sin puntos ni comas"
                              ref={register({
                                required: true,
                                minLength: 5,
                                maxLength: 20
                              })}
                            />
                            {errors.numero_documento && errors.numero_documento.type === "required" && (
                              <p style={{color: 'red'}}>
                                El número de documento es requerido!
                              </p>
                            )}
                            {errors.numero_documento && errors.numero_documento.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                El número de documento tiene una longitud inferior a 5 dígitos!
                              </p>
                            )}
                            {errors.numero_documento && errors.numero_documento.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El número de documento tiene una longitud superior a 20 dígitos!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.numeroDocumento}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col4>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        CORREO ELECTRÓNICO
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="correo"
                              type="email"
                              placeholder="Correo electrónico"
                              ref={register({
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: 'El email es requerido o no es válido!'
                                }
                              })}
                            />
                            {errors.correo && errors.correo.type === "pattern" && (
                              <p style={{color: 'red'}}>
                                El correo electrónico no es válido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.correo}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        TELÉFONO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="telefono"
                              type="text"
                              placeholder="Número de teléfono o celular"
                              ref={register({
                                required: true,
                                minLength: 7,
                                maxLength: 15
                              })}
                            />
                            {errors.telefono && errors.telefono.type === "required" && (
                              <p style={{color: 'red'}}>
                                El número de teléfono es requerido!
                              </p>
                            )}
                            {errors.telefono && errors.telefono.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                Mínimo 7 dígitos!
                              </p>
                            )}
                            {errors.telefono && errors.telefono.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Máximo 15 dígitos!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.telefono}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        DIRECCIÓN <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              name="direccion"
                              type="text"
                              placeholder="Dirección del lugar de trabajo"
                              ref={register({
                                required: true,
                                minLength: 10,
                                maxLength: 150
                              })}
                            />
                            {errors.direccion && errors.direccion.type === "required" && (
                              <p style={{color: 'red'}}>
                                La dirección es requerida!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                Mínimo 10 caracteres!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Máximo 150 caracteres!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.direccion}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                </GridContainer>
              </Card>
              <Card title=" ">
                <CardTitleBox>
                  <H1>Roles</H1>
                </CardTitleBox>
                <GridContainer>
                  <Row>
                    <Col2>
                      <Label>
                        TIPO DE ENTIDAD <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <input
                              name="tipo"
                              type="radio"
                              value="autoridad"
                              ref={register({required: true})}
                              onChange={(e) => handleChangeType(e)}
                              defaultChecked
                            /> Autoridad ambiental
                            <input
                              name="tipo"
                              type="radio"
                              value="disposicion"
                              ref={register({required: true})}
                              onChange={(e) => handleChangeType(e)}
                            /> Entidad de dispocisión
                            {errors.tipo && errors.tipo.type === "required" && (
                              <p style={{color: 'red'}}>
                                El tipo es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{(selected.autoridad_ambiental.nombre !== '') ? 'Autoridad ambiental' : ((selected.entidad_disposicion.nombre !== '') ? 'Entidad de dispocisión' : '---')}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        ENTIDAD <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="entidad" ref={register({required: true})}>
                              {watch('tipo') === 'autoridad' ? (
                                <SelectOptions selectOptions={autoridadesAmbientales}/>
                              ) : (
                                <SelectOptions selectOptions={entidadesDisposicion}/>
                              )}
                            </Select>
                            {errors.entidad && errors.entidad.type === "required" && (
                              <p style={{color: 'red'}}>
                                La {watch('tipo') === 'autoridad' ? 'Autoridad ambiental' : 'Entidad de Disposición'} es requerida!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{(selected.autoridad_ambiental.nombre !== '') ? selected.autoridad_ambiental.nombre : ((selected.entidad_disposicion.nombre !== '') ? selected.entidad_disposicion.nombre : '---')}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        ROL <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select name="roles" ref={register({required: true})}>
                              <SelectOptions selectOptions={rolesEntidad}/>
                            </Select>
                            {errors.roles && errors.roles.type === "required" && (
                              <p style={{color: 'red'}}>
                                El rol es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.rol.nombre}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      {mode !== 'view' ? (
                        <>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                          <Label style={{ width: 'auto' }}>
                            <Button type="submit">
                              {mode === 'edit' ? ('ACTUALIZAR') : ('CREAR')}
                            </Button>
                          </Label>
                        </>
                      ) : (
                        <>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                        </>
                      )}
                    </Col>
                  </Row>
                </GridContainer>
              </Card>
            </Form>
          </>
        )}
      </Page>
    </ErrorBoundary>
  );
};

UserForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

UserForm.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(UserForm);
