import React from 'react'
import Chart from 'react-google-charts';

const BarChart = ({statistics, title, titleAxixX, titleAxixY, color , width, height}) => {
  return (
    <div>
      <Chart
        width={width}
        height={height}
        chartType="BarChart"
        loader={<div>Cargando estadistica ...</div>}
        data={statistics}
        options={{
          legend: {
            position: 'bottom',
            textStyle: {
              fontName: 'Roboto',
              fontSize: 14,
              bold: false,
              italic: false }
          },
          fontName: 'Roboto',
          fontSize: 16,
          title: title,
          chartArea: { width: '50%' },
          colors: [color],
          isStacked: true,
          hAxis: {
            title: titleAxixX
          },
          vAxis: {
            title: titleAxixY,
            minValue: ""
          },
        }}
        // For tests
        rootProps={{ 'data-testid': '3' }}
      />
    </div>
  );
};

export default BarChart
