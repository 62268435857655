import React, {useCallback, useEffect} from 'react';
import {Col2, GridContainer, Row} from "../Grid";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import {CardTitleBox} from "../CardTitleBox";
import {H5} from "../H5";
import {CardContainerBody} from "../../pages/styled";
import {H1} from "../H1";

function Estadistica({data}) {
  var estadisticaTipoProcedimiento = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaClaseRecurso = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaTipoVida = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaParteProducto = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaGruposBiologicos = React.useRef([['Grupo', 'Cantidad']])
  var estadisticaEstadoDesarrollo = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaSexo = React.useRef([['Tipo', 'Cantidad']])
  var estadisticaExpedientePorAutoridad = React.useRef([['Autoridad', 'Número de expedientes (acumulado)']])
  var estadisticaEspecimenesPorDepartamento = React.useRef([['Departamento', 'Número de especímenes (acumulado)']])
  var estadisticaEspecimenesPorMunicipio = React.useRef([['Municipio', 'Número de especímenes (acumulado)']])
  var estadisticaEspecimenesPorTaxonomia = React.useRef([['Especie', 'Número de especímenes (acumulado)']])
  var estadisticaEspecimenesPorMes = React.useRef([['Mes', 'Cantidad de especímenes (acumulado)']])

  async function setDataOnGraphic(dataSource, bindData) {
    dataSource.map((element, i) => {
      var category
      if ((element._id.domainName !== undefined && element._id.domainName !== "") || (element._id.constantName !== undefined && element._id.constantName !== "") || (element._id.canonicalName !== undefined && element._id.canonicalName !== "")) {
        if (element._id.domainName) {
          category = element._id.domainName
        } else if (element._id.constantName) {
          category = element._id.constantName
        } else if (element._id.canonicalName) {
          category = element._id.canonicalName
        }
      } else {
        if (element._id[0] !== undefined && element._id[0] !== null) {
          category = element._id[0]
        } else {
          if (element._id !== undefined) {
            category = element._id[0]
          } else {
            category = "Indeterminado"
          }
        }
      }
      bindData.push(
        [category, parseInt(element.count)]
      );
    })
  }

  const draw = useCallback(async (data) => {
    await setDataOnGraphic(data.expedientes_tipo_procedimiento, estadisticaTipoProcedimiento.current)
    await setDataOnGraphic(data.especimens_clase_recurso, estadisticaClaseRecurso.current)
    await setDataOnGraphic(data.especimens_estado, estadisticaTipoVida.current)
    await setDataOnGraphic(data.especimens_parte_producto, estadisticaParteProducto.current)
    await setDataOnGraphic(data.especimens_grupo_biologicos, estadisticaGruposBiologicos.current)
    await setDataOnGraphic(data.especimens_estado_desarrollo, estadisticaEstadoDesarrollo.current)
    await setDataOnGraphic(data.especimens_sexo, estadisticaSexo.current)
    await setDataOnGraphic(data.expedientes_autoridad_ambiental, estadisticaExpedientePorAutoridad.current)
    await setDataOnGraphic(data.especimens_departamento_procedimiento, estadisticaEspecimenesPorDepartamento.current)
    await setDataOnGraphic(data.especimens_municipio_procedimiento, estadisticaEspecimenesPorMunicipio.current)
    await setDataOnGraphic(data.especimens_taxonomia, estadisticaEspecimenesPorTaxonomia.current)
    await setDataOnGraphic(data.especimenes_incautados_mensual, estadisticaEspecimenesPorMes.current)
  }, [data])

  useEffect(() => {
    draw(data)
  }, [draw]);

  return (
    <>
      <GridContainer>
        <Row>
          <span>Este reporte contiene gráficos y estadísticas acumulativas de la actividad general de tráfico ilegal reportada en PIFS. El reporte resalta la información sobre los eventos de tráficos y los especímenes asociados, según las Actas Únicas de Control al Tráfico Ilegal.</span>
        </Row>
        <br/>
      </GridContainer>
      <GridContainer>
        <BarChart statistics={estadisticaExpedientePorAutoridad.current} title={"Número de expedientes por autoridad ambiental"}
                  titleAxixX={""} titleAxixY={""} color={'#40c1aa'} width={'1100px'}
                  height={'600px'}/>
      </GridContainer>
      <GridContainer>
        <Row>
          <Col2>
            <PieChart statistics={estadisticaTipoProcedimiento.current} title={"Tipo de procedimiento - Número de expedientes"} width={'1100px'}
                      height={'300px'}/>
          </Col2>
        </Row>
      </GridContainer>
      <GridContainer>
        <BarChart statistics={estadisticaEspecimenesPorDepartamento.current}
                  title={"Tráfico de especímenes por departamento"}
                  titleAxixX={""} titleAxixY={""} color={'#ec4478'} width={'1100px'}
                  height={'600px'}/>
      </GridContainer>
      <GridContainer>
        <BarChart statistics={estadisticaEspecimenesPorMunicipio.current}
                  title={"Tráfico de especímenes por municipio - Top 30"}
                  titleAxixX={""} titleAxixY={""} color={'#40c1aa'} width={'1100px'}
                  height={'600px'}/>
      </GridContainer>
      <GridContainer>
        <Row>
          <Col2>
            <PieChart statistics={estadisticaTipoVida.current} title={"Estados de vida - Número de especímenes"} width={'500px'}
                      height={'300px'}/>
          </Col2>
          <Col2>
            <PieChart statistics={estadisticaParteProducto.current} title={"Partes, productos y subproductos de fauna silvestre - Número de registros"}
                      width={'500px'}
                      height={'300px'}/>
          </Col2>
        </Row>
      </GridContainer>
      <br/>
      <GridContainer>
        <Row>
          <Col2>
            <PieChart statistics={estadisticaClaseRecurso.current} title={"Clase de recurso - Número de especímenes"} width={'500px'}
                      height={'300px'}/>
          </Col2>
          <Col2>
            <PieChart statistics={estadisticaGruposBiologicos.current}
                      title={"Tráfico por grupo biológicos - Número de especímenes"} width={'500px'}
                      height={'300px'}/>
          </Col2>
        </Row>
      </GridContainer>
      <br/>
      <GridContainer>
        <Row>
          <Col2>
            <BarChart statistics={estadisticaEspecimenesPorTaxonomia.current}
                      title={"Especímenes más traficados - Top 30"}
                      titleAxixX={""} titleAxixY={"Nombre científico"} color={'#F42F63'} width={'1100px'}
                      height={'600px'}/>
          </Col2>
        </Row>
      </GridContainer>
      <br/>
      <GridContainer>
        <Row>
          <Col2>
            <PieChart statistics={estadisticaSexo.current} title={"Clasificación por sexo - Número de especímenes"} width={'500px'} height={'300px'}/>
          </Col2>
          <Col2>
            <PieChart statistics={estadisticaEstadoDesarrollo.current} title={"Estado de desarrollo - Número de especímenes"} width={'500px'}
                      height={'300px'}/>
          </Col2>
        </Row>
      </GridContainer>
    </>)
}

export default Estadistica;
