/* Defined Constants */
import { DOMAINS_URL_API as URL_API } from "../config/const";
/* Defined Endpoints */
import endpoints from "../config/endpoints";
import { domainTypesByKey } from "../config/const";


let apiHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: ""
};

const fetchParams = (method, data = "") => {
  const body = data ? { body: JSON.stringify(data) } : {};
  return {
    method,
    headers: apiHeaders,
    credentials: "same-origin",
    ...body
  };
};

//file name in the left
const resourceByFilename = {
  dominios: "domains",
  "taxonomía": "taxonInfo",
  constantes: "constants"
};

export const apiDomains = {
  downloadFile: async (fileName, token) => {
    console.log(token);
    apiHeaders = { ...apiHeaders };
    try {
      const resource = resourceByFilename[fileName];
      const path = endpoints[resource].download;
      const url = new URL(path, URL_API);
      const response = await fetch(url, fetchParams("GET"));
      const fileBlob = await response.blob();
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${fileName}.csv`;
      a.click();
      return true;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  uploadFile: async (file, fileName, downloadFileName, token) => {
    const dataf = new FormData();
    dataf.append(fileName, file);
    const newApiHeaders = new Headers();
    newApiHeaders.append("Accept", "application/json");
    newApiHeaders.append("Authorization", token);
    const resource = resourceByFilename[downloadFileName];
    console.log(resource, downloadFileName);
    const path = endpoints[resource].bulkUpdate;
    const url = new URL(path, URL_API);
    const params = {
      method: "POST",
      headers: newApiHeaders,
      credentials: "same-origin",
      body: dataf
    };
    try {
      const response = await fetch(url, params);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getAllDomains: async token => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    const path = endpoints.domains.search;
    const url = new URL(path, URL_API);
    try {
      const response = await fetch(url, fetchParams("GET"));
      if (!response.ok) {
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getAllTaxonomy: async ([from, to], token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    const path = `${endpoints.taxonInfo.list}?from=${from}&to=${to}`;
    const url = new URL(path, URL_API);
    try {
      const response = await fetch(url, fetchParams("GET"));
      if (!response.ok) {
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getOptionsByDomainTypes: async (domainKeys) => {
    try {
      const domainTypesParam = domainKeys.map(k => domainTypesByKey[k]);
      const path = endpoints.domains.getOptionsByDomainTypes + domainTypesParam;
      const url = new URL(path, URL_API);
      const response = await fetch(url, fetchParams('GET'));
      if (typeof response.ok === 'undefined') {
        throw new Error(response.status_message);

      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
};

export default apiDomains;
