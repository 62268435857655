import {
  CONSULTA_POR_TABLA_SEARCH_INIT,
  CONSULTA_POR_TABLA_SEARCH_SUCCESS,
  CONSULTA_POR_TABLA_SEARCH_ERROR,
  CONSULTA_POR_GEOVISOR_SEARCH_INIT,
  CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS,
  CONSULTA_POR_GEOVISOR_SEARCH_ERROR,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR,
	CONSULTA_POR_ESTADISTICAS_SEARCH_INIT,
	CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS,
	CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR
} from "./types";

export const searchTablaInit = () => {
	return {
		type: CONSULTA_POR_TABLA_SEARCH_INIT
	};
};

export const searchTablaSuccess = (data, pageCount, from, to) => {
	return {
		type: CONSULTA_POR_TABLA_SEARCH_SUCCESS,
		payload: { data, pageCount, from, to }
	};
};

export const searchTablaError = error => {
	return {
		type: CONSULTA_POR_TABLA_SEARCH_ERROR,
		payload: error
	};
};

export const searchGeovisorInit = () => {
	return {
		type: CONSULTA_POR_GEOVISOR_SEARCH_INIT
	};
};

export const searchGeovisorSuccess = data => {
	return {
		type: CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS,
		payload: { data }
	};
};

export const searchGeovisorError = error => {
	return {
		type: CONSULTA_POR_GEOVISOR_SEARCH_ERROR,
		payload: error
	};
};

export const descargarReporteInit = () => {
  return {
		type: CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT,
	};
};

export const descargarReporteSuccess = message => {
  return {
		type: CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS,
        payload: message
	};
};

export const descargarReporteError = error => {
	return {
		type: CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR,
		payload: error
	};
};

export const searchEstadisticasInit = () => {
	return {
		type: CONSULTA_POR_ESTADISTICAS_SEARCH_INIT
	};
};

export const searchEstadisticasSuccess = (data) => {
	return {
		type: CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS,
		payload: {data}
	};
};

export const searchEstadisticasError = error => {
	return {
		type: CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR,
    payload: error
	};
};
