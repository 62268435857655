/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, {useCallback, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {withRouter} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";

import {removeEmpty} from "../utils";

import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import {Button} from "../components/form-ui-components/Button";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";

import {
    useAutoridadAmbiental,
    useDomains,
    useConstants,
    useNotification,
    useLogin
} from "../hooks";
/* Style Components */
import { CardContainerBody } from './styled';
import {Col, Col2, Col3, GridContainer, Row} from "../components/Grid";
import {H5} from "../components/H5";
import ErrorBoundary from "../components/ErrorBoundary";

const AutoridadesAmbientales = ({ history }) => {
    const [loaded, setLoaded] = useState(false);
    const [ municipiosFiltrados, setMunicipiosFiltrados ] = useState([]);
    const {register, handleSubmit, setValue, reset} = useForm({});
    const {domainsByKey, getOptionsByDomainTypes} = useDomains();
    const {
        constants,
        constantsByKey,
        getConstantOptionsByTypes
    } = useConstants();
    const {
        isLoading,
        error,
        data: autoridadesAmbientales,
        deleteAutoridadAmbiental,
        searchAutoridadAmbiental
    } = useAutoridadAmbiental();
    const { createNotification } = useNotification();
    const { isLoggedIn, token } = useLogin();

    const handleChangeDpto = e => {
      e.preventDefault();
      const { value } = e.target;
      const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
      setMunicipiosFiltrados(newData);
    };

    const autoridadesEstados = [{ _id: true, nombre: 'Activa' }, { _id: false, nombre: 'Inactiva' }];

    const gotoLogin = useCallback(() => {
      history.push('/');
    }, [history]);

    const load = useCallback(async () => {
      await Promise.all([searchAutoridadAmbiental({}, token), getConstantOptionsByTypes(["paises", "departamentos", "municipios"]), getOptionsByDomainTypes(["tiposAutoridadAmbiental"])]);
      setValue('pais', 3000001);
    }, [
        searchAutoridadAmbiental,
        getConstantOptionsByTypes,
        getOptionsByDomainTypes
    ]);

    const AlertThenDelete = async original => {
      confirmAlert({
        title: 'DESACTIVAR AUTORIDAD AMBIENTAL',
        message: `¿Esta seguro que desea desactivar la autoridad ambiental: ${original.nombre}`,
        buttons: [
          {
            label: "ACEPTAR",
            onClick: async () => {
              let coordenadasOriginales = null
              if('coordenadas' in original && original.coordenadas!==null){
                coordenadasOriginales = {
                  latitud: {
                    decimal: original.coordenadas.latitud.decimal ? Number(original.coordenadas.latitud.decimal) : 0,
                  },
                  longitud: {
                    decimal: original.coordenadas.longitud.decimal ? Number(original.coordenadas.longitud.decimal) : 0,
                  }
                }
              }
              const newData = {
                ...original,
                observaciones: original.observaciones ? original.observaciones : "no hay observaciones",
                tipo: original.tipo ? Number(original.tipo._id) : null,
                pais: original.pais ? Number(original.pais._id) : null,
                departamento: original.departamento ? Number(original.departamento._id) : null,
                municipio: original.municipio ? Number(original.municipio._id) : null,
                coordenadas: coordenadasOriginales,
                estado: false,
              };
              const {msg, err} = await deleteAutoridadAmbiental(newData, original._id, token);
              createNotification({message: msg, visible: true, error: err});
              load();
            }
          },
          {
            label: "CANCELAR",
            onClick: () => {
            }
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
    };

    const columns = [
        {Header: "Nombre", accessor: "nombre"},
        {Header: "Sigla", accessor: "sigla"},
        {
          Header: 'Tipo',
          accessor: d => {
            return ((d.tipo.domainName !== '') && (d.tipo.domainName !== ' ') && (d.tipo.domainName !== undefined)) ? d.tipo.domainName : '---';
          }
        },
        // {Header: "Teléfono Móvil", accessor: "telefono_movil"},
        // {Header: "Teléfono Fijo", accessor: "telefono_fijo"},
        {Header: "Pais", accessor: e => e.pais.constantName},
        {
          Header: 'Departamento',
          accessor: d => {
            return ((d.departamento.constantName !== '') && (d.departamento.constantName !== ' ') && (d.departamento.constantName !== undefined)) ? d.departamento.constantName : '---';
          }
        },
        {
          Header: 'Municipio',
          accessor: d => {
            return ((d.municipio.constantName !== '') && (d.municipio.constantName !== ' ') && (d.municipio.constantName !== undefined)) ? d.municipio.constantName : '---';
          }
        },
        {
          Header: 'Estado',
          accessor: d => {
              return ((d.estado !== '') && (d.estado !== ' ') && (d.estado !== undefined) && (d.estado)) ? 'Activo' : 'Inactivo';
          }
        },
        {
          Header: "Acciones",
          Cell: ({ row }) => {
            const { original } = row;
            const buttons = [
              {
                id: original._id,
                iconName: 'remove_red_eye',
                title: 'Ver Autoridad Ambiental',
                visible: true,
                handleAction: () => {
                  history.push(`/autoridad-ambiental-form/view/${original._id}`);
                }
              },
              {
                id: original._id,
                iconName: "edit",
                title: "Actualizar Autoridad Ambiental",
                visible: true,
                handleAction: () => {
                  history.push(`/autoridad-ambiental-form/edit/${original._id}`);
                }
              },
              {
                id: original._id,
                iconName: 'visibility_off',
                title: 'Desactivar Autoridad Ambiental',
                visible: true,
                handleAction: async () => AlertThenDelete(original)
              },
            ];
            return <ButtonsActionRow buttons={buttons} />;
          }
        }
    ];

    const handleClearSearchFields = async e => {
      e.preventDefault();
      reset();
      setMunicipiosFiltrados([]);
      await searchAutoridadAmbiental({}, token);
      setValue('pais', 3000001);
    };

    useEffect(() => {
      if ((isLoggedIn && !loaded)) {
        load();
        setLoaded(true);
      } else if (!isLoggedIn) {
        gotoLogin();
      }
    }, [isLoggedIn, loaded, load, gotoLogin]);

    const onSubmit = async (data, e) => {
      e.preventDefault();
      try {
        data.tipo = data.tipo ? Number(data.tipo) : null;
        data.pais = data.pais ? Number(data.pais) : null;
        data.municipio = data.municipio ? Number(data.municipio) : null;
        data.departamento = data.departamento ? Number(data.departamento) : null;
        data.estado = data.estado ? (String(true) === data.estado) : null;
        await searchAutoridadAmbiental(removeEmpty(data), token);
      } catch (err) {
        console.log(err);
      }
      e.stopPropagation();
    };

    return (
      <ErrorBoundary>
        <Page useSideBarSpace>
          {isLoggedIn && (
            <>
              <PageHeader>
                <H1>Ver Autoridad Ambiental</H1>
              </PageHeader>
              <Card title=" ">
                <CardTitleBox>
                  <H5>Datos de búsqueda</H5>
                </CardTitleBox>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <GridContainer>
                    <Row>
                      <Col2>
                        <Label>
                          NOMBRE DE LA AUTORIDAD AMBIENTAL
                          <Input
                            name="nombre"
                            type="text"
                            ref={register}
                            placeholder="escriba el nombre de la autoridad ambiental"
                          />
                        </Label>
                      </Col2>
                      <Col2>
                        <Label>
                          SIGLA DE LA AUTORIDAD AMBIENTAL
                          <Input
                            name="sigla"
                            type="text"
                            ref={register}
                            placeholder="iniciales de la autoridad ambiental"
                          />
                        </Label>
                      </Col2>
                    </Row>
                    <Row>
                      <Col2>
                        <Label>
                          TIPO AUTORIDAD AMBIENTAL
                          <Select name="tipo" ref={register}>
                            <SelectOptions selectOptions={domainsByKey.tiposAutoridadAmbiental} />
                          </Select>
                        </Label>
                      </Col2>
                      <Col2>
                        <Label>
                          ESTADO DE AUTORIDAD AMBIENTAL<br />
                          <Select name="estado" ref={register}>
                            <SelectOptions selectOptions={autoridadesEstados}/>
                          </Select>
                        </Label>
                      </Col2>
                    </Row>
                    <Row>
                      <Col3>
                        <Label>
                          PAÍS
                          <Select name="pais" ref={register}>
                            <SelectOptions selectOptions={constantsByKey.paises}/>
                          </Select>
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          DEPARTAMENTO
                          <Select name="departamento" onChange={handleChangeDpto} ref={register}>
                            <SelectOptions selectOptions={constantsByKey.departamentos}/>
                          </Select>
                        </Label>
                      </Col3>
                      <Col3>
                        <Label>
                          CIUDAD O MUNICIPIO
                          <Select name="municipio" ref={register}>
                              <SelectOptions selectOptions={municipiosFiltrados}/>
                          </Select>
                        </Label>
                      </Col3>
                    </Row>
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        <>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                              LIMPIAR FORMULARIO
                            </Button>
                          </Label>
                          <Label style={{ width: 'auto' }}>
                            <Button type="submit">
                              BUSCAR
                            </Button>
                          </Label>
                        </>
                      </Col>
                    </Row>
                  </GridContainer>
                </Form>
              </Card>
              <Card container>
                {isLoading ? (
                  <div style={{justifyContent: 'flex-end', width: '100%'}}>
                    <div>Cargando información, un momento por favor...</div>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                ) : (
                  <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                    <CardContainerBody>
                      <div className="card-body-row">
                        <PaginationTable columns={columns} data={autoridadesAmbientales}/>
                      </div>
                    </CardContainerBody>
                    {error && (
                      <CardContainerBody>
                        <CardTitleBox className="error" error>
                          <H5>Ups, hubo un error: {error}</H5>
                        </CardTitleBox>
                      </CardContainerBody>
                    )}
                  </div>
                )}
              </Card>
            </>
          )}
        </Page>
      </ErrorBoundary>
    );
};

AutoridadesAmbientales.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

AutoridadesAmbientales.defaultProps = {
  history: {},
};

export default withRouter(AutoridadesAmbientales);
