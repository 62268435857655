export const ENTIDAD_DISPOSICION_SEARCH_INIT = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_SEARCH_INIT';
export const ENTIDAD_DISPOSICION_SEARCH_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_SEARCH_SUCCESS';
export const ENTIDAD_DISPOSICION_SEARCH_ERROR = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_SEARCH_ERROR';
export const ENTIDAD_DISPOSICION_CREATE_INIT = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_CREATE_INIT';
export const ENTIDAD_DISPOSICION_CREATE_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_CREATE_SUCCESS';
export const ENTIDAD_DISPOSICION_CREATE_ERROR = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_CREATE_ERROR';
export const ENTIDAD_DISPOSICION_UPDATE_INIT = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_UPDATE_INIT';
export const ENTIDAD_DISPOSICION_UPDATE_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_UPDATE_SUCCESS';
export const ENTIDAD_DISPOSICION_UPDATE_ERROR = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_UPDATE_ERROR';
export const ENTIDAD_DISPOSICION_DELETE_INIT = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_DELETE_INIT';
export const ENTIDAD_DISPOSICION_DELETE_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_DELETE_SUCCESS';
export const ENTIDAD_DISPOSICION_DELETE_ERROR = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_DELETE_ERROR';
export const ENTIDAD_DISPOSICION_CLEAR_SELECTED = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_CLEAR_SELECTED';
export const ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT';
export const ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS';
export const ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR';
export const ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS = '[ENTIDAD_DISPOSICION] ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS';
