import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import taxonomyReducer from '../../store/taxonomy';

const { dispatchers } = taxonomyReducer;

const useTaxonomy = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { taxonomy } = state;

  // List of Dispatchers
	const {
    getAllTaxonomy
  } = dispatchers;

  // Bind Actions
	const taxonomyActions = bindActions({
    getAllTaxonomy
  }, dispatch);

  return { ...taxonomy, ...taxonomyActions };
};

export default useTaxonomy;
