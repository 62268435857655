/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* Defined Constants */
import { USERS_URL_API_PIFS, FAKE_JSON_API, wait } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '', token = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};
  if(Array.isArray(data)) {
    const newApiHeaders = new Headers();
    newApiHeaders.append("Content-Type", "application/json");
    newApiHeaders.append("Accept", "application/json");
    newApiHeaders.append("Authorization", token);
    return {
      method,
      headers: newApiHeaders,
      credentials: 'same-origin',
      ...body,
    };
  }

  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiEspecimenes = {
  put: async (id, especimenes, token) => {
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.put}/${id}/especimenes`, fetchParams('PUT', especimenes, token ));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  putTraslado: async (id, especimenes, token) => {
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.putTraslado}/${id}/especimenes`, fetchParams('PUT', especimenes, token ));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  search: async (values, from, to,  token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.search}/${from}/${to}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  delete: async id => {
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.delete}/${id}`, fetchParams('DELETE'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  get: async id => {
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.get}/${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  getEspecimenTraslado: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.getEspecimenTraslado}/${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  searchEspecimenTraslado: async (values, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.especimenes.searchEspecimenTraslado}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
};

export default apiEspecimenes;
