import styled from "styled-components";
import { pxToRem } from "../../utils";

export const Label = styled.label`
  display: inline-block;
  text-align: left;
  margin: auto 0;
  font-size: ${pxToRem(13)};
  padding: ${pxToRem(8)};
  width: 100%;
  font-weight: ${props => props.weight ? 'bold' : 300 };
  border-bottom: ${props => props.border ? `${pxToRem(1)} solid ${props.theme.primaryColor}` : 'none' };
  text-transform: lowercase;
  &::first-letter {
  	text-transform: uppercase;
  }
`;

export default Label;
