import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
/* Hooks */
import {
  useLogin
} from '../hooks';
/* Components */
import ErrorBoundary from "../components/ErrorBoundary";
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import {H1} from "../components/H1";
import PieChart from "../components/Charts/PieChart";
import BarChart from "../components/Charts/BarChart";
import {Col2, GridContainer, Row} from "../components/Grid";

var estadisticaTipoProcedimiento = [['Tipo', 'Cantidad']]
var estadisticaClaseRecurso = [['Tipo', 'Cantidad']]
var estadisticaTipoVida = [['Tipo', 'Cantidad']]
var estadisticaParteProducto = [['Tipo', 'Cantidad']]
var estadisticaGruposBiologicos = [['Grupo', 'Cantidad']]
var estadisticaEstadoDesarrollo = [['Tipo', 'Cantidad']]
var estadisticaSexo = [['Tipo', 'Cantidad']]
var estadisticaExpedientePorAutoridad = [['Autoridad', 'Número de expedientes']]
var estadisticaEspecimenesPorDepartamento = [['Departamento', 'Número de especimenes']]
var estadisticaEspecimenesPorMunicipio = [['Municipio', 'Número de especímenes']]
var estadisticaEspecimenesPorTaxonomia = [['Especie', 'Número de  especímenes']]
var estadisticaEspecimenesPorMes = [['Mes', 'Cantidad de especimenes incautados']]

const ConsultaPorEstadisticas = ({history}) => {
  function setDataOnGraphic(dataSource, bindData) {
    dataSource.map((element, i) => {
      var category
      if ((element._id.domainName !== undefined && element._id.domainName !== "") || (element._id.constantName !== undefined && element._id.constantName !== "") || (element._id.canonicalName !== undefined && element._id.canonicalName !== "")) {
        if (element._id.domainName) {
          category = element._id.domainName
        } else if (element._id.constantName) {
          category = element._id.constantName
        } else if (element._id.canonicalName) {
          category = element._id.canonicalName
        }
      } else {
        if (element._id[0] !== undefined && element._id[0] !== null) {
          category = element._id[0]
        } else {
          if (element._id !== undefined) {
            category = element._id[0]
          } else {
            category = "Indeterminado"
          }
        }
      }
      bindData.push(
        [category, parseInt(element.count)]
      );
    })
  }

  useEffect(async () => {
    console.log("estadsticas consultadas")
    var options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    };
    await fetch('http://192.168.0.16:8004/api/reporte/especimen/estadistica', options)
      .then(res => {
        return res.json()
      })
      .then(async (data) => {
        await setDataOnGraphic(data.data.expedientes_tipo_procedimiento, estadisticaTipoProcedimiento)
        await setDataOnGraphic(data.data.especimens_clase_recurso, estadisticaClaseRecurso)
        await setDataOnGraphic(data.data.especimens_estado, estadisticaTipoVida)
        await setDataOnGraphic(data.data.especimens_parte_producto, estadisticaParteProducto)
        await setDataOnGraphic(data.data.especimens_grupo_biologicos, estadisticaGruposBiologicos)
        await setDataOnGraphic(data.data.especimens_estado_desarrollo, estadisticaEstadoDesarrollo)
        await setDataOnGraphic(data.data.especimens_sexo, estadisticaSexo)
        await setDataOnGraphic(data.data.expedientes_autoridad_ambiental, estadisticaExpedientePorAutoridad)
        await setDataOnGraphic(data.data.especimens_departamento_procedimiento, estadisticaEspecimenesPorDepartamento)
        await setDataOnGraphic(data.data.especimens_municipio_procedimiento, estadisticaEspecimenesPorMunicipio)
        await setDataOnGraphic(data.data.especimens_taxonomia, estadisticaEspecimenesPorTaxonomia)
        await setDataOnGraphic(data.data.especimenes_incautados_mensual, estadisticaEspecimenesPorMes)
      })
      .catch(console.log)
  }, []);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        <PageHeader>
          <H1>Estadísticas</H1>
        </PageHeader>
        <br/>
        <GridContainer>
          <BarChart statistics={estadisticaExpedientePorAutoridad} title={"Expedientes por autoridad ambiental"}
                    titleAxixX={""} titleAxixY={""} color={'#40c1aa'} width={'1100px'}
                    height={'600px'}/>
        </GridContainer>
        <br/>
        <GridContainer>
          <Row>
            <Col2>
              <PieChart statistics={estadisticaTipoProcedimiento} title={"Tipo de procedimiento"} width={'500px'}
                        height={'300px'}/>
            </Col2>
            <Col2>
              <PieChart statistics={estadisticaClaseRecurso} title={"Clase de recurso"} width={'500px'}
                        height={'300px'}/>
            </Col2>
          </Row>
        </GridContainer>
        <br/>
        <GridContainer>
          <BarChart statistics={estadisticaEspecimenesPorDepartamento} title={"Tráfico de especímenes por departamento"}
                    titleAxixX={""} titleAxixY={""} color={'#ec4478'}  width={'1100px'}
                    height={'600px'}/>
        </GridContainer>
        <br/>
        <GridContainer>
          <BarChart statistics={estadisticaEspecimenesPorMunicipio}
                    title={"Tráfico de especímenes por municipio - Top 30"}
                    titleAxixX={""} titleAxixY={""} color={'#40c1aa'}  width={'1100px'}
                    height={'600px'}/>
        </GridContainer>
        <br/>
        <GridContainer>
          <Row>
            <Col2>
              <PieChart statistics={estadisticaTipoVida} title={"Estado de vida"} width={'500px'} height={'300px'}/>
            </Col2>
            <Col2>
              <PieChart statistics={estadisticaParteProducto} title={"Partes, productos y subproductos"} width={'500px'}
                        height={'300px'}/>
            </Col2>
          </Row>
        </GridContainer>
        <br/>
        <GridContainer>
          <PieChart statistics={estadisticaGruposBiologicos} title={"Tráfico por grupo biológicos - Número de especímenes"} width={'1100px'}
                    height={'300px'}/>
        </GridContainer>
        <br/>
        <GridContainer>
          <Row>
            <Col2>
              <BarChart statistics={estadisticaEspecimenesPorTaxonomia} title={"Especímenes más traficados - Top 30"}
                        titleAxixX={""} titleAxixY={"Nombre científico"} color={'#F42F63'}  width={'1100px'}
                        height={'600px'}/>
            </Col2>
          </Row>
        </GridContainer>
        <br/>
        <GridContainer>
          <Row>
            <Col2>
              <PieChart statistics={estadisticaSexo} title={"Sexo"} width={'500px'} height={'300px'}/>
            </Col2>
            <Col2>
              <PieChart statistics={estadisticaEstadoDesarrollo} title={"Estado de desarrollo"} width={'500px'}
                        height={'300px'}/>
            </Col2>
          </Row>
        </GridContainer>
      </Page>
    </ErrorBoundary>
  )
};

ConsultaPorEstadisticas.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConsultaPorEstadisticas.defaultProps = {
  history: {},
};

export default withRouter(ConsultaPorEstadisticas);
