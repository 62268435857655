/* eslint-disable import/no-cycle */
import autoridadAmbientalReducer from "./autoridadAmbiental";
import domainsReducer from "./domains";
import contactsReducer from "./contacts";
import constantsReducer from "./constants";
import taxonomyReducer from "./taxonomy";
import entidadDisposicionReducer from "./entidadDisposicion";
import loginReducer from "./login";
import notificationReducer from "./notification";
import usersReducer from "./users";
import expedienteReducer from "./expediente";
import trasladoReducer from "./traslado";
import reportesReducer from "./reportes";

import { logger } from "./middlewares";

export const initialState = {
  autoridadAmbiental: autoridadAmbientalReducer.initialState,
  contacts: contactsReducer.initialState,
  constants: constantsReducer.initialState,
  domains: domainsReducer.initialState,
  taxonomy: taxonomyReducer.initialState,
  entidadDisposicion: entidadDisposicionReducer.initialState,
  login: loginReducer.initialState,
  notification: notificationReducer.initialState,
  users: usersReducer.initialState,
  expediente: expedienteReducer.initialState,
  traslado: trasladoReducer.initialState,
  reportes: reportesReducer.initialState
};

export default function mainReducer(state, action) {
  // Receiving previous state here
  const {
    autoridadAmbiental,
    constants,
    contacts,
    domains,
    taxonomy,
    entidadDisposicion,
    login,
    users,
    expediente,
    notification,
    traslado,
    reportes
  } = state;

  // Receiving current state here
  const currentState = {
    autoridadAmbiental: autoridadAmbientalReducer.reducer(
      autoridadAmbiental,
      action
    ),
    constants: constantsReducer.reducer(constants, action),
    contacts: contactsReducer.reducer(contacts, action),
    taxonomy: taxonomyReducer.reducer(taxonomy, action),
    entidadDisposicion: entidadDisposicionReducer.reducer(entidadDisposicion, action),
    domains: domainsReducer.reducer(domains, action),
    login: loginReducer.reducer(login, action),
    notification: notificationReducer.reducer(notification, action),
    users: usersReducer.reducer(users, action),
    expediente: expedienteReducer.reducer(expediente, action),
    traslado: trasladoReducer.reducer(traslado, action),
    reportes: reportesReducer.reducer(reportes, action),
  };

  // Middlewares
  logger(action, state, currentState);

  return currentState;
}
