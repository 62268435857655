import {
  CONTACTS_SEARCH_INIT,
  CONTACTS_SEARCH_SUCCESS,
  CONTACTS_SEARCH_ERROR,
  CONTACT_CREATE_INIT,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_ERROR,
  CONTACT_UPDATE_INIT,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_ERROR,
  CONTACT_DELETE_INIT,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_ERROR,
  CONTACTS_CLEAR_SELECTED,
  CONTACTS_LOAD_SELECTED_INIT,
  CONTACTS_LOAD_SELECTED_SUCCESS,
  CONTACTS_LOAD_SELECTED_ERROR,
  CONTACTS_CHANGE_MODE_SUCCESS
} from "./types";

export const createContactA = () => {
  return {
    type: CONTACT_CREATE_INIT
  };
};

export const createSuccess = data => {
  return {
    type: CONTACT_CREATE_SUCCESS,
    payload: { data }
  };
};

export const createFailed = error => {
  return {
    type: CONTACT_CREATE_ERROR,
    payload: error
  };
};

export const updateInit = () => {
  return {
    type: CONTACT_UPDATE_INIT,
  };
};

export const updateSuccess = data => {
  return {
    type: CONTACT_UPDATE_SUCCESS,
    payload: { data }
  };
};

export const updateFailed = error => {
  return {
    type: CONTACT_UPDATE_ERROR,
    payload: error
  };
};

export const searchContactsA = () => {
  return {
    type: CONTACTS_SEARCH_INIT
  };
};

export const searchSuccess = data => {
  return {
    type: CONTACTS_SEARCH_SUCCESS,
    payload: { data }
  };
};

export const searchFailed = error => {
  return {
    type: CONTACTS_SEARCH_ERROR,
    payload: error
  };
};

export const deleteContactA = data => {
  return {
    type: CONTACT_DELETE_INIT,
    payload: { data }
  };
};

export const deleteSuccess = data => {
  return {
    type: CONTACT_DELETE_SUCCESS,
    payload: { data }
  };
};

export const deleteFailed = error => {
  return {
    type: CONTACT_DELETE_ERROR,
    payload: error
  };
};

export const clearSelectedA = () => {
  return {
    type: CONTACTS_CLEAR_SELECTED,
  };
};

export const changeModeSuccess = mode => {
  return {
    type: CONTACTS_CHANGE_MODE_SUCCESS,
    payload: mode
  };
};

export const loadSelectedA = data => {
  return {
    type: CONTACTS_LOAD_SELECTED_INIT,
    payload: { data }
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: CONTACTS_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: CONTACTS_LOAD_SELECTED_ERROR,
    payload: error
  };
};
