import styled from 'styled-components';
import { pxToRem } from "../../utils";

export const CardContainer = styled.div`
  display: flex;
  padding: ${pxToRem(16)};
  padding-top: ${props => props.title ? pxToRem(56) : pxToRem(16)};
  background-color: ${props => props.theme.colors.white};
  margin: ${pxToRem(16)} auto;
  position: relative;
  box-sizing: border-box;
`;

export const ModalContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  .card-body-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .text-info {
      font-size: 14px;
      color: ${props => props.theme.colors.mediumGrey};
    }

    button {
      cursor: pointer;
    }
  }

  .card-body-row-only {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: auto;

    button {
      cursor: pointer;
    }
  }
`;

export default ModalContainerBody;
