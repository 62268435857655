/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useLogin,
  useTraslado,
  useDomains,
  useNotification
} from '../hooks';
/* Components */
import ErrorBoundary from '../components/ErrorBoundary';
import Page from '../components/Page';
import {PageHeader} from '../components/PageHeader';
import PaginationTableServerSide from '../components/Table/PaginationTableServerSide';
import ButtonsActionRow from '../components/Table/ButtonsActionRow';
import {CardTitleBox} from '../components/CardTitleBox';
import {Col, Col1, Col2, Col3, GridContainer, Row} from "../components/Grid";
import {Card} from '../components/Card';
import {H1} from '../components/H1';
import {H5} from '../components/H5';
import {Input} from '../components/form-ui-components/Input';
import {Form} from '../components/form-ui-components/Form';
import {Label} from '../components/form-ui-components/Label';
import {Select} from '../components/form-ui-components/Select';
import {SelectOptions} from '../components/form-ui-components/SelectOptions';
import {Button} from '../components/form-ui-components/Button';
/* Style Components */
import {CardContainerBody} from './styled';
/* Utils */
import {removeEmpty} from '../utils';
import {wait} from '../config/const';

dayjs.locale('es');

const Traslados = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const [loadedInfo, setLoadedInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opcionListado, setOpcionListado] = useState(null);
  const [entidadDestino, setEntidadDestino] = useState(false);
  const fetchIdRef = useRef(0);
  const {register, handleSubmit, errors, reset, watch, setValue, getValues} = useForm({});
  const {isLoggedIn, token, userLogin, isRolCoordinadorExpedientes, isRolCoordinadorEntidades} = useLogin();
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {createNotification} = useNotification();
  const {
    entidadesProvisional,
    entidadesFinal,
    solicitante,
    isLoading,
    uploadedInfoEntidad,
    error,
    searchTraslados,
    data: traslados,
    pageCount,
    getUsuarioEntidad,
    searchEntidadDisposicion,
    trasladoCancelarRequest
  } = useTraslado();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const splitDate = timestamp => timestamp.split('T')[0];

  const handleChangeTypeTraslado = e => {
    const {type} = e.target;
    console.log(type, e.target, e.currentTarget, e.target.innerText, e.target.innerHtml);
    if (e.target.innerText === 'Asignados') {
      console.log('entro asignados');
    }

    if (e.target.innerText === 'Solicitados') {
      console.log('entro solicitados');
    }
    setOpcionListado(e.target.innerText);
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleChangeType = e => {
    const {value} = e.target;
    if (Number(value) === 15000001) {
      setEntidadDestino(entidadesProvisional);
    } else {
      setEntidadDestino(entidadesFinal);
    }
  };

  const fetchData = useCallback(async ({pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch}) => {
    setLoading(true);
    console.log('fetchData 1', fetchIdRef.current, pageSize, pageIndex, loaderFecth, loarderSearch, loading, isLoading);
    const fetchId = ++fetchIdRef.current;
    if ((fetchId === fetchIdRef.current) && !loading) {
      console.log('fetchData 2');
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;

      const values = getValues();
      const search = {
        entidad_disposicion_destino: values.entidad_disposicion_destino ? values.entidad_disposicion_destino : null,
        tipo: values.tipo ? Number(values.tipo) : null,
        estado: values.estado ? Number(values.estado) : null,
        fecha_solicitud: values.fecha_solicitud ? parseDate(values.fecha_solicitud) : null,
        fecha_envio: values.fecha_envio ? parseDate(values.fecha_envio) : null,
        fecha_recepcion: values.fecha_recepcion ? parseDate(values.fecha_recepcion) : null,
        codigo_traslado: values.codigo_traslado || null,
      };

      await searchTraslados(removeEmpty(search), startRow, pageSize, pageSize, token);
      setLoading(false);
      console.log('fetchData 3', loaderFecth, loarderSearch);
    }
  }, []);

  const handleClearSearchFields = async e => {
    e.preventDefault();
    reset();
    // await searchTraslados({}, 0, 10, token);
    await fetchData({pageSize: 10, pageIndex: 0, loading, isLoading});
    setValue('solicitante_autoridad', solicitante.nombre);
    if (solicitante.tipo === 'entidad') setValue('solicitante_entidad', solicitante.entidad);
  };

  const loadSolicitante = useCallback(async () => {
    if (solicitante) {
      setValue('solicitante_autoridad', solicitante.nombre);
      if (solicitante.tipo === 'entidad') setValue('solicitante_entidad', solicitante.entidad);
      const searchProvisional = {
        autoridad_ambiental: solicitante._id,
        tipo_disposicion: 15000001,
        estado: true,
      };
      const searchFinal = {
        autoridad_ambiental: solicitante._id,
        tipo_disposicion: 15000002,
        estado: true,
      };
      await Promise.all([searchEntidadDisposicion(searchProvisional, 'provisional', token), searchEntidadDisposicion(searchFinal, 'final', token)]);
      setLoadedInfo(true);
    }
  }, [setValue, solicitante, searchEntidadDisposicion]);

  const load = useCallback(async () => {
    // searchTraslados({}, 0, 100, token),
    setLoadedInfo(false);
    await Promise.all([getUsuarioEntidad(userLogin, token), getOptionsByDomainTypes(['tiposEstadoTraslado'])]);
  }, [
    userLogin,
    getUsuarioEntidad,
    getOptionsByDomainTypes
  ]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await fetchData({pageSize: 10, pageIndex: 0, loading, isLoading});
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const AlertThenDelete = async original => {
    confirmAlert({
      title: 'CANCELAR TRASLADO',
      message: '¿Está seguro que desea cancelar el traslado?',
      buttons: [
        {
          label: 'CONFIRMAR',
          onClick: async () => {
            const {msg, err} = await trasladoCancelarRequest(original._id, token);
            createNotification({message: msg, visible: true, error: err});
            load();
          }
        },
        {
          label: 'CANCELAR',
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Cod. Traslado',
        accessor: d => {
          return ((d.codigo) && (d.codigo !== '') && (d.codigo !== ' ')) ? d.codigo : '---';
        }
      },
      {
        Header: 'Tipo de Traslado',
        accessor: d => {
          return ((d.tipo.domainName) && (d.tipo.domainName !== '') && (d.tipo.domainName !== ' ')) ? d.tipo.domainName : '---';
        }
      },
      {
        Header: 'Entidad solicitante o actual',
        accessor: d => {
          return ((d.solicitante_autoridad.nombre !== '') && (d.solicitante_autoridad.nombre !== ' ') && (d.solicitante_autoridad.nombre !== undefined)) ? d.solicitante_autoridad.nombre : '---';
        }
      },
      {
        Header: 'Entidad de destino',
        accessor: d => {
          return ((d.entidad_disposicion_destino.nombre !== '') && (d.entidad_disposicion_destino.nombre !== ' ') && (d.entidad_disposicion_destino.nombre !== undefined)) ? d.entidad_disposicion_destino.nombre : '---';
        }
      },
      {
        Header: 'Fecha de solicitud',
        accessor: d => {
          return ((d.fecha_solicitud) && (d.fecha_solicitud !== '') && (d.fecha_solicitud !== ' ')) ? splitDate(d.fecha_solicitud) : '---';
        }
      },
      {
        Header: 'Fecha de envío',
        accessor: d => {
          return ((d.fecha_envio) && (d.fecha_envio !== '') && (d.fecha_envio !== ' ')) ? splitDate(d.fecha_envio) : '---';
        }
      },
      {
        Header: 'Fecha de recepcion',
        accessor: d => {
          return ((d.fecha_recepcion || d.fecha_recepcion) && (d.fecha_recepcion !== '' || d.fecha_recepcion !== '') && (d.fecha_recepcion !== ' ' || d.fecha_recepcion !== ' ')) ? ((d.fecha_recepcion && splitDate(d.fecha_recepcion)) || (d.fecha_recepcion && splitDate(d.fecha_recepcion))) : '---';
        }
      },
      {
        Header: 'Estado',
        accessor: d => {
          return ((d.estado.domainName) && (d.estado.domainName !== '') && (d.estado.domainName !== ' ')) ? d.estado.domainName : '---';
        }
      },
      {
        Header: "Acciones",
        Cell: ({row}) => {
          const {original} = row;
          const buttons = [
            {
              id: original._id,
              iconName: 'remove_red_eye',
              title: 'Ver traslado',
              visible: true,
              handleAction: () => {
                history.push(`/traslados-form/view/${original._id}`);
              }
            },
            {
              id: original._id,
              iconName: 'cancel',
              title: 'Cancelar traslado',
              visible: (Number(original.estado._id) === 13000001 || Number(original.estado._id) === 13000004) && isRolCoordinadorExpedientes,
              handleAction: async () => AlertThenDelete(original)
            },
            {
              id: original._id,
              iconName: 'chat_bubble',
              title: 'Responder traslado',
              visible: (Number(original.estado._id) === 13000001) && isRolCoordinadorEntidades,
              handleAction: () => {
                history.push(`/traslados-form/responder/${original._id}`);
              }
            },
            {
              id: original._id,
              iconName: 'error',
              title: 'Ingresar fecha de recepcion',
              visible: (Number(original.estado._id) === 13000002) && isRolCoordinadorEntidades,
              handleAction: () => {
                history.push(`/traslados-form/recepcion/${original._id}`);
              }
            },
          ];
          return <ButtonsActionRow buttons={buttons}/>;
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  useEffect(() => {
    if (solicitante) {
      loadSolicitante();
    }
  }, [solicitante]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Ver traslados.</H1>
            </PageHeader>
            <Card title=" ">
              <CardTitleBox>
                <H5>Datos de búsqueda</H5>
              </CardTitleBox>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                  <Row>
                    <Col3>
                      <Label>
                        TIPO DE TRASLADO <span className="obligatory">*</span><br/>
                        <>
                          <input
                            name="tipo"
                            value={15000001}
                            onChange={(e) => handleChangeType(e)}
                            type="radio"
                            ref={register}
                          /> Provisional
                          <input
                            name="tipo"
                            value={15000002}
                            onChange={(e) => handleChangeType(e)}
                            type="radio"
                            ref={register}
                          /> Final
                        </>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        AUTORIDAD AMBIENTAL
                        <>
                          <Input
                            name="solicitante_autoridad"
                            type="text"
                            ref={register}
                            readOnly
                          />
                        </>
                      </Label>
                    </Col3>
                    {(solicitante && solicitante.tipo === 'autoridad') ? (
                      <Col3>
                        <Label>
                          ENTIDAD DE DESTINO
                          <>
                            <Select name="entidad_disposicion_destino" ref={register}>
                              {Number(watch('tipo')) === 15000001 ? (
                                <SelectOptions selectOptions={entidadesProvisional}/>
                              ) : (
                                <SelectOptions selectOptions={entidadesFinal}/>
                              )}
                            </Select>
                          </>
                        </Label>
                      </Col3>
                    ) : (
                      <Col3>
                        <Label>
                          NOMBRE ENTIDAD DE DISPOSICIÓN
                          <>
                            <Input
                              name="solicitante_entidad"
                              type="text"
                              ref={register}
                              readOnly
                            />
                          </>
                        </Label>
                      </Col3>
                    )}
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        FECHA DE SOLICITUD
                        <>
                          <Input
                            style={{width: '100%'}}
                            name="fecha_solicitud"
                            type="date"
                            placeholder="dd/mm/yyyy"
                            ref={register}
                          />
                        </>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        FECHA DE ENVÍO
                        <>
                          <Input
                            style={{width: '100%'}}
                            name="fecha_envio"
                            type="date"
                            placeholder="dd/mm/yyyy"
                            ref={register}
                          />
                        </>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        FECHA DE RECEPCIÓN
                        <>
                          <Input
                            style={{width: '100%'}}
                            name="fecha_recepcion"
                            type="date"
                            placeholder="dd/mm/yyyy"
                            ref={register}
                          />
                        </>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        ESTADO DEL TRASLADO
                        <Select style={{width: '100%'}} name="estado" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEstadoTraslado}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CÓDIGO DEL TRASLADO
                        <Input
                          style={{width: '100%'}}
                          name="codigo_traslado"
                          type="text"
                          placeholder="Ingrese código del traslado"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3/>
                  </Row>
                  <Row style={{justifyContent: 'flex-end'}}>
                    <Col style={{justifyContent: 'flex-end'}} right>
                      <Label style={{width: 'auto'}}>
                        <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                          LIMPIAR CAMPOS
                        </Button>
                      </Label>
                      <Label style={{width: 'auto'}}>
                        <Button type="submit">
                          BUSCAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </GridContainer>
              </Form>
            </Card>
            <Card container>
              {/* {isLoading && (
                <div style={{justifyContent: 'flex-end', width: '100%'}}>
                  Cargando información por favor espere un momento...
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              )} */}
              <GridContainer>
                {(solicitante && loadedInfo) && (
                  <>
                    {/* <Row>
                      <Col1>
                        <CardContainerBody>
                          <div className="card-body-row-only-left">
                            <Label style={{ cursor: 'pointer' }} type="asignados" onClick={handleChangeTypeTraslado} border={(opcionListado === 'Asignados')} weight={(opcionListado === 'Asignados')}>ASIGNADOS</Label>
                            <Label style={{ cursor: 'pointer' }} type="solicitados" onClick={handleChangeTypeTraslado} border={(opcionListado === 'Solicitados')} weight={(opcionListado === 'Solicitados')}>SOLICITADOS</Label>
                          </div>
                        </CardContainerBody>
                      </Col1>
                    </Row> */}
                    <Row>
                      <Col1>
                        <CardContainerBody>
                          <div className="card-body-row">
                            <PaginationTableServerSide columns={columns} data={traslados} fetchData={fetchData}
                                                       loading={loading} isLoading={isLoading} pageCount={pageCount}/>
                          </div>
                        </CardContainerBody>
                        {error && (
                          <CardContainerBody>
                            <CardTitleBox className="error" error>
                              <H5> Ups, hubo un error {error}</H5>
                            </CardTitleBox>
                          </CardContainerBody>
                        )}
                      </Col1>
                    </Row>
                  </>
                )}
              </GridContainer>
            </Card>
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
};

Traslados.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

Traslados.defaultProps = {
  history: {},
};

export default withRouter(Traslados);
