/* eslint-disable no-underscore-dangle */
import {
  optionsByType,
  optionsByTypeSuccess,
  optionsByTypeFailed,
  listDomains,
  listSuccess,
  listFailed
} from "./actions";
import * as DomainsServices from "../../services";

export const getAllDomains = token => {
  return async dispatch => {
    dispatch(listDomains());
    try {
      const data = await DomainsServices.apiDomains.getAllDomains(token);
      dispatch(listSuccess(data.data));
    } catch (error) {
      dispatch(listFailed(error));
    }
  };
};

export const getOptionsByDomainTypes = domainTypes => {
  return async dispatch => {
    dispatch(optionsByType);
    try {
      const data = await DomainsServices.apiDomains.getOptionsByDomainTypes(
        domainTypes
      );
      if (typeof data.status !== "undefined") {
        dispatch(optionsByTypeSuccess(domainTypes, data.data ));
      } else if (typeof data.error !== "undefined") {
        dispatch(optionsByTypeFailed(data.error));
      }
    } catch (error) {
      dispatch(optionsByTypeFailed(error));
    }
  };
};

export default getAllDomains;
