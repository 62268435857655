import styled from "styled-components";
import { pxToRem } from "../../utils";

export const Button = styled.button`
  display: inline-block;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.disabled ? props.theme.colors.fuchsiaLight : props.theme.primaryColor};
  border: solid ${pxToRem(1)} transparent;
  font-size: ${pxToRem(14)};
  padding: ${pxToRem(6)} ${pxToRem(24)};
  margin: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(8)};
  text-decoration: none;
  text-transform: lowercase;
  transition: all .2s ease;
  cursor: pointer;
  &::first-letter {
  	text-transform: uppercase;
  }
  &:hover {
    border: solid ${pxToRem(1)} ${props => props.theme.white};
    color: ${props => props.theme.white};
    background-color: ${props => props.disabled ? props.theme.colors.fuchsiaLight : props.theme.primaryColor};
  }
`;


export const ButtonSecondary = styled(Button)`
  color: ${props => props.theme.primaryColor};
  background-color: ${props => props.theme.colors.white};
  border: solid ${pxToRem(1)} ${props => props.theme.primaryColor};
  &:hover {
    border: solid ${pxToRem(1)} ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryColor};
    background-color: transparent;
  }
`;


