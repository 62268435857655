import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import contactsReducer from '../../store/contacts';

const { dispatchers } = contactsReducer;

const useContact = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { contacts } = state;

  // List of Dispatchers
	const {
    createContact,
    getAllContacts,
    getAllContactsSearch,
    getContactById,
    searchContact,
    deleteContact,
    updateContact,
    clearSelected,
    loadContactSelected,
    changeMode
  } = dispatchers;

  // Bind Actions
	const contactsActions = bindActions({
    createContact,
    getAllContacts,
    getAllContactsSearch,
    getContactById,
    searchContact,
    deleteContact,
    updateContact,
    clearSelected,
    loadContactSelected,
    changeMode
  }, dispatch);

  return { ...contacts, ...contactsActions };
};

export default useContact;
