import React, { useEffect } from "react";

import styled from "styled-components";
import { pxToRem } from "../utils";

import { Icon } from "./Icon";

import { useNotification } from "../hooks";

export const StyledNotification = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: ${pxToRem(76)};
  padding: ${pxToRem(16)};
  z-index: 9998;
  background-color: ${props => props.theme.complementaryColor};
  box-shadow: ${props => props.theme.cardShadow};
  margin: ${pxToRem(16)};
  width: 96%;
  cursor: pointer;
  @media (min-width: 600px) {
    max-width: 60vw;
  }
`;

export const StyledText = styled.span`
  display: inline-grid;
  width: 60%;
  font-size: ${pxToRem(13)};
  margin: ${pxToRem(2)} auto;
  text-align: center;
  color: ${props => props.theme.complementaryColorDark};
;
`;
export const Notification = ({ message, visible }: Props) => {
  const { deleteNotification } = useNotification();

  useEffect(() => {
    setTimeout(() => deleteNotification(), 3000);
  }, []);

  const onClickF = () => {
    deleteNotification();
  };
  return (
    visible && (
      <StyledNotification onClick={onClickF}>
        <StyledText> {message.toString()} </StyledText>
        <Icon>close</Icon>
      </StyledNotification>
    )
  );
};
