import React, {useEffect} from "react";
import styled from "styled-components";
import Page from "../components/Page";
import {pxToRem} from "../utils";
import IguanaJPEG from "../assets/images/ph-iguana.jpg";
import ContactsIcon from "../assets/images/contacts.png";
import StatisticsIcon from "../assets/images/statistics.png";
import StudiesIcon from "../assets/images/studies.png";
import SignIn from "./SignIn";
import {Col3, GridContainer, Row} from "../components/Grid";
import {P} from "../components/P";
import {Link} from "react-router-dom";

const SlideCont = styled.div`
    height: 80vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: url(${props => props.bgImg}) top;
    background-size: cover;
`;

const BlurbA = styled.a`
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  opacity: .7;
  transition: opacity .3s ease-in;
  p {
    color: white;
    font-size: ${pxToRem(18)};
    font-weight: bold;
    margin-top: ${pxToRem(20)};
    transition: margin-top .3s;
  }
  &:hover {
    opacity: 1;
  }
`;

const BlurbLink = styled(Link)`
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  opacity: .7;
  transition: opacity .3s ease-in;
  p {
    color: white;
    font-size: ${pxToRem(18)};
    font-weight: bold;
    margin-top: ${pxToRem(20)};
    transition: margin-top .3s;
  }
  &:hover {
    opacity: 1;
  }
`;

const StyledIcon = styled.div`
  text-align: center;
  border: 4px solid white;
  border-radius: 50%;
  height: ${pxToRem(150)};
  width: ${pxToRem(150)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: ${pxToRem(70)};
  }
`;

const HomePage = () => {
  return (
    <Page fullWidth>
      <SlideCont bgImg={IguanaJPEG}>
        <GridContainer center>
          <Row center>
            <Col3 center>
              <BlurbLink to={"/consulta-por-estadisticas-search"}>
                <StyledIcon>
                  <img src={StatisticsIcon}/>
                </StyledIcon>
                <P>Cifras y estadísticas</P>
              </BlurbLink>
            </Col3>
            <Col3 center>
              <BlurbA
                href="https://www.mendeley.com/community/pifs-portal-de-informacion-de-trafico-ilegal-de-fauna-silvestre/"
                target="_blank">
                <StyledIcon>
                  <img src={StudiesIcon}/>
                </StyledIcon>
                <P>Estudios sobre fauna silvestre</P>
              </BlurbA>
            </Col3>
            <Col3 center>
              <BlurbA href="https://statics.sibcolombia.net/pifs/pifs_entidades.pdf" target="_blank">
                <StyledIcon>
                  <img src={ContactsIcon}/>
                </StyledIcon>
                <P>Directorio de entidades</P>
              </BlurbA>
            </Col3>
          </Row>
        </GridContainer>
      </SlideCont>
    </Page>
  );
};

export default HomePage;
