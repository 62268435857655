/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useLogin,
  useDomains,
  useConstants,
  useExpedientes,
  useAutoridadAmbiental,
  useEntidadDisposicion,
  useReportes
} from '../hooks';
/* Components */
import Geovisor from "../components/Geovisor/Geovisor";
import ErrorBoundary from '../components/ErrorBoundary';
import Page from '../components/Page';
import {PageHeader} from '../components/PageHeader';
import PaginationTable from '../components/Table/PaginationTable';
import ButtonsActionRow from '../components/Table/ButtonsActionRow';
import {CardTitleBox} from '../components/CardTitleBox';
import {Col, Col1, Col2, Col3, GridContainer, Row} from "../components/Grid";
import {Card} from '../components/Card';
import {H1} from '../components/H1';
import {H5} from '../components/H5';
import {Input} from '../components/form-ui-components/Input';
import {Form} from '../components/form-ui-components/Form';
import {Label} from '../components/form-ui-components/Label';
import {Select} from '../components/form-ui-components/Select';
import {SelectOptions} from '../components/form-ui-components/SelectOptions';
import {Button} from '../components/form-ui-components/Button';
import Autocomplete from '../components/Autocomplete';
/* Style Components */
import {CardContainerBody, SpanDivide, SpanContact} from './styled';
/* Utils */
import {removeEmpty} from '../utils';
import {Icon} from "../components/Icon";
import Swal from "sweetalert2";

dayjs.locale('es');

const ConsultaPorGeovisor = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verMas, setVerMas] = useState(false);
  // Auto Complete
  const [suggestions, setSuggestions] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [municipiosFiltradosProcEspe, setMunicipiosFiltradosProcEspe] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  // Fin Auto Complete

  const {setValue, register, handleSubmit, errors, reset, watch, getValues} = useForm({});

  const {isLoggedIn, userLogin, token} = useLogin();
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {taxonomyInformation, especimenTaxonomyInfoRequest} = useExpedientes();
  const {data: autoridadesAmbientales, searchAutoridadAmbiental} = useAutoridadAmbiental();
  const {data: entidadesDisposicion, searchEntidadDisposicion} = useEntidadDisposicion();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const {
    searchTablaRequest,
    tabla
  } = useReportes();
  const {
    isLoading,
    error
  } = useReportes();

  // Auto Complete
  const loadTaxonomyInfo = useCallback(() => {
    setSuggestions(taxonomyInformation);
  }, [taxonomyInformation]);

  const handleAutoCompleteChange = async e => {
    if (document.querySelector("select[name='taxonRank']").value !== '') {
      const newUserInput = e.currentTarget.value;
      const search = {
        taxonRank: document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const {taxonomyInfo, msg, err} = await especimenTaxonomyInfoRequest(search, token);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInformation);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalName').value = newUserInput;
    setValue('taxonomia', taxonomiaId);
    setValue('PIFS_grupoBio', info[1]);
  };

  // Fin Auto Complete
  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const handleOpenVerMas = e => {
    e.preventDefault();
    setVerMas(!verMas);
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const load = useCallback(async ({pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch}) => {
    setLoading(true);
    const values = getValues();
    const search = {
      autoridad_ambiental: values.autoridad_ambiental || null,
      acta: values.acta || null,
      codigo_expediente: values.codigo_expediente || null,
      tipo_procedimiento: Number(values.tipo_procedimiento) || null,
      clase_recurso: Number(values.clase_recurso) || null,
      estado: Number(values.estado) || null,
      parte_producto: Number(values.parte_producto) || null,
      taxonomia: Number(values.taxonomia) || null,
      sexo: Number(values.sexo) || null,
      estado_desarrollo: Number(values.estado_desarrollo) || null,
      departamento_procedimiento: Number(values.departamento_procedimiento) || null,
      municipio_procedimiento: Number(values.municipio_procedimiento) || null,
      entidad_disposicion_inicial: values.entidad_disposicion_inicial || null,
      fecha_procedimiento_inicial: values.fecha_procedimiento_inicial && parseDate(values.fecha_procedimiento_inicial) || null,
      fecha_procedimiento_final: values.fecha_procedimiento_final && parseDate(values.fecha_procedimiento_final) || null,
      departamento_procedencia_declarado: Number(values.departamento_procedencia_declarado) || null,
      municipio_procedencia_declarado: Number(values.municipio_procedencia_declarado) || null,
      disposicion_final: Number(values.disposicion_final) || null
    };
    console.log("search",search)
    await Promise.all([searchTablaRequest(removeEmpty(search), 0, 10000, token), searchAutoridadAmbiental({}, token), searchEntidadDisposicion({}, token), getOptionsByDomainTypes(['tiposEvento', 'tiposRecurso', 'tiposEstadoEspecimen', 'tiposParte', 'tiposSexo', 'estadosDesarrollo', 'tiposDisposicion']), getConstantOptionsByTypes(['departamentos', 'municipios', 'tiposDocumento', 'clasificacionTaxonomia'])]);
    setLoading(false);
  }, [
    searchAutoridadAmbiental,
    searchEntidadDisposicion,
    getOptionsByDomainTypes
  ]);

  const handleClearSearchFields = async e => {
    e.preventDefault();
    setValue('taxonomia', null);
    setValue('PIFS_grupoBio', null);
    reset();
    await load({pageSize: 10000, pageIndex: 0, loading, isLoading});
  };

  const handleChangeDptoProceEspe = e => {
    const {value} = e.target;
    const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(value));
    setMunicipiosFiltradosProcEspe(newData);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await load({pageSize: 100, pageIndex: 0, loading, isLoading});
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    loadTaxonomyInfo();
  }, [taxonomyInformation]);

  useEffect(() => {
    if (isLoggedIn && !loaded) {
      load({pageSize: 10, pageIndex: 0, loading, isLoading});
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Vista de resultados en geovisor.</H1>
            </PageHeader>
            <Card title="Geovisor">
              <CardTitleBox>
                <H5>Datos de búsqueda</H5>
              </CardTitleBox>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                  <Row>
                    <Col1>
                      <SpanDivide>
                        <br/>Información del expediente
                      </SpanDivide>
                    </Col1>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        AUTORIDAD AMBIENTAL
                        <Select style={{width: '100%'}} name="autoridad_ambiental" ref={register}>
                          <SelectOptions selectOptions={autoridadesAmbientales}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NÚMERO DE ACTA ÚNICA
                        <Input
                          name="acta"
                          type="text"
                          placeholder="Escriba el número de acta única"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CÓDIGO DEL EXPEDIENTE
                        <Input
                          name="codigo_expediente"
                          type="text"
                          placeholder="Escriba el código del expediente"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        TIPO DE EVENTO
                        <Select name="tipo_procedimiento" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEvento}/>
                        </Select>
                      </Label>
                    </Col3>
                    {!verMas && (
                      <Col style={{justifyContent: 'flex-end', width: '100%'}} right>
                        <Label style={{width: 'auto'}}>
                          <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                            LIMPIAR CAMPOS
                          </Button>
                        </Label>
                        <Label style={{width: 'auto'}}>
                          <Button type="submit">
                            BUSCAR
                          </Button>
                        </Label>
                      </Col>
                    )}
                  </Row>
                  {!verMas && (
                    <Row>
                      <Col1>
                        <Label>
                          <SpanContact onClick={(e) => handleOpenVerMas(e)}>VER
                            MAS <Icon>arrow_downward</Icon></SpanContact>
                        </Label>
                      </Col1>
                    </Row>
                  )}
                  {verMas && (
                    <>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br/>Información del espécimen
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            CLASE DE RECURSO
                            <Select style={{width: '100%'}} name="clase_recurso" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposRecurso}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO
                            <Select style={{width: '100%'}} name="estado" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimen}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            PARTE, PRODUCTO O SUBPRODUCTO
                            <Select style={{width: '100%'}} name="parte_producto" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposParte}/>
                            </Select>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            CATEGORÍA TAXONÓMICA
                            <Select style={{width: '100%'}} name="taxonRank" ref={register}>
                              <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            NOMBRE CIENTÍFICO
                            <Autocomplete
                              name="canonicalName"
                              placeholder="Indique nombre científico"
                              suggestions={suggestions}
                              loading={loadingAutoComplete}
                              activeSuggestion={activeSuggestion}
                              showSuggestions={showSuggestions}
                              onChange={handleAutoCompleteChange}
                              onKeyDown={handleAutoCompleteKeyDown}
                              onClick={handleAutoCompleteClick}
                              userInput={userInput}
                              ref={register}
                            />
                            <Input
                              style={{width: '100%'}}
                              name="taxonomia"
                              type="hidden"
                              ref={register}
                            />
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            GRUPO BIOLÓGICO
                            <>
                              <Input
                                style={{width: '100%'}}
                                name="PIFS_grupoBio"
                                type="text"
                                placeholder="Grupo biológico"
                                ref={register}
                                readOnly={true}
                              />
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            SEXO
                            <Select style={{width: '100%'}} name="sexo" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposSexo}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO DE DESARROLLO
                            <Select style={{width: '100%'}} name="estado_desarrollo" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.estadosDesarrollo}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label/>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br/>Información de ubicación y fecha
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            DEPARTAMENTO DE PROCEDIMIENTO
                            <Select style={{width: '100%'}} onChange={handleChangeDptoProceEspe}
                                    name="departamento_procedimiento" ref={register}>
                              <SelectOptions selectOptions={constantsByKey.departamentos}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CIUDAD O MUNICIPIO DE PROCEDIMIENTO
                            <Select style={{width: '100%'}} name="municipio_procedimiento" ref={register}>
                              <SelectOptions selectOptions={municipiosFiltradosProcEspe}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            FECHA DEL PROCEDIMIENTO INICIAL
                            <Input
                              style={{width: '100%'}}
                              name="fecha_procedimiento_inicial"
                              type="date"
                              placeholder="dd/mm/yyyy"
                              ref={register}/>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            FECHA DEL PROCEDIMIENTO FINAL
                            <Input
                              style={{width: '100%'}}
                              name="fecha_procedimiento_final"
                              type="date"
                              placeholder="dd/mm/yyyy"
                              ref={register}
                            />
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            TIPO DE DISPOSICIÓN
                            <Select style={{width: '100%'}} name="disposicion_final" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposDisposicion}/>
                            </Select>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            <SpanContact onClick={(e) => handleOpenVerMas(e)}>VER
                              MENOS <Icon>arrow_upward</Icon></SpanContact>
                          </Label>
                        </Col3>
                        <Col style={{justifyContent: 'flex-end', width: '100%'}} right>
                          <Label style={{width: 'auto'}}>
                            <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                              LIMPIAR CAMPOS
                            </Button>
                          </Label>
                          <Label style={{width: 'auto'}}>
                            <Button type="submit">
                              BUSCAR
                            </Button>
                          </Label>
                        </Col>
                      </Row>
                    </>
                  )}
                </GridContainer>
              </Form>
            </Card>
            <Card container>
              {isLoading ? (
                <div style={{justifyContent: 'flex-end', width: '100%'}}>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              ) : (
                <CardContainerBody>
                  <div className="card-body-row">
                    <Geovisor data={tabla}/>
                  </div>
                </CardContainerBody>
              )}
              {error && (
                <CardContainerBody>
                  <CardTitleBox className="error">
                    <H5> Ups, hubo un error {error}</H5>
                  </CardTitleBox>
                </CardContainerBody>
              )}
            </Card>
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
};

ConsultaPorGeovisor.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConsultaPorGeovisor.defaultProps = {
  history: {},
};

export default withRouter(ConsultaPorGeovisor);
