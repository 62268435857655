/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from "react";
// import PropTypes from 'prop-types';
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import {H1} from "../components/H1";
// import {H3} from "../components/H3";
// import { H5 } from "../components/H5";
import {P} from "../components/P";
import {Card} from "../components/Card";
// import ErrorBoundary from '../components/ErrorBoundary';
import {Col12, GridContainer, Row} from "../components/Grid";

const ContactPage = () => {

    return (
        <Page>
            <PageHeader>
                <H1>Contacto</H1>
            </PageHeader>

            <Card>
                <GridContainer>
                    <Row>
                        <Col12>
                            <P>
                                Ministerio de Ambiente y Desarrollo Sostenible
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                Correo: servicioalciudadano@minambiente.gov.co
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                Calle 37 No. 8-40 - Conmutador: (57-1) 3323400 Horario de atención: Lunes a Viernes de 8:00 am a 5:00 pm
                            </P>
                        </Col12>

                    </Row>
                </GridContainer>
            </Card>
        </Page>
    );
};

export default ContactPage;
