/* eslint-disable no-underscore-dangle */
import {
  CONTACT_CREATE_INIT,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_ERROR,
  CONTACT_UPDATE_INIT,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_ERROR,
  CONTACTS_SEARCH_INIT,
  CONTACTS_SEARCH_SUCCESS,
  CONTACTS_SEARCH_ERROR,
  CONTACT_DELETE_INIT,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_ERROR,
  CONTACTS_CLEAR_SELECTED,
  CONTACTS_LOAD_SELECTED_INIT,
  CONTACTS_LOAD_SELECTED_SUCCESS,
  CONTACTS_LOAD_SELECTED_ERROR,
  CONTACTS_CHANGE_MODE_SUCCESS
} from "./types";

const formData = {
  nombre: null,
  telefono_fijo: null,
  telefono_movil: null,
  email: null
};

const initialState = {
  data: [],
  selected: null,
  mode: null,
  isLoading: false,
  error: ""
};

const contactsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CONTACT_CREATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONTACT_CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case CONTACT_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONTACT_UPDATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONTACT_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        error: ""
      };
    }

    case CONTACT_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONTACTS_SEARCH_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONTACTS_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: ""
      };
    }

    case CONTACTS_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONTACT_DELETE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONTACT_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        data: state.data.filter((a) => a._id !== payload.data._id)
      };
    }

    case CONTACT_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONTACTS_CLEAR_SELECTED: {
      return {
        ...state,
        error: "",
        selected: null,
        mode: null,
        isLoading: false
      }
    }

    case CONTACTS_LOAD_SELECTED_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONTACTS_LOAD_SELECTED_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        selected: payload.data,
        mode: payload.mode,
        error: ""
      };
    }

    case CONTACTS_LOAD_SELECTED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONTACTS_CHANGE_MODE_SUCCESS: {
      return {
        ...state,
        mode: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { contactsReducer as default, initialState };
