import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @keyframes growAnimation {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(1);
    }
  }

  .loader {
    margin: 0px auto;
    width: 64px;
    height: 64px;
    background-color: #f42f6391;
    border-radius: 50%;
    animation: growAnimation 0.9s ease-in-out infinite;
  }

  .loading-text {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #888;
  }
`;

export default Container;
