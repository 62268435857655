import {
  DOMAIN_OPTIONS_BY_TYPE,
  DOMAIN_OPTIONS_BY_TYPE_SUCCESS,
  DOMAIN_OPTIONS_BY_TYPE_ERROR,
  DOMAINS_LIST_INIT,
  DOMAINS_LIST_SUCCESS,
  DOMAINS_LIST_ERROR
} from "./types";

export const listDomains = () => {
  return {
    type: DOMAINS_LIST_INIT
  };
};

export const listSuccess = data => {
  return {
    type: DOMAINS_LIST_SUCCESS,
    payload: { data }
  };
};

export const listFailed = error => {
  return {
    type: DOMAINS_LIST_ERROR,
    payload: error
  };
};

export const optionsByType = data => {
  return {
    type: DOMAIN_OPTIONS_BY_TYPE,
    payload: data
  };
};
export const optionsByTypeSuccess = (domainTypes, data) => {
  return {
    type: DOMAIN_OPTIONS_BY_TYPE_SUCCESS,
    payload: { domainTypes, data }
  };
};
export const optionsByTypeFailed = data => {
  return {
    type: DOMAIN_OPTIONS_BY_TYPE_ERROR,
    payload: data
  };
};
