/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import useForm from 'react-hook-form';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import nanoid from 'nanoid';
/* Hooks */
import {
    useUsers,
    useLogin,
    useNotification,
    useDomains,
    useAutoridadAmbiental,
    useEntidadDisposicion
} from '../hooks';
/* Components */
import Page from "../components/Page";
import {Card} from "../components/Card";
import {PageHeader} from "../components/PageHeader";
import {Form} from "../components/form-ui-components/Form";
import PaginationTable from "../components/Table/PaginationTable";
import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {Label} from '../components/form-ui-components/Label';
import {Input} from '../components/form-ui-components/Input';
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import {Button} from '../components/form-ui-components/Button';
import ErrorBoundary from "../components/ErrorBoundary";
import { removeEmpty } from "../utils";

/* Style Components */
import {CardContainerBody} from './styled';

/* Defined Constants */
import {Col, Col3, Col4, GridContainer, Row} from "../components/Grid";

const Users = ({history}) => {
    const [loaded, setLoaded] = useState(false);
    const [rolesEntidad, setRolesEntidad] = useState([]);
    const {isLoggedIn, token} = useLogin();
    const {
      getUsersRequest,
      getRolesRequest,
      userDeleteRequest,
      data: users,
      rolesData,
      error,
      isLoading
    } = useUsers();
    const { createNotification } = useNotification();
    const { getOptionsByDomainTypes } = useDomains();
    const { data: entidadesDisposicion, searchEntidadDisposicion } = useEntidadDisposicion();
    const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();
    const { register, handleSubmit, watch, reset, errors } = useForm();

    const handleChangeType = e => {
      const {value} = e.target;
      const newRolesData = (value === 'disposicion') ? rolesData.filter(({ name }) => (name.includes('COORDINADOR DE EXPEDIENTES')) || (name.includes('COORDINADOR DE ENTIDADES DE DISPOSICIÓN'))) : rolesData;
      setRolesEntidad(newRolesData);
    };

    const gotoLogin = useCallback(() => {
        history.push('/');
    }, [history]);

    const load = useCallback(async () => {
        await Promise.all([getUsersRequest({}, token), searchEntidadDisposicion({}, token), searchAutoridadAmbiental({}, token), getRolesRequest(), getOptionsByDomainTypes(['tiposAutoridadAmbiental', 'tiposEntidadDisposicion'])]);
    }, [
      getUsersRequest,
      searchEntidadDisposicion,
      searchAutoridadAmbiental,
      getRolesRequest,
      getOptionsByDomainTypes
    ]);

    const AlertThenDelete = async original => {
      confirmAlert({
        title: 'DESACTIVAR USUARIO',
        message: `¿Esta seguro que desea desactivar el usuario: ${original.nombre}`,
        buttons: [
          {
            label: "ACEPTAR",
            onClick: async () => {
              let newData = {
                ...original,
                estado: false,
                tipo_documento: Number(original.tipo_documento._id),
                numero_documento: original.numeroDocumento,
                autoridad_ambiental: (original.autoridad_ambiental._id !== '') ? original.autoridad_ambiental._id : '',
                roles: [original.rol._id]
              };

              let infoDelete = {};

              if (original.entidad_disposicion._id !== '') {
                newData = {
                  ...newData,
                  entidad_disposicion: original.entidad_disposicion._id,
                }

                const { _id, numeroDocumento, rol, usuario, ...infoData } = newData;
                infoDelete = infoData;
              } else {
                const { _id, numeroDocumento, entidad_disposicion, rol, usuario, ...infoData } = newData;
                infoDelete = infoData;
              }

              const {msg, err} = await userDeleteRequest(infoDelete, original.usuario, token);
              createNotification({message: msg, visible: true, error: err});
              load();
            }
          },
          {
            label: "CANCELAR",
            onClick: () => {
            }
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
    };

    const handleClearSearchFields = async e => {
      e.preventDefault();
      reset();
      await getUsersRequest({}, token);
    };

    const onSubmit = async (data, e) => {
      e.preventDefault();
      try {
        await getUsersRequest(removeEmpty(data), token);
      } catch (err) {
        console.log(err);
      }
      e.stopPropagation();
    };

    useEffect(() => {
      if (isLoggedIn && !loaded) {
        load();
        setLoaded(true);
      } else if (!isLoggedIn) {
        gotoLogin();
      }
    }, [isLoggedIn, loaded, load, gotoLogin]);

    const columns = [
        {
            Header: "Nombres y Apellidos",
            accessor: d => {
                return ((d.nombres !== '' && d.apellidos !== '') && (d.nombres !== ' ' && d.apellidos !== ' ') && (d.nombres !== undefined && d.apellidos !== undefined)) ? `${d.nombres} ${d.apellidos}` : '---';
            }
        },
        {
            Header: "Documento",
            accessor: d => {
                return ((d.numeroDocumento) && (d.numeroDocumento !== ' ') && (d.numeroDocumento !== undefined)) ? d.numeroDocumento : '---';
            }
        },
        {
            Header: "Correo electrónico",
            accessor: d => {
                return ((d.correo !== '') && (d.correo !== ' ') && (d.correo !== undefined)) ? d.correo : '---';
            }
        },
        {
            Header: "Entidad",
            accessor: d => {
                return (d.entidad === 'Autoridad' && d.autoridad_ambiental.nombre) || (d.entidad === 'Entidad' && d.entidad_disposicion.nombre) || ('---');
            }
        },
        {
            Header: "Rol",
            accessor: d => {
                return ((d.rol.nombre !== '') && (d.rol.nombre !== ' ') && (d.rol.nombre !== undefined)) ? d.rol.nombre : '---';
            }
        },
        {
            Header: "Estado",
            accessor: d => {
                return d.estado ? "activo" : "inactivo";
            }
        },
        {
            Header: "Acciones1",
            Cell: ({row}) => {
              const {original} = row
              const buttons = [
                {
                  id: original._id,
                  iconName: 'remove_red_eye',
                  title: 'Ver Usuario',
                  visible: true,
                  handleAction: () => {
                    history.push(`/user-form/view/${original.usuario}`);
                  }
                },
                {
                  id: original._id,
                  iconName: 'edit',
                  title: 'Actualizar Usuario',
                  visible: true,
                  handleAction: () => {
                    history.push(`/user-form/edit/${original.usuario}`);
                  }
                },
                {
                  id: original._id,
                  iconName: 'person_add_disabled',
                  title: 'Desactivar Usuario',
                  visible: true,
                  handleAction: () => AlertThenDelete(original)
                },
              ];
              return <ButtonsActionRow buttons={buttons}/>;
            },
        }
    ];

    useEffect(() => {
        if (rolesData.length > 0 && rolesEntidad.length === 0) {
            setRolesEntidad(rolesData);
        }
    }, [rolesData, rolesEntidad]);

    return (
        <ErrorBoundary>
          <Page useSideBarSpace>
            {isLoggedIn && (
              <>
                <PageHeader>
                  <H1>Usuarios</H1>
                </PageHeader>
                <Card title=" ">
                  <CardTitleBox>
                    <H5>Datos de búsqueda</H5>
                  </CardTitleBox>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <GridContainer>
                      <Row>
                        <Col4>
                          <Label>
                            Nombres
                            <Input
                              style={{width: '100%'}}
                              name="nombres"
                              type="text"
                              placeholder="Escriba el nombre"
                              ref={register}
                            />
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            Apellidos
                            <Input
                              style={{width: '100%'}}
                              name="apellidos"
                              type="text"
                              placeholder="Escriba el apellido"
                              ref={register}
                            />
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            Correo electrónico
                            <Input
                              style={{width: '100%'}}
                              name="correo"
                              type="email"
                              placeholder="Escriba el correo"
                              ref={register({
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: 'El email es requerido o no es válido!'
                                }
                              })}
                            />
                            {errors.correo && errors.correo.type === "pattern" && (
                              <p style={{color: 'red'}}>
                                El email no es válido!
                              </p>
                            )}
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            Número de Documento de Identidad
                            <Input
                              style={{width: '100%'}}
                              name="numeroDocumento"
                              type="text"
                              placeholder="Escriba el número de documento"
                              ref={register}
                            />
                          </Label>
                        </Col4>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            Tipo de entidad<br/>
                            <>
                              <input
                                id="autoridad"
                                name="tipo"
                                value="autoridad"
                                type="radio"
                                onChange={(e) => handleChangeType(e)}
                                ref={register}
                              /> Autoridad Ambiental
                              <input
                                id="disposicion"
                                name="tipo"
                                value="disposicion"
                                type="radio"
                                onChange={(e) => handleChangeType(e)}
                                ref={register}
                              /> Entidad Disposición
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            Entidad:
                            <Select name="entidad" ref={register}>
                              {watch('tipo') === 'autoridad' ? (
                                <SelectOptions selectOptions={autoridadesAmbientales} />
                              ) : (
                                <SelectOptions selectOptions={entidadesDisposicion} />
                              )}
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            Rol:
                            <Select name="roles" ref={register}>
                              <SelectOptions selectOptions={rolesEntidad} />
                            </Select>
                          </Label>
                        </Col3>
                      </Row>
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Col right>
                          <>
                            <Label>
                              <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                                LIMPIAR CAMPOS
                              </Button>
                              <Button type="submit">
                                BUSCAR
                              </Button>
                            </Label>
                          </>
                        </Col>
                      </Row>
                    </GridContainer>
                  </Form>
                </Card>
                <Card container>
                  {isLoading ? (
                    <div style={{justifyContent: 'flex-end', width: '100%'}}>
                      <div>Cargando información, un momento por favor...</div>
                      <SkeletonTheme color="#f42f6391" highlightColor="#444">
                        <Skeleton height={34}/>
                      </SkeletonTheme>
                    </div>
                  ) : (
                    <CardContainerBody>
                      <div className="card-body-row">
                        <PaginationTable columns={columns} data={users}/>
                      </div>
                    </CardContainerBody>
                  )}
                  {error && (
                    <CardContainerBody>
                      <CardTitleBox className="error">
                        <H5> Ups, hubo un error {error}</H5>
                      </CardTitleBox>
                    </CardContainerBody>
                  )}
                </Card>
              </>
            )}
          </Page>
        </ErrorBoundary>
    );
};

Users.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object]),
};

Users.defaultProps = {
    history: {},
};

export default withRouter(Users);
