/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useExpedientes,
  useDomains,
  useLogin
} from '../../hooks';
/* Components */
import ErrorBoundary from "../ErrorBoundary";
import { CardTitleBox } from "../CardTitleBox";
import {Col, Col1, Col2, GridContainer, Row} from "../Grid";
import { Form } from '../form-ui-components/Form';
import { Label } from '../form-ui-components/Label';
import { Input } from '../form-ui-components/Input';
import { Select } from '../form-ui-components/Select';
import { SelectOptions } from '../form-ui-components/SelectOptions';
import { Button } from '../form-ui-components/Button';
import { H5 } from "../H5";
import Loader from '../Loader';

/* Style Components */
import { ModalContainerBody } from './styled';
import { SpanDivide, SpanView } from '../../pages/styled';

/* Utils */
import { removeEmpty } from "../../utils";

const modalRoot = document.getElementById('modal-root');
const customStyles = {
  overflowY: 'auto',
};

dayjs.locale('es');

const ModalFiles = ({ onClick, animation, modalFile }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ tmpFile, setTmpFile ] = useState(null);

  const { register, setValue, handleSubmit, errors, reset } = useForm();

  const { userLogin, token } = useLogin();
  const {
    isLoadingFile,
    filesMode,
    fileSelected,
    expedienteFilesAdd,
    expedienteFilesUpdate,
    expedienteFilesDownload
  } = useExpedientes();
  const { domainsByKey } = useDomains();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const splitDate = timestamp => timestamp.split('T')[0];

  const handleDownloadFile = async () => {
    if(fileSelected) {
      await expedienteFilesDownload(fileSelected, token);
    }
  };

  const handleAttachFiles = e => {
    e.preventDefault();
    document.getElementById('archivos').click();
    e.stopPropagation();
  };

  const handleCancelar = e => {
    e.preventDefault();
    reset();
    onClick(e);
  };

  const handleChangeFiles = e => {
    Object.keys(e.target.files).forEach(key => {
      setTmpFile(e.target.files[key]);
      console.log('name', e.target.files[key].name);
      setValue('nombre_archivo', e.target.files[key].name);
    });
  };

  const loadFileSelected = useCallback(() => {
    if(fileSelected) {
      setValue('nombre_archivo', fileSelected.nombre_archivo);
      setValue('tipo', fileSelected.tipo._id);
      setValue('fecha_creacion', splitDate(fileSelected.fecha_creacion));
      setValue('nombre', fileSelected.nombre);
      setValue('detalle', fileSelected.detalle);
      setValue('usuario_modificacion', fileSelected.usuario_modificacion);
      setValue('usuario_firma', fileSelected.usuario_firma);
      setValue('cargo', fileSelected.cargo);
      if(typeof fileSelected.archivo !== 'string') {
        setTmpFile(fileSelected.archivo);
      }
    } else {
      setValue('fecha_creacion', getActualDate());
      setValue('usuario_modificacion', userLogin);
    }
  }, [setValue, fileSelected]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data._id   = fileSelected ? fileSelected._id : nanoid();
      data.tipo  = {
        _id: Number(data.tipo),
        domainName: document.querySelector("select[name='tipo']")[document.querySelector("select[name='tipo']").selectedIndex].innerHTML
      };
      data.activo         = true;
      data.archivo        = tmpFile;
      data.fecha_creacion = parseDateTime(data.fecha_creacion);
      data.archivo_cierre_expediente = false;
      if(fileSelected) {
        data.nuevo = false;
        data.memory = true;
        await expedienteFilesUpdate(removeEmpty(data));
      } else {
        data.nuevo = true;
        data.memory = true;
        await expedienteFilesAdd(removeEmpty(data));
      }
      reset();
      onClick(e);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if(!loaded) {
      loadFileSelected();
      setLoaded(true);
    }
  }, [loaded, loadFileSelected]);

  useEffect(() => {
    loadFileSelected();
  }, [fileSelected]);

  return ReactDOM.createPortal(
    <Rodal visible={modalFile}
          onClose={onClick}
          animation={animation}
          customStyles={customStyles}
          className="modal-rodal-container"
          width={35}
          height={90}
          measure="%"
          closeOnEsc
    >
      <ErrorBoundary>
        <ModalContainerBody title=" ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <Row>
                <Col1>
                  <SpanDivide>
                    <H5>Agregar soportes al expediente.</H5>
                  </SpanDivide>
                </Col1>
              </Row>
              <Row>
                {filesMode !== 'view' ? (
                  <>
                    <Col2>
                      <Label>
                        SELECCIONE ARCHIVO <span className="obligatory">*</span>
                        <Input
                          style={{ width: '100%' }}
                          name="nombre_archivo"
                          type="text"
                          placeholder="Buscar archivo..."
                          ref={register({ required: true })}
                        />
                        {errors.nombre_archivo && errors.nombre_archivo.type === "required" && (
                          <p style={{ color: 'red'}}>
                            Debe adjuntar un archivo!
                          </p>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                          <Button type="button" onClick={handleAttachFiles}>
                            ADJUNTAR
                          </Button>
                        </Label>
                    </Col2>
                  </>
                ) : (
                  <Col1>
                    <Label>
                    {(filesMode === 'view' && fileSelected.nuevo) ? (
                      <>
                        <br /><SpanView>{fileSelected.nombre_archivo || '---'}</SpanView>
                      </>
                    ) : (
                      !isLoadingFile ? (
                        <Button type="button" onClick={handleDownloadFile}>
                          DESCARGAR ARCHIVO
                        </Button>
                      ) : (
                        <Loader message="Descargando archivo por favor espere un momento..."/>
                      )
                    )}
                    </Label>
                  </Col1>
                )}
              </Row>
              <Row>
                <Col1>
                  <Label>
                    TIPO DE DOCUMENTO <span className="obligatory">*</span>
                    {filesMode !== 'view' ? (
                      <>
                        <Select style={{ width: '100%' }} name="tipo" ref={register({ required: true })}>
                          <SelectOptions selectOptions={domainsByKey.tiposAdjunto} />
                        </Select>
                        {errors.tipo && errors.tipo.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El tipo de documento es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.tipo.domainName || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    NOMBRE DEL DOCUMENTO <span className="obligatory">*</span>
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="nombre"
                          type="text"
                          placeholder="Escriba el nombre del documento"
                          ref={register({ required: true })}
                        />
                        {errors.nombre && errors.nombre.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El nombre del documento es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.nombre || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    DETALLES DEL DOCUMENTO
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="detalle"
                          type="text"
                          placeholder="Agregue detalles del documento"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.detalle || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FECHA DE CARGUE <span className="obligatory">*</span>
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="fecha_creacion"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.fecha_creacion && errors.fecha_creacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            La fecha de cargue es requerida!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{splitDate(fileSelected.fecha_creacion || '---')}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    USUARIO <span className="obligatory">*</span>
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="usuario_modificacion"
                          type="text"
                          placeholder="Coordinador del expediente"
                          ref={register({ required: true })}
                          readOnly
                        />
                        {errors.usuario_modificacion && errors.usuario_modificacion.type === "required" && (
                          <p style={{ color: 'red'}}>
                            El usuario es requerido!
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.usuario_modificacion || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    FUNCIONARIO QUE FIRMA EL DOCUMENTO
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="usuario_firma"
                          type="text"
                          placeholder="Escriba el nombre del funcionario"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.usuario_firma || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              <Row>
                <Col1>
                  <Label>
                    CARGO DEL FUNCIONARIO
                    {filesMode !== 'view' ? (
                      <>
                        <Input
                          style={{ width: '100%' }}
                          name="cargo"
                          type="text"
                          placeholder="Escriba el cargo del funcionario"
                          ref={register}
                        />
                      </>
                    ) : (
                      <>
                        <br /><SpanView>{fileSelected.cargo || '---'}</SpanView>
                      </>
                    )}
                  </Label>
                </Col1>
              </Row>
              {filesMode !== 'view' && (
                <>
                  <Row>
                    <Col1>
                      <Input
                        style={{ display: 'none' }}
                        name="archivos"
                        id="archivos"
                        type="file"
                        onChange={e => handleChangeFiles(e) }
                        accept=".doc,.docx,.odt,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={handleCancelar}>
                          CANCELAR
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="submit">
                          GUARDAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </>
              )}
            </GridContainer>
          </Form>
        </ModalContainerBody>
      </ErrorBoundary>
    </Rodal>,
    modalRoot
  )
}

ModalFiles.propTypes = {
  modalFile: PropTypes.bool,
  animation: PropTypes.string,
  onClick: PropTypes.func,
};

ModalFiles.defaultProps = {
  modalFile: false,
  animation: '',
  onClick: () => {},
};

export default ModalFiles;
