/* eslint-disable no-underscore-dangle */
import {
  USER_ME_INIT,
  USER_ME_SUCCESS,
  USER_ME_ERROR,
  USER_ME_UPDATE_INIT,
  USER_ME_UPDATE_SUCCESS,
  USER_ME_UPDATE_ERROR,
  USERS_LIST_INIT,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USERS_CREATE_INIT,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_ERROR,
  USERS_UPDATE_INIT,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_ERROR,
  USERS_DELETE_INIT,
  USERS_DELETE_SUCCESS,
  USERS_DELETE_ERROR,
  USER_SELECTED,
  USER_SELECTED_CLEAR,
  ROLES_LIST_INIT,
  ROLES_LIST_SUCCESS,
  ROLES_LIST_ERROR,
  TIPOS_ENTIDAD_LIST_INIT,
  TIPOS_ENTIDAD_SUCCESS,
  TIPOS_ENTIDAD_ERROR,
  TIPOS_DOCUMENTO_LIST_INIT,
  TIPOS_DOCUMENTO_SUCCESS,
  TIPOS_DOCUMENTO_ERROR,
  DOMAINS_BY_TYPE,
  USER_LOAD_SELECTED_INIT,
  USER_LOAD_SELECTED_SUCCESS,
  USER_LOAD_SELECTED_ERROR
} from './types';

const initialState = {
  data: [],
  rolesData: [],
  tiposEntidad: [],
  tiposDocumento: [],
  tipoData: [],
  isLoading: false,
  error: '',
  errorRoles: '',
  errorTiposEntidad: '',
  errorTiposDocumento: '',
  userMe: '',
  responseMe: '',
  response: '',
  selected: null,
  mode: null,
  newUser: false,
};

const users = (state = initialState, {type, payload}) => {
    switch (type) {
      case USER_ME_INIT: {
        return {
          ...state,
          error: '',
          responseMe: '',
          isLoading: true,
        };
      }

      case USER_ME_SUCCESS: {
        return {
          ...state,
          userMe: payload.data,
          isLoading: false,
        };
      }

      case USER_ME_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }

      case USER_ME_UPDATE_INIT: {
        return {
          ...state,
          error: '',
          responseMe: '',
          isLoading: true,
        };
      }

      case USER_ME_UPDATE_SUCCESS: {
        return {
          ...state,
          responseMe: payload,
          isLoading: false,
        };
      }

      case USER_ME_UPDATE_ERROR: {
        return {
          ...state,
          isLoading: false,
          responseMe: '',
          error: payload,
        };
      }

      case USERS_LIST_INIT: {
        return {
          ...state,
          error: '',
          isLoading: true,
        };
      }

      case USERS_LIST_SUCCESS: {
        let usersFiltered = [];

        if (Object.keys(payload.values).length === 0) {
          usersFiltered = payload.data;
        } else {
          console.log(payload.values)
          usersFiltered = payload.data
            .filter(({nombres: firstName}) => {
              if ((typeof payload.values.nombres === 'undefined') || (payload.values.nombres === '')) return true;
              if (firstName.includes(payload.values.nombres)) return true;

              return false;
            })
            .filter(({apellidos: lastName}) => {
              if ((typeof payload.values.apellidos === 'undefined') || (payload.values.apellidos === '')) return true;
              if (lastName.includes(payload.values.apellidos)) return true;

              return false;
            })
            .filter(({correo: email}) => {
              if ((typeof payload.values.correo === 'undefined') || (payload.values.correo === '')) return true;
              if (email.includes(payload.values.correo)) return true;

              return false;
            })
            .filter(({numeroDocumento}) => {
              if ((typeof payload.values.numeroDocumento === 'undefined') || (payload.values.numeroDocumento === '')) return true;
              if (numeroDocumento === (payload.values.numeroDocumento)) return true;

              return false;
            })
            .filter(({rol}) => {
              if ((typeof payload.values.roles === 'undefined') || (payload.values.roles === '')) return true;
              if (rol._id === (payload.values.roles)) return true;
              return false;
            })
            .filter(({autoridad_ambiental: autoridadAmbiental, entidad_disposicion: entidadDisposicion}) => {
              if (autoridadAmbiental._id !== '') {
                if ('entidad' in payload.values) {
                  if (payload.values.entidad === autoridadAmbiental._id) {
                    return true
                  } else {
                    return false
                  }
                }
                if (payload.values.tipo === 'disposicion') {
                  return false
                }
                return true
              } else if (entidadDisposicion._id !== '') {
                console.log("entro")
                if ('entidad' in payload.values){
                  if (payload.values.entidad === entidadDisposicion._id) {
                    return true
                  } else {
                    return false
                  }
                }
                if ((payload.values.tipo === 'autoridad')) {
                  return false
                }
                return true
              }
            })
        }

        console.log("users", usersFiltered.length)

        return {
          ...state,
          data: usersFiltered,
          isLoading: false,
          newUser: false,
        };
      }

      case
      USERS_LIST_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }

      case
      USERS_CREATE_INIT: {
        return {
          ...state,
          error: '',
          isLoading: true,
        };
      }

      case
      USERS_CREATE_SUCCESS: {
        return {
          ...state,
          data: [payload.data, ...state.data],
          isLoading: false,
          newUser: true,
          selected: null,
        };
      }

      case
      USERS_CREATE_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }

      case
      USERS_UPDATE_INIT: {
        return {
          ...state,
          error: '',
          isLoading: true,
        };
      }

      case
      USERS_UPDATE_SUCCESS: {
        return {
          ...state,
          data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
          isLoading: false,
          selected: null,
        };
      }

      case
      USERS_UPDATE_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }

      case
      USERS_DELETE_INIT: {
        return {
          ...state,
          error: '',
          isLoading: true,
        };
      }

      case
      USERS_DELETE_SUCCESS: {
        return {
          ...state,
          data: state.data.map((item) => (item._id === payload.data._id) ? {...item, estado: !item.estado} : item),
          isLoading: false,
        };
      }

      case
      USERS_DELETE_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload,
        };
      }

      case
      USER_SELECTED: {
        return {
          ...state,
          selected: {...state.data.filter((item) => item._id === payload)[0]},
        };
      }

      case
      USER_SELECTED_CLEAR: {
        return {
          ...state,
          selected: null,
          mode: null,
        };
      }

      case
      ROLES_LIST_INIT: {
        return {
          ...state,
          errorRoles: '',
        };
      }

      case
      ROLES_LIST_SUCCESS: {
        return {
          ...state,
          rolesData: payload.data,
        };
      }

      case
      ROLES_LIST_ERROR: {
        return {
          ...state,
          errorRoles: payload,
        };
      }

      case
      TIPOS_ENTIDAD_LIST_INIT: {
        return {
          ...state,
          errorTiposEntidad: '',
        };
      }

      case
      TIPOS_ENTIDAD_SUCCESS: {
        return {
          ...state,
          tiposEntidad: payload.data,
        };
      }

      case
      TIPOS_ENTIDAD_ERROR: {
        return {
          ...state,
          errorTiposEntidad: payload,
        };
      }

      case
      TIPOS_DOCUMENTO_LIST_INIT: {
        return {
          ...state,
          errorTiposDocumento: '',
        };
      }

      case
      TIPOS_DOCUMENTO_SUCCESS: {
        return {
          ...state,
          tiposDocumento: payload.data,
        };
      }

      case
      TIPOS_DOCUMENTO_ERROR: {
        return {
          ...state,
          errorTiposDocumento: payload,
        };
      }

      case
      DOMAINS_BY_TYPE: {
        return {
          ...state,
          tipoData: payload.data,
        };
      }

      case
      USER_LOAD_SELECTED_INIT: {
        return {
          ...state,
          error: "",
          isLoading: true
        };
      }

      case
      USER_LOAD_SELECTED_SUCCESS: {
        return {
          ...state,
          isLoading: true,
          selected: payload.data,
          mode: payload.mode,
          error: ""
        };
      }

      case
      USER_LOAD_SELECTED_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: payload
        };
      }

      default: {
        return state;
      }
    }
  }
;

export {users as default, initialState};
