/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useEffect }  from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from "styled-components";
import {useTable, usePagination, useRowSelect} from "react-table";
import {pxToRem} from "../../utils";
import {StyledCell} from "./Cell";

import {Button} from '../form-ui-components/Button';

const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const TableContainer = styled.div`
  display: grid;
  overflow: auto;
  width: 100%;
`;

const TableButtons = styled.div`
  display: flex;
  overflow: auto;
  width: auto;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
`;

const Table = styled.table`
  border: ${pxToRem(1)} solid;
  border-color: ${props => props.theme.complementaryColor};
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
`;

const TableHead = styled.thead`
  color: ${props => props.theme.hoverColor};
  background-color: ${props => props.theme.complementaryColor};
`;

const TH = styled.th`
  padding: ${pxToRem(12)} ${pxToRem(8)};
  font-weight: 300;
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;

const StyledPaginationControls = styled.div`
  display: inline-block;
  margin: auto;
  padding: ${pxToRem(16)};
`;

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const PaginationTableRowSelectServerSide = ({columns, data, onClick, eraser, textEraser, fetchData, loading, isLoading, pageCount: controlledPageCount }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize, selectedRowIds}
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({getToggleAllRowsSelectedProps}) => (
            eraser && (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            )
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({row}) => (
            eraser && (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          ),
        },
        ...columns,
      ])
    },
    usePagination
  );

  useEffect(() => {
    if(!loading) {
      console.log('se ejecuta dentro del paginationtable 1', pageIndex, pageSize, loading, isLoading);
      (async () => { await fetchData({ pageIndex, pageSize, loading, isLoading }); })();
      console.log('se ejecuta dentro del paginationtable 2', pageIndex, pageSize, loading, isLoading);
    }
  }, [fetchData, pageIndex, pageSize])

  // Render the UI for your table
  return (
    <ContainerMain>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`fill-${i}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, j) => (
                  <TH key={`col-${i}-${j}`} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TH>
                ))}
              </tr>
            ))}
          </TableHead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, k) => {
              prepareRow(row);
              return (
                <tr key={`row-${k}`} {...row.getRowProps()}>
                  {row.cells.map((cell, n) => {
                    return (
                      <StyledCell key={`cell-${k}-${n}`} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledCell>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {loading ? (
                <td colSpan="10000">
                  <div style={{justifyContent: 'flex-end', width: '100%'}}>
                    Cargando información por favor espere un momento...
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                </td>
              ) : (
                <td colSpan="10000">
                  Viendo {page.length} de ~{controlledPageCount * pageSize}{' '}
                  resultados
                </td>
              )}
            </tr>
          </tbody>
        </Table>
        {/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
        <StyledPaginationControls>
          <button onClick={(e) => { e.preventDefault(); gotoPage(0); e.stopPropagation(); }} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={(e) => { e.preventDefault(); previousPage(); e.stopPropagation(); }} disabled={!canPreviousPage}>
            {"<"}
          </button>{' '}
          <button onClick={(e) => { e.preventDefault(); nextPage(); e.stopPropagation(); }} disabled={!canNextPage}>
            {">"}
          </button>{' '}
          <button onClick={(e) => { e.preventDefault(); gotoPage(pageCount - 1); e.stopPropagation(); }} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Página{' '}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Ir a la página:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = (e.target.value && (Number(e.target.value) - 1)) || 0;
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
                setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Ver {pageSize}
              </option>
            ))}
          </select>
        </StyledPaginationControls>
      </TableContainer>
      {eraser &&
        Object.keys(selectedRowIds).length > 0 && (
          <TableButtons>
            <Button type="button" onClick={(e) => onClick(e, selectedRowIds, selectedFlatRows)}>
              {textEraser} {Object.keys(selectedRowIds).length}
            </Button>
          </TableButtons>
        )
      }
    </ContainerMain>
  );
};

PaginationTableRowSelectServerSide.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
  eraser: PropTypes.bool.isRequired,
  textEraser: PropTypes.string.isRequired,
  fetchData: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
};

PaginationTableRowSelectServerSide.defaultProps = {
  columns: [],
  data: [],
  fetchData: () => {},
};

export default PaginationTableRowSelectServerSide;
