import React from 'react';
import PropTypes from 'prop-types';
/* Style Components */
import { Container } from './styled';

const Loader = ({ message }) => {
  return (
    <Container>
      <div className="loader" />
      <span className="loading-text">
        {message || "Loading..."}
      </span>
    </Container>
  )
}

Loader.propTypes = {
  message: PropTypes.string,
};

Loader.defaultProps = {
  message: 'Loading...',
};

export default Loader;
