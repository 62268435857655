/* eslint-disable no-console */
/* Defined Constants */
import { USERS_URL_API as URL_API, wait } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};
  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiLogin = {
  logout: async ({ token }) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await wait(200);
      // if (!response.ok || response.status === 404 || response.status === 400 || response.status === 422) {
      //   return response.statusText;
      // }
      // const data = await response.json();
      // if (typeof data.error !== 'undefined') {
      //   return data.error;
      // }
      // if (typeof response.message !== 'undefined') {
      //   return response.message;
      // }
      return response;
    } catch (error) {
      return error.toString();
    }
  },
  login: async (login) => {
    try {
      const response = await fetch(`${URL_API}${endpoints.login.signIn}`, fetchParams('POST', { ...login }));
      if (!response.ok || response.status === 404 || response.status === 400 || response.status === 422) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      if (typeof response.message !== 'undefined') {
        return response.message;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  recovery: async (recovery) => {
    try {
      const response = await fetch(`${URL_API}${endpoints.login.recovery}`, fetchParams('POST', { ...recovery }));
      if (!response.ok || response.status === 404 || response.status === 400 || response.status === 422) {
        return 'El correo electrónico ingresado no se encuentra registrado'; // response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return 'El correo electrónico ingresado no se encuentra registrado';
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  password: async (password) => {
    try {
      const response = await fetch(`${URL_API}${endpoints.login.reset}`, fetchParams('POST', { ...password }));
      const data = await response.json();
      if (!response.ok || response.status === 404 || response.status === 400 || response.status === 422) {
        return data;
      }
      if (typeof data.error !== 'undefined') {
        return data.message;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  me: async token => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${URL_API}${endpoints.login.me}`, fetchParams('GET'));
      if (!response.ok || response.status === 404 || response.status === 400 || response.status === 422 || response.status === 500) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
};

export default apiLogin;
