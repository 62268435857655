import styled from "styled-components";
import { pxToRem } from "../utils";

export const H3 = styled.h3`
  font-size: ${pxToRem(23)};
  font-weight: 400;
  margin: ${pxToRem(2)};
  text-align: start;
`;

export default H3;
