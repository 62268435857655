/* eslint-disable no-underscore-dangle */
import {
  TRASLADO_SEARCH_INIT,
  TRASLADO_SEARCH_SUCCESS,
  TRASLADO_SEARCH_ERROR,
  TRASLADO_CREATE_INIT,
  TRASLADO_CREATE_SUCCESS,
  TRASLADO_CREATE_ERROR,
  TRASLADO_ESPECIMENES_SEARCH_INIT,
  TRASLADO_ESPECIMENES_SEARCH_SUCCESS,
  TRASLADO_ESPECIMENES_SEARCH_ERROR,
  TRASLADO_USUARIO_ENTIDAD_SUCCESS,
  TRASLADO_USUARIO_ENTIDAD_ERROR,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS,
  TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR,
  TRASLADO_LOAD_SOPORTE_SUCCESS,
  TRASLADO_LOAD_SOPORTE_ERROR,
  TRASLADO_CANCELAR_INIT,
  TRASLADO_CANCELAR_SUCCESS,
  TRASLADO_CANCELAR_ERROR,
  TRASLADO_CLEAR_SELECTED,
  TRASLADO_CONTACT_ADD_SUCCESS,
  TRASLADO_CONTACT_ADD_ERROR,
  TRASLADO_CONTACT_DELETE_SUCCESS,
  TRASLADO_CONTACT_DELETE_ERROR,
  TRASLADO_ESPECIMEN_SELECTED_SUCCESS,
  TRASLADO_ESPECIMEN_SELECTED_ERROR,
  TRASLADO_ESPECIMENES_ADD_SUCCESS,
  TRASLADO_ESPECIMENES_ADD_ERROR,
  TRASLADO_ESPECIMENES_DELETE_SUCCESS,
  TRASLADO_ESPECIMENES_DELETE_ERROR,
  TRASLADO_RESPUESTA_SUCCESS,
  TRASLADO_RESPUESTA_ERROR,
  TRASLADO_SOPORTES_ADD_SUCCESS,
  TRASLADO_SOPORTES_ADD_ERROR,
  TRASLADO_SOPORTES_UPDATE_SUCCESS,
  TRASLADO_SOPORTES_UPDATE_ERROR,
  TRASLADO_SOPORTES_DELETE_SUCCESS,
  TRASLADO_SOPORTES_DELETE_ERROR,
  FILE_CLEAR_SELECTED,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_ERROR,
  TRASLADO_SOPORTES_DOWNLOAD_INIT,
  TRASLADO_SOPORTES_DOWNLOAD_SUCCESS,
  TRASLADO_SOPORTES_DOWNLOAD_ERROR,
  CLEAN_CONTACTS_TO_TRASLADO_SUCCESS,
  CLEAN_CONTACTS_TO_TRASLADO_ERROR,
  SEARCH_ESPECIMENES_SIN_TRASLADO_INIT,
  SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS,
  SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR,
  SEARCH_ESPECIMENES_CON_TRASLADO_INIT,
  SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS,
  SEARCH_ESPECIMENES_CON_TRASLADO_ERROR,
  TRASLADO_LOAD_SELECTED_INIT,
  TRASLADO_LOAD_SELECTED_SUCCESS,
  TRASLADO_LOAD_SELECTED_ERROR
} from "./types";
import { isPresent } from "../../utils";

const initialState = {
  solicitante: null,
  data: [],
  pageCount: 0,
  pageCountEspecimenes: 0,
  contacts: [],
  entidadesProvisional: [],
  entidadesFinal: [],
  especimenesAddaded: [],
  especimenesSinTraslado: [],
  especimenesConTraslado: [],
  especimenes: [],
  files: [],
  filesAux: [],
  selected: null,
  mode: null,
  isLoading: false,
  uploadedInfoEntidad: false,
  error: '',
  respuesta: null,
  selectedEspecimen: null,
  modeEspecimen: null,
  isLoadingEspecimenes: false,
  isLoadingEspecimenesAddaded: false,
  errorEspecimen: '',
  soporteSelected: null,
  soporteMode: null,
  errorSoporte: '',
  isLoadingContacts: '',
  errorContacts: '',
  isLoadingFile: false,
};

const traslado = (state = initialState, { type, payload }) => {
  switch (type) {
    case TRASLADO_SEARCH_INIT: {
			return {
				...state,
				error: '',
        isLoading: true,
        selected: null,
        mode: null,
        especimenesAddaded: [],
        especimenesSinTraslado: [],
        especimenesConTraslado: [],
        especimenes: [],
        files: [],
        filesAux: [],
        soporteSelected: null,
        soporteMode: null,
        errorSoporte: '',
        selectedEspecimen: null,
        modeEspecimen: null,
      };
    }

		case TRASLADO_SEARCH_SUCCESS: {
			return {
				...state,
        data: payload.data,
        pageCount: payload.pageCount,
        isLoading: false,
        error: ''
			};
    }

		case TRASLADO_SEARCH_ERROR: {
			return {
				...state,
				isLoading: false,
				error: payload,
      };
    }

    case TRASLADO_CREATE_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
        selected: null,
        mode: null,
      };
    }

    case TRASLADO_CREATE_SUCCESS: {
      return {
        ...state,
        // data: [payload.data, ...state.data],
        isLoading: false,
      };
    }

    case TRASLADO_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case TRASLADO_USUARIO_ENTIDAD_SUCCESS: {
      return {
        ...state,
        solicitante: payload.data,
        error: ''
      };
    }

    case TRASLADO_USUARIO_ENTIDAD_ERROR: {
      return {
        ...state,
        error: payload
      };
    }

    case TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT: {
      return {
        ...state,
        uploadedInfoEntidad: false,
        error: "",
      };
    }

    case TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS: {
      return {
        ...state,
        uploadedInfoEntidad: true,
        entidadesProvisional: payload.type === 'provisional' ? payload.data : state.entidadesProvisional,
        entidadesFinal: payload.type === 'final' ? payload.data : state.entidadesFinal,
        error: ""
      };
    }

    case TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR: {
      return {
        ...state,
        uploadedInfoEntidad: false,
        error: payload
      };
    }

    case TRASLADO_LOAD_SOPORTE_SUCCESS: {
      return {
        ...state,
        soporteSelected: payload.file,
        soporteMode: payload.mode,
        errorSoporte: '',
      };
    }

    case TRASLADO_LOAD_SOPORTE_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
      };
    }

    case TRASLADO_CANCELAR_INIT: {
      return {
        ...state,
        error: ""
      };
    }

    case TRASLADO_CANCELAR_SUCCESS: {
      return {
        ...state,
        error: ""
      };
    }

    case TRASLADO_CANCELAR_ERROR: {
      return {
        ...state,
        error: payload
      };
    }

    case TRASLADO_CLEAR_SELECTED: {
      return {
        ...state,
        error: "",
        selected: null,
        mode: null,
        isLoading: false,
        uploadedInfoEntidad: false,
        contacts: [],
        entidadesProvisional: [],
        entidadesFinal: [],
        especimenesAddaded: [],
        especimenesSinTraslado: [],
        especimenesConTraslado: [],
        especimenes: [],
        files: [],
        filesAux: [],
      }
    }

    case TRASLADO_CONTACT_ADD_SUCCESS: {
      return {
        ...state,
        contacts: [...state.contacts, payload.data],
        errorContacts: ''
      };
    }

    case TRASLADO_CONTACT_ADD_ERROR: {
      return {
        ...state,
        errorContacts: payload
      };
    }

    case TRASLADO_CONTACT_DELETE_SUCCESS: {
      return {
        ...state,
        contacts: state.contacts.filter((item) => item._id !== payload),
        errorContacts: ''
      };
    }

    case TRASLADO_CONTACT_DELETE_ERROR: {
      return {
        ...state,
        errorContacts: payload
      };
    }

    case TRASLADO_ESPECIMEN_SELECTED_SUCCESS: {
      return {
        ...state,
        selectedEspecimen: {...state.especimenes.filter((item) => item._id === payload.id)[0]},
        modeEspecimen: payload.mode,
        errorEspecimen: ''
      };
    }

    case TRASLADO_ESPECIMEN_SELECTED_ERROR: {
      return {
        ...state,
        errorEspecimen: payload
      };
    }

    case TRASLADO_ESPECIMENES_SEARCH_INIT: {
      return {
        ...state,
        errorEspecimen: '',
        isLoadingEspecimenesAddaded: true
      };
    }

    case TRASLADO_ESPECIMENES_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoadingEspecimenesAddaded: false,
        especimenesAddaded: payload.data,
        especimenes: payload.data,
        pageCountEspecimenes: payload.pageCount,
        errorEspecimen: ''
      };
    }

    case TRASLADO_ESPECIMENES_SEARCH_ERROR: {
      return {
        ...state,
        isLoadingEspecimenesAddaded: false,
        errorEspecimen: payload
      };
    }

    case SEARCH_ESPECIMENES_SIN_TRASLADO_INIT: {
      return {
        ...state,
        errorEspecimen: '',
        isLoadingEspecimenes: true
      };
    }

    case SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        especimenes: payload.data,
        pageCountEspecimenes: payload.pageCount,
        errorEspecimen: ''
      };
    }

    case SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimen: payload
      };
    }

    case SEARCH_ESPECIMENES_CON_TRASLADO_INIT: {
      return {
        ...state,
        errorEspecimen: '',
        isLoadingEspecimenes: true
      };
    }

    case SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        especimenes: payload.data,
        pageCountEspecimenes: payload.pageCount,
        errorEspecimen: ''
      };
    }

    case SEARCH_ESPECIMENES_CON_TRASLADO_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimen: payload
      };
    }

    case TRASLADO_ESPECIMENES_ADD_SUCCESS: {
      const { selectedRowIds, traslado: isTraslado } = payload;
      // [ ...state.especimenesAddaded.filter(({ _id }) => selectedFlatRows.some(({ original }) => original._id !== _id)),
      let especimenesFiltered = state.especimenes.filter((_, index) => isPresent(selectedRowIds, index));
      console.log('especimenesFiltered 1', especimenesFiltered);
      especimenesFiltered = especimenesFiltered.filter(({ _id }) => !state.especimenesAddaded.some(({ _id: idAdd }) => _id === idAdd ));
      console.log('especimenesFiltered 2', especimenesFiltered);
      const especimenesSinTrasladoFiltered = especimenesFiltered.filter(({ _id }) => !state.especimenesSinTraslado.some(({ _id: idAdd }) => _id === idAdd ));
      console.log('especimenesSinTrasladoFiltered 3', especimenesSinTrasladoFiltered);
      const especimenesConTrasladoFiltered = especimenesFiltered.filter(({ _id }) => !state.especimenesConTraslado.some(({ _id: idAdd }) => _id === idAdd ));
      console.log('especimenesConTrasladoFiltered 4', especimenesConTrasladoFiltered);

      return {
        ...state,
        especimenesSinTraslado: (String(true) === isTraslado) ? [ ...state.especimenesSinTraslado, ...especimenesSinTrasladoFiltered ] : state.especimenesSinTraslado,
        especimenesConTraslado: (String(true) !== isTraslado) ?  [...state.especimenesConTraslado, ...especimenesConTrasladoFiltered ] : state.especimenesConTraslado,
        especimenesAddaded: [ ...state.especimenesSinTraslado, ...state.especimenesConTraslado, ...especimenesFiltered ],
        errorEspecimen: ''
      };
    }

    case TRASLADO_ESPECIMENES_ADD_ERROR: {
      return {
        ...state,
        errorEspecimen: payload
      };
    }

    case TRASLADO_ESPECIMENES_DELETE_SUCCESS: {
      const { selectedRowIds } = payload;
      const especimenesDeleted = state.especimenesAddaded.filter((_, index) => !isPresent(selectedRowIds, index));

      return {
        ...state,
        especimenesSinTraslado: state.especimenesSinTraslado.filter(({ _id }) => especimenesDeleted.some(({ _id: idDelete }) => _id === idDelete )),
        especimenesConTraslado: state.especimenesConTraslado.filter(({ _id }) => especimenesDeleted.some(({ _id: idDelete }) => _id === idDelete )),
        especimenesAddaded: especimenesDeleted,
        errorEspecimen: ''
      };
    }

    case TRASLADO_ESPECIMENES_DELETE_ERROR: {
      return {
        ...state,
        errorEspecimen: payload
      };
    }

    case TRASLADO_RESPUESTA_SUCCESS: {
      return {
        ...state,
        respuesta: payload.traslado,
        error: ''
      };
    }

    case TRASLADO_RESPUESTA_ERROR: {
      return {
        ...state,
        error: payload
      };
    }

    case TRASLADO_SOPORTES_ADD_SUCCESS: {
      return {
        ...state,
        files: [ ...state.files, payload.data ],
        filesAux: [ ...state.filesAux, payload.data ],
        errorSoporte: '',
      };
    }

    case TRASLADO_SOPORTES_ADD_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
      };
    }

    case TRASLADO_SOPORTES_UPDATE_SUCCESS: {
      return {
        ...state,
        files: state.files.map((item) => (item._id === payload.data._id) ? payload.data : item),
        filesAux: state.filesAux.map((item) => (item._id === payload.data._id) ? payload.data : item),
        errorSoporte: '',
      };
    }

    case TRASLADO_SOPORTES_UPDATE_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
      };
    }

    case TRASLADO_SOPORTES_DELETE_SUCCESS: {
      return {
        ...state,
        files: state.files.map((item, index) => isPresent(payload.selectedRowIds, index) ? { ...item, activo: false } : item),
        filesAux: state.filesAux.filter((_, index) => !isPresent(payload.selectedRowIds, index)),
        errorSoporte: '',
      };
    }

    case TRASLADO_SOPORTES_DELETE_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
      };
    }

    case FILE_CLEAR_SELECTED: {
      return {
        ...state,
        soporteMode: null,
        soporteSelected: null,
        errorSoporte: '',
      }
    }

    case SELECTED_FILE_SUCCESS: {
      return {
        ...state,
        soporteSelected: {...state.filesAux.filter((item) => item._id === payload.id)[0]},
        soporteMode: payload.mode,
        errorSoporte: '',
      };
    }

    case SELECTED_FILE_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
      };
    }

    case TRASLADO_SOPORTES_DOWNLOAD_INIT: {
      return {
        ...state,
        errorFile: '',
        isLoadingFile: true,
      };
    }

    case TRASLADO_SOPORTES_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        errorSoporte: '',
        isLoadingFile: false,
      };
    }

    case TRASLADO_SOPORTES_DOWNLOAD_ERROR: {
      return {
        ...state,
        errorSoporte: payload,
        isLoadingFile: false,
      };
    }

    case CLEAN_CONTACTS_TO_TRASLADO_SUCCESS: {
      return {
				...state,
				contacts: [],
      };
    }

    case CLEAN_CONTACTS_TO_TRASLADO_ERROR: {
      return {
				...state,
				errorContacts: payload,
      };
    }

    case TRASLADO_LOAD_SELECTED_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true,
        files: [],
        filesAux: [],
        especimenesAddaded: [],
        especimenesSinTraslado: [],
        especimenesConTraslado: [],
        especimenes: [],
        contacts: [],
        soporteSelected: null,
        soporteMode: null,
        errorSoporte: '',
        selectedEspecimen: null,
        modeEspecimen: null,
      };
    }

    case TRASLADO_LOAD_SELECTED_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selected: payload.data,
        contacts: payload.data.funcionarios.filter(({ estado }) => estado === true),
        files: payload.data.files,
        filesAux: payload.data.files,
        mode: payload.mode,
        especimenesAddaded: [],
        error: ""
      };
    }

    case TRASLADO_LOAD_SELECTED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { traslado as default, initialState };
