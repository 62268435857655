import {
  AUTORIDAD_AMBIENTAL_SEARCH_INIT,
  AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS,
  AUTORIDAD_AMBIENTAL_SEARCH_ERROR,
  AUTORIDAD_AMBIENTAL_CREATE_INIT,
  AUTORIDAD_AMBIENTAL_CREATE_SUCCESS,
  AUTORIDAD_AMBIENTAL_CREATE_ERROR,
  AUTORIDAD_AMBIENTAL_DELETE_INIT,
  AUTORIDAD_AMBIENTAL_DELETE_SUCCESS,
  AUTORIDAD_AMBIENTAL_DELETE_ERROR,
  AUTORIDAD_AMBIENTAL_CLEAR_SELECTED,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR,
  AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS,
  AUTORIDAD_AMBIENTAL_UPDATE_INIT,
  AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS,
  AUTORIDAD_AMBIENTAL_UPDATE_ERROR
} from "./types";

export const createAutoridadAmbientalA = () => {
  return {
    type: AUTORIDAD_AMBIENTAL_CREATE_INIT
  };
};

export const createSuccess = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_CREATE_SUCCESS,
    payload: { data }
  };
};

export const createFailed = error => {
  return {
    type: AUTORIDAD_AMBIENTAL_CREATE_ERROR,
    payload: error
  };
};

export const updateAutoridadAmbientalA = () => {
  return {
    type: AUTORIDAD_AMBIENTAL_UPDATE_INIT,
  };
};

export const updateSuccess = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS,
    payload: { data }
  };
};

export const updateFailed = error => {
  return {
    type: AUTORIDAD_AMBIENTAL_UPDATE_ERROR,
    payload: error
  };
};

export const searchAutoridadAmbientalA = () => {
  return {
    type: AUTORIDAD_AMBIENTAL_SEARCH_INIT
  };
};

export const searchSuccess = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS,
    payload: { data }
  };
};

export const searchFailed = error => {
  return {
    type: AUTORIDAD_AMBIENTAL_SEARCH_ERROR,
    payload: error
  };
};

export const deleteAutoridadAmbientalA = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_DELETE_INIT,
    payload: { data }
  };
};

export const deleteSuccess = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_DELETE_SUCCESS,
    payload: { data }
  };
};

export const deleteFailed = error => {
  return {
    type: AUTORIDAD_AMBIENTAL_DELETE_ERROR,
    payload: error
  };
};

export const clearSelectedA = () => {
  return {
    type: AUTORIDAD_AMBIENTAL_CLEAR_SELECTED,
  };
};

export const changeModeSuccess = mode => {
  return {
    type: AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS,
    payload: mode
  };
};

export const loadSelectedA = data => {
  return {
    type: AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT,
    payload: { data }
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR,
    payload: error
  };
};
