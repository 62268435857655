/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import styled from "styled-components";

import { Map, TileLayer, Marker, Popup, useLeaflet } from "react-leaflet";
import L from "leaflet";
import { pxToRem } from "../../utils";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

const size = {
  height: `${pxToRem(420)}`,
  width: "94%",
  margin: `${pxToRem(5)} auto`,
  borderRadius: `${pxToRem(5)}`,
  padding: `${pxToRem(5)}`
};

const center = { lat: 4.5709, lng: -74.2973 };

const GeocoderControl = ({ setResult }: Props) => {
  const { map } = useLeaflet();

  let marker;

  useEffect(() => {
    const eventHandler = e => {
      const geocoder = L.Control.Geocoder.nominatim();
      geocoder.reverse(
        e.latlng,
        map.options.crs.scale(map.getZoom()),
        results => {
          if (marker) {
            marker
              .setLatLng(results[0].center)
              .setPopupContent(results[0].html || results[0].name)
              .openPopup();
          } else {
            marker = L.marker(results[0].center)
              .bindPopup(results[0].name)
              .addTo(map)
              .openPopup();
          }
          setResult(results[0]);
        }
      );
    };
    map.on("click", eventHandler);
    return () => {
      map.off("click", eventHandler);
    };
  },[]);
  return <> </>;
};

export const MapWithGeocoderControl = ({ setResult }) => {
  return (
    <Map style={size} center={center} zoom={8}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeocoderControl setResult={setResult} />
    </Map>
  );
};

export default MapWithGeocoderControl;
