/* Defined Constants */
import { CONSTANTS_URL_API as URL_API, constantTypesByKey } from "../config/const";

/* Defined Endpoints */
import endpoints from "../config/endpoints";

let apiHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: ""
};

const fetchParams = (method, data = "") => {
  const body = data ? { body: JSON.stringify(data) } : {};
  return {
    method,
    headers: apiHeaders,
    credentials: "same-origin",
    ...body
  };
};

export const constantsApi = {
  getConstantOptionsByTypes: async constantKeys => {
    try {
      const constantTypeParam = constantKeys.map(k => constantTypesByKey[k]);
      const path = endpoints.constants.getConstantOptionsByTypes + constantTypeParam;
      const url = new URL(path, URL_API);
      const response = await fetch(url, fetchParams("GET"));
      if (typeof response.ok === "undefined") {
        // throw new Error(response.status_message);
        console.log(response.status_message);
      }
      const data = await response.json();
      if (typeof data.error !== "undefined") {
        // throw new Error(data.error);
        console.log(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getAllConstants: async token => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    const path = `${endpoints.constants.search}/constantIds=&constantTypes=`;
    const url = new URL(path, URL_API);
    try {
      const response = await fetch(url, fetchParams("GET"));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  }
};

export default constantsApi;
