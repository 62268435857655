/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* Defined Constants */
import { USERS_URL_API_PIFS, DOMAINS_URL_API } from '../config/const';
/* Defined Endpoints */
import endpoints from '../config/endpoints';

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};

  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

export const apiExpedientes = {
  create: async (expediente, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.create}`, fetchParams('POST', { ...expediente }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  update: async (id, expediente, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.update}/${id}`, fetchParams('PUT', { ...expediente }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  close: async (id, close, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.close}/${id}`, fetchParams('PUT', { ...close }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  search: async (values, from, to, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.search}/${from}/${to}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  delete: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.delete}/${id}`, fetchParams('DELETE'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return data.message;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  get: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.get}/${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error;
    }
  },
  radicar: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${USERS_URL_API_PIFS}${endpoints.expediente.radicar}/${id}`, fetchParams('PUT'));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  taxonInfo: async (values, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.taxonInfo.search}`, fetchParams('POST', { ...values }));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
  taxonGet: async (id, token) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await fetch(`${DOMAINS_URL_API}${endpoints.taxonInfo.get}?ids=${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 404 || response.status === 403 || response.status === 409 || response.status === 500 ) {
        const data = await response.json();
        if (typeof data.message !== 'undefined') {
          return `${data.message} Error ${data.error}`;
        }
        if (typeof data.error !== 'undefined') {
          return data.error;
        }
        return response.statusText;
      }
      const data = await response.json();
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      return error.toString();
    }
  },
};

export default apiExpedientes;
