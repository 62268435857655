import {
  ENTIDAD_DISPOSICION_SEARCH_INIT,
  ENTIDAD_DISPOSICION_SEARCH_SUCCESS,
  ENTIDAD_DISPOSICION_SEARCH_ERROR,
  ENTIDAD_DISPOSICION_CREATE_INIT,
  ENTIDAD_DISPOSICION_CREATE_SUCCESS,
  ENTIDAD_DISPOSICION_CREATE_ERROR,
  ENTIDAD_DISPOSICION_DELETE_INIT,
  ENTIDAD_DISPOSICION_DELETE_SUCCESS,
  ENTIDAD_DISPOSICION_DELETE_ERROR,
  ENTIDAD_DISPOSICION_CLEAR_SELECTED,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR,
  ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS,
  ENTIDAD_DISPOSICION_UPDATE_INIT,
  ENTIDAD_DISPOSICION_UPDATE_SUCCESS,
  ENTIDAD_DISPOSICION_UPDATE_ERROR
} from "./types";

export const createEntidadDisposicionA = () => {
  return {
    type: ENTIDAD_DISPOSICION_CREATE_INIT
  };
};

export const createSuccess = data => {
  return {
    type: ENTIDAD_DISPOSICION_CREATE_SUCCESS,
    payload: { data }
  };
};

export const createFailed = error => {
  return {
    type: ENTIDAD_DISPOSICION_CREATE_ERROR,
    payload: error
  };
};

export const updateInit = () => {
  return {
    type: ENTIDAD_DISPOSICION_UPDATE_INIT,
  };
};

export const updateSuccess = data => {
  return {
    type: ENTIDAD_DISPOSICION_UPDATE_SUCCESS,
    payload: { data }
  };
};

export const updateFailed = error => {
  return {
    type: ENTIDAD_DISPOSICION_UPDATE_ERROR,
    payload: error
  };
};

export const searchEntidadDisposicionA = () => {
  return {
    type: ENTIDAD_DISPOSICION_SEARCH_INIT
  };
};

export const searchSuccess = data => {
  return {
    type: ENTIDAD_DISPOSICION_SEARCH_SUCCESS,
    payload: { data }
  };
};

export const searchFailed = error => {
  return {
    type: ENTIDAD_DISPOSICION_SEARCH_ERROR,
    payload: error
  };
};

export const deleteEntidadDisposicionA = data => {
  return {
    type: ENTIDAD_DISPOSICION_DELETE_INIT,
    payload: { data }
  };
};

export const deleteSuccess = data => {
  return {
    type: ENTIDAD_DISPOSICION_DELETE_SUCCESS,
    payload: { data }
  };
};

export const deleteFailed = error => {
  return {
    type: ENTIDAD_DISPOSICION_DELETE_ERROR,
    payload: error
  };
};

export const clearSelectedA = () => {
  return {
    type: ENTIDAD_DISPOSICION_CLEAR_SELECTED,
  };
};

export const changeModeSuccess = mode => {
  return {
    type: ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS,
    payload: mode
  };
};

export const loadSelectedA = data => {
  return {
    type: ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT,
    payload: { data }
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR,
    payload: error
  };
};
