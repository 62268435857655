/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import React, {useState} from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from "styled-components";
import {pxToRem} from "../utils";
import {apiDomains} from "../services";
import {Input} from "./form-ui-components/Input";
import {Button} from "./form-ui-components/Button";
import {P} from "./P";
/* Hooks */
import {
  useLogin,
  useNotification,
  useDomains,
  useConstants,
  useTaxonomy
} from "../hooks";
import {GridContainer, Row, Col2} from "./Grid";

const Downloader = styled.a`
  color: ${props => props.theme.primaryColor};
  font-weight: 300;
  font-size: ${pxToRem(12)};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.primaryColorDark};
  }
`;

const DottedBox = styled.div`
  align-items: center;
  border: ${pxToRem(0.5)} dashed;
  border-color: ${props => props.theme.primaryColorDark};
  display: inline-flex;
  flex-direction: column;
  margin-bottom: ${pxToRem(24)};
  padding: ${pxToRem(8)};
  text-align: center;
  width: 100%;
`;



const UploadWidget = ({ fileName, downloadFileName}) => {
  const [file, setFile] = useState({});
  const [process, setProcess] = useState(false);
  const [response, setResponse] = useState({});
  const {createNotification} = useNotification();
  const {token} = useLogin();
  const { getAllDomains } = useDomains();
  const { getAllConstants } = useConstants();
  const { getAllTaxonomy } = useTaxonomy();

    const onSubmit = async e => {
      e.preventDefault();
      setResponse(null);
      setProcess(true);
      const response = await apiDomains.uploadFile(file, fileName, downloadFileName, token);
      if (response) {
        setResponse(response);
        createNotification({message: response.message, visible: true});
        switch (downloadFileName) {
          case 'dominios':
            setProcess(false);
            await getAllDomains(token);
          break;

          case 'constantes':
            setProcess(false);
            await getAllConstants(token);
          break;

          case 'taxonomía':
            setProcess(false);
            await getAllTaxonomy(0, 30086, token);
          break;

          default:
            ;
          break;
        }
      }
    };

    return (
        <>
            <P center>Arrastre o seleccione el archivo .csv desde su equipo.</P>
            <Input
                uploader
                name={fileName}
                type="file"
                onChange={e => setFile(e.target.files[0])}
            />
            {downloadFileName === 'dominios' && !process ? (
                <Button onClick={e => onSubmit(e)}>Procesar Archivo</Button>
              ) : (downloadFileName === 'constantes' && !process ? (
                <Button onClick={e => onSubmit(e)}>Procesar Archivo</Button>
              ) : (downloadFileName === 'taxonomía' && !process ? (
                  <Button onClick={e => onSubmit(e)}>Procesar Archivo</Button>
                ) : (
                  <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
                    <P center>Importando información por favor espere un momento...</P>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                )
              )
            )}
        </>
    );
};

UploadWidget.propTypes = {
    fileName: PropTypes.string,
    downloadFileName: PropTypes.string,
};

UploadWidget.defaultProps = {
    fileName: "",
    downloadFileName: "",
};

const DownloadWidget = ({fileName}) => (
    <Downloader
        onClick={async () => {
            await apiDomains.downloadFile(fileName);
        }}
    >
        Descargar Lista
    </Downloader>
);

DownloadWidget.propTypes = {
    fileName: PropTypes.string
};

DownloadWidget.defaultProps = {
    fileName: ""
};

const StyledFileWidget = styled.span``;

export const FileWidget = ({ history, match, fileName, downloadFileName}) => (
    <StyledFileWidget>
        <GridContainer>
            <Row>
                <Col2>
                    <P>
                        {`Para crear, actualizar o eliminar valores del listado de
                        ${downloadFileName} debe descargar la plantilla, actualizar y subir nuevamente.
                        El sistema solo admite archivos de textos plano (.csv, .txt) cuya codificación sea UTF-8
                        y el separador de columnas sea punto y coma ';'.`}
                    </P>
                </Col2>
                <Col2>

                    <DottedBox>
                        <UploadWidget history={history} match={match} fileName={fileName} downloadFileName={downloadFileName}/>
                        <DownloadWidget fileName={downloadFileName}/>
                    </DottedBox>
                </Col2>
            </Row>
        </GridContainer>
    </StyledFileWidget>
);

FileWidget.propTypes = {
    fileName: PropTypes.string,
    downloadFileName: PropTypes.string,
    history: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
};

FileWidget.defaultProps = {
    fileName: "",
    downloadFileName: "",
    history: {},
    match: {},
};

export default withRouter(FileWidget);
