export const EXPEDIENTE_CREATE_INIT = '[EXPEDIENTE] EXPEDIENTE_CREATE_INIT';
export const EXPEDIENTE_CREATE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_CREATE_SUCCESS';
export const EXPEDIENTE_CREATE_ERROR = '[EXPEDIENTE] EXPEDIENTE_CREATE_ERROR';
export const EXPEDIENTE_UPDATE_INIT = '[EXPEDIENTE] EXPEDIENTE_UPDATE_INIT';
export const EXPEDIENTE_UPDATE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_UPDATE_SUCCESS';
export const EXPEDIENTE_UPDATE_ERROR = '[EXPEDIENTE] EXPEDIENTE_UPDATE_ERROR';
export const EXPEDIENTE_CLOSE_INIT = '[EXPEDIENTE] EXPEDIENTE_CLOSE_INIT';
export const EXPEDIENTE_CLOSE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_CLOSE_SUCCESS';
export const EXPEDIENTE_CLOSE_ERROR = '[EXPEDIENTE] EXPEDIENTE_CLOSE_ERROR';
export const EXPEDIENTE_SEARCH_INIT = '[EXPEDIENTE] EXPEDIENTE_SEARCH_INIT';
export const EXPEDIENTE_SEARCH_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_SEARCH_SUCCESS';
export const EXPEDIENTE_SEARCH_ERROR = '[EXPEDIENTE] EXPEDIENTE_SEARCH_ERROR';
export const EXPEDIENTE_DELETE_INIT = '[EXPEDIENTE] EXPEDIENTE_DELETE_INIT';
export const EXPEDIENTE_DELETE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_DELETE_SUCCESS';
export const EXPEDIENTE_DELETE_ERROR = '[EXPEDIENTE] EXPEDIENTE_DELETE_ERROR';
export const EXPEDIENTE_GETBYID_INIT = '[EXPEDIENTE] EXPEDIENTE_GETBYID_INIT';
export const EXPEDIENTE_GETBYID_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_GETBYID_SUCCESS';
export const EXPEDIENTE_GETBYID_ERROR = '[EXPEDIENTE] EXPEDIENTE_GETBYID_ERROR';
export const EXPEDIENTE_SELECTED = '[EXPEDIENTE] EXPEDIENTE_SELECTED';
export const DOMAINS_BY_TYPE = '[DOMAINS] DOMAINS_BY_TYPE';
export const EXPEDIENTE_CONTACT_DELETE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_CONTACT_DELETE_SUCCESS';
export const EXPEDIENTE_CONTACT_DELETE_ERROR = '[EXPEDIENTE] EXPEDIENTE_CONTACT_DELETE_ERROR';
export const EXPEDIENTE_CONTACT_SEARCH_INIT = '[EXPEDIENTE] EXPEDIENTE_CONTACT_SEARCH_INIT';
export const EXPEDIENTE_CONTACT_SEARCH_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_CONTACT_SEARCH_SUCCESS';
export const CONTACTS_SEARCH_INIT = '[MODAL_CONTACTS] CONTACTS_SEARCH_INIT';
export const CONTACTS_SEARCH_SUCCESS = '[MODAL_CONTACTS] CONTACTS_SEARCH_SUCCESS';
export const CONTACTS_SEARCH_ERROR = '[MODAL_CONTACTS] CONTACTS_SEARCH_ERROR';
export const ADD_CONTACT_TO_EXPEDIENTE_INIT = '[MODAL_CONTACTS] ADD_CONTACT_TO_EXPEDIENTE_INIT';
export const ADD_CONTACT_TO_EXPEDIENTE_SUCCESS = '[MODAL_CONTACTS] ADD_CONTACT_TO_EXPEDIENTE_SUCCESS';
export const ADD_CONTACT_TO_EXPEDIENTE_ERROR = '[MODAL_CONTACTS] ADD_CONTACT_TO_EXPEDIENTE_ERROR';
export const CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS = '[MODAL_CONTACTS] CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS';
export const ESPECIMENES_EXPEDIENTE_SEARCH_INIT = '[EXPEDIENTE] ESPECIMENES_EXPEDIENTE_SEARCH_INIT';
export const ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS = '[EXPEDIENTE] ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS';
export const ESPECIMENES_EXPEDIENTE_SEARCH_ERROR = '[EXPEDIENTE] ESPECIMENES_EXPEDIENTE_SEARCH_ERROR';
export const ESPECIMEN_GETBYID_INIT = '[MODAL_ESPECIMEN] ESPECIMEN_GETBYID_INIT';
export const ESPECIMEN_GETBYID_SUCCESS = '[MODAL_ESPECIMEN] ESPECIMEN_GETBYID_SUCCESS';
export const ESPECIMEN_GETBYID_ERROR = '[MODAL_ESPECIMEN] ESPECIMEN_GETBYID_ERROR';
export const EXPEDIENTE_RADICAR_INIT = '[EXPEDIENTE] EXPEDIENTE_RADICAR_INIT';
export const EXPEDIENTE_RADICAR_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_RADICAR_SUCCESS';
export const EXPEDIENTE_RADICAR_ERROR = '[EXPEDIENTE] EXPEDIENTE_RADICAR_ERROR';
export const ADD_SAME_ESPECIMEN_SUCCESS = '[EXPEDIENTE] ADD_SAME_ESPECIMEN_SUCCESS';
export const ADD_SAME_ESPECIMEN_ERROR = '[EXPEDIENTE] ADD_SAME_ESPECIMEN_ERROR';
export const DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS = '[EXPEDIENTE] DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS';
export const DELETE_ESPECIMEN_EXPEDIENTE_ERROR = '[EXPEDIENTE] DELETE_ESPECIMEN_EXPEDIENTE_ERROR';
export const SELECTED_FILE_SUCCESS = '[MODAL_FILE] SELECTED_FILE_SUCCESS';
export const SELECTED_FILE_ERROR = '[MODAL_FILE] SELECTED_FILE_ERROR';
export const FILE_CLEAR_SELECTED = '[MODAL_FILE] FILE_CLEAR_SELECTED';
export const SELECTED_IMAGE_SUCCESS = '[MODAL_IMAGE] SELECTED_IMAGE_SUCCESS';
export const SELECTED_IMAGE_ERROR = '[MODAL_IMAGE] SELECTED_IMAGE_ERROR';
export const IMAGE_CLEAR_SELECTED = '[MODAL_IMAGE] IMAGE_CLEAR_SELECTED';
export const EXPEDIENTE_CLEAR_SELECTED = '[EXPEDIENTE] EXPEDIENTE_CLEAR_SELECTED';
export const EXPEDIENTE_LOAD_SELECTED_INIT = '[EXPEDIENTE] EXPEDIENTE_LOAD_SELECTED_INIT';
export const EXPEDIENTE_LOAD_SELECTED_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_LOAD_SELECTED_SUCCESS';
export const EXPEDIENTE_LOAD_SELECTED_ERROR = '[EXPEDIENTE] EXPEDIENTE_LOAD_SELECTED_ERROR';
export const SELECTED_FILE_CLOSE_SUCCESS = '[MODAL_FILE_CLOSE] SELECTED_FILE_CLOSE_SUCCESS';
export const SELECTED_FILE_CLOSE_ERROR = '[MODAL_FILE_CLOSE] SELECTED_FILE_CLOSE_ERROR';
export const FILE_CLOSE_CLEAR_SELECTED = '[MODAL_FILE_CLOSE] FILE_CLOSE_CLEAR_SELECTED';
export const EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT = '[EXPEDIENTE] EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT';
export const EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS';
export const EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR = '[EXPEDIENTE] EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR';
export const EXPEDIENTE_FILES_ADD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_ADD_SUCCESS';
export const EXPEDIENTE_FILES_ADD_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_ADD_ERROR';
export const EXPEDIENTE_FILES_UPDATE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_UPDATE_SUCCESS';
export const EXPEDIENTE_FILES_UPDATE_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_UPDATE_ERROR';
export const EXPEDIENTE_FILES_DELETE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_DELETE_SUCCESS';
export const EXPEDIENTE_FILES_DELETE_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_DELETE_ERROR';
export const EXPEDIENTE_FILES_DOWNLOAD_INIT = '[EXPEDIENTE] EXPEDIENTE_FILES_DOWNLOAD_INIT';
export const EXPEDIENTE_FILES_DOWNLOAD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_DOWNLOAD_SUCCESS';
export const EXPEDIENTE_FILES_DOWNLOAD_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_DOWNLOAD_ERROR';
export const EXPEDIENTE_IMAGES_ADD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_IMAGES_ADD_SUCCESS';
export const EXPEDIENTE_IMAGES_ADD_ERROR = '[EXPEDIENTE] EXPEDIENTE_IMAGES_ADD_ERROR';
export const EXPEDIENTE_IMAGES_UPDATE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_IMAGES_UPDATE_SUCCESS';
export const EXPEDIENTE_IMAGES_UPDATE_ERROR = '[EXPEDIENTE] EXPEDIENTE_IMAGES_UPDATE_ERROR';
export const EXPEDIENTE_IMAGES_DELETE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_IMAGES_DELETE_SUCCESS';
export const EXPEDIENTE_IMAGES_DELETE_ERROR = '[EXPEDIENTE] EXPEDIENTE_IMAGES_DELETE_ERROR';
export const EXPEDIENTE_IMAGES_DOWNLOAD_INIT = '[EXPEDIENTE] EXPEDIENTE_IMAGES_DOWNLOAD_INIT';
export const EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS';
export const EXPEDIENTE_IMAGES_DOWNLOAD_ERROR = '[EXPEDIENTE] EXPEDIENTE_IMAGES_DOWNLOAD_ERROR';
export const EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS';
export const EXPEDIENTE_FILES_CLOSE_ADD_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_ADD_ERROR';
export const EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS';
export const EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR';
export const EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS';
export const EXPEDIENTE_FILES_CLOSE_DELETE_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_DELETE_ERROR';
export const EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT';
export const EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS';
export const EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR = '[EXPEDIENTE] EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR';
export const EXPEDIENTE_ESPECIMEN_UPDATE_INIT = '[MODAL_ESPECIMEN] EXPEDIENTE_ESPECIMEN_UPDATE_INIT';
export const EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS = '[MODAL_ESPECIMEN] EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS';
export const EXPEDIENTE_ESPECIMEN_UPDATE_ERROR = '[MODAL_ESPECIMEN] EXPEDIENTE_ESPECIMEN_UPDATE_ERROR';
