import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import useForm from 'react-hook-form';
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { pxToRem } from "../utils";
import { useLogin } from '../hooks';

/* Components */
import Page from "../components/Page";
import { CardTitleBox } from "../components/CardTitleBox";
import { Form } from "../components/form-ui-components/Form";
import { Input } from "../components/form-ui-components/Input";
import { Label } from "../components/form-ui-components/Label";
import { Button } from "../components/form-ui-components/Button";
import { H1 } from "../components/H1";
import { H5 } from "../components/H5";
import { StyledLink } from '../components/StyledLink';
import { SmallCard } from "../components/SmallCard";


/* Defined Constants */
import { wait } from '../config/const';
import {FormMessage} from "../components/form-ui-components/Messages";
import {GridContainer} from "../components/Grid";

/* Services */
const Reset = ({ history, match }) => {
  const { params } = match;
  const [tokenText, setTokenText] = useState('');
  const { isLoading, error, handleReset, response } = useLogin();
  const { register, handleSubmit, errors, watch } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    try {
      const obj = {
        token: tokenText,
        password: data.password,
        verify: data.verify,
      }
      handleReset(obj);
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const gotoLogin = useCallback(async () => {
    await wait(3000);
    history.push('/oauth/sign-in');
  }, [history]);


  useEffect(() => {
    if (typeof params.id !== 'undefined') setTokenText(params.id);
  }, [params]);

  return (
    <Page>
      <SmallCard title>
        <CardTitleBox>
          <H1>genera tu nueva contraseña</H1>
        </CardTitleBox>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>

          <Label>
            Contraseña:
            <Input
              name="password"
              type="password"
              placeholder="Escriba su contraseña"
              ref={register({ required: true })}
            />
            {errors.password && (
              <p style={{ color: 'red'}}>
                {'La contraseña es requerida!'}
              </p>
            )}
          </Label>
          <Label>
            Contraseña:
            <Input
              name="verify"
              type="password"
              placeholder="Repita su nueva contraseña"
              ref={register({ required: true, validate: value => value === watch('password') })}
            />
            {errors.verify && errors.verify.type === "required" && (
              <FormMessage error>
                {'La confirmación de contraseña es requerida!'}
              </FormMessage>
            )}
            {errors.verify && errors.verify.type === "validate" && (
              <FormMessage error>
                {'La confirmación de contraseña no coincide con la contraseña!'}
              </FormMessage>
            )}
          </Label>
          {isLoading ? (
            <SkeletonTheme color="#f42f6391" highlightColor="#444">
              <Skeleton height={34} />
            </SkeletonTheme>
          ) : (
            <Button type="submit">
              Guardar contraseña
            </Button>
          )}
          <br />
          {error && (
            <>
              <FormMessage error>
                <H5>Hubo un error: {response.message}</H5>
              </FormMessage>
              <br />
            </>
          )}
          {response && (
            <>
              <FormMessage>
                <H5>{response.message}</H5>
              </FormMessage>
              <br />
            </>
          )}
          <StyledLink center to="/oauth/sign-in">Volver al inicio</StyledLink>
          </GridContainer>
        </Form>
      </SmallCard>
    </Page>
  )
}

Reset.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

Reset.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(Reset);
