import React, {useEffect, useState} from "react";
import SideBarItem from "./SideBarItem";
import styled from "styled-components";
import {pxToRem} from "../utils";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${pxToRem(70)};
  left: 0;
  background-color: ${props => props.theme.sideBarBackground};
  height: 100%;
  width: ${pxToRem(42)};
  overflow: auto;
  @media (min-width: 600px) {
    width: ${pxToRem(250)};
  }
`;


const SideBar = ({items}) =>  (
    <SidebarContainer>
      {items.map((item, index) => (
        <SideBarItem {...item} key={index}/>
      ))}
    </SidebarContainer>
  )


export default SideBar;
