import React from 'react'
import Chart from 'react-google-charts';

const PieChart = ({statistics, title, width, height}) => {
  return (
    <div style={{padding: 0}}>
      <Chart
        width={width}
        height={height}
        chartType="PieChart"
        loader={<div>Cargando estadistica ...</div>}
        data={statistics}
        options={{
          chartArea: { width: '75%' },
          title: title,
          colors: [
            '#3366CC',
            '#6896FC',
            '#F42F63',
            '#e15a9d',
            '#CCCCCC',
            '#94c48f',
            '#4A4A4F',
            '#1B1B20',
            '#459D8E',
            '#E2ECED',
            '#286357',
            '#E6E6E6',
            '#3366CC',
            '#06616C',
            '#6896FC',
            '#323234',
            '#F08439',
            '#6369D1',
            '#5C9EAD',
            '#033860',
            '#8D8D92'
            ],
          legend: {
            position: 'right',
            textStyle: {
              fontName: 'Roboto',
              fontSize: 14,
              bold: false,
              italic: false }
          },
          fontName: 'Roboto',
          fontSize: 14,
          pieHole: 0.4
        }}
        rootProps={{'data-testid': '2'}}
      />
    </div>
  );
};

export default PieChart
