/* eslint-disable no-underscore-dangle */
import {
  login,
  success,
  failed,
  terminate,
  recover,
  recoverSuccess,
  recoverFailed,
  reset,
  resetSuccess,
  resetFailed,
  getUserSessionInit,
  getUserSessionSuccess,
  getUserSessionError
} from './actions';
import * as LoginServices from "../../services";
import {
  ADMINISTRADOR_SISTEMA,
  COORDINADOR_AUTORIDAD,
  COORDINADOR_ENTIDADES,
  COORDINADOR_EXPEDIENTE,
  SUPERUSUARIO
} from "../../config/const";

export const handleLogin = formData => {
  return async (dispatch) => {
    dispatch(login());
    try {
      const newForm = {
        ...formData,
        system: 'PIFS'
      };
      const data = await LoginServices.apiLogin.login(newForm);
      if (typeof data.token !== 'undefined') {
        const {token} = data;
        const {username} = formData;

        const buildStateData = async () => {
          const session = await LoginServices.apiLogin.me(token);
          let completeRols = [];
          let roles = [];
          let userMenu = [];
          if (typeof session === 'object' && typeof session.session === 'object') {
            if (Array.isArray(session.session.dataSession.roles)) {
              completeRols = session.session.dataSession.roles;
            }
            roles = Array.isArray(session.roles) ? session.roles : [];
          }

          let isRolAdministradorSistema = false
          let isRolCoordinadorExpedientes = false
          let isRolCoordinadorAutoridades = false
          let isRolCoordinadorEntidades =false

          var rolAdmin = await completeRols.filter(rol => {
            return rol.name === "ADMINISTRADOR DEL SISTEMA"
          })

          if (rolAdmin.length > 0) {
            userMenu = ADMINISTRADOR_SISTEMA
            isRolAdministradorSistema = true
          }

          var rolCoordinadorExpedientes = await completeRols.filter(rol => {
            return rol.name === "COORDINADOR DE EXPEDIENTES"
          })

          if (rolCoordinadorExpedientes.length > 0) {
            userMenu = COORDINADOR_EXPEDIENTE
            isRolCoordinadorExpedientes = true
          }

          var rolCoordinadorAutoridades = await completeRols.filter(rol => {
            return rol.name === "COORDINADOR DE AUTORIDAD AMBIENTAL"
          })

          if (rolCoordinadorAutoridades.length > 0) {
            userMenu = COORDINADOR_AUTORIDAD
            isRolCoordinadorAutoridades = true
          }

          var rolCoordinadorEntidades = await completeRols.filter(rol => {
            return rol.name === "COORDINADOR DE ENTIDADES DE DISPOSICIÓN"
          })

          if (rolCoordinadorEntidades.length > 0) {
            userMenu = COORDINADOR_ENTIDADES
            isRolCoordinadorEntidades = true
          }

          if (isRolAdministradorSistema && isRolCoordinadorExpedientes && isRolCoordinadorAutoridades && isRolCoordinadorEntidades) {
            userMenu = SUPERUSUARIO
            isRolCoordinadorEntidades = true
          }

          return {
            isRolAdministradorSistema,
            isRolCoordinadorExpedientes,
            isRolCoordinadorAutoridades,
            isRolCoordinadorEntidades,
            userMenu,
            permissions: completeRols,
            roles,
          }
        };

        const newData = await buildStateData();

        const {isRolAdministradorSistema, isRolCoordinadorExpedientes, isRolCoordinadorAutoridades, isRolCoordinadorEntidades,userMenu, permissions, roles} = newData;

        const newObj = {
          isRolAdministradorSistema,
          isRolCoordinadorExpedientes,
          isRolCoordinadorAutoridades,
          isRolCoordinadorEntidades,
          userMenu,
          username,
          token,
          permissions,
          roles
        };
        dispatch(success(newObj));
      } else {
        dispatch(failed(data));
      }
    } catch (error) {
      dispatch(failed('Se genero un error al autenticarse por favor consulte al administrador!'));
    }
  };
};

export const handleLogout = () => {
  return async (dispatch) => {
    try {
      const obj = {};
      const data = await LoginServices.apiLogin.logout(obj);
      dispatch(terminate());
    } catch (error) {
      dispatch(failed(error.response.data));
    }
  };
};

export const handleRecover = formData => {
  const newForm = {
    ...formData,
    system: 'PIFS'
  };
  return async (dispatch) => {
    dispatch(recover());
    try {
      const data = await LoginServices.apiLogin.recovery(newForm);
      if (typeof data.message !== 'undefined') {
        dispatch(recoverSuccess(data));
      } else {
        dispatch(recoverFailed(data));
      }
    } catch (error) {
      dispatch(recoverFailed('Se genero un error al recuperar la contraseña por favor consulte al administrador!'));
    }
  };
};

export const handleReset = formData => {
  return async (dispatch) => {
    dispatch(reset());
    try {
      const data = await LoginServices.apiLogin.password(formData);

      if (typeof data !== 'undefined') {
        dispatch(resetSuccess(data));
      } else if (typeof data.error !== 'undefined') {
        dispatch(resetFailed(data));
      }
    } catch (error) {
      dispatch(resetFailed(error.response.data));
    }
  };
};

export const getUserSession =  (token) =>{
  return async (dispatch) =>{
    dispatch(getUserSessionInit())

    const buildStateData = async () => {
      const session = await LoginServices.apiLogin.me(token);
      let completeRols = [];
      let roles = [];
      let userMenu = [];
      if (typeof session === 'object' && typeof session.session === 'object') {
        if (Array.isArray(session.session.dataSession.roles)) {
          completeRols = session.session.dataSession.roles;
        }
        roles = Array.isArray(session.roles) ? session.roles : [];
      }

      let isRolAdministradorSistema = false
      let isRolCoordinadorExpedientes = false
      let isRolCoordinadorAutoridades = false
      let isRolCoordinadorEntidades =false

      var rolAdmin = await completeRols.filter(rol => {
        return rol.name === "ADMINISTRADOR DEL SISTEMA"
      })

      if (rolAdmin.length > 0) {
        userMenu = ADMINISTRADOR_SISTEMA
        isRolAdministradorSistema = true
      }

      var rolCoordinadorExpedientes = await completeRols.filter(rol => {
        return rol.name === "COORDINADOR DE EXPEDIENTES"
      })

      if (rolCoordinadorExpedientes.length > 0) {
        userMenu = COORDINADOR_EXPEDIENTE
        isRolCoordinadorExpedientes = true
      }

      var rolCoordinadorAutoridades = await completeRols.filter(rol => {
        return rol.name === "COORDINADOR DE AUTORIDAD AMBIENTAL"
      })

      if (rolCoordinadorAutoridades.length > 0) {
        userMenu = COORDINADOR_AUTORIDAD
        isRolCoordinadorAutoridades = true
      }

      var rolCoordinadorEntidades = await completeRols.filter(rol => {
        return rol.name === "COORDINADOR DE ENTIDADES DE DISPOSICIÓN"
      })

      if (rolCoordinadorEntidades.length > 0) {
        userMenu = COORDINADOR_ENTIDADES
        isRolCoordinadorEntidades = true
      }

      if (isRolAdministradorSistema && isRolCoordinadorExpedientes && isRolCoordinadorAutoridades && isRolCoordinadorEntidades) {
        userMenu = SUPERUSUARIO
        isRolCoordinadorEntidades = true
      }

      let username = session.username

      return {
        isRolAdministradorSistema,
        isRolCoordinadorExpedientes,
        isRolCoordinadorAutoridades,
        isRolCoordinadorEntidades,
        userMenu,
        username,
        permissions: completeRols,
        roles,
      }
    };

    const updatedData = await buildStateData();
    try{
      const {username, isRolAdministradorSistema, isRolCoordinadorExpedientes, isRolCoordinadorAutoridades, isRolCoordinadorEntidades,userMenu, permissions, roles} = updatedData;
      const newObj = {
        isRolAdministradorSistema,
        isRolCoordinadorExpedientes,
        isRolCoordinadorAutoridades,
        isRolCoordinadorEntidades,
        username,
        userMenu,
        token,
        permissions,
        roles
      };
      dispatch(success(newObj));
    } catch (error) {
      dispatch(getUserSessionError(error.message));
    }
  };
};
