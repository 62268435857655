import styled from "styled-components";
import { pxToRem } from "../../utils";

export const FormMessageDeprecated = styled.span`
  display: inline-block;
  color: ${props => props.error ? props.theme.primaryColor : props.theme.colors.darkgray};
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: ${props => props.theme.white};
  padding: ${pxToRem(4)} ${pxToRem(26)};
  max-width: 100%;
  font-size: ${pxToRem(12)};
  h1, h2, h3, h4, h5, h6, p ,span {
    font-size: ${pxToRem(12)};
    font-weight: 300;
  }
  &.error {
    margin-left: ${pxToRem(-34)} !important;
    width: auto;
  }
`;


export const FormMessage = styled.span`
  display: block;
  color: ${props => props.error ? props.theme.primaryColor : props.theme.colors.darkgray};
  border: 1px solid ${props => props.theme.primaryColor};
  padding: ${pxToRem(2)} ${pxToRem(5)};
  max-width: 100%;
  margin-top: 5px;
  font-size: ${pxToRem(12)};
  h1, h2, h3, h4, h5, h6, p ,span {
    font-size: ${pxToRem(12)};
    font-weight: 300;
  }
  
`;
