/* eslint-disable no-underscore-dangle */
import {
  deleteContactA,
  deleteSuccess,
  deleteFailed,
  createContactA,
  createSuccess,
  createFailed,
  clearSelectedA,
  loadSelectedA,
  loadSelectedSuccess,
  loadSelectedFailed,
  searchContactsA,
  searchSuccess,
  searchFailed,
  changeModeSuccess,
  updateInit,
  updateSuccess,
  updateFailed
} from "./actions";
import * as ContactsServices from "../../services";
import { domainTypesByKey } from '../../config/const';

export const createContact = (inputData, token) => {
  return async dispatch => {
    dispatch(createContactA());
    try {
      const data = await ContactsServices.apiContacts.create(inputData, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(createSuccess(data.data));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(createFailed('Se genero un error creando el contacto por favor contacte al administrador!'));
      return { msg: 'Se genero un error creando el contacto por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(createFailed(error));
      return { msg: 'no se pudo crear el contacto', err: true };
    }
  };
};

export const updateContact = (inputData, idEntidad, token) => {
  return async dispatch => {
    dispatch(updateInit());
    try {
      const data = await ContactsServices.apiContacts.update(inputData, idEntidad, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(updateSuccess(data.data));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(updateFailed('Se genero un error actualizando el contacto por favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando el contacto por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(updateFailed(error));
      return { msg: 'no se pudo actualizar el contacto', err: true };
    }
  };
};

export const deleteContact = (contact, id, token) => {
  return async dispatch => {
    dispatch(deleteContactA());
    try {
      const data = await ContactsServices.apiContacts.delete(contact, id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(deleteSuccess(data));
        return { msg: 'contacto desactivado', err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(deleteFailed('Se genero un error desactivando el contacto por favor contacte al administrador!'));
      return { msg: 'Se genero un error desactivando el contacto por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(deleteFailed(error));
      return { msg: "no se puedo desactivar", err: true };
    }
  };
};

export const getAllContacts = (inputData={}, token) => {
  return async dispatch => {
    dispatch(searchContactsA());
    try {
      const data = await ContactsServices.apiContacts.list(token);
      dispatch(searchSuccess(data));
    } catch (error) {
      dispatch(searchFailed(error));
    }
  };
};

export const getAllContactsSearch = (inputData={}, token) => {
  return async dispatch => {
    dispatch(searchContactsA());
    try {
      const data = await ContactsServices.apiContacts.search(inputData, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {

        const anAsyncFunction = async contact => {
          // const autoridad = contact.autoridad_ambiental ? await ContactsServices.apiAutoridadAmbiental.get(contact.autoridad_ambiental, token) : '';
          // const autoridadName = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          // const autoridadTipo = (typeof contact.autoridad_ambiental === 'object' && typeof contact.autoridad_ambiental.tipo ) ? await ContactsServices.apiUser.getByDomainIdsTypes(autoridad.data.tipo._id, domainTypesByKey.tiposAutoridadAmbiental, token) : '';
          // const domainName = (typeof autoridadTipo === 'object' && autoridadTipo.data.length === 1) ? autoridadTipo.data[0].domainName : '';
          return {
            ...contact,
            autoridad_ambiental: { _id: contact.autoridad_ambiental._id, nombre: contact.autoridad_ambiental.nombre, tipo: '' },
            tipo_entidad_disposicion: { _id: contact.tipo_entidad_disposicion._id, domainName: contact.tipo_entidad_disposicion.domainName }
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(contact => anAsyncFunction(contact)))
        }

        getData().then(newData => {
          dispatch(searchSuccess(newData));
        })
      } else {
        dispatch(searchFailed('Se genero un error cargando los contactos por favor contacte al administrador!'));
      }
    } catch (error) {
      dispatch(searchFailed(error));
    }
  };
};

export const changeMode = mode => {
  return async dispatch => {
    try {
      dispatch(changeModeSuccess(mode));
    } catch (error) {
      dispatch(searchFailed('Se genero un error al cargar la información por favor consulte al administrador!'));
    }
  };
};

export const loadContactSelected = (id, mode, token) => {
  return async dispatch => {
    dispatch(loadSelectedA());
    try {
      const data = await ContactsServices.apiContacts.get(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const autoridad = data.data.autoridad_ambiental ? await ContactsServices.apiAutoridadAmbiental.get(data.data.autoridad_ambiental, token) : '';
          const autoridadName = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          return {
            ...data.data,
            autoridad_ambiental: { _id: data.data.autoridad_ambiental, nombre: autoridadName },
          };
        };
        const newData = await anAsyncFunction();
        dispatch(loadSelectedSuccess(newData, mode));
      } else {
        dispatch(loadSelectedFailed('Se genero un error cargando el contacto por favor contacte al administrador!'));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(clearSelectedA());
  };
};

export default createContact;
