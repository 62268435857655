import styled from "styled-components";
import { pxToRem } from "../utils";

export const CardTitleBox = styled.span`
  display: inline-block;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.secondaryColor};
  padding: ${pxToRem(4)} ${pxToRem(26)};
  position: ${props => props.error ? '' : 'absolute'};
  left: 0;
  top: ${pxToRem(16)};
  max-width: 100%;
  h1, h2, h3, h4, h5, h6, p ,span {
    font-size: ${pxToRem(16)};
    line-height: 1;
    font-weight: 300;
  }
  &.error {
    margin-left: ${pxToRem(-28)} !important;
    width: auto;
  }
`;
