import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useLogin, useDomains } from '../hooks';

import Page from "../components/Page";
import { Card } from "../components/Card";
import { H1 } from "../components/H1";
import { PageHeader } from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";
import { FileWidget } from "../components/FileWidget";
import { CardTitleBox } from "../components/CardTitleBox";
import { H5 } from "../components/H5";
import {GridContainer, Row} from "../components/Grid";
import ErrorBoundary from "../components/ErrorBoundary";

/* Style Components */
import { CardContainerBody } from './styled';

const DomainsPage = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const { isLoggedIn, token } = useLogin();
  const { getAllDomains, alldomains, error, isLoading } = useDomains();

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const load = useCallback(async () => {
    await getAllDomains(token);
  }, [
    getAllDomains
  ]);

  useEffect(() => {
    if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if(!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, gotoLogin, load]);

  const columns = [
    { Header: "Id", accessor: "_id" },
    { Header: "Nombre dominio padre", accessor: "domainParent" },
    { Header: "Tipo", accessor: "domainType" },
    { Header: "Nombre", accessor: "domainName" },
    {
      Header: "Estado",
      accessor: d => {
        return d.state ? "activo" : "inactivo";
      }
    }
  ];

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        <PageHeader>
          <H1>Dominios</H1>
        </PageHeader>
        <Card>
          <GridContainer>
            <Row>
              <FileWidget fileName="domain_file" downloadFileName="dominios"/>
            </Row>
            <Row>
            {isLoading ? (
              <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
                <div>Cargando lista, un momento por favor...</div>
                <SkeletonTheme color="#f42f6391" highlightColor="#444">
                  <Skeleton height={34}/>
                </SkeletonTheme>
              </div>
            ) : (
              <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                <CardContainerBody>
                  <div className="card-body-row">
                    <PaginationTable columns={columns} data={alldomains} />
                  </div>
                </CardContainerBody>
                {error && (
                  <CardContainerBody>
                    <CardTitleBox className="error" error>
                      <H5>Ups, hubo un error: {error}</H5>
                    </CardTitleBox>
                  </CardContainerBody>
                )}
              </div>
            )}
            </Row>
          </GridContainer>
        </Card>
      </Page>
    </ErrorBoundary>
  );
};

DomainsPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

DomainsPage.defaultProps = {
  history: {},
};

export default withRouter(DomainsPage);
