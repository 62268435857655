/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from "react";

import styled from "styled-components";
import { pxToRem } from "../../utils";

const StyledOption = styled.option`
`;

export const SelectOptions = ({ selectOptions }) => (
  <>
    <StyledOption value=""> Seleccione... </StyledOption>
    {selectOptions &&
      selectOptions.length > 0 &&
      selectOptions.map(({ _id, domainName, constantName, nombre, name }) => (
        <StyledOption key={_id} value={_id}>
          {domainName || constantName || nombre || name}
        </StyledOption>
      ))
    }
  </>
);
