/* eslint-disable no-underscore-dangle */
import {
  deleteAutoridadAmbientalA,
  deleteSuccess,
  deleteFailed,
  createAutoridadAmbientalA,
  createSuccess,
  createFailed,
  updateAutoridadAmbientalA,
  updateSuccess,
  updateFailed,
  clearSelectedA,
  loadSelectedA,
  loadSelectedSuccess,
  loadSelectedFailed,
  searchAutoridadAmbientalA,
  searchSuccess,
  searchFailed,
  changeModeSuccess
} from "./actions";
import * as AutoridadAmbientalServices from "../../services";
import { domainTypesByKey } from '../../config/const';

export const createAutoridadAmbiental = (inputData, token) => {
  return async dispatch => {
    dispatch(createAutoridadAmbientalA());
    try {
      const data = await AutoridadAmbientalServices.apiAutoridadAmbiental.create(inputData, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(createSuccess(data.data));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(createFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(createFailed('Se genero un error creando la autoridad ambiental por favor contacte al administrador!'));
      return { msg: 'Se genero un error creando la autoridad ambiental por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(createFailed(error));
      return { msg: "no se pudo crear autoridad ambiental", err: true };
    }
  };
};

export const updateAutoridadAmbiental = (inputData, idAutoridad, token) => {
  return async dispatch => {
    dispatch(updateAutoridadAmbientalA());
    try {
      const data = await AutoridadAmbientalServices.apiAutoridadAmbiental.update(inputData, idAutoridad, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(updateSuccess(data.data));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(updateFailed('Se genero un error actualizando la autoridad ambiental por favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando la autoridad ambiental por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(updateFailed(error));
      return { msg: `no se pudo actualizar autoridad ambiental`, err: true };
    }
  };
};


export const deleteAutoridadAmbiental = (autoridad, id, token) => {
  return async dispatch => {
    dispatch(deleteAutoridadAmbientalA());
    try {
      const data = await AutoridadAmbientalServices.apiAutoridadAmbiental.delete(autoridad, id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(deleteSuccess(data.data));
        return { msg: 'Se desactivo la autoridad ambiental', err: false };
      }
      dispatch(deleteFailed('Se genero un error desactivando la autoridad ambiental por favor contacte al administrador!'));
      return { msg: 'Se genero un error desactivando la autoridad ambiental por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(deleteFailed(error));
      return { msg: "no se puedo desactivar", err: true };
    }
  };
};

export const searchAutoridadAmbiental = (inputData={}, token) => {
  return async dispatch => {
    dispatch(searchAutoridadAmbientalA());
    try {
      const data = await AutoridadAmbientalServices.apiAutoridadAmbiental.search(inputData, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async autoridad => {
          const autoridadTipo = autoridad.tipo ? await AutoridadAmbientalServices.apiUser.getByDomainIdsTypes(autoridad.tipo._id, domainTypesByKey.tiposAutoridadAmbiental, token) : '';
          const domainName = (typeof autoridadTipo === 'object' && Array.isArray(autoridadTipo.data) && autoridadTipo.data.length > 0) ? autoridadTipo.data[0].domainName : '';
          return {
            ...autoridad,
            tipo: { _id: autoridad.tipo._id, domainName },
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(autoridad => anAsyncFunction(autoridad)))
        }

        getData().then(newData => {
          dispatch(searchSuccess(newData));
          return { msg: '', err: false };
        })
      } else if(typeof data === 'string') {
        dispatch(searchFailed(data));
        return { msg: `${data}`, err: true };
      }
    } catch (error) {
      dispatch(searchFailed(error));
      return { msg: "no se puedo cargar", err: true };
    }
  };
};

export const changeMode = mode => {
  return async dispatch => {
    try {
      dispatch(changeModeSuccess(mode));
    } catch (error) {
      dispatch(searchFailed('Se genero un error al cargar la información por favor consulte al administrador!'));
    }
  };
};

export const loadSelected = (id, mode, token) => {
  return async dispatch => {
    dispatch(loadSelectedA());
    try {
      const data = await AutoridadAmbientalServices.apiAutoridadAmbiental.get(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = () => {
          return {
            ...data.data
          };
        };
        const newData = anAsyncFunction();
        dispatch(loadSelectedSuccess(newData, mode));
      } else {
        dispatch(loadSelectedFailed('Se genero un error cargando la autoridad ambiental por favor contacte al administrador!'));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(clearSelectedA());
  };
};

export default createAutoridadAmbiental;
