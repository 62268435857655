/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { pxToRem } from "../../utils";

export const Textarea = styled.textarea`
  display: inline-block;
  padding-left: ${pxToRem(16)};
  width: "auto";
  margin-left: ${pxToRem(8)};
  align-self: start;
`;
