/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import {withRouter} from "react-router-dom";
import * as yup from "yup";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import Page from "../components/Page";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {PageHeader} from "../components/PageHeader";
import ErrorBoundary from '../components/ErrorBoundary';
import {Button, ButtonSecondary} from "../components/form-ui-components/Button";
// import {ButtonLight} from "../components/form-ui-components/ButtonLight";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";

import {
    useAutoridadAmbiental,
    useContact,
    useDomains,
    useConstants,
    useNotification,
    useLogin
} from "../hooks";
/* Style Components */
import {SpanTextInfo, SpanDivide, SpanView} from './styled';
import {MapWithGeocoderControl} from "../components/form-ui-components/MapWithGeocoderControl";
import {Col, Col1, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";

import {removeEmpty} from '../utils';

const ContactsForm = ({history, match}) => {
    const [loaded, setLoaded] = useState(false);
    const [municipiosFiltrados, setMunicipiosFiltrados] = useState([]);
    const [reverseGeocodingResult, setResult] = useState();
    const [loadedInfo, setLoadedInfo] = useState(false);
    const {
        data: autoridadesAmbientales,
        searchAutoridadAmbiental
    } = useAutoridadAmbiental();
    const {domainsByKey, getOptionsByDomainTypes} = useDomains();
    const {constantsByKey, getConstantOptionsByTypes} = useConstants();
    const {isLoggedIn, token} = useLogin();
    const {
        selected,
        mode,
        createContact,
        updateContact,
        loadContactSelected,
        clearSelected
    } = useContact();
    const {createNotification} = useNotification();

    const schema = yup.object().shape({});

    const searchOption = (val, datalist) => {
        if (!datalist) {
            return null;
        }
        for (let j = 0; j < datalist.length; j++) {
            if (val === datalist[j].constantName) {
                return datalist[j];
            }
        }
    };

    const {setValue, register, handleSubmit, errors, reset} = useForm();

    const handleChangeDpto = e => {
        e.preventDefault();
        const {value} = e.target;
        const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(value));
        setMunicipiosFiltrados(newData);
    };

    const handleClose = async e => {
        e.preventDefault();
        reset();
        await clearSelected();
        history.push('/contacts-search');
        e.stopPropagation();
    };

    const gotoLogin = useCallback(() => {
        history.push('/');
    }, [history]);

    const clearContacto = useCallback(async () => {
        window.scrollTo(0, 0);
        await clearSelected();
        reset();
        setLoadedInfo(true);
    }, [history]);

    const gotoContacto = useCallback(async () => {
        await clearSelected();
        history.push('/contacts-search');
    }, [history]);

    const gotoContactoCrear = useCallback(async () => {
        await clearSelected();
        history.push('/contacts-form');
    }, [history]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        try {
            const newData = {
                ...data,
                observaciones: data.observaciones.length > 0 ? data.observaciones : "no hay observaciones",
                tipo_entidad_disposicion: data.tipo_entidad_disposicion != null ? Number(data.tipo_entidad_disposicion) : "",
                tipo_documento: data.tipo_documento ? Number(data.tipo_documento) : null,
                pais: data.pais ? Number(data.pais) : null,
                departamento: data.departamento ? Number(data.departamento) : null,
                municipio: data.municipio ? Number(data.municipio) : null,
            };

            let isError = false;
            if (selected !== null) {
                const {msg, err} = await updateContact(removeEmpty(newData), selected._id, token);
                isError = err;
                createNotification({
                    message: msg,
                    error: err,
                    visible: true
                });
            } else {
                const {msg, err} = await createContact(removeEmpty(newData), token);
                isError = err;
                createNotification({
                    message: msg,
                    error: err,
                    visible: true
                });
            }

            if (!isError) {
                reset();
                await gotoContacto();
            }
        } catch (err) {
            console.log(err);
        }
        e.stopPropagation();
    };

    const loadMunicipioSelected = useCallback(() => {
        if (selected && municipiosFiltrados.length > 0) {
            setValue("municipio", selected.municipio._id);
        }
    }, [municipiosFiltrados, selected]);

    const loadSelected = useCallback(() => {
        if (selected) {
            setValue("nombre", selected.nombre);
            setValue("autoridad_ambiental", selected.autoridad_ambiental._id);
            setValue("tipo_entidad_disposicion", selected.tipo_entidad_disposicion._id);
            setValue("tipo_documento", selected.tipo_documento._id);
            setValue("numero_documento", selected.numero_documento);
            setValue("cargo", selected.cargo);
            setValue("estado", selected.estado);
            setValue("pais", selected.pais._id);
            setValue("departamento", selected.departamento._id);
            setValue("direccion", selected.direccion);
            setValue("telefono_movil", selected.telefono_movil);
            setValue("telefono_fijo", selected.telefono_fijo);
            setValue("correo_electronico", selected.correo_electronico);
            setValue("observaciones", selected.observaciones);

            const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(selected.departamento._id));
            setMunicipiosFiltrados(newData);
        }
    }, [setValue, selected]);

    const load = useCallback(async () => {
        window.scrollTo(0, 0);
        await Promise.all([clearSelected(), searchAutoridadAmbiental({}, token), getOptionsByDomainTypes(["tiposEntidadDisposicion"]), getConstantOptionsByTypes(["tiposDocumento", "paises", "departamentos", "municipios"])]);
        if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
            await loadContactSelected(match.params.id, match.params.mode, token);
            if (match.params.mode === 'edit') loadSelected();
        } else {
            setValue("pais", 3000001);
        }
        setLoadedInfo(true);
    }, [
        searchAutoridadAmbiental,
        getConstantOptionsByTypes,
        getOptionsByDomainTypes,
        loadContactSelected,
        loadSelected,
        clearSelected,
        match.params.id,
        match.params.mode
    ]);

    useEffect(() => {
        if (!isLoggedIn) {
            gotoLogin();
        } else if (isLoggedIn && !loaded) {
            load();
            setLoaded(true);
        }
    }, [isLoggedIn, loaded, load, gotoLogin]);

    useEffect(() => {
        loadSelected();
    }, [selected]);

    useEffect(() => {
        loadMunicipioSelected();
    }, [municipiosFiltrados]);

    useEffect(() => {
        if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
            clearContacto();
        }
    }, [clearContacto, match.params.id, match.params.mode]);

    return (
        <ErrorBoundary>
            <Page useSideBarSpace>
                {isLoggedIn && (
                    <>
                        {!loadedInfo && (
                            <>
                                <br/>
                                <div>Cargando información por favor espere un momento...</div>
                                <div>
                                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                                        <Skeleton height={34}/>
                                    </SkeletonTheme>
                                </div>
                            </>
                        )}
                        <PageHeader>
                            <H1>{mode === 'view' ? ('Ver Contacto') : (mode === 'edit' ? ('Modificar Contacto') : ('Crear Contacto'))}</H1>
                        </PageHeader>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Card title=" ">
                                <CardTitleBox>
                                    <H5>Datos Generales</H5>
                                </CardTitleBox>
                                <GridContainer>
                                    <Row>
                                        <Col3>
                                            <Label>
                                                AUTORIDAD AMBIENTAL <span className="obligatory">*</span>
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="autoridad_ambiental"
                                                                ref={register({required: true})}>
                                                            <SelectOptions selectOptions={autoridadesAmbientales}/>
                                                        </Select>
                                                        {errors.autoridad_ambiental && errors.autoridad_ambiental.type === "required" && (
                                                            <p style={{color: 'red'}}>
                                                                La autoridad ambiental es requerida!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.autoridad_ambiental.nombre}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                TIPO DE ENTIDAD DE DISPOSICIÓN
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="tipo_entidad_disposicion" ref={register}>
                                                            <SelectOptions
                                                                selectOptions={domainsByKey.tiposEntidadDisposicion}/>
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.tipo_entidad_disposicion.domainName}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                NOMBRE DEL CONTACTO <span className="obligatory">*</span>
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="nombre"
                                                            type="text"
                                                            ref={register({required: true, maxLength: 50})}
                                                            placeholder="Escriba el nombre del contacto"
                                                        />
                                                        {errors.nombre && errors.nombre.type === "required" && (
                                                            <p style={{color: 'red'}}>
                                                                El nombre del contacto es requerido!
                                                            </p>
                                                        )}
                                                        {errors.nombre && errors.nombre.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                Al nombre le sobran caracteres máximo (50)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.nombre}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                    </Row>
                                    <Row>
                                        <Col3>
                                            <Label>
                                                TIPO DE DOCUMENTO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="tipo_documento" ref={register}>
                                                            <SelectOptions
                                                                selectOptions={constantsByKey.tiposDocumento}/>
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.tipo_documento.constantName}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                NÚMERO DE DOCUMENTO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="numero_documento"
                                                            type="text"
                                                            ref={register({maxLength: 100})}
                                                            placeholder="ingrese el número de identificación"
                                                        />
                                                        {errors.numero_documento && errors.numero_documento.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                El número de documento le sobran caracteres máximo
                                                                (100)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.numero_documento}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                CARGO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="cargo"
                                                            type="text"
                                                            ref={register({minLength: 5, maxLength: 30})}
                                                            placeholder="ingrese el cargo del contacto"
                                                        />
                                                        {errors.cargo && errors.cargo.type === "minLength" && (
                                                            <p style={{color: 'red'}}>
                                                                El cargo le falta caracteres mínimo (5)!
                                                            </p>
                                                        )}
                                                        {errors.cargo && errors.cargo.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                El cargo le sobran caracteres máximo (30)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.cargo}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                    </Row>
                                    <Row>
                                        <Col3>
                                            <Label>
                                                CORREO ELECTRÓNICO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="correo_electronico"
                                                            type="email"
                                                            ref={register({maxLength: 50})}
                                                            placeholder="ingrese el correo del contacto"
                                                        />
                                                        {errors.correo_electronico && errors.correo_electronico.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                El correo del contacto le sobran caracteres máximo (50)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.correo_electronico}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                ESTADO <span className="obligatory">*</span>
                                                {mode !== 'view' ? (
                                                    <>
                                                        Activa: <Input name="estado" type="checkbox" ref={register}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.estado ? 'Activo' : 'Inactivo'}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                    </Row>
                                    <Row>
                                        <Col1>
                                            <Label>
                                                OBSERVACIONES GENERALES
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="observaciones"
                                                            type="text"
                                                            ref={register({minLength: 5, maxLength: 500})}
                                                            placeholder="Ingrese las observaciones sobre el contacto"
                                                        />
                                                        {errors.observaciones && errors.observaciones.type === "minLength" && (
                                                            <p style={{color: 'red'}}>
                                                                Las observaciones del contacto le falta caracteres
                                                                mínimo (5)!
                                                            </p>
                                                        )}
                                                        {errors.observaciones && errors.observaciones.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                Las observaciones del contacto le sobran caracteres
                                                                máximo (500)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.observaciones}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col1>
                                    </Row>
                                    <Row>
                                        <Col1>
                                            <SpanDivide>
                                                <br/>
                                                Datos de ubicación del contacto.
                                            </SpanDivide>
                                        </Col1>
                                    </Row>
                                    <Row>
                                        <Col3>
                                            <Label>
                                                PAÍS
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="pais" ref={register}>
                                                            <SelectOptions selectOptions={constantsByKey.paises}/>
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.pais.constantName}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                DEPARTAMENTO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="departamento" onChange={handleChangeDpto}
                                                                ref={register}>
                                                            <SelectOptions
                                                                selectOptions={constantsByKey.departamentos}/>
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.departamento.constantName}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                CIUDAD O MUNICIPIO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Select name="municipio" ref={register}>
                                                            <SelectOptions selectOptions={municipiosFiltrados}/>
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.municipio.constantName}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                    </Row>
                                    <Row>
                                        <Col3>
                                            <Label>
                                                DIRECCIÓN <span className="obligatory">*</span>
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="direccion"
                                                            type="text"
                                                            ref={register({minLength: 5, maxLength: 100})}
                                                            placeholder="Ingrese dirección de la autoridad."
                                                        />
                                                        {errors.direccion && errors.direccion.type === "minLength" && (
                                                            <p style={{color: 'red'}}>
                                                                La dirección le falta caracteres mínimo (5)!
                                                            </p>
                                                        )}
                                                        {errors.direccion && errors.direccion.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                La dirección le sobran caracteres máximo (100)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.direccion}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                TELÉFONO FIJO
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="telefono_fijo"
                                                            type="tel"
                                                            ref={register({minLength: 7, maxLength: 20})}
                                                            placeholder="ex.: +57 1 123 7777"
                                                        />
                                                        {errors.telefono_fijo && errors.telefono_fijo.type === "minLength" && (
                                                            <p style={{color: 'red'}}>
                                                                EL teléfono fijo le falta dígitos mínimo (7)!
                                                            </p>
                                                        )}
                                                        {errors.telefono_fijo && errors.telefono_fijo.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                EL teléfono fijo le dígitos máximo (20)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.telefono_fijo}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                        <Col3>
                                            <Label>
                                                TELÉFONO MÓVIL
                                                {mode !== 'view' ? (
                                                    <>
                                                        <Input
                                                            name="telefono_movil"
                                                            type="tel"
                                                            ref={register({minLength: 7, maxLength: 20})}
                                                            placeholder="ex.: 310 777 1234"
                                                        />
                                                        {errors.telefono_movil && errors.telefono_movil.type === "minLength" && (
                                                            <p style={{color: 'red'}}>
                                                                EL teléfono móvil le falta dígitos mínimo (7)!
                                                            </p>
                                                        )}
                                                        {errors.telefono_movil && errors.telefono_movil.type === "maxLength" && (
                                                            <p style={{color: 'red'}}>
                                                                EL teléfono móvil le sobran dígitos máximo (20)!
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <br/><br/><SpanView>{selected.telefono_movil}</SpanView>
                                                    </>
                                                )}
                                            </Label>
                                        </Col3>
                                    </Row>
                                    <Row style={{justifyContent: 'flex-end'}}>
                                        <Col right>
                                            {mode !== 'view' ? (
                                                <>
                                                    <Label style={{width: 'auto'}}>
                                                        <Button type="button" onClick={(e) => handleClose(e)}>
                                                            CANCELAR
                                                        </Button>
                                                    </Label>
                                                    <Label style={{width: 'auto'}}>
                                                        <Button type="submit">
                                                            {mode === 'edit' ? ('ACTUALIZAR') : ('CREAR')}
                                                        </Button>
                                                    </Label>
                                                </>
                                            ) : (
                                                <>
                                                    <Label style={{width: 'auto'}}>
                                                        <Button type="button" onClick={(e) => handleClose(e)}>
                                                            CANCELAR
                                                        </Button>
                                                    </Label>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </GridContainer>
                            </Card>
                        </Form>
                    </>
                )}
            </Page>
        </ErrorBoundary>
    );
};

ContactsForm.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
};

ContactsForm.defaultProps = {
    history: {},
    match: {},
};

export default withRouter(ContactsForm);
