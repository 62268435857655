import {useGlobalStore} from '../../store';
import bindActions from '../../store/bindActions';
import autoridadAmbientalReducer from '../../store/autoridadAmbiental';

const {dispatchers} = autoridadAmbientalReducer;

const useAutoridadAmbiental = () => {
    const {state, dispatch} = useGlobalStore();

    // List of Props
    const {autoridadAmbiental} = state;

    // List of Dispatchers
    const {
        createAutoridadAmbiental,
        updateAutoridadAmbiental,
        deleteAutoridadAmbiental,
        loadSelected,
        clearSelected,
        searchAutoridadAmbiental,
        changeMode
    } = dispatchers;

    // Bind Actions
    const actions = bindActions({
        createAutoridadAmbiental,
        updateAutoridadAmbiental,
        deleteAutoridadAmbiental,
        loadSelected,
        clearSelected,
        searchAutoridadAmbiental,
        changeMode
    }, dispatch);

    return {...autoridadAmbiental, ...actions};
};

export default useAutoridadAmbiental;
