/* eslint-disable no-underscore-dangle */
import {
  searchTablaInit,
  searchTablaSuccess,
  searchTablaError,
  searchGeovisorInit,
  searchGeovisorSuccess,
  searchGeovisorError,
  descargarReporteInit,
  descargarReporteSuccess,
  descargarReporteError,
  searchEstadisticasInit,
  searchEstadisticasSuccess,
  searchEstadisticasError
} from "./actions";
import * as ReportesServices from '../../services';
import { domainTypesByKey } from '../../config/const';

export const searchTablaRequest = (values, from, to, token) => {
  return async dispatch => {
    dispatch(searchTablaInit());
    try {
      const data = await ReportesServices.apiReportes.search(values, from, to, token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async especimen => {
          return {
            ...especimen
          };
        };
        const getData = async () => {
          return Promise.all(data.data.map(especimen => anAsyncFunction(especimen)))
        };
        await getData().then(newData => {
          const countValues = Object.keys(values).length;
          // TODO: descomentar a penas backend incluya esto const total = ((data.metadata && (countValues === 0)) && data.metadata.total) || data.data.length;
          const total = (newData.length <= 10 && newData.length) || 100;
          const pageCount = Math.round(total / to) || Math.ceil(total / to);
          dispatch(searchTablaSuccess(newData, pageCount, from, to));
        });
      } else if (typeof data === 'string') {
        dispatch(searchTablaError(data));
      }
    } catch (error) {
      dispatch(searchTablaError(error));
    }
  };
};

export const searchGeovisorRequest = (values, token) => {
  return async dispatch => {
    dispatch(searchGeovisorInit());
    try {
      const data = await ReportesServices.apiReportes.generar(values, token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        dispatch(searchGeovisorSuccess(data.data));
      } else if (typeof data === 'string') {
        dispatch(searchGeovisorError(data));
      }
    } catch (error) {
      dispatch(searchGeovisorError(error));
    }
  };
};

export const descargarReporteRequest = (values, token) => {
  return async (dispatch) => {
    dispatch(descargarReporteInit());
    try {
      const data =await ReportesServices.apiReportes.generar(values, token);
      dispatch(descargarReporteSuccess('Se generó la descarga y se enviara al correo.'));
      return { msg: `${data.message} `, err: false };
    } catch (error) {
      dispatch(descargarReporteError('Se genero un error al descargar el reporte por favor consulte al administrador!'));
      return { msg: 'Error al hacer la petición de la descarga.', err: true };
    }
  };
};

export const searchEstadisticasRequest = (values, token) =>{
  return async (dispatch) =>{
    dispatch(searchEstadisticasInit())
    try{
      const data = await ReportesServices.apiReportes.estadistica(token)
      dispatch(searchEstadisticasSuccess(data.data))
    }catch(error){
      dispatch(searchEstadisticasError('Se genero un error al consultar las estadísticas.'))
    }
  }
}
