/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
// import swal from '@sweetalert/with-react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
/* Hooks */
import {
  useExpedientes,
  useLogin,
  useConstants,
  useDomains,
  useAutoridadAmbiental,
  useNotification,
  useEntidadDisposicion
} from '../hooks';
/* Style Components */
import { ContainerHeader, SpanContact, SpanTextInfo, SpanDivide, SpanView } from './styled';
import {Col, Col1, Col2, Col3, Col4, Col5, Col12, GridContainer, Row} from "../components/Grid";
/* Components */
import Page from "../components/Page";
import { PageHeader } from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";
import PaginationTableRowSelect from "../components/Table/PaginationTableRowSelect";
import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import { Form } from "../components/form-ui-components/Form";
import { Card } from "../components/Card";
import { CardTitleBox } from "../components/CardTitleBox";
import { H1 } from "../components/H1";
import { H3 } from "../components/H3";
// import { H5 } from "../components/H5";
import { Label } from '../components/form-ui-components/Label';
import { Input } from '../components/form-ui-components/Input';
import { Textarea } from '../components/form-ui-components/Textarea';
import { Select } from "../components/form-ui-components/Select";
import { SelectOptions } from "../components/form-ui-components/SelectOptions";
import { Button } from '../components/form-ui-components/Button';
// import { ButtonLight } from '../components/form-ui-components/ButtonLight';
import ErrorBoundary from '../components/ErrorBoundary';
import ModalContact from '../components/ModalContact';
import ModalFiles from '../components/ModalFiles';
import ModalImages from '../components/ModalImages';
import ModalEspecimenes from '../components/ModalEspecimenes';
import { MapWithGeocoderControl } from '../components/form-ui-components/MapWithGeocoderControl';
import Autocomplete from '../components/Autocomplete';
import { Icon } from '../components/Icon';
import Loader from '../components/Loader';
import { decimalDegreesToSexagesimalDegrees, removeEmpty } from "../utils";
/* Defined Constants */
// import { wait } from '../config/const';

dayjs.locale('es');

const ExpedienteForm = ({ history, match }) => {
  const [reverseGeocodingResult, setResult] = useState();
  const [ departamentosPersoReadOnly, setDepartamentosPersoReadOnly ] = useState(false);
  const [ municipiosPersoReadOnly, setMunicipiosPersoReadOnly ] = useState(false);
  const [ departamentosProceReadOnly, setDepartamentosProceReadOnly ] = useState(false);
  const [ municipiosProceReadOnly, setMunicipiosProceReadOnly ] = useState(false);
  const [ departamentosProceEspeReadOnly, setDepartamentosProceEspeReadOnly ] = useState(false);
  const [ municipiosProceEspeReadOnly, setMunicipiosProceEspeReadOnly ] = useState(false);
  const [ loadedInfo, setLoadedInfo ] = useState(false);
  const [ latitudDecimal, setLatitudDecimal ] = useState(false);
  const [ latitudCoord, setLatitudCoord ] = useState(false);
  const [ longitudDecimal, setLongitudDecimal ] = useState(false);
  const [ longitudCoord, setLongitudCoord ] = useState(false);
  const [ enabledMap, setEnabledMap ] = useState(false);
  const [ ingresaCoordenadas, setIngresaCoordenadas ] = useState(false);
  const [ isCantidadAgregarRead, setIsCantidadAgregarRead ] = useState(false);
  const [ isCantidadUnidadRead, setIsCantidadUnidadRead ] = useState(false);
  const [ municipiosFiltradosProce, setMunicipiosFiltradosProce ] = useState(null);
  const [ municipiosFiltradosPerso, setMunicipiosFiltradosPerso ] = useState(null);
  const [ municipiosFiltradosProceEspe, setMunicipiosFiltradosProceEspe ] = useState([]);
  const [ enableFieldOtroSitio, setEnableFieldOtroSitio ] = useState(false);
  const [ enableFieldOtroOrigen, setEnableFieldOtroOrigen ] = useState(false);
  const [ enableParteProducto, setEnableParteProducto ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ modalContacts, setModalContacts ] = useState(false);
  const [ modalFile, setModalFile ] = useState(false);
  const [ modalImage, setModalImage ] = useState(false);
  const [ modalEspecimenes, setModalEspecimenes ] = useState(false);
  const [ animation, setAnimation  ] = useState('zoom');
  // Auto Complete
  const [ suggestions, setSuggestions ] = useState([]);
  const [ loadingAutoComplete, setLoadingAutoComplete ] = useState(false);
  const [ activeSuggestion, setActiveSuggestion ] = useState(0);
  const [ showSuggestions, setShowSuggestions ] = useState(false);
  const [ userInput, setUserInput ] = useState('');
  // Fin Auto Complete
  // Auto Complete Search
  const [ suggestionsSearch, setSuggestionsSearch ] = useState([]);
  const [ loadingAutoCompleteSearch, setLoadingAutoCompleteSearch ] = useState(false);
  const [ activeSuggestionSearch, setActiveSuggestionSearch ] = useState(0);
  const [ showSuggestionsSearch, setShowSuggestionsSearch ] = useState(false);
  const [ userInputSearch, setUserInputSearch ] = useState('');
  // Fin Auto Complete Search

  const { isLoggedIn, token } = useLogin();
  const {
    isLoading,
    isLoadingRadicar,
    isLoadingEspecimenes,
    images,
    imagesAux,
    files,
    filesAux,
    filesAuxClose,
    taxonomyInformation,
    error,
    selected,
    mode,
    fileSelected,
    imageSelected,
    createExpedienteRequest,
    updateExpedienteRequest,
    contacts,
    contactDeleteExpediente,
    getEspecimenesExpedienteRequest,
    especimenesExpediente,
    getByIdEspecimenRequest,
    radicarExpedienteRequest,
    addSameEspecimeneByCantidad,
    deleteEspecimenesExpediente,
    getByIdArchivoRequest,
    getByIdImagenRequest,
    clearSelected,
    loadSelected,
    especimenTaxonomyInfoRequest,
    expedienteFilesDelete,
    expedienteImagesDelete,
    clearSelectedFile,
    clearSelectedImage
  } = useExpedientes();
  const { createNotification } = useNotification();
  const { constantsByKey, getConstantOptionsByTypes } = useConstants();
  const { domainsByKey, getOptionsByDomainTypes } = useDomains();
  const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();
  const { data: entidadesDisposicion, searchEntidadDisposicion } = useEntidadDisposicion();

  const { register, setValue, handleSubmit, errors, getValues, watch, reset, setError, clearError } = useForm();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const getActualDateTime = () => dayjs().format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const splitDate = timestamp => timestamp.split('T')[0];
  const tiposCoordenadas = [{ _id: 1, nombre: 'Ubicar en mapa' }, { _id: 2, nombre: 'Coordenadas en grados, minutos, segundos' },{ _id: 3, nombre: 'Coordenadas decimales' }];

  const getLabelHemisferioLat = hemisferio => {
    return constantsByKey.hemisferioLatitud.filter(({_id}) => _id === Number(hemisferio))[0].constantName;
  };

  const getLabelHemisferioLong = hemisferio => {
    return constantsByKey.hemisferioLongitud.filter(({_id}) => _id === Number(hemisferio))[0].constantName;
  };

  //Función que carga el label de tipo de coordenada segun el atributo de coordenadas del objeto consultado
  const labelTypeCoord = coordenadas => {
    let isEmptyLatitud = 0;
    let isEmptyLongitud = 0;

    Object.keys(coordenadas.latitud).map(key => {
      if(coordenadas.latitud[key] === '') {
        isEmptyLatitud +=1;
      };
    });

    Object.keys(coordenadas.longitud).map(key => {
      if(coordenadas.longitud[key] === '') {
        isEmptyLongitud +=1;
      };
    });

    if(Object.keys(coordenadas.latitud).length === 5) {
      return tiposCoordenadas.filter(({_id}) => _id === 1)[0].nombre;
    }

    if(Object.keys(coordenadas.latitud).length === 4) {
      return tiposCoordenadas.filter(({_id}) => _id === 2)[0].nombre;
    }

    if(Object.keys(coordenadas.latitud).length === 1) {
      return tiposCoordenadas.filter(({_id}) => _id === 3)[0].nombre;
    }

    return 'Sin tipo coordenadas configuradas';
  };

  const handleChangeUnidadMedida = e => {
    const { value } = e.target;
    if(Number(value) === 20000007) {
      setValue('cantidad', 1);
      setValue('cantidad_agregar', '');
      setIsCantidadUnidadRead(true);
      setIsCantidadAgregarRead(false);
    } else {
      setValue('cantidad_agregar', 1);
      setIsCantidadUnidadRead(false);
      setIsCantidadAgregarRead(true);
    }
  };

  //funcion que implementa la lógica en la selección de un tipo de coordenadas
  const handleChangeTipoCoord = e => {
    const { value } = e.target;
    if(!value) {
      console.log("sn valor")
      setIngresaCoordenadas(false)
      setEnabledMap(false);
      setLatitudDecimal(false);
      setLongitudDecimal(false);
      setLatitudCoord(false);
      setLongitudCoord(false);
    } else if(Number(value) === 1) {
      console.log("coordenadas")
      setValue('coordenadas.latitud.grados', '');
      setValue('coordenadas.latitud.minutos', '');
      setValue('coordenadas.latitud.segundos', '');
      setValue('coordenadas.latitud.hemisferio', '');
      setValue('coordenadas.latitud.decimal', '');
      setValue('coordenadas.longitud.grados', '');
      setValue('coordenadas.longitud.minutos', '');
      setValue('coordenadas.longitud.segundos', '');
      setValue('coordenadas.longitud.hemisferio', '');
      setValue('coordenadas.longitud.decimal', '');
      setIngresaCoordenadas(true)
      setEnabledMap(true);
      setLatitudDecimal(false);
      setLongitudDecimal(false);
      setLatitudCoord(true);
      setLongitudCoord(true);
    } else if(Number(value) === 2) {
      console.log("decimal")
      setValue('coordenadas.latitud.grados', '');
      setValue('coordenadas.latitud.minutos', '');
      setValue('coordenadas.latitud.segundos', '');
      setValue('coordenadas.latitud.hemisferio', '');
      setValue('coordenadas.latitud.decimal', '');
      setValue('coordenadas.longitud.grados', '');
      setValue('coordenadas.longitud.minutos', '');
      setValue('coordenadas.longitud.segundos', '');
      setValue('coordenadas.longitud.hemisferio', '');
      setValue('coordenadas.longitud.decimal', '');
      setIngresaCoordenadas(true)
      setEnabledMap(false);
      setLatitudDecimal(true);
      setLongitudDecimal(true);
      setLatitudCoord(false);
      setLongitudCoord(false);
    } else if(Number(value) === 3) {
      console.log("coordenadas")
      setValue('coordenadas.latitud.grados', '');
      setValue('coordenadas.latitud.minutos', '');
      setValue('coordenadas.latitud.segundos', '');
      setValue('coordenadas.latitud.hemisferio', '');
      setValue('coordenadas.latitud.decimal', '');
      setValue('coordenadas.longitud.grados', '');
      setValue('coordenadas.longitud.minutos', '');
      setValue('coordenadas.longitud.segundos', '');
      setValue('coordenadas.longitud.hemisferio', '');
      setValue('coordenadas.longitud.decimal', '');
      setIngresaCoordenadas(true)
      setEnabledMap(false);
      setLatitudDecimal(false);
      setLongitudDecimal(false);
      setLatitudCoord(true);
      setLongitudCoord(true);
    }
  };

  const handleChangeCoordLat = (e, field = null) => {
    const { value } = e.target;
    if(value && !field) {
      setLatitudDecimal(true);
    } else if (value && field) {
      setLatitudCoord(true);
    } else {
      setLatitudDecimal(false);
      setLatitudCoord(false);
    }
  };

  const handleChangeCoordLong = (e, field = null) => {
    const { value } = e.target;
    if(value && !field) {
      setLongitudDecimal(true);
    } else if (value && field) {
      setLongitudCoord(true);
    } else {
      setLongitudDecimal(false);
      setLongitudCoord(false);
    }
  };

  const handleChangeEstadoEspecimen = e => {
    const { value } = e.target;
    setEnableParteProducto((Number(value) === 8000002));
  };

  const handleChangePaisProce = e => {
    e.preventDefault();
    const { value } = e.target;
    if(Number(value) !== 3000001) {
      setValue('departamento_procedimiento', '');
      setValue('municipio_procedimiento', '');
      setDepartamentosProceReadOnly(true);
      setMunicipiosProceReadOnly(true);
    } else {
      setDepartamentosProceReadOnly(false);
      setMunicipiosProceReadOnly(false);
    }
  };

  const handleChangeDptoProce = e => {
    e.preventDefault();
    const { value } = e.target;
    const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
    setMunicipiosFiltradosProce(newData);
  };

  const handleChangePaisPerso = e => {
    e.preventDefault();
    const { value } = e.target;
    if(Number(value) !== 3000001) {
      setValue('departamento', '');
      setValue('municipio', '');
      setDepartamentosPersoReadOnly(true);
      setMunicipiosPersoReadOnly(true);
    } else {
      setDepartamentosPersoReadOnly(false);
      setMunicipiosPersoReadOnly(false);
    }
  };

  const handleChangeDptoPerso = e => {
    e.preventDefault();
    const { value } = e.target;
    const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
    setMunicipiosFiltradosPerso(newData);
  };

  const handleChangePaisProceEspe = e => {
    e.preventDefault();
    const { value } = e.target;
    if(Number(value) !== 3000001) {
      setValue('departamento_procedencia_declarado', '');
      setValue('municipio_procedencia_declarado', '');
      setDepartamentosProceEspeReadOnly(true);
      setMunicipiosProceEspeReadOnly(true);
    } else {
      setDepartamentosProceEspeReadOnly(false);
      setMunicipiosProceEspeReadOnly(false);
    }
  };

  const handleChangeDptoProceEspe = e => {
    const { value } = e.target;
    const newData = constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(value));
    setMunicipiosFiltradosProceEspe(newData);
  };

  const handleChangeSitio = e => {
    // const {value} = e.target;
    setEnableFieldOtroSitio(false);
    if(Number(e.currentTarget.value) === 5000010) {
      setEnableFieldOtroSitio(true);
    }
  };

  const handleChangeOrigen = e => {
    setEnableFieldOtroOrigen(false);
    if(Number(e.currentTarget.value) === 6000004) {
      setEnableFieldOtroOrigen(true);
    }
  };

  // Auto Complete
  // const loadTaxonomyInfo = useCallback(() => {
  //   setSuggestions(taxonomyInformation);
  // }, [taxonomyInformation]);

  //Función para la selección de taxonomía
  const handleAutoCompleteChange = async e => {
    const newUserInput = e.currentTarget.value;
    if(document.querySelector("select[name='taxonRank']").value !== '') {
      const search = {
        taxonRank: document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const { taxonomyInfo, msg, err } = await especimenTaxonomyInfoRequest(search, token);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInfo);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId  = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalName').value = newUserInput;
    setValue('taxonomia', taxonomiaId);
    setValue('PIFS_grupoBio', info[1]);
  };
  // Fin Auto Complete

  // Auto Complete Busqueda Espécimen
  const handleAutoCompleteSearchChange = async e => {
    const newUserInput = e.currentTarget.value;
    if(document.querySelector("select[name='taxonRankSearch']").value !== '') {
      const search = {
        taxonRank: document.querySelector("select[name='taxonRankSearch']")[document.querySelector("select[name='taxonRankSearch']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoCompleteSearch(true);
      const { taxonomyInfo, msg, err } = await especimenTaxonomyInfoRequest(search, token);
      setLoadingAutoCompleteSearch(false);
      setUserInputSearch(newUserInput);
      setSuggestionsSearch(taxonomyInfo);
      setActiveSuggestionSearch(0);
      setShowSuggestionsSearch(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteSearchKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestionSearch(0);
      setShowSuggestionsSearch(false);
      setUserInputSearch(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestionSearch(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestionSearch(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteSearchClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId  = Number(info[0]);
    setActiveSuggestionSearch(0);
    setSuggestionsSearch([]);
    setShowSuggestionsSearch(false);
    setUserInputSearch(newUserInput);
    document.getElementById('canonicalNameSearch').value = newUserInput;
    setValue('taxonomiaSearch', taxonomiaId);
  };
  // Fin Auto Complete Busqueda Espécimen

  const handleListarExpedientes = async e => {
    e.preventDefault();
    history.push('/expedientes-search');
  };

  const handleRadicarExpediente = async e => {
    e.preventDefault();
    const hayActaUnica = filesAux.filter(({ tipo }) => Number(tipo._id) === 14000001);
    if(especimenesExpediente.length === 0) {
      Swal.fire({
        title: 'REGISTRAR ESPÉCIMEN!',
        text: 'Debe ingresar al menos un espécimen en el expediente!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    } else if(hayActaUnica.length === 0) {
      Swal.fire({
        title: 'REGISTRAR ACTA ÚNICA!',
        text: 'Para poder radicar el expediente debe incluir adjunto el archivo de Acta única!',
        icon: 'info',
        confirmButtonText: 'ACEPTAR'
      })
    } else {
      const values = getValues();
      confirmAlert({
        title: 'RADICAR EXPEDIENTE',
        message: `¿Esta seguro desea radicar el expediente ${values.acta}?`,
        buttons: [
          {
            label: "VOLVER AL FORMULARIO",
            onClick: () => {}
          },
          {
            label: "ACEPTAR",
            onClick: async () => {
              const id = selected ? selected._id : null;
              const {msg, err} = await radicarExpedienteRequest(id, token);
              createNotification({
                message: msg,
                error: err,
                visible: true
              });

              if(!err) {
                await clearSelected();
                history.push('/expedientes-search');
              }
            }
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
    }
    e.stopPropagation();
  };

  const handleOpenModalFiles = async () => {
    await clearSelectedFile();
    setAnimation('zoom');
    setModalFile(!modalFile);
  };

  const handleOpenModalImages = async () => {
    await clearSelectedImage();
    setAnimation('zoom');
    setModalImage(!modalImage);
  };

  // const handleClearImages = e => {
  //   e.preventDefault();
  //   setImages([]);
  //   e.stopPropagation();
  // };

  const handleOpenModalContacts = () => {
    setAnimation('zoom');
    setModalContacts(!modalContacts);
  };

  const handleOpenModalEspecimenes = () => {
    setAnimation('zoom');
    setModalEspecimenes(!modalEspecimenes);
  };

  //Fución que implementa la lógica para agregar especimenes en la página
  const handleAddEspecimenes = e => {
    e.preventDefault();
    const values = getValues();
    const especimen = {
      clase_recurso: { _id: values.clase_recurso, domainName: values.clase_recurso ? document.querySelector("select[name='clase_recurso']")[document.querySelector("select[name='clase_recurso']").selectedIndex].innerHTML : '' },
      estado: { _id: values.estado, domainName: (Number(values.estado) === 8000001) ? 'Vivo' : 'Muerto' },
      parte_producto: { _id: values.parte_producto, domainName: values.parte_producto ? document.querySelector("select[name='parte_producto']")[document.querySelector("select[name='parte_producto']").selectedIndex].innerHTML : '' },
      categoria_taxonomica: { _id: values.taxonRank, domainName: values.taxonRank ? document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML : '' },
      taxonomia: { _id: values.taxonomia, canonicalName: document.getElementById('canonicalName').value, PIFS_grupoBio: values.PIFS_grupoBio },
      nombre_comun: values.nombre_comun,
      descripcion: values.descripcion,
      hongos_enfermedades: (String(true) === values.hongos_enfermedades),
      marcador_genetico: values.marcador_genetico,
      sexo: { _id: values.sexo, domainName: values.sexo ? document.querySelector("select[name='sexo']")[document.querySelector("select[name='sexo']").selectedIndex].innerHTML : '' },
      tiempo_cautiverio: values.tiempo_cautiverio ? Number(values.tiempo_cautiverio) : '',
      unidad_medida: { _id: values.unidad_medida, domainName: values.unidad_medida ? document.querySelector("select[name='unidad_medida']")[document.querySelector("select[name='unidad_medida']").selectedIndex].innerHTML : '' },
      condicion: { _id: values.condicion, domainName: values.condicion ? document.querySelector("select[name='condicion']")[document.querySelector("select[name='condicion']").selectedIndex].innerHTML : '' },
      estado_desarrollo: { _id: values.estado_desarrollo, domainName: values.estado_desarrollo ? document.querySelector("select[name='estado_desarrollo']")[document.querySelector("select[name='estado_desarrollo']").selectedIndex].innerHTML : '' },
      pais_procedencia_declarado: { _id: values.pais_procedencia_declarado, constantName: values.pais_procedencia_declarado ? document.querySelector("select[name='pais_procedencia_declarado']")[document.querySelector("select[name='pais_procedencia_declarado']").selectedIndex].innerHTML : '' },
      departamento_procedencia_declarado: { _id: values.departamento_procedencia_declarado, constantName: values.departamento_procedencia_declarado ? document.querySelector("select[name='departamento_procedencia_declarado']")[document.querySelector("select[name='departamento_procedencia_declarado']").selectedIndex].innerHTML : '' },
      municipio_procedencia_declarado: { _id: values.municipio_procedencia_declarado, constantName: values.municipio_procedencia_declarado ? document.querySelector("select[name='municipio_procedencia_declarado']")[document.querySelector("select[name='municipio_procedencia_declarado']").selectedIndex].innerHTML : '' },
      corregimiento_procedencia_declarado: values.corregimiento_procedencia_declarado,
      vereda_procedencia_declarado: values.vereda_procedencia_declarado,
      barrio_procedencia_declarado: values.barrio_procedencia_declarado,
      predio_procedencia_declarado: values.predio_procedencia_declarado,
      lugar_procedencia_declarado: values.lugar_procedencia_declarado,
      entidad_disposicion_inicial: { _id: values.entidad_disposicion_inicial, nombre: values.entidad_disposicion_inicial ? document.querySelector("select[name='entidad_disposicion_inicial']")[document.querySelector("select[name='entidad_disposicion_inicial']").selectedIndex].innerHTML : '' },
      observaciones: values.observaciones_generales,
      castrado: (String(true) === values.castrado),
      prenado: (String(true) === values.prenado),
      dieta: values.dieta,
      estado_posesion_expediente: { _id: 25000001, domainName: 'Agregado' },
      nuevo: true,
      cantidad: values.cantidad ? Number(values.cantidad) : '',
      disposicion_final: false,
    };
    let countEmpty = 0;
    let fieldsWithError = [];
    clearError(['clase_recurso', 'categoria_taxonomica', 'taxonRank', 'taxonomia', 'sexo', 'unidad_medida', 'cantidad', 'condicion', 'estado_desarrollo', 'entidad_disposicion_inicial'])
    Object.keys(especimen).map(key => {
      if(key !== 'tiempo_cautiverio' && key !== 'parte_producto' && key !== 'observaciones' && key !== 'dieta' && key !== 'nombre_comun' && key !== 'marcador_genetico' && key !== 'descripcion' && key !== 'hongos_enfermedades' && key !== 'corregimiento_procedencia_declarado' && key !== 'vereda_procedencia_declarado' && key !== 'barrio_procedencia_declarado' && key !== 'predio_procedencia_declarado' && key !== 'lugar_procedencia_declarado' && key !== 'pais_procedencia_declarado' && key !== 'departamento_procedencia_declarado' && key !== 'municipio_procedencia_declarado') {
        if(((typeof especimen[key] === 'object' ) && (especimen[key]._id === '')) || (especimen[key] === '')) {
          countEmpty+=1;
          fieldsWithError = [...fieldsWithError, { type: 'required', name: (key === 'categoria_taxonomica') ? 'taxonRank' : key }];
        };
      }
    });

    if(countEmpty > 0) {
      Swal.fire({
        title: 'CAMPOS OBLIGATORIOS AGREGAR ESPÉCIMEN!',
        icon: 'info',
        html: 'Debe diligenciar los campos obligatorios para agregar el espécimen.',
        confirmButtonText: 'ACEPTAR'
      })
      setError(fieldsWithError);
    } else if( Number(values.cantidad_agregar) === 0 ) {
      Swal.fire({
        title: 'CANTIDAD AGREGAR ESPÉCIMEN!',
        icon: 'info',
        html: 'Debe ingresar una cantidad mayor a cero para agregar los especímenes!.<br />Solo se permite agregar <b>un espécimen</b> para las unidades de medida <b>m, cm, kg, g, L, unidades.</b><br />Para la unidad de medida <b>Individuos</b> se pueden agregar (n) cantidades.',
        confirmButtonText: 'ACEPTAR'
      })
    } else if( Number(values.cantidad_agregar) !== 1 && values.unidad_medida !== '' && Number(values.unidad_medida) !== 20000007) {
      const unidad = document.querySelector("select[name='unidad_medida']")[document.querySelector("select[name='unidad_medida']").selectedIndex].innerHTML;
      Swal.fire({
        title: 'CANTIDAD AGREGAR ESPÉCIMEN!',
        icon: 'info',
        html: `Debe ingresar una cantidad igual a <b>uno</b> ya que la unidad seleccionada fue <b>${unidad}</b> y por lo tanto para esta unidad solo se permite agregar un espécimen.`,
        confirmButtonText: 'ACEPTAR'
      })
    } else if((countEmpty === 0) && (Number(values.cantidad_agregar) > 0)) {
      const cantidad = Number(values.cantidad_agregar);
      addSameEspecimeneByCantidad(especimen, cantidad);
    }
  };

  const handleBorrarEspecimenes = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: `¿Esta seguro de eliminar los ${Object.keys(selectedRowIds).length} especimenes del listado?`,
      message: `Al borrar los especímenes del listado quedan desasociados del expediente`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: () => {
            deleteEspecimenesExpediente(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const isPresent = (rows, index) => {
    let esta = false;
    Object.keys(rows).map(key => {
      if(key === index.toString()){
        esta = true;
      }
    });
    return esta;
  };

  const handleDeleteFiles = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: 'BORRAR ARCHIVOS',
      message: `¿Esta seguro de eliminar los ${Object.keys(selectedRowIds).length} archivos del listado?`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            await expedienteFilesDelete(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const handleDeleteImages = (e, selectedRowIds, selectedFlatRows) => {
    e.preventDefault();
    confirmAlert({
      title: 'BORRAR IMAGENES',
      message: `¿Esta seguro de eliminar las ${Object.keys(selectedRowIds).length} imagenes del listado?`,
      buttons: [
        {
          label: "VOLVER AL FORMULARIO",
          onClick: () => {}
        },
        {
          label: "ACEPTAR",
          onClick: async () => {
            await expedienteImagesDelete(selectedRowIds, selectedFlatRows);
          }
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const handleClearEspecimenesFields = async e => {
    e.preventDefault();
    setValue('clase_recurso_consulta', '');
    setValue('estado_consulta', '');
    setValue('parte_producto_consulta', '');
    document.getElementById('canonicalNameSearch').value = '';
    setValue('taxonomiaSearch', '');
    setValue('nombre_comun_consulta', '');
    setValue('sexo_consulta', '');
    setValue('estado_desarrollo_consulta', '');
    setValue('estado_posesion_consulta', '');
    const search = {
      expediente: selected ? selected._id : null
    };
    await getEspecimenesExpedienteRequest(removeEmpty(search), 0, 50, token);
  };

  const handleSearchEspecimenesFields = async e => {
    e.preventDefault();
    const values = getValues();
    const search = {
      expediente: selected ? selected._id : null,
      clase_recurso: values.clase_recurso_consulta ? Number(values.clase_recurso_consulta) : null,
      parte_producto: values.parte_producto_consulta ? Number(values.parte_producto_consulta) : null,
      estado: values.estado_consulta ? Number(values.estado_consulta) : null,
      taxonomia: values.taxonomiaSearch ? Number(values.taxonomiaSearch) : null,
      nombre_comun: values.nombre_comun_consulta ? values.nombre_comun_consulta : null,
      sexo: values.sexo_consulta ? Number(values.sexo_consulta) : null,
      estado_posesion_expediente: values.estado_posesion_consulta ? Number(values.estado_posesion_consulta) : null,
      estado_desarrollo: values.estado_desarrollo_consulta ? Number(values.estado_desarrollo_consulta) : null,
    };
    await getEspecimenesExpedienteRequest(removeEmpty(search), 0, 50, token);
  };

  const handleClose = async e => {
    e.preventDefault();
    reset();
    await clearSelected();
    history.push('/expedientes-search');
    e.stopPropagation();
  };

  const gotoLogin = useCallback(async () => {
    history.push('/');
  }, [history]);

  const clearExpediente = useCallback(async () => {
    window.scrollTo(0, 0);
    await clearSelected();
    reset();
    setValue('fecha_procedimiento', getActualDate());
    setValue('pais_procedimiento', 3000001);
    setValue('pais', 3000001);
    setValue('pais_procedencia_declarado', 3000001);
    setLoadedInfo(false);
  }, [history]);

  const gotoExpedientes = useCallback(async () => {
    await clearSelected();
    history.push('/expedientes-search');
  }, [history]);

  const loadMunicipioProceSelected = useCallback(() => {
    if(municipiosFiltradosProce && selected) {
      setValue('municipio_procedimiento', selected.municipio_procedimiento._id);
    }
  }, [municipiosFiltradosProce, selected]);

  const loadMunicipioPersoSelected = useCallback(() => {
    if(municipiosFiltradosPerso && selected) {
      setValue("municipio", selected.personas.municipio._id);
    }
  }, [municipiosFiltradosPerso, selected]);

  const loadEspecimenesSelected = useCallback(async () => {
    if (selected) {
      await getEspecimenesExpedienteRequest(({ expediente: selected._id }), 0, 50, token);
    }
  }, [selected, getEspecimenesExpedienteRequest]);

  const loadExpedienteSelected = useCallback(() => {
    if (selected) {
      setValue('autoridad_ambiental', selected.autoridad_ambiental._id);
      setValue('acta', selected.acta);
      setValue('tipo_procedimiento', selected.tipo_procedimiento._id);
      setValue('fecha_procedimiento', splitDate(selected.fecha_procedimiento));
      setValue('tipo_zona', `${selected.tipo_zona._id}`);
      setValue('pais_procedimiento', selected.pais_procedimiento._id);
      setValue('departamento_procedimiento', selected.departamento_procedimiento._id);
      // setValue('municipio_procedimiento', selected.municipio_procedimiento._id);
      setValue('corregimiento_procedimiento', selected.corregimiento_procedimiento);
      setValue('vereda_procedimiento', selected.vereda_procedimiento);
      setValue('barrio_procedimiento', selected.barrio_procedimiento);
      setValue('direccion_procedimiento', selected.direccion_procedimiento);

      // Ubicación del procedimiento
      // Latitud
      setValue('coordenadas.latitud.grados', selected.coordenadas.latitud.grados);
      setValue('coordenadas.latitud.minutos', selected.coordenadas.latitud.minutos);
      setValue('coordenadas.latitud.segundos', selected.coordenadas.latitud.segundos);
      setValue('coordenadas.latitud.hemisferio', selected.coordenadas.latitud.hemisferio);
      setValue('coordenadas.latitud.decimal', selected.coordenadas.latitud.decimal);
      // Longitud
      setValue('coordenadas.longitud.grados', selected.coordenadas.longitud.grados);
      setValue('coordenadas.longitud.minutos', selected.coordenadas.longitud.minutos);
      setValue('coordenadas.longitud.segundos', selected.coordenadas.longitud.segundos);
      setValue('coordenadas.longitud.hemisferio', selected.coordenadas.longitud.hemisferio);
      setValue('coordenadas.longitud.decimal', selected.coordenadas.longitud.decimal);

      // Información del procedimiento - adjuntos
      setValue('flagrancia', `${selected.flagrancia}`);
      setValue('tipo_sitio_procedimiento', selected.tipo_sitio_procedimiento._id);
      console.log("sected en pagina----->",selected)
      console.log("Procedimineto id----->",selected.tipo_sitio_procedimiento._id)
      console.log("Procedimineto----->",selected.otro_sitio_procedimiento)
      setValue('otro_sitio_procedimiento', selected.otro_sitio_procedimiento);
      setValue('sitio_procedimiento', selected.sitioProcedimiento);
      setValue('justificacion_no_registrar', selected.justificacion_no_registrar);
      setValue('origen_procedimiento', selected.origen_procedimiento._id);
      setValue('otro_origen_procedimiento', selected.otro_origen_procedimiento);
      setValue('justificacion_sin_imagen', selected.justificacion_sin_imagen);
      setValue('proceso_administrativo', selected.proceso_administrativo);
      setValue('proceso_judicial', selected.proceso_judicial);
      setValue('observaciones', selected.observaciones);

      // Procedimiento persona
      setValue('tipo_documento', selected.personas.tipo_documento._id);
      setValue('numero_identificacion', selected.personas.numero_identificacion);
      setValue('nombre', selected.personas.nombre);
      setValue('pais', selected.personas.pais._id);
      setValue('departamento', selected.personas.departamento._id);
      // setValue('municipio', selected.personas[0].municipio_persona);
      setValue('direccion', selected.personas.direccion);
      setValue('telefono', selected.personas.telefono);
      setValue('edad', selected.personas.edad);
      setValue('ocupacion', selected.personas.ocupacion);
      setValue('nombre_padre', selected.personas.nombre_padre);
      setValue('nombre_madre', selected.personas.nombre_madre);
      setValue('declaracion', selected.personas.declaracion);

      const newDataProce = constantsByKey.municipios ? constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(selected.departamento_procedimiento._id)) : [];
      setMunicipiosFiltradosProce(newDataProce);

      const newDataPerso = constantsByKey.municipios ? constantsByKey.municipios.filter(({ constantParent }) => constantParent === Number(selected.personas.departamento._id)) : [];
      setMunicipiosFiltradosPerso(newDataPerso);

      let tipoCoord = '';
      if(Object.keys(selected.coordenadas.latitud).length === 5) {
        tipoCoord = tiposCoordenadas.filter(({_id}) => _id === 1)[0]._id;
        console.log("mapa enabled")
        setEnabledMap(true);
      }

      if(Object.keys(selected.coordenadas.latitud).length === 4) {
        tipoCoord = tiposCoordenadas.filter(({_id}) => _id === 2)[0]._id;
        console.log("mapa no enabled1")
        setEnabledMap(false);
      }

      if(Object.keys(selected.coordenadas.latitud).length === 1) {
        tipoCoord = tiposCoordenadas.filter(({_id}) => _id === 3)[0]._id;
        console.log("mapa no enabled2")
        setEnabledMap(false);
      }
      setValue('tipo_coordenadas', tipoCoord);

      setLoadedInfo(false);
    } else {
      setValue('fecha_procedimiento', getActualDate());
      setValue('pais_procedimiento', 3000001);
      setValue('pais', 3000001);
      setValue('pais_procedencia_declarado', 3000001);
      setLoadedInfo(false);
    }
  }, [setValue, selected]);

  const load = useCallback(async () => {
    window.scrollTo(0, 0);
    await Promise.all([clearSelected(), searchAutoridadAmbiental({}, token), searchEntidadDisposicion({ estado: true }, token), getOptionsByDomainTypes(['tiposAutoridadAmbiental', 'tiposEntidadDisposicion', 'tiposEvento', 'tiposProcedimientoSitio', 'tiposProcedimientoOrigen', 'tiposRecurso', 'tiposParte', 'tiposSexo', 'estadosCondicion', 'estadosDesarrollo', 'unidadesMedida', 'tiposEstado', 'tiposEstadoEspecimen', 'tiposRespuestaTraslado', 'motivosCierre', 'tiposAdjunto', 'tiposEstadoEspecimenExpediente']), getConstantOptionsByTypes(['paises', 'departamentos', 'municipios', 'tiposDocumento', 'clasificacionTaxonomia', 'hemisferioLatitud', 'hemisferioLongitud'])]);
    if (typeof match.params.id !== 'undefined' && typeof match.params.mode !== 'undefined') {
      await loadSelected(match.params.id, match.params.mode, token);
      console.log("seleccion------->",selected)
      if (match.params.mode === 'edit') await loadExpedienteSelected();
    } else {
      setValue('fecha_procedimiento', getActualDate());
      setValue('pais_procedimiento', 3000001);
      setValue('pais', 3000001);
      setValue('pais_procedencia_declarado', 3000001);
      setLoadedInfo(false);
    }
  }, [
    setValue,
    loadSelected,
    clearSelected,
    getEspecimenesExpedienteRequest,
    searchAutoridadAmbiental,
    searchEntidadDisposicion,
    loadExpedienteSelected,
    getOptionsByDomainTypes,
    getConstantOptionsByTypes,
    match.params.id,
    match.params.mode
  ]);

  const gotoExpedienteEdit = useCallback(async (id) => {
    await clearSelected();
    history.push(`/expediente-form/edit/${id}`);
    await loadSelected(id, 'edit', token);
    await loadExpedienteSelected();
  }, [history]);

  const setSuggestedValues = useCallback(() => {
    const dmsLonLat = decimalDegreesToSexagesimalDegrees(
      reverseGeocodingResult.center
    );
    setValue('coordenadas.latitud.grados', Number(parseInt(Number(dmsLonLat.latitud.grados), 10)));
    setValue('coordenadas.latitud.minutos', Number(parseInt(Number(dmsLonLat.latitud.minutos), 10)));
    setValue('coordenadas.latitud.segundos', Number(parseFloat(Number(dmsLonLat.latitud.segundos)).toFixed(2)));
    setValue('coordenadas.latitud.hemisferio', constantsByKey.hemisferioLatitud.filter(({constantName}) => constantName === dmsLonLat.latitud.hemisferio)[0]._id || '');
    setValue('coordenadas.latitud.decimal', Number(parseFloat(Number(reverseGeocodingResult.center.lat)).toFixed(5)));
    setValue('coordenadas.longitud.grados', Number(parseInt(Number(dmsLonLat.longitud.grados), 10)));
    setValue('coordenadas.longitud.minutos', Number(parseInt(Number(dmsLonLat.longitud.minutos), 10)));
    setValue('coordenadas.longitud.segundos', Number(parseFloat(Number(dmsLonLat.longitud.segundos)).toFixed(2)));
    setValue('coordenadas.longitud.hemisferio', constantsByKey.hemisferioLongitud.filter(({constantName}) => constantName === dmsLonLat.longitud.hemisferio)[0]._id || '');
    setValue('coordenadas.longitud.decimal', Number(parseFloat(Number(reverseGeocodingResult.center.lng)).toFixed(5)));
    // setLatitudDecimal(true);
    // setLongitudDecimal(true);
    // setLatitudCoord(true);
    // setLongitudCoord(true);
  }, [setValue, reverseGeocodingResult]);

  const columnsFiles = [
    {
      Header: 'Tipo de documento',
      accessor:  d => {
        return ((d.tipo) && (d.tipo.domainName !== '') && (d.tipo.domainName !== ' ')) ? `${d.tipo.domainName}` : '---';
      }
    },
    {
      Header: 'Nombre del documento',
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? `${d.nombre}` : '---';
      }
    },
    {
      Header: 'Fecha cargue',
      accessor:  d => {
        return ((d.fecha_creacion) && (d.fecha_creacion !== '') && (d.fecha_creacion !== ' ')) ? `${splitDate(d.fecha_creacion)}` : '---';
      }
    },
    {
      Header: 'Usuario',
      accessor:  d => {
        return ((d.usuario_modificacion) && (d.usuario_modificacion !== '') && (d.usuario_modificacion !== ' ')) ? `${d.usuario_modificacion}` : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Archivo',
            visible: true,
            handleAction: async () => {
              await getByIdArchivoRequest(original._id, 'view');
              setAnimation('zoom');
              setModalFile(!modalFile);
            }
          },
          {
            id: original._id,
            iconName: 'edit',
            title: 'Actualizar Archivo',
            visible: original.nuevo || (mode !== 'view'),
            handleAction: async () => {
              await getByIdArchivoRequest(original._id, 'edit');
              setAnimation('zoom');
              setModalFile(!modalFile);
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsImages = [
    {
      Header: 'Nombre de la imagen',
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? `${d.nombre}` : '---';
      }
    },
    {
      Header: 'Fecha cargue',
      accessor:  d => {
        return ((d.fecha_creacion) && (d.fecha_creacion !== '') && (d.fecha_creacion !== ' ')) ? `${splitDate(d.fecha_creacion)}` : '---';
      }
    },
    {
      Header: 'Fecha captura',
      accessor:  d => {
        return ((d.fecha_captura) && (d.fecha_captura !== '') && (d.fecha_captura !== ' ')) ? `${splitDate(d.fecha_captura)}` : '---';
      }
    },
    {
      Header: 'Usuario',
      accessor:  d => {
        return ((d.usuario_modificacion) && (d.usuario_modificacion !== '') && (d.usuario_modificacion !== ' ')) ? `${d.usuario_modificacion}` : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Imagen',
            visible: true,
            handleAction: async () => {
              await getByIdImagenRequest(original._id, 'view');
              setAnimation('zoom');
              setModalImage(!modalImage);
            }
          },
          {
            id: original._id,
            iconName: 'edit',
            title: 'Actualizar Imagen',
            visible: original.nuevo || (mode !== 'view'),
            handleAction: async () => {
              await getByIdImagenRequest(original._id, 'edit');
              setAnimation('zoom');
              setModalImage(!modalImage);
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsContacts = [
    {
      Header: 'Nombre del Funcionario',
      accessor:  d => {
        return ((d.nombre) && (d.nombre !== '') && (d.nombre !== ' ')) ? `${d.nombre}` : '---';
      }
    },
    {
      Header: 'Autoridad Ambiental',
      accessor:  d => {
        return ((d.autoridad_ambiental) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ')) ? d.autoridad_ambiental.nombre : '---';
      }
    },
    {
      Header: "Tipo",
      accessor:  d => {
        return ((d.tipo_entidad_disposicion) && (d.tipo_entidad_disposicion.domainName !== '') && (d.tipo_entidad_disposicion.domainName !== ' ')) ? d.tipo_entidad_disposicion.domainName : '---';
      }
    },
    {
      Header: "Identificación",
      accessor: d => {
        return ((d.numero_documento) && (d.numero_documento !== '') && (d.numero_documento !== ' ')) ? d.numero_documento : '---';
      }
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'cancel',
            title: 'Desactivar Responsable',
            visible: (mode !== 'view'),
            handleAction: async () => {
              confirmAlert({
                title: `¿Esta seguro que desea desactivar al responsable: ${((original.nombre !== '') && (original.nombre !== undefined)) ? original.nombre : '---'}`,
                message: "al desactivar este responsable se desasocia del expediente",
                buttons: [
                  {
                    label: "DESACTIVAR FUNCIONARIO",
                    onClick: async () => {
                      await contactDeleteExpediente(original._id);
                    }
                  },
                  {
                    label: "VOLVER AL LISTADO",
                    onClick: () => {}
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true
              });
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const columnsEspecimenes = [
    {
      Header: 'Clase de recurso',
      accessor:  d => {
        return ((d.clase_recurso) && (d.clase_recurso.domainName !== '') && (d.clase_recurso.domainName !== ' ')) ? d.clase_recurso.domainName : '---';
      }
    },
    {
      Header: 'Estado',
      accessor:  d => {
        return ((d.estado) && (d.estado.domainName !== '') && (d.estado.domainName !== ' ')) ? d.estado.domainName : '---';
      }
    },
    {
      Header: 'Parte, producto o subproducto',
      accessor: d => {
        return ((d.parte_producto) && (d.parte_producto.domainName !== '') && (d.parte_producto.domainName !== ' ')) ? d.parte_producto.domainName : '---';
      }
    },
    {
      Header: 'Nombre Cientifíco',
      accessor: d => {
        return ((d.taxonomia) && (d.taxonomia.canonicalName !== '') && (d.taxonomia.canonicalName !== ' ')) ? d.taxonomia.canonicalName : '---';
      }
    },
    {
      Header: 'Nombre Común',
      accessor: d => {
        return ((d.nombre_comun) && (d.nombre_comun !== '') && (d.nombre_comun !== ' ')) ? d.nombre_comun : '---';
      }
    },
    {
      Header: 'Sexo',
      accessor: d => {
        return ((d.sexo) && (d.sexo.domainName !== '') && (d.sexo.domainName !== ' ')) ? d.sexo.domainName : '---';
      }
    },
    {
      Header: 'Estado de desarrollo',
      accessor: d => {
        return ((d.estado_desarrollo) && (d.estado_desarrollo.domainName !== '') && (d.estado_desarrollo.domainName !== ' ')) ? d.estado_desarrollo.domainName : '---';
      }
    },
    {
      Header: 'Estado de posesión',
      accessor: d => {
        return ((d.estado_posesion_expediente) && (d.estado_posesion_expediente.domainName !== '') && (d.estado_posesion_expediente.domainName !== ' ')) ? d.estado_posesion_expediente.domainName : '---';
      }
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => {
        const { original } = row;
        const buttons = [
          {
            id: original._id,
            iconName: 'remove_red_eye',
            title: 'Ver Especímen',
            visible: original.nuevo || selected,
            handleAction: async () => {
              await getByIdEspecimenRequest(original._id, 'view');
              handleOpenModalEspecimenes();
            }
          },
          {
            id: original._id,
            iconName: 'edit',
            title: 'Actualizar Especímen',
            visible: (mode !== 'view'),
            handleAction: async () => {
              await getByIdEspecimenRequest(original._id, 'edit');
              handleOpenModalEspecimenes();
            }
          },
        ];
        return <ButtonsActionRow buttons={buttons} />;
      },
    }
  ];

  const onSubmit = async (data, e) => {
    console.log("data para guardar",data)
    e.preventDefault();
    try {
      let isError = false;
      clearError(['departamento_procedimiento', 'municipio_procedimiento', 'departamento', 'municipio']);
      if(Number(data.pais_procedimiento) === 3000001 && data.departamento_procedimiento === '' && data.municipio_procedimiento === '' && !isError) {
        isError = true;
        setError(['departamento_procedimiento', 'municipio_procedimiento']);
        document.querySelector("select[name='departamento_procedimiento']").focus();
        Swal.fire({
          title: 'REGISTRAR DEPARTAMENTO Y MUNICIPIO!',
          text: 'Debe ingresar un departamento y un municipio para el procedimiento del expediente!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      if(Number(data.pais) === 3000001 && data.departamento === '' && data.municipio === '' && !isError) {
        isError = true;
        setError(['departamento', 'municipio']);
        document.querySelector("select[name='departamento']").focus();
        Swal.fire({
          title: 'REGISTRAR DEPARTAMENTO Y MUNICIPIO!',
          text: 'Debe ingresar un departamento y un municipio de la persona a la que se le hace el procedimiento!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      const funcionarios = contacts.map(({_id}) => _id);
      let personas = [
        {
          tipo_documento: Number(data.tipo_documento),
          numero_identificacion: data.numero_identificacion,
          nombre: data.nombre,
          pais: Number(data.pais),
          departamento: data.departamento ? Number(data.departamento) : '',
          municipio: data.municipio ? Number(data.municipio) : '',
          direccion: data.direccion,
          telefono: data.telefono,
          edad: Number(data.edad),
          ocupacion: data.ocupacion,
          nombre_padre: data.nombre_padre,
          nombre_madre: data.nombre_madre,
          declaracion: data.declaracion,
        }
      ];

      if(personas[0].departamento === '' && personas[0].municipio === '') {
        const { departamento, municipio, ...infoPersonas } = personas[0];
        personas = [{...infoPersonas}];
      }

      let newData = {
        ...data,
        coordenadas: {
          latitud: {
            grados: data.coordenadas.latitud.grados ? Number(parseInt(Number(data.coordenadas.latitud.grados), 10)) : '',
            minutos: data.coordenadas.latitud.minutos ? Number(parseInt(Number(data.coordenadas.latitud.minutos), 10)) : '',
            segundos: data.coordenadas.latitud.segundos ? Number(parseFloat(Number(data.coordenadas.latitud.segundos)).toFixed(2)) : '',
            hemisferio: data.coordenadas.latitud.hemisferio ? Number(data.coordenadas.latitud.hemisferio) : '',
            decimal: data.coordenadas.latitud.decimal ? Number(parseFloat(Number(data.coordenadas.latitud.decimal)).toFixed(5)) : '',
          },
          longitud: {
            grados: data.coordenadas.longitud.grados ? Number(parseInt(Number(data.coordenadas.longitud.grados), 10)) : '',
            minutos: data.coordenadas.longitud.minutos ? Number(parseInt(Number(data.coordenadas.longitud.minutos), 10)) : '',
            segundos: data.coordenadas.longitud.segundos ? Number(parseFloat(Number(data.coordenadas.longitud.segundos)).toFixed(2)) : '',
            hemisferio: data.coordenadas.longitud.hemisferio ? Number(data.coordenadas.longitud.hemisferio) : '',
            decimal: data.coordenadas.longitud.decimal ? Number(parseFloat(Number(data.coordenadas.longitud.decimal)).toFixed(5)) : '',
          },
          proveniente_mapa: true,
        },
        personas,
        funcionarios,
        tipo_procedimiento: Number(data.tipo_procedimiento),
        tipo_zona: Number(data.tipo_zona),
        tipo_sitio_procedimiento: Number(data.tipo_sitio_procedimiento),
        origen_procedimiento: Number(data.origen_procedimiento),
        fecha_procedimiento: parseDateTime(data.fecha_procedimiento),
        creacion_finalizada: true,
        flagrancia: (String(true) === data.flagrancia),
        pais_procedimiento: Number(data.pais_procedimiento),
        departamento_procedimiento: data.departamento_procedimiento ? Number(data.departamento_procedimiento) : '',
        municipio_procedimiento: data.municipio_procedimiento ? Number(data.municipio_procedimiento) : '',
      };

      if(newData.departamento_procedimiento === '' && newData.municipio_procedimiento === '') {
        const { departamento_procedimiento, municipio_procedimiento, ...infoData} = newData;
        newData = infoData;
      }

      // Extra validators
      let isEmptyLatitud = 0;
      let isEmptyLongitud = 0;

      Object.keys(newData.coordenadas.latitud).map(key => {
        if(newData.coordenadas.latitud[key] === '') {
          isEmptyLatitud +=1;
        };
      });

      Object.keys(newData.coordenadas.longitud).map(key => {
        if(newData.coordenadas.longitud[key] === '') {
          isEmptyLongitud +=1;
        };
      });

      if(contacts.length === 0 && !isError) {
        isError = true;
        document.querySelector("select[name='autoridad_ambiental']").focus();
        Swal.fire({
          title: 'REGISTRAR CONTACTO!',
          text: 'Debe asociar al menos un contacto en el expediente!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      const hayActaUnica = filesAux.filter(({ tipo }) => Number(tipo._id) === 14000001);
      if(hayActaUnica.length === 0) {
        isError = true;
        Swal.fire({
          title: 'REGISTRAR ACTA ÚNICA!',
          text: 'Para poder guardar el expediente debe incluir adjunto el archivo de Acta única!',
          icon: 'info',
          confirmButtonText: 'ACEPTAR'
        })
      }

      // if(files.length === 0 && !isError) {
      //   isError = true;
      //   document.querySelector("select[name='origen_procedimiento']").focus();
      //   Swal.fire({
      //     title: 'REGISTRAR SOPORTE!',
      //     text: 'Debe ingresar al menos un soporte de archivo en el expediente!',
      //     icon: 'error',
      //     confirmButtonText: 'ACEPTAR'
      //   })
      // }

      if(especimenesExpediente.length === 0 && !isError) {
        isError = true;
        document.querySelector("select[name='clase_recurso']").focus();
        Swal.fire({
          title: 'REGISTRAR ESPÉCIMEN!',
          text: 'Debe ingresar al menos un espécimen en el expediente!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      if(isEmptyLatitud === 5 && isEmptyLongitud === 5 && newData.justificacion_no_registrar === '' && !isError) {
        isError = true;
        document.querySelector("input[name='justificacion_no_registrar']").focus();
        Swal.fire({
          title: 'REGISTRAR JUSTIFICACIÓN!',
          text: 'Debe ingresar una justificación de no registrar ya que no se indico la georreferenciación o indicar las coordenadas según sea necesario',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      } else if(isEmptyLatitud === 1 && isEmptyLongitud === 1) {
        delete newData.coordenadas.latitud.decimal
        delete newData.coordenadas.longitud.decimal
      }

      if(enableFieldOtroSitio && newData.otro_sitio_procedimiento === '' && !isError) {
        isError = true;
        document.querySelector("input[name='otro_sitio_procedimiento']").focus();
        Swal.fire({
          title: 'REGISTRAR OTRO SITIO!',
          text: 'Debe ingresar el otro sitio del procedimiento ya que selecciono otro sitio de procedimiento!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      if(enableFieldOtroOrigen && newData.otro_origen_procedimiento === '' && !isError) {
        isError = true;
        document.querySelector("input[name='otro_origen_procedimiento']").focus();
        Swal.fire({
          title: 'REGISTRAR OTRO ORIGEN!',
          text: 'Debe ingresar el otro origen del procedimiento ya que selecciono otro origen de procedimiento!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      if(images.length === 0 && newData.justificacion_sin_imagen === '' && !isError) {
        isError = true;
        document.querySelector("input[name='justificacion_sin_imagen']").focus();
        Swal.fire({
          title: 'REGISTRAR JUSTIFICACIÓN IMAGEN!',
          text: 'Debe ingresar una justificación de sin imagen ya que no se adjuntaron imágenes!',
          icon: 'error',
          confirmButtonText: 'ACEPTAR'
        })
      }

      // End Extra Validators
      let idExpediente = '';
      if (selected && !isError) {
        let infoUpdate = {
          ...newData,
          _id: selected._id,
          estado: 22000001
        };

        if(isEmptyLatitud === 4 && isEmptyLongitud === 4) {
          console.log("isEmptyLatitud === 4")
          const { coordenadas, ...dataUpdate } = infoUpdate;
          infoUpdate = dataUpdate;
        } else if(isEmptyLatitud === 3 && isEmptyLongitud === 3) {
          console.log("isEmptyLatitud === 3")
          infoUpdate = {
            ...infoUpdate,
            coordenadas: {
              latitud: {
                decimal: data.coordenadas.latitud.decimal ? Number(parseFloat(Number(data.coordenadas.latitud.decimal)).toFixed(5)) : '',
              },
              longitud: {
                decimal: data.coordenadas.longitud.decimal ? Number(parseFloat(Number(data.coordenadas.longitud.decimal)).toFixed(5)) : '',
              },
              proveniente_mapa: false,
            }
          };
        } else if(isEmptyLatitud === 1 && isEmptyLongitud === 1) {
          console.log("isEmptyLatitud === 1")
          infoUpdate = {
            ...infoUpdate,
            coordenadas: {
              latitud: {
                grados: data.coordenadas.latitud.grados ? Number(parseInt(Number(data.coordenadas.latitud.grados), 10)) : '',
                minutos: data.coordenadas.latitud.minutos ? Number(parseInt(Number(data.coordenadas.latitud.minutos), 10)) : '',
                segundos: data.coordenadas.latitud.segundos ? Number(parseFloat(Number(data.coordenadas.latitud.segundos)).toFixed(2)) : '',
                hemisferio: data.coordenadas.latitud.hemisferio ? Number(data.coordenadas.latitud.hemisferio) : '',
              },
              longitud: {
                grados: data.coordenadas.longitud.grados ? Number(parseInt(Number(data.coordenadas.longitud.grados), 10)) : '',
                minutos: data.coordenadas.longitud.minutos ? Number(parseInt(Number(data.coordenadas.longitud.minutos), 10)) : '',
                segundos: data.coordenadas.longitud.segundos ? Number(parseFloat(Number(data.coordenadas.longitud.segundos)).toFixed(2)) : '',
                hemisferio: data.coordenadas.longitud.hemisferio ? Number(data.coordenadas.longitud.hemisferio) : '',
              },
              proveniente_mapa: false,
            }
          };
        } else if(isEmptyLatitud === 5 && isEmptyLongitud === 5) {
          console.log("isEmptyLatitud === 5")
          infoUpdate = {
            ...infoUpdate,
            proveniente_mapa: false,
          }
          delete infoUpdate.coordenadas
        }

        const { msg, err, _id } = await updateExpedienteRequest(infoUpdate, files, images, especimenesExpediente, token);
        isError = err;
        idExpediente = _id;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      } else if(!isError) {
        let infoCreate = {
          ...newData,
          estado: 22000001
        };

        if(isEmptyLatitud === 3 && isEmptyLongitud === 3) {
          const { coordenadas, ...dataCreate } = infoCreate;
          infoCreate = dataCreate;
        } else if(isEmptyLatitud === 4 && isEmptyLongitud === 4) {
          infoCreate = {
            ...infoCreate,
            coordenadas: {
              latitud: {
                decimal: data.coordenadas.latitud.decimal ? Number(parseFloat(Number(data.coordenadas.latitud.decimal)).toFixed(5)) : '',
              },
              longitud: {
                decimal: data.coordenadas.longitud.decimal ? Number(parseFloat(Number(data.coordenadas.longitud.decimal)).toFixed(5)) : '',
              },
              proveniente_mapa: false,
            }
          };
        } else if(isEmptyLatitud === 5 && isEmptyLongitud === 5) {
          infoCreate = {
            ...infoCreate,
            coordenadas: {
              latitud: {
                grados: data.coordenadas.latitud.grados ? Number(parseInt(Number(data.coordenadas.latitud.grados), 10)) : '',
                minutos: data.coordenadas.latitud.minutos ? Number(parseInt(Number(data.coordenadas.latitud.minutos), 10)) : '',
                segundos: data.coordenadas.latitud.segundos ? Number(parseFloat(Number(data.coordenadas.latitud.segundos)).toFixed(2)) : '',
                hemisferio: data.coordenadas.latitud.hemisferio ? Number(data.coordenadas.latitud.hemisferio) : '',
              },
              longitud: {
                grados: data.coordenadas.longitud.grados ? Number(parseInt(Number(data.coordenadas.longitud.grados), 10)) : '',
                minutos: data.coordenadas.longitud.minutos ? Number(parseInt(Number(data.coordenadas.longitud.minutos), 10)) : '',
                segundos: data.coordenadas.longitud.segundos ? Number(parseFloat(Number(data.coordenadas.longitud.segundos)).toFixed(2)) : '',
                hemisferio: data.coordenadas.longitud.hemisferio ? Number(data.coordenadas.longitud.hemisferio) : '',
              },
              proveniente_mapa: false,
            }
          };
        }

        const { msg, err, _id } = await createExpedienteRequest(infoCreate, files, images, especimenesExpediente, token);
        isError = err;
        idExpediente = _id;
        createNotification({
          message: msg,
          error: err,
          visible: true
        });
      }

      if(!isError) {
        reset();
        if(mode === 'edit') await gotoExpedientes();
        else if(mode === null) await gotoExpedienteEdit(idExpediente);
      }
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  // useEffect(() => {
  //   loadTaxonomyInfo();
  // }, [taxonomyInformation]);

  useEffect(() => {
      loadExpedienteSelected();
  }, [selected, match.params.mode]);

  useEffect(() => {
    loadEspecimenesSelected();
  }, [selected])

  useEffect(() => {
    if(match.params.mode !== 'view') {
      loadMunicipioProceSelected();
    }
  }, [municipiosFiltradosProce, match.params.mode]);

  useEffect(() => {
    if(match.params.mode !== 'view') {
      loadMunicipioPersoSelected();
    }
  }, [municipiosFiltradosPerso, match.params.mode]);

  useEffect(() => {
    if(!isLoggedIn) {
      gotoLogin();
    } else if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
      setLoadedInfo(true);
    }
  }, [isLoggedIn, load, loaded, gotoLogin]);

  useEffect(() => {
    setLatitudDecimal(false);
    setLongitudDecimal(false);
    setLatitudCoord(true);
    setLongitudCoord(true);
    if (reverseGeocodingResult) {
      setSuggestedValues();
    }
  }, [reverseGeocodingResult, setSuggestedValues]);

  useEffect(() => {
    if (typeof match.params.id === 'undefined' && typeof match.params.mode === 'undefined') {
      clearExpediente();
    }
  }, [clearExpediente, match.params.id, match.params.mode]);

  useEffect(() => {
    // console.log(match.params.id, match.params.mode, history, match);
  }, [history, match]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            {(isLoading) && (
              <>
                <br />
                <div>Cargando información por favor espere un momento...</div>
                <div>
                  <SkeletonTheme color="#f42f6391" highlightColor="#444">
                    <Skeleton height={34}/>
                  </SkeletonTheme>
                </div>
              </>
            )}
            <PageHeader>
              <ContainerHeader>
                <div className="header-col">
                  <H3>Expediente de Tráfico Ilegal</H3>
                </div>
                <div className="header-col">
                  <span className="text-info">Estado del expediente</span>
                  <H3>{selected ? selected.estado.domainName : '-- -- --'}</H3>
                </div>
                {(selected && (Number(selected.estado._id) === 22000002 || Number(selected.estado._id) === 22000003)) && (
                  <div className="header-col">
                    <span className="text-info">Código del expediente</span>
                    <H3>{selected ? ((selected.codigo_expediente) || '---') : '---'}</H3>
                  </div>
                )}
                <div className="header-col">
                  <span className="text-info">Número de acta</span>
                  <H3>{selected ? selected.acta : ((watch('acta') !== '') ? watch('acta') : '-- -- --')}</H3>
                </div>
              </ContainerHeader>
            </PageHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card title=" ">
                <GridContainer>
                  <CardTitleBox>
                    <H1>Responsable del procedimiento</H1>
                  </CardTitleBox>
                  {mode !== 'view' && (
                    <Row>
                      <Col1>
                        <SpanContact onClick={(e) => handleOpenModalContacts(e)}>
                          <span className="button-item"><Icon>add_circle</Icon> AGREGAR RESPONSABLE</span> <span className="obligatory">*</span>
                        </SpanContact>
                      </Col1>
                    </Row>
                  )}
                  <Row>
                    <Col1>
                      <SpanTextInfo>Lista de responsables del expediente. Si requiere modificar o eliminar algún responsable haga click en las acciones.</SpanTextInfo>
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      <PaginationTable columns={columnsContacts} data={contacts} />
                    </Col1>
                  </Row>
                </GridContainer>
              </Card>
              <Card title=" ">
                <GridContainer>
                  <CardTitleBox>
                    <H1>Datos generales</H1>
                  </CardTitleBox>
                  <Row>
                    <Col3>
                      <Label>
                        AUTORIDAD AMBIENTAL <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="autoridad_ambiental" ref={register({ required: true })}>
                              <SelectOptions selectOptions={autoridadesAmbientales} />
                            </Select>
                            {errors.autoridad_ambiental && errors.autoridad_ambiental.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La autoridad ambiental es requerida!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.autoridad_ambiental ? selected.autoridad_ambiental.nombre : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NÚMERO DE ACTA ÚNICA <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="acta"
                                type="text"
                                placeholder="Escriba el número de acta única"
                                ref={register({ required: true, maxLength: 50 })}
                              />
                              {errors.acta && errors.acta.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El número de acta única es requerida!
                                </p>
                              )}
                              {errors.acta && errors.acta.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El número de acta única le sobran dígitos máximo (50)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.acta ? selected.acta : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        TIPO DE EVENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Select style={{ width: '100%' }} name="tipo_procedimiento" ref={register({ required: true })}>
                                <SelectOptions selectOptions={domainsByKey.tiposEvento} />
                              </Select>
                              {errors.tipo_procedimiento && errors.tipo_procedimiento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El tipo de evento es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.tipo_procedimiento ? selected.tipo_procedimiento.domainName : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        FECHA DE EVENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="fecha_procedimiento"
                                type="date"
                                placeholder="dd/mm/yyyy"
                                ref={register({ required: true })}
                              />
                              {errors.fecha_procedimiento && errors.fecha_procedimiento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La fecha de evento es requerida!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.fecha_procedimiento ? splitDate(selected.fecha_procedimiento) : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        TIPO DE ZONA <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <br />
                              <input
                                name="tipo_zona"
                                value={4000002}
                                type="radio"
                                ref={register({ required: true })}
                                defaultChecked
                              /> Urbana
                              <input
                                name="tipo_zona"
                                value={4000001}
                                type="radio"
                                ref={register({ required: true })}
                              /> Rural
                              {errors.tipo_zona && errors.tipo_zona.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El tipo de zona es requido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.tipo_zona ? selected.tipo_zona.domainName : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        PAIS <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Select style={{ width: '100%' }} name="pais_procedimiento" onChange={handleChangePaisProce} ref={register({ required: true })}>
                                <SelectOptions selectOptions={constantsByKey.paises} />
                              </Select>
                              {errors.pais_procedimiento && errors.pais_procedimiento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El país de procedimiento es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.pais_procedimiento ? selected.pais_procedimiento.constantName : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        DEPARTAMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Select style={{ width: '100%' }} name="departamento_procedimiento" onChange={handleChangeDptoProce} ref={register} readOnly={departamentosProceReadOnly}>
                                <SelectOptions selectOptions={constantsByKey.departamentos} />
                              </Select>
                              {errors.departamento_procedimiento && errors.departamento_procedimiento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El departamento de procedimiento es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.departamento_procedimiento ? selected.departamento_procedimiento.constantName : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CIUDAD O MUNICIPIO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                            <>
                              <Select style={{ width: '100%' }} name="municipio_procedimiento" ref={register} readOnly={municipiosProceReadOnly}>
                                <SelectOptions selectOptions={municipiosFiltradosProce} />
                              </Select>
                              {errors.municipio_procedimiento && errors.municipio_procedimiento.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El municipio de procedimiento es requerido!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.municipio_procedimiento ? selected.municipio_procedimiento.constantName : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col4>
                      <Label>
                        CORREGIMIENTO
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="corregimiento_procedimiento"
                                type="text"
                                placeholder="Escriba el nombre del corregimiento"
                                ref={register({maxLength: 100})}
                              />
                              {errors.corregimiento_procedimiento && errors.corregimiento_procedimiento.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre del corregimiento le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.corregimiento_procedimiento ? selected.corregimiento_procedimiento : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        VEREDA
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="vereda_procedimiento"
                                type="text"
                                placeholder="Escriba el nombre de la vereda"
                                ref={register({maxLength: 100})}
                              />
                              {errors.vereda_procedimiento && errors.vereda_procedimiento.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre de la vereda le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.vereda_procedimiento ? selected.vereda_procedimiento : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        BARRIO
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="barrio_procedimiento"
                                type="text"
                                placeholder="Ingrese el nombre del barrio"
                                ref={register({maxLength: 50})}
                              />
                              {errors.barrio_procedimiento && errors.barrio_procedimiento.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre del barrio le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.barrio_procedimiento ? selected.barrio_procedimiento : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col4>
                    <Col4>
                      <Label>
                        DIRECCIÓN
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="direccion_procedimiento"
                                type="text"
                                placeholder="Ingrese dirección del procedimiento"
                                ref={register({maxLength: 200})}
                              />
                              {errors.direccion_procedimiento && errors.direccion_procedimiento.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La dirección del procedimiento le sobran caracteres máximo (200)!
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.direccion_procedimiento ? selected.direccion_procedimiento : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col4>
                  </Row>
                </GridContainer>
              </Card>
              <Card title=" ">
                <GridContainer>
                  <CardTitleBox>
                    <H1>Ubicación del procedimiento</H1>
                  </CardTitleBox>
                  <Row>
                    <Col1>
                      <Label>
                        <span className="text-info">Si el acta única no cuenta con las coordenadas pero conoce la ubicación del procedimiento, busque el sitio y márquelo en el mapa.
                          Si cuenta con las coordenadas, documéntelas en los campos correspondientes. Si elige la captura de coordenadas con mapa, haga Zoom in (acercarce) al área
                          del procedimiento para que la coordenada sea capturada correctamente.</span><br /><br />
                        TIPO DE COORDENADAS <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <Row>
                            <Col3>
                              <Select style={{ width: '100%' }} name="tipo_coordenadas" onChange={handleChangeTipoCoord} ref={register}>
                                <SelectOptions selectOptions={tiposCoordenadas} />
                              </Select>
                              {errors.tipo_coordenadas && errors.tipo_coordenadas.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El tipo de coordenadas es requerido!
                                </p>
                              )}
                            </Col3>
                          </Row>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? labelTypeCoord(selected.coordenadas) : '---'}</SpanView>
                          </>
                        )}
                        {enabledMap && (<MapWithGeocoderControl setResult={setResult} />) }
                      </Label>
                    </Col1>
                  </Row>
                  <Row>
                    <Col5>
                      <Label>
                        LATITUD
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.latitud.grados"
                              type="text"
                              placeholder="Grados"
                              ref={register}
                              readOnly={latitudCoord}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.latitud.grados ? selected.coordenadas.latitud.grados : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.latitud.minutos"
                              type="text"
                              placeholder="Minutos"
                              ref={register}
                              readOnly={latitudCoord}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.latitud.minutos ? selected.coordenadas.latitud.minutos : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.latitud.segundos"
                              type="text"
                              placeholder="Segundos"
                              ref={register}
                              readOnly={latitudCoord}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.latitud.segundos ? selected.coordenadas.latitud.segundos : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="coordenadas.latitud.hemisferio" ref={register} readOnly={latitudCoord}>
                              <SelectOptions selectOptions={constantsByKey.hemisferioLatitud} />
                            </Select>
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.latitud.hemisferio ? (<span style={{ textTransform: 'uppercase' }}>{getLabelHemisferioLat(selected.coordenadas.latitud.hemisferio)}</span>) : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <span className="text-info">
                          valor decimal: <Icon
                                data-for="main"
                                data-tip="1. Si las coordenadas en el acta única fueron capturadas en Grados, minutos, segundos (ej: Lat: 4°52'25''N, Lon: 7°25'42''W),<br />documéntelas en los campos correspondientes del formulario, y las coordenadas decimales se generarán automáticamente.<br />
                                2. Si las coordenadas en el acta única fueron tomadas en grados decimales (ej: 4.75842, -75.24568)<br />documéntelas en los campos de coordenadas decimales del formulario.<br />
                                3. Si seleccionó la ubicación del procedimiento en el mapa las coordenadas decimales se generarán automáticamente.<br />
                                4. Si no es posible ingresar las coordenadas ingrese el motivo en el campo correspondiente.<br />"
                                data-iscapture="true"
                                data-multiline="true"
                                data-place="left"
                                data-class="tooltipfield"
                                data-event="click"
                                style={{ cursor: 'pointer' }}
                              >
                                contact_support
                              </Icon>
                        </span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.latitud.decimal"
                              type="number"
                              step="any"
                              ref={register}
                              readOnly={latitudDecimal}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.latitud.decimal ? selected.coordenadas.latitud.decimal : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                  </Row>
                  <Row>
                    <Col5>
                      <Label>
                        LONGITUD
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.longitud.grados"
                              type="text"
                              placeholder="Grados"
                              ref={register}
                              readOnly={longitudCoord}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.longitud.grados ? selected.coordenadas.longitud.grados : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="coordenadas.longitud.minutos"
                                type="text"
                                placeholder="Minutos"
                                ref={register}
                                readOnly={longitudCoord}
                              />
                              {/* {errors.coordenadas.longitud.minutos && errors.coordenadas.longitud.minutos.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  Los minutos de la longitud son requeridos!
                                </p>
                              )} */}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.longitud.minutos ? selected.coordenadas.longitud.minutos : '---') : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="coordenadas.longitud.segundos"
                                type="text"
                                placeholder="Segundos"
                                ref={register}
                                readOnly={longitudCoord}
                              />
                              {/* {errors.coordenadas.longitud.segundos && errors.coordenadas.longitud.segundos.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  Los segundos de la longitud son requeridos!
                                </p>
                              )} */}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.longitud.segundos ? selected.coordenadas.longitud.segundos : '---'): '----'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <br />
                        {mode !== 'view' ? (
                            <>
                              <Select style={{ width: '100%' }} name="coordenadas.longitud.hemisferio" ref={register} readOnly={longitudCoord}>
                                <SelectOptions selectOptions={constantsByKey.hemisferioLongitud} />
                              </Select>
                              {/* <Input
                                style={{ width: '100%' }}
                                name="coordenadas.longitud.hemisferio"
                                type="text"
                                placeholder="Hemisferio"
                                ref={register}
                              /> */}
                            </>
                          ) : (
                            <>
                              <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.longitud.hemisferio ? (<span style={{ textTransform: 'uppercase' }}>{getLabelHemisferioLong(selected.coordenadas.longitud.hemisferio)}</span>) : '---') : '---'}</SpanView>
                            </>
                          )
                        }
                      </Label>
                    </Col5>
                    <Col5>
                      <Label>
                        <span className="text-info">
                          valor decimal: <Icon
                                data-for="main"
                                data-tip="1. Si las coordenadas en el acta única fueron capturadas en Grados, minutos, segundos (ej: Lat: 4°52'25''N, Lon: 7°25'42''W),<br />documéntelas en los campos correspondientes del formulario, y las coordenadas decimales se generarán automáticamente.<br />
                                2. Si las coordenadas en el acta única fueron tomadas en grados decimales (ej: 4.75842, -75.24568)<br />documéntelas en los campos de coordenadas decimales del formulario.<br />
                                3. Si seleccionó la ubicación del procedimiento en el mapa las coordenadas decimales se generarán automáticamente.<br />
                                4. Si no es posible ingresar las coordenadas ingrese el motivo en el campo correspondiente.<br />"
                                data-iscapture="true"
                                data-multiline="true"
                                data-place="left"
                                data-class="tooltipfield"
                                data-event="click"
                                style={{ cursor: 'pointer' }}
                              >
                                contact_support
                              </Icon>
                        </span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="coordenadas.longitud.decimal"
                              type="number"
                              step="any"
                              ref={register}
                              readOnly={longitudDecimal}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.coordenadas ? (selected.coordenadas.longitud.decimal ? selected.coordenadas.longitud.decimal : '---') : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col5>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        <span className="text-info">Si no es posible documentar la ubicación del procedimiento, indique el motivo:</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              readOnly={ingresaCoordenadas}
                              name="justificacion_no_registrar"
                              type="text"
                              placeholder="Ingrese texto aquí."
                              ref={register}
                            />
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.justificacion_no_registrar ? selected.justificacion_no_registrar : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                </GridContainer>
              </Card>
              <Card title=" ">
                <GridContainer>
                  <CardTitleBox>
                    <H1>Información del procedimiento</H1>
                  </CardTitleBox>
                  <Row>
                    <Col1>
                      <Label>
                        FLAGRANCIA <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <br />
                            <input
                              name="flagrancia"
                              value
                              type="radio"
                              ref={register({ required: true })}
                            /> Sí
                            <input
                              name="flagrancia"
                              value={false}
                              type="radio"
                              ref={register({ required: true })}
                              defaultChecked
                            /> No
                            {errors.flagrancia && errors.flagrancia.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La flagrancia es requerida!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.flagrancia ? 'Si' : 'No'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        SITIO DEL PROCEDIMIENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="tipo_sitio_procedimiento" onChange={handleChangeSitio} ref={register({ required: true })}>
                              <SelectOptions selectOptions={domainsByKey.tiposProcedimientoSitio} />
                            </Select>
                            {errors.tipo_sitio_procedimiento && errors.tipo_sitio_procedimiento.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El sitio de procedimiento es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.tipo_sitio_procedimiento ? selected.tipo_sitio_procedimiento.domainName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        ¿CUAL?
                        {(mode !== 'view' && enableFieldOtroSitio) ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="otro_sitio_procedimiento"
                              type="text"
                              placeholder="Ingrese información adicional"
                              ref={register({maxLength: 100})}
                            />
                            {errors.otro_sitio_procedimiento && errors.otro_sitio_procedimiento.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El otro sitio de procedimiento le sobran caracteres máximo (100)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{(mode === 'view' && selected.otro_sitio_procedimiento) ? selected.otro_sitio_procedimiento : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        ORIGEN DEL PROCEDIMIENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="origen_procedimiento" onChange={handleChangeOrigen} ref={register({ required: true })}>
                              <SelectOptions selectOptions={domainsByKey.tiposProcedimientoOrigen} />
                            </Select>
                            {errors.origen_procedimiento && errors.origen_procedimiento.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El origen del procedimiento es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.origen_procedimiento ? selected.origen_procedimiento.domainName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        ¿CUAL?
                        {(mode !== 'view' && enableFieldOtroOrigen) ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="otro_origen_procedimiento"
                              type="text"
                              placeholder="Ingrese información adicional"
                              ref={register({maxLength: 50})}
                            />
                            {errors.otro_origen_procedimiento && errors.otro_origen_procedimiento.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El otro origen de procedimiento le sobran caracteres máximo (50)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{(mode === 'view' && selected.otro_origen_procedimiento) ? selected.otro_origen_procedimiento : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        ADJUNTAR SOPORTES <span className="obligatory">*</span><br />
                      </Label>
                      <PaginationTableRowSelect columns={columnsFiles} data={filesAux} onClick={handleDeleteFiles} eraser={((!selected) || (mode === 'edit'))} textEraser="QUITAR" />
                    </Col1>
                  </Row>
                  {mode !== 'view' &&
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        {/* <Label>
                          <Button type="button" onClick={(e) => handleClearFiles(e) }>
                            QUITAR
                          </Button>
                        </Label> */}
                        <Label>
                          <Button type="button" onClick={(e) => handleOpenModalFiles(e)}>
                            ADJUNTAR
                          </Button>
                        </Label>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col1>
                      <Label>
                        ADJUNTAR IMÁGENES <br />
                      </Label>
                      <PaginationTableRowSelect columns={columnsImages} data={imagesAux} onClick={handleDeleteImages} eraser={((!selected) || (mode === 'edit'))} textEraser="QUITAR" />
                    </Col1>
                  </Row>
                  {mode !== 'view' &&
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col right>
                        {/* <Label>
                          <Button type="button" onClick={(e) => handleClearImages(e) }>
                            QUITAR
                          </Button>
                        </Label> */}
                        <Label>
                          <Button type="button" onClick={(e) => handleOpenModalImages(e)}>
                            ADJUNTAR
                          </Button>
                        </Label>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col1>
                      <Label>
                        <span className="text-info">En caso de no adjuntar imágenes indique el motivo.</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="justificacion_sin_imagen"
                              type="text"
                              placeholder=""
                              ref={register({maxLength: 200})}
                            />
                            {errors.justificacion_sin_imagen && errors.justificacion_sin_imagen.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                La justificación sin imagen le sobran caracteres máximo (200)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.justificacion_sin_imagen ? selected.justificacion_sin_imagen : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        PROCEDIMIENTO ADMINISTRATIVO
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="proceso_administrativo"
                              type="text"
                              placeholder="Ingrese el número de registro"
                              ref={register({maxLength: 30})}
                            />
                            {errors.proceso_administrativo && errors.proceso_administrativo.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El número de registro le sobran caracteres máximo (30)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.proceso_administrativo ? selected.proceso_administrativo : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        PROCEDIMIENTO JUDICIAL
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="proceso_judicial"
                              type="text"
                              placeholder="Ingrese el número de registro"
                              ref={register({maxLength: 30})}
                            />
                            {errors.proceso_judicial && errors.proceso_judicial.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El número de registro le sobran caracteres máximo (30)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.proceso_judicial ? selected.proceso_judicial : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col1>
                      <Label style={{ width: '100%' }}>
                        OBSERVACIONES
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="observaciones"
                              type="text"
                              placeholder="Ingrese el texto aquí"
                              ref={register({minLength: 5, maxLength: 500})}
                            />
                            {errors.observaciones && errors.observaciones.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                Las observaciones del expediente le falta caracteres mínimo (5)!
                              </p>
                            )}
                            {errors.observaciones && errors.observaciones.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                Las observaciones del expediente le sobran caracteres máximo (500)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.observaciones ? selected.observaciones : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                  <Row>
                    <Col1>
                      <SpanDivide>
                        <br />
                        Información de la persona a la que se le hace el procedimiento.
                      </SpanDivide>
                    </Col1>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        TIPO DOCUMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="tipo_documento" ref={register({ required: true })}>
                              <SelectOptions selectOptions={constantsByKey.tiposDocumento} />
                            </Select>
                            {errors.tipo_documento && errors.tipo_documento.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El tipo de documento de la persona es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.tipo_documento.constantName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NÚMERO DE DOCUMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="numero_identificacion"
                              type="text"
                              placeholder="Escriba el número del documento"
                              ref={register({ required: true, maxLength: 10 })}
                            />
                            {errors.numero_identificacion && errors.numero_identificacion.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El número de documento de la persona es requerido!
                              </p>
                            )}
                            {errors.numero_identificacion && errors.numero_identificacion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El número de documento le sobran dígitos máximo (10)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.numero_identificacion : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        EDAD
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="edad"
                              type="number"
                              placeholder="Solo números"
                              ref={register({
                                required: true,
                                pattern: { value: /^[0-9]+$/ },
                                minLength: 2,
                                maxLength: 3
                              })}
                            />
                            {errors.edad && errors.edad.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La edad de la persona es requerida!
                              </p>
                            )}
                            {errors.edad && errors.edad.type === "minLength" && (
                              <p style={{color: 'red'}}>
                                La edad de la persona le falta dígitos mínimo (2)!
                              </p>
                            )}
                            {errors.edad && errors.edad.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                La edad de la persona le sobran dígitos máximo (3)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.edad : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        NOMBRE COMPLETO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="nombre"
                              type="text"
                              placeholder="Ingrese nombre"
                              ref={register({ required: true, maxLength: 50 })}
                            />
                            {errors.nombre && errors.nombre.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El nombre completo de la persona es requerido!
                              </p>
                            )}
                            {errors.nombre && errors.nombre.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El nombre completo de la persona le sobran caracteres máximo (50)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.nombre : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        OCUPACIÓN
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="ocupacion"
                              type="text"
                              placeholder="Ingrese ocupación"
                              ref={register({maxLength: 50})}
                            />
                            {errors.ocupacion && errors.ocupacion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                La ocupación de la persona le sobran caracteres máximo (50)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.ocupacion : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        PAIS <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="pais" onChange={handleChangePaisPerso} ref={register({ required: true })}>
                              <SelectOptions selectOptions={constantsByKey.paises} />
                            </Select>
                            {errors.pais && errors.pais.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El país de la persona es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.pais.constantName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        DEPARTAMENTO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="departamento" onChange={handleChangeDptoPerso} ref={register} readOnly={departamentosPersoReadOnly}>
                              <SelectOptions selectOptions={constantsByKey.departamentos} />
                            </Select>
                            {errors.departamento && errors.departamento.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El departamento de la persona es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.departamento.constantName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CIUDAD O MUNICIPIO <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Select style={{ width: '100%' }} name="municipio" ref={register} readOnly={municipiosPersoReadOnly}>
                              <SelectOptions selectOptions={municipiosFiltradosPerso} />
                            </Select>
                            {errors.municipio && errors.municipio.type === "required" && (
                              <p style={{ color: 'red'}}>
                                El municipio de la persona es requerido!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.municipio.constantName : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        DIRECCIÓN <span className="obligatory">*</span>
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="direccion"
                              type="text"
                              placeholder="Ingrese dirección de residencia"
                              ref={register({required: true, maxLength: 100})}
                            />
                            {errors.direccion && errors.direccion.type === "required" && (
                              <p style={{ color: 'red'}}>
                                La dirección de la persona es requerida!
                              </p>
                            )}
                            {errors.direccion && errors.direccion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                La dirección de la persona le sobran caracteres máximo (100)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.direccion : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        TELÉFONO
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="telefono"
                              type="text"
                              placeholder="Ingrese teléfono"
                              ref={register({
                                maxLength: 20,
                                // pattern: {
                                //   value: /^[0-9]{2,3}-? ?[0-9]{6,7}$/,
                                //   message: 'Debe ingresar un número de telefono válido!'
                                // }
                              })}
                            />
                            {/* {errors.telefono && errors.telefono.type === "pattern" && (
                              <p style={{color: 'red'}}>
                                El teléfono debe contener solo números!
                              </p>
                            )} */}
                            {errors.telefono && errors.telefono.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El teléfono de la persona le sobran dígitos máximo (20)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.telefono : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col2>
                      <Label>
                        NOMBRE DEL PADRE
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="nombre_padre"
                              type="text"
                              placeholder="Ingrese nombre"
                              ref={register({maxLength: 100})}
                            />
                            {errors.nombre_padre && errors.nombre_padre.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El nombre del padre le sobran caracteres máximo (100)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.nombre_padre : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                    <Col2>
                      <Label>
                        NOMBRE DE LA MADRE
                        {mode !== 'view' ? (
                          <>
                            <Input
                              style={{ width: '100%' }}
                              name="nombre_madre"
                              type="text"
                              placeholder="Ingrese nombre"
                              ref={register({maxLength: 100})}
                            />
                            {errors.nombre_madre && errors.nombre_madre.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El nombre de la madre le sobran caracteres máximo (100)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.nombre_madre : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col2>
                  </Row>
                  <Row>
                    <Col1>
                      <Label>
                        DECLARACIÓN
                        {mode !== 'view' ? (
                          <>
                            <Textarea
                              rows="6"
                              style={{ width: '100%' }}
                              name="declaracion"
                              type="text"
                              placeholder=""
                              ref={register({maxLength: 500})}
                            />
                            {errors.declaracion && errors.declaracion.type === "maxLength" && (
                              <p style={{color: 'red'}}>
                                El declaración le sobran caracteres máximo (500)!
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <br /><br /><SpanView>{selected.personas ? selected.personas.declaracion : '---'}</SpanView>
                          </>
                        )}
                      </Label>
                    </Col1>
                  </Row>
                </GridContainer>
              </Card>
              <Card title=" ">
                <GridContainer scroll>
                  <CardTitleBox>
                    <H1>Especímenes</H1>
                  </CardTitleBox>
                  {mode !== 'view' && (
                    <>
                      <Row>
                        <Col3>
                          <Label>
                            CLASE DE RECURSO <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="clase_recurso" ref={register}>
                                <SelectOptions selectOptions={domainsByKey.tiposRecurso} />
                              </Select>
                              {errors.clase_recurso && errors.clase_recurso.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La clase de recurso del espécimen es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO <span className="obligatory">*</span><br />
                            <>
                              <input
                                name="estado"
                                value={8000001}
                                type="radio"
                                ref={register({ required: true })}
                                onChange={handleChangeEstadoEspecimen}
                                defaultChecked
                              /> Vivo
                              <input
                                name="estado"
                                value={8000002}
                                type="radio"
                                ref={register({ required: true })}
                                onChange={handleChangeEstadoEspecimen}
                              /> Muerto
                              {errors.estado && errors.estado.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El estado del espécimen es requerido!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            PARTE, PRODUCTO O SUBPRODUCTO
                            {(enableParteProducto) && (
                              <>
                                <Select style={{ width: '100%' }} name="parte_producto" ref={register}>
                                  <SelectOptions selectOptions={domainsByKey.tiposParte} />
                                </Select>
                              </>
                            )}
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col2>
                          <Label>
                            CATEGORÍA TAXONÓMICA <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="taxonRank" ref={register}>
                                <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia} />
                              </Select>
                              {errors.taxonRank && errors.taxonRank.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La categoria taxonómica es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                        <Col2>
                          <Label>
                            NOMBRE CIENTÍFICO <span className="obligatory">*</span>
                            <>
                              <Autocomplete
                                name="canonicalName"
                                placeholder="Indique nombre científico"
                                suggestions={suggestions}
                                loading={loadingAutoComplete}
                                activeSuggestion={activeSuggestion}
                                showSuggestions={showSuggestions}
                                onChange={handleAutoCompleteChange}
                                onKeyDown={handleAutoCompleteKeyDown}
                                onClick={handleAutoCompleteClick}
                                userInput={userInput}
                              />
                              <Input
                                style={{ width: '100%' }}
                                name="taxonomia"
                                type="hidden"
                                ref={register}
                              />
                              {errors.taxonomia && errors.taxonomia.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El nombre científico es requerido!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                      </Row>
                      <Row>
                        <Col2>
                          <Label>
                            GRUPO BIOLÓGICO
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="PIFS_grupoBio"
                                type="text"
                                placeholder="Ingrese nombre biológico"
                                ref={register}
                                readOnly
                              />
                            </>
                          </Label>
                        </Col2>
                        <Col2>
                          <Label>
                            NOMBRE COMÚN
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="nombre_comun"
                                type="text"
                                placeholder="Ingrese nombre común"
                                ref={register({maxLength: 80})}
                              />
                              {errors.nombre_comun && errors.nombre_comun.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre común le sobran caracteres máximo (80)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            SEXO <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="sexo" ref={register}>
                                <SelectOptions selectOptions={domainsByKey.tiposSexo} />
                              </Select>
                              {errors.sexo && errors.sexo.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El sexo del espécimen es requerido!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CONDICIÓN <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="condicion" ref={register}>
                                <SelectOptions selectOptions={domainsByKey.estadosCondicion} />
                              </Select>
                              {errors.condicion && errors.condicion.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La condición del espécimen es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO DE DESARROLLO <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="estado_desarrollo" ref={register}>
                                <SelectOptions selectOptions={domainsByKey.estadosDesarrollo} />
                              </Select>
                              {errors.estado_desarrollo && errors.estado_desarrollo.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  El estado de desarrollo del espécimen es requerido!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            NOMBRE DE MARCADOR GENÉTICO
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="marcador_genetico"
                                type="text"
                                placeholder="Ingrese marcador genético"
                                ref={register({maxLength: 50})}
                              />
                              {errors.marcador_genetico && errors.marcador_genetico.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El marcador genético del espécimen le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            UNIDAD DE MEDIDA <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="unidad_medida" onChange={handleChangeUnidadMedida} ref={register}>
                                <SelectOptions selectOptions={domainsByKey.unidadesMedida} />
                              </Select>
                              {errors.unidad_medida && errors.unidad_medida.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La unidad de medida del espécimen es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CANTIDAD UNIDAD <span className="obligatory">*</span>
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="cantidad"
                                type="text"
                                placeholder="Ingrese la cantidad para la unidad"
                                ref={register}
                                readOnly={isCantidadUnidadRead}
                              />
                              {errors.cantidad && errors.cantidad.type === "required" && (
                                <p style={{color: 'red'}}>
                                  La cantidad de unidad del espécimen es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col4>
                          <Label>
                            CASTRADO<br />
                            <>
                              <input
                                name="castrado"
                                value
                                type="radio"
                                ref={register}
                              /> Si
                              <input
                                name="castrado"
                                value={false}
                                type="radio"
                                ref={register}
                                defaultChecked
                              /> No
                            </>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            PREÑADO<br />
                            <>
                              <input
                                name="prenado"
                                value
                                type="radio"
                                ref={register}
                              /> Si
                              <input
                                name="prenado"
                                value={false}
                                type="radio"
                                ref={register}
                                defaultChecked
                              /> No
                            </>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            CANTIDAD DE DÍAS EN CAUTIVERIO
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="tiempo_cautiverio"
                                type="number"
                                placeholder="Ingrese la cantidad"
                                ref={register({
                                  pattern: { value: /^[0-9]+$/ },
                                  maxLength: 3
                                })}
                              />
                              {/* {errors.tiempo_cautiverio && errors.tiempo_cautiverio.type === "required" && (
                                <p style={{color: 'red'}}>
                                  La cantidad de días en cautiverio es requerida!
                                </p>
                              )} */}
                              {errors.tiempo_cautiverio && errors.tiempo_cautiverio.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La cantidad de días en cautiverio le sobran dígitos máximo (3)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            DIETA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="dieta"
                                type="text"
                                placeholder="Ingrese la dieta"
                                ref={register({maxLength: 100})}
                              />
                              {errors.dieta && errors.dieta.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La dieta del espécimen le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col4>
                      </Row>
                      <Row>
                        <Col1>
                          <Label>
                            DESCRIPCIÓN
                            <>
                              <Textarea
                                rows="6"
                                style={{ width: '100%' }}
                                name="descripcion"
                                type="text"
                                placeholder="Ingrese la descripción"
                                ref={register({maxLength: 100})}
                              />
                              {errors.descripcion && errors.descripcion.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La descripción del espécimen le sobran caracteres máximo (100)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col1>
                      </Row>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br />
                            Información de procedencia del espécimen.
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            PAÍS DE PROCEDENCIA
                            <>
                              <Select style={{ width: '100%' }} name="pais_procedencia_declarado" onChange={handleChangePaisProceEspe} ref={register}>
                                <SelectOptions selectOptions={constantsByKey.paises} />
                              </Select>
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            DEPARTAMENTO DE PROCEDENCIA
                            <>
                              <Select style={{ width: '100%' }} onChange={handleChangeDptoProceEspe} name="departamento_procedencia_declarado" ref={register} readOnly={departamentosProceEspeReadOnly}>
                                <SelectOptions selectOptions={constantsByKey.departamentos} />
                              </Select>
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CIUDAD O MUNICIPIO DE PROCEDENCIA
                            <>
                              <Select style={{ width: '100%' }} name="municipio_procedencia_declarado" ref={register} readOnly={municipiosProceEspeReadOnly}>
                                <SelectOptions selectOptions={municipiosFiltradosProceEspe} />
                              </Select>
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            CORREGIMIENTO DE PROCEDENCIA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="corregimiento_procedencia_declarado"
                                type="text"
                                placeholder="Escriba el nombre del corregimiento"
                                ref={register({maxLength: 50})}
                              />
                              {errors.corregimiento_procedencia_declarado && errors.corregimiento_procedencia_declarado.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El corregimiento de procedencia le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            VEREDA DE PROCEDENCIA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="vereda_procedencia_declarado"
                                type="text"
                                placeholder="Ingrese el nombre de la vereda"
                                ref={register({maxLength: 50})}
                              />
                              {errors.vereda_procedencia_declarado && errors.vereda_procedencia_declarado.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El nombre de la vereda de procedencia le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            BARRIO DE PROCEDENCIA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="barrio_procedencia_declarado"
                                type="text"
                                placeholder="Ingrese el nombre del barrio"
                                ref={register({maxLength: 50})}
                              />
                              {errors.barrio_procedencia_declarado && errors.barrio_procedencia_declarado.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El barrio de procedencia le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col2>
                          <Label>
                            PREDIO DE PROCEDENCIA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="predio_procedencia_declarado"
                                type="text"
                                placeholder="Escriba el predio"
                                ref={register({maxLength: 50})}
                              />
                              {errors.predio_procedencia_declarado && errors.predio_procedencia_declarado.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El predio de procedencia le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                        <Col2>
                          <Label>
                            LUGAR DE PROCEDENCIA
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="lugar_procedencia_declarado"
                                type="text"
                                placeholder="Ingrese el lugar"
                                ref={register({maxLength: 50})}
                              />
                              {errors.lugar_procedencia_declarado && errors.lugar_procedencia_declarado.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  El lugar de procedencia le sobran caracteres máximo (50)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                      </Row>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br />
                            Agregar especímenes
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col1>
                          <Label>
                            <SpanTextInfo>Indique cuantos especímenes desea agregar que tengan las mismas características consignadas anteriormente.</SpanTextInfo>
                          </Label>
                        </Col1>
                      </Row>
                      <Row>
                        <Col2>
                          <Label>
                            CANTIDAD DE ESPECÍMENES A AGREGAR
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="cantidad_agregar"
                                type="number"
                                placeholder="Ingrese la cantidad"
                                ref={register({
                                  pattern: { value: /^[0-9]+$/ },
                                  maxLength: 3
                                })}
                                readOnly={isCantidadAgregarRead}
                              />
                              {errors.cantidad_agregar && errors.cantidad_agregar.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  La cantidad de especímenes a agregar le sobran caracteres máximo (3)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                        <Col2>
                          <Label>
                            ENTIDAD DE DISPOSICIÓN ACTUAL <span className="obligatory">*</span>
                            <>
                              <Select style={{ width: '100%' }} name="entidad_disposicion_inicial" ref={register}>
                                <SelectOptions selectOptions={entidadesDisposicion} />
                              </Select>
                              {errors.entidad_disposicion_inicial && errors.entidad_disposicion_inicial.type === "required" && (
                                <p style={{ color: 'red'}}>
                                  La entidad de disposición actual es requerida!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col2>
                      </Row>
                      <Row>
                        <Col1>
                          <Label>
                            OBSERVACIONES GENERALES
                            <>
                              <Input
                                style={{ width: '100%' }}
                                name="observaciones_generales"
                                type="text"
                                placeholder="Ingrese observaciones generales"
                                ref={register({minLength: 5, maxLength: 500})}
                              />
                              {errors.observaciones_generales && errors.observaciones_generales.type === "minLength" && (
                                <p style={{color: 'red'}}>
                                  Las observaciones del espécimen le falta caracteres mínimo (5)!
                                </p>
                              )}
                              {errors.observaciones_generales && errors.observaciones_generales.type === "maxLength" && (
                                <p style={{color: 'red'}}>
                                  Las observaciones del espécimen le sobran caracteres máximo (500)!
                                </p>
                              )}
                            </>
                          </Label>
                        </Col1>
                      </Row>
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Col right>
                          <Label>
                            <Button type="button" onClick={(e) => handleAddEspecimenes(e)}>
                              AGREGAR
                            </Button>
                          </Label>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col1>
                      <SpanDivide>
                        <br />
                        Lista de especímenes asociados al expediente
                      </SpanDivide>
                    </Col1>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        CLASE DE RECURSO
                        <Select style={{ width: '100%' }} name="clase_recurso_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposRecurso} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ESTADO
                        <Select style={{ width: '100%' }} name="estado_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimen} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        PARTE, PRODUCTO O SUBPRODUCTO
                        <Select style={{ width: '100%' }} name="parte_producto_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposParte} />
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        CATEGORÍA TAXONÓMICA <span className="obligatory">*</span>
                        <Select style={{ width: '100%' }} name="taxonRankSearch" ref={register}>
                          <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NOMBRE CIENTÍFICO
                        <>
                            <Autocomplete
                              name="canonicalNameSearch"
                              placeholder="Indique nombre científico"
                              suggestions={suggestionsSearch}
                              loading={loadingAutoCompleteSearch}
                              activeSuggestion={activeSuggestionSearch}
                              showSuggestions={showSuggestionsSearch}
                              onChange={handleAutoCompleteSearchChange}
                              onKeyDown={handleAutoCompleteSearchKeyDown}
                              onClick={handleAutoCompleteSearchClick}
                              userInput={userInputSearch}
                            />
                            <Input
                              style={{ width: '100%' }}
                              name="taxonomiaSearch"
                              type="hidden"
                              ref={register}
                            />
                          </>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NOMBRE COMÚN
                        <Input
                          style={{ width: '100%' }}
                          name="nombre_comun_consulta"
                          type="text"
                          placeholder="Ingrese nombre común"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        ESTADO DE DESARROLLO
                        <Select style={{ width: '100%' }} name="estado_desarrollo_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.estadosDesarrollo} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ESTADO DE POSESIÓN
                        <Select style={{ width: '100%' }} name="estado_posesion_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimenExpediente} />
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        SEXO
                        <Select style={{ width: '100%' }} name="sexo_consulta" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposSexo} />
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col right>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={(e) => handleClearEspecimenesFields(e)}>
                          LIMPIAR CAMPOS
                        </Button>
                      </Label>
                      <Label style={{ width: 'auto' }}>
                        <Button type="button" onClick={(e) => handleSearchEspecimenesFields(e)}>
                          BUSCAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col1>
                      {isLoadingEspecimenes ? (
                        <div style={{justifyContent: 'flex-end', width: '100%'}}>
                          Cargando información por favor espere un momento...
                          <SkeletonTheme color="#f42f6391" highlightColor="#444">
                            <Skeleton height={34}/>
                          </SkeletonTheme>
                        </div>
                      ) : (
                        <PaginationTableRowSelect columns={columnsEspecimenes} data={especimenesExpediente} onClick={handleBorrarEspecimenes} eraser={((!selected) || (mode === 'edit' && selected && Number(selected.estado._id) === 22000001))} textEraser="BORRAR" />
                      )}
                    </Col1>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    {mode !== 'view' ? (
                      <>
                        <Col right>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                          {!isLoading ? (
                            <Label style={{ width: 'auto' }}>
                              <Button type="submit">
                                GUARDAR
                              </Button>
                            </Label>
                          ) : (
                            <Label style={{justifyContent: 'flex-end', width: '25vw'}}>
                              <Loader message="Guardando el expediente por favor espere un momento... No recargue la página ya que de acuerdo a los archivos e imagenes adjuntos, es el tiempo de procesamiento"/>
                              <SkeletonTheme color="#f42f6391" highlightColor="#444">
                                Guardando...<Skeleton height={34}/>
                              </SkeletonTheme>
                            </Label>
                          )}
                          {isLoadingRadicar ? (
                            <Label style={{justifyContent: 'flex-end', width: '25vw'}}>
                              <Loader message="Radicando el expediente por favor espere un momento... No recargue la página ya que se puede alterar el proceso de radicación del expediente"/>
                            </Label>
                          ) : (
                            <Label style={{ width: 'auto' }}>
                              <Button type="button" onClick={(e) => handleRadicarExpediente(e)} disabled={!selected}>
                                RADICAR EXPEDIENTE
                              </Button>
                            </Label>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col right>
                          <Label style={{ width: 'auto' }}>
                            <Button type="button" onClick={(e) => handleClose(e)}>
                              CANCELAR
                            </Button>
                          </Label>
                        </Col>
                      </>
                    )}
                  </Row>
                </GridContainer>
              </Card>
              {((mode === 'view') && (Number(selected.estado._id) === 22000003)) && (
                <Card title=" ">
                  <GridContainer scroll>
                    <CardTitleBox>
                      <H1>Datos de cierre</H1>
                    </CardTitleBox>
                    <Row>
                      <Col2>
                        <Label>
                          MOTIVO DE CIERRE <span className="obligatory">*</span>
                          <>
                            <br/><SpanView>{(selected.motivo_cierre && selected.motivo_cierre.domainName) || ('---')}</SpanView>
                          </>
                        </Label>
                      </Col2>
                      <Col2>
                        <Label>
                          FECHA DE CIERRE <span className="obligatory">*</span>
                          <>
                            <br /><SpanView>{(selected.fecha_cierre && splitDate(selected.fecha_cierre)) || ('---')}</SpanView>
                          </>
                        </Label>
                      </Col2>
                    </Row>
                    <Row>
                      <Col1>
                        <Label>
                          OBSERVACIONES GENERALES <span className="obligatory">*</span>
                          <>
                            <br/><SpanView>{(selected.observaciones_cierre && selected.observaciones_cierre) || ('---')}</SpanView>
                          </>
                        </Label>
                      </Col1>
                    </Row>
                    <Row>
                      <Col1>
                        <Label>
                          ACTA DE CIERRE<br />
                          <PaginationTableRowSelect columns={columnsFiles} data={filesAuxClose} onClick={handleDeleteFiles} eraser={false} textEraser="QUITAR" />
                        </Label>
                      </Col1>
                    </Row>
                  </GridContainer>
                </Card>
              )}
            </Form>
            {modalContacts && <ModalContact onClick={handleOpenModalContacts} modalContacts={modalContacts} animation={animation} />}
            {modalFile && <ModalFiles onClick={(e) => handleOpenModalFiles(e)} modalFile={modalFile} animation={animation} />}
            {modalImage && <ModalImages onClick={(e) => handleOpenModalImages(e)} modalImage={modalImage} animation={animation} />}
            {modalEspecimenes && <ModalEspecimenes onClick={handleOpenModalEspecimenes} modalEspecimen={modalEspecimenes} animation={animation} />}
            <ReactTooltip
              id="main"
              type="light"
              effect="float"
              wrapper="span"
              multiline
            />
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
}

ExpedienteForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
};

ExpedienteForm.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(ExpedienteForm);
