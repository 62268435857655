import {
	LOGIN_INIT,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN_TERMINATE,
	RECOVER_INIT,
	RECOVER_SUCCESS,
	RECOVER_ERROR,
	RESET_INIT,
	RESET_SUCCESS,
	RESET_ERROR,
	GET_SESSION_INIT,
	GET_SESSION_SUCCESS,
	GET_SESSION_ERROR
} from './types';

export const login = () => {
	return {
		type: LOGIN_INIT
	};
};

export const success = data => {
	return {
		type: LOGIN_SUCCESS,
		payload: { data }
	};
};

export const failed = error => {
	return {
		type: LOGIN_ERROR,
		payload: error
	};
};

export const terminate = () => {
	return {
		type: LOGIN_TERMINATE
	};
};

export const recover = () => {
	return {
		type: RECOVER_INIT
	};
};

export const recoverSuccess = data => {
	return {
		type: RECOVER_SUCCESS,
		payload: { data }
	};
};

export const recoverFailed = error => {
	return {
		type: RECOVER_ERROR,
		payload: error
	};
};

export const reset = () => {
	return {
		type: RESET_INIT
	};
};

export const resetSuccess = data => {
	return {
		type: RESET_SUCCESS,
		payload: { data }
	};
};

export const resetFailed = error => {
	return {
		type: RESET_ERROR,
		payload: error
	};
};

export const getUserSessionInit = () => {
	return {
		type: GET_SESSION_INIT
	};
}

export const getUserSessionSuccess = data => {
	return {
		type: GET_SESSION_SUCCESS,
		payload: { data }
	};
}

export const getUserSessionError = error => {
	return {
		type: GET_SESSION_ERROR,
		payload: error
	};
}



