export const LOGIN_INIT = '[LOGIN] LOGIN_INIT';
export const LOGIN_SUCCESS = '[LOGIN] LOGIN_SUCCESS';
export const LOGIN_ERROR = '[LOGIN] LOGIN_ERROR';
export const LOGIN_TERMINATE = '[LOGIN] LOGIN_TERMINATE';
export const RECOVER_INIT = '[RECOVER] RECOVER_INIT';
export const RECOVER_SUCCESS = '[RECOVER] RECOVER_SUCCESS';
export const RECOVER_ERROR = '[RECOVER] RECOVER_ERROR';
export const RESET_INIT = '[RESET] RESET_INIT';
export const RESET_SUCCESS = '[RESET] RESET_SUCCESS';
export const RESET_ERROR = '[RESET] RESET_ERROR';
export const GET_SESSION_INIT = '[SESSION] GET_SESSION_INIT';
export const GET_SESSION_SUCCESS = '[SESSION] GET_SESSION_SUCCESS';
export const GET_SESSION_ERROR = '[SESSION] GET_SESSION_ERROR';

