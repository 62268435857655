/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import {withRouter} from 'react-router-dom';
import useForm from 'react-hook-form';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import {removeEmpty} from "../utils";

/* Hooks */
import {
  useExpedientes,
  useLogin,
  useDomains,
  useConstants,
  useAutoridadAmbiental,
  useNotification,
  useTraslado
} from '../hooks';
/* Style Components */
import {ContainerRow, CardContainerBody} from './styled';
/* Components */
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import {Form} from "../components/form-ui-components/Form";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import {H5} from "../components/H5";
import {Label} from '../components/form-ui-components/Label';
import {Input} from '../components/form-ui-components/Input';
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import {Button} from '../components/form-ui-components/Button';
import ErrorBoundary from "../components/ErrorBoundary";
import PaginationTableServerSide from "../components/Table/PaginationTableServerSide";
import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import ModalClose from '../components/ModalClose';
import Autocomplete from '../components/Autocomplete';
/* Defined Constants */
import {wait} from '../config/const';
import {Col, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";

dayjs.locale('es');

const ExpedientesSearch = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const [loadedInfo, setLoadedInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [municipiosFiltrados, setMunicipiosFiltrados] = useState([]);
  const [modalClose, setModalClose] = useState(false);
  const [animation, setAnimation] = useState('zoom');
  const fetchIdRef = useRef(0);
  // Auto Complete
  const [suggestions, setSuggestions] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  // Fin Auto Complete
  const {isLoggedIn, token, userLogin, isRolCoordinadorExpedientes} = useLogin();
  const {
    data: expedientes,
    pageCount,
    error,
    isLoading,
    searchExpedienteRequest,
    getbyidExpedienteRequest,
    especimenTaxonomyInfoRequest
  } = useExpedientes();
  const {solicitante, getUsuarioEntidad} = useTraslado();
  const {register, handleSubmit, getValues, reset, setValue} = useForm();
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const {data: autoridadesAmbientales, searchAutoridadAmbiental} = useAutoridadAmbiental();
  const {createNotification} = useNotification();

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const splitDate = timestamp => timestamp.split('T')[0];

  // Auto Complete
  // const loadTaxonomyInfo = useCallback(() => {
  //   setSuggestions(taxonomyInformation);
  // }, [taxonomyInformation]);

  const handleAutoCompleteChange = async e => {
    const newUserInput = e.currentTarget.value;
    if (document.querySelector("select[name='taxonRank']").value !== '') {
      const search = {
        taxonRank: document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const {taxonomyInfo, msg, err} = await especimenTaxonomyInfoRequest(search, token);
      console.log(taxonomyInfo, msg, err);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInfo);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalName').value = newUserInput;
    setValue('especimen_taxonomia', taxonomiaId);
  };
  // Fin Auto Complete

  const handleChangeDpto = e => {
    const {value} = e.target;
    const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(value));
    setMunicipiosFiltrados(newData);
  };

  const handleOpenModalClose = () => {
    setAnimation('zoom');
    setModalClose(!modalClose);
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const AlertThenDelete = async original => {
    confirmAlert({
      title: 'CERRAR EXPEDIENTE',
      message: `¿Desea cerrar el expediente : ${original.acta}?`,
      buttons: [
        {
          label: "CERRAR",
          onClick: async () => {
            await getbyidExpedienteRequest(original);
            handleOpenModalClose();
          }
        },
        {
          label: "CANCELAR",
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  };

  const load = useCallback(async () => {
    setLoadedInfo(false);
    await Promise.all([getUsuarioEntidad(userLogin, token), searchAutoridadAmbiental({}, token), getOptionsByDomainTypes(['tiposEvento', 'cierreMotivo', 'tiposAdjunto', 'tiposEstadoEspecimen', 'tiposRecurso']), getConstantOptionsByTypes(['paises', 'departamentos', 'municipios', 'clasificacionTaxonomia'])]);
    setValue('pais_procedimiento', 3000001);
    setLoadedInfo(true);
  }, [
    searchAutoridadAmbiental,
    getOptionsByDomainTypes,
    getConstantOptionsByTypes
  ]);

  const fetchData = useCallback(async ({pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch}) => {
    setLoading(true);
    console.log('fetchData 1', fetchIdRef.current, pageSize, pageIndex, loaderFecth, loarderSearch, loading, isLoading);
    const fetchId = ++fetchIdRef.current;
    if ((fetchId === fetchIdRef.current) && !loading) {
      console.log('fetchData 2');
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;

      const values = getValues();
      const search = {
        autoridad_ambiental: values.autoridad_ambiental || null,
        acta: values.acta || null,
        pais_procedimiento: Number(values.pais_procedimiento) || null,
        tipo_procedimiento: Number(values.tipo_procedimiento) || null,
        departamento_procedimiento: Number(values.departamento_procedimiento) || null,
        municipio_procedimiento: Number(values.municipio_procedimiento) || null,
        fecha_procedimiento_inicial: (values.fecha_procedimiento_inicial && parseDateTime(values.fecha_procedimiento_inicial)) || null,
        fecha_procedimiento_final: (values.fecha_procedimiento_final && parseDateTime(values.fecha_procedimiento_final)) || null,
        especimen_estado: Number(values.especimen_estado) || null,
        especimen_clase_recurso: Number(values.especimen_clase_recurso) || null,
        especimen_taxonomia: Number(values.especimen_taxonomia) || null,
      };
      console.log(search)
      await searchExpedienteRequest(removeEmpty(search), startRow, pageSize, token);
      setLoading(false);
      console.log('fetchData 3', loaderFecth, loarderSearch);
    }
  }, []);

  const handleClearExpedientesFields = async e => {
    e.preventDefault();
    reset();
    await fetchData({pageSize: 10, pageIndex: 0, loading, isLoading});
    setValue('especimen_taxonomia', '');
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await fetchData({pageSize: 10, pageIndex: 0, loading, isLoading});
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Autoridad ambiental',
        accessor: d => {
          return ((d.autoridad_ambiental) && (d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ') && (d.autoridad_ambiental.nombre !== undefined)) ? d.autoridad_ambiental.nombre : '---';
        }
      },
      {
        Header: 'Número de acta',
        accessor: d => {
          return ((d.acta !== '') && (d.acta !== ' ') && (d.acta !== undefined)) ? d.acta : '---';
        }
      },
      {
        Header: 'Tipo de evento',
        accessor: d => {
          return ((d.tipo_procedimiento) && (d.tipo_procedimiento.domainName !== undefined) && (d.tipo_procedimiento.domainName !== '') && (d.tipo_procedimiento.domainName !== ' ')) ? d.tipo_procedimiento.domainName : '---';
        }
      },
      {
        Header: 'Fecha de evento',
        accessor: d => {
          return ((d.fecha_procedimiento) && (d.fecha_procedimiento !== '') && (d.fecha_procedimiento !== ' ')) ? splitDate(d.fecha_procedimiento) : '---';
        }
      },
      {
        Header: 'País',
        accessor: d => {
          return ((d.pais_procedimiento) && (d.pais_procedimiento.constantName !== '') && (d.pais_procedimiento.constantName !== ' ')) ? d.pais_procedimiento.constantName : '---';
        }
      },
      {
        Header: 'Departamento',
        accessor: d => {
          return ((d.departamento_procedimiento) && (d.departamento_procedimiento.constantName !== '') && (d.departamento_procedimiento.constantName !== ' ')) ? d.departamento_procedimiento.constantName : '---';
        }
      },
      {
        Header: 'Municipio',
        accessor: d => {
          return ((d.municipio_procedimiento) && (d.municipio_procedimiento.constantName !== '') && (d.municipio_procedimiento.constantName !== ' ')) ? d.municipio_procedimiento.constantName : '---';
        }
      },
      {
        Header: 'Estado',
        accessor: d => {
          return ((d.estado) && (d.estado.domainName !== '') && (d.estado.domainName !== ' ')) ? d.estado.domainName : 'Borrador';
        }
      },
      {
        Header: 'Acciones',
        Cell: ({row}) => {
          const {original} = row
          const buttons = [
            {
              id: original._id,
              iconName: 'remove_red_eye',
              title: 'Ver Expediente',
              visible: true,
              handleAction: async () => {
                history.push(`/expediente-form/view/${original._id}`);
              }
            },
            {
              id: original._id,
              iconName: 'edit',
              title: 'Actualizar Expediente',
              visible: (original.estado ? (Number(original.estado._id) === 22000001) : false) && isRolCoordinadorExpedientes,
              handleAction: async () => {
                history.push(`/expediente-form/edit/${original._id}`);
              }
            },
            {
              id: original._id,
              iconName: 'highlight_off',
              title: 'Cerrar Expediente',
              visible: (original.estado ? (Number(original.estado._id) === 22000002) : false) && isRolCoordinadorExpedientes,
              handleAction: async () => AlertThenDelete(original)
            },
          ];
          return <ButtonsActionRow buttons={buttons}/>;
        },
      }
    ],
    []
  );

  useEffect(() => {
    if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, gotoLogin, load]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Expedientes</H1>
            </PageHeader>
            <Card title=" ">
              <CardTitleBox>
                <H5>Datos de búsqueda</H5>
              </CardTitleBox>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                  <Row>
                    <Col3>
                      <Label>
                        AUTORIDAD AMBIENTAL
                        <Select style={{width: '100%'}} name="autoridad_ambiental" ref={register}>
                          <SelectOptions selectOptions={autoridadesAmbientales}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NÚMERO DE ACTA ÚNICA
                        <Input
                          name="acta"
                          type="text"
                          placeholder="Escriba el número de acta única"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        TIPO DE EVENTO
                        <Select name="tipo_procedimiento" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEvento}/>
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        PAÍS
                        <Select name="pais_procedimiento" ref={register}>
                          <SelectOptions selectOptions={constantsByKey.paises}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        DEPARTAMENTO
                        <Select name="departamento_procedimiento" onChange={handleChangeDpto} ref={register}>
                          <SelectOptions selectOptions={constantsByKey.departamentos}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CIUDAD O MUNICIPIO
                        <Select name="municipio_procedimiento" ref={register}>
                          <SelectOptions selectOptions={municipiosFiltrados}/>
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        FECHA DE EVENTO INICIAL
                        <Input
                          name="fecha_procedimiento_inicial"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        FECHA DE EVENTO FINAL
                        <Input
                          name="fecha_procedimiento_final"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        ESTADO
                        <Select style={{width: '100%'}} name="especimen_estado" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimen}/>
                        </Select>
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        CLASE DE RECURSO
                        <Select style={{width: '100%'}} name="especimen_clase_recurso" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposRecurso}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CATEGORÍA TAXONÓMICA
                        <Select style={{width: '100%'}} name="taxonRank" ref={register}>
                          <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NOMBRE CIENTÍFICO
                        <Autocomplete
                          name="canonicalName"
                          placeholder="Indique nombre científico"
                          suggestions={suggestions}
                          loading={loadingAutoComplete}
                          activeSuggestion={activeSuggestion}
                          showSuggestions={showSuggestions}
                          onChange={handleAutoCompleteChange}
                          onKeyDown={handleAutoCompleteKeyDown}
                          onClick={handleAutoCompleteClick}
                          userInput={userInput}
                        />
                        <Input
                          style={{width: '100%'}}
                          name="especimen_taxonomia"
                          type="hidden"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                  </Row>
                  <Row style={{justifyContent: 'flex-end'}}>
                    <Col right>
                      <Label style={{width: 'auto'}}>
                        <Button type="button" onClick={(e) => handleClearExpedientesFields(e)}>
                          LIMPIAR CAMPOS
                        </Button>
                      </Label>
                      <Label style={{width: 'auto'}}>
                        <Button type="submit">
                          BUSCAR
                        </Button>
                      </Label>
                    </Col>
                  </Row>
                </GridContainer>
              </Form>
            </Card>
            <Card container>
              <GridContainer>
                {(loadedInfo) && (
                  <>
                    {/* {isLoading ? (
                        <div style={{justifyContent: 'flex-end', width: '100%'}}>
                          <div>Cargando información, un momento por favor...</div>
                          <SkeletonTheme color="#f42f6391" highlightColor="#444">
                            <Skeleton height={34}/>
                          </SkeletonTheme>
                        </div>
                      ) : ( */}
                    <Row>
                      <Col>
                        <CardContainerBody>
                          <div className="card-body-row">
                            <PaginationTableServerSide columns={columns} data={expedientes} fetchData={fetchData}
                                                       loading={loading} isLoading={isLoading} pageCount={pageCount}/>
                          </div>
                        </CardContainerBody>
                        {error && (
                          <CardContainerBody>
                            <CardTitleBox className="error" error>
                              <H5> Ups, hubo un error {error}</H5>
                            </CardTitleBox>
                          </CardContainerBody>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </GridContainer>
              {/* )} */}
            </Card>
            {modalClose && <ModalClose onClick={handleOpenModalClose} modalClose={modalClose} animation={animation}/>}
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
}

ExpedientesSearch.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ExpedientesSearch.defaultProps = {
  history: {},
};

export default withRouter(ExpedientesSearch);
