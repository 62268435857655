import {
  TAXONOMY_LIST_INIT,
  TAXONOMY_LIST_SUCCESS,
  TAXONOMY_LIST_ERROR,
} from "./types";

export const listInit = () => {
	return {
		type: TAXONOMY_LIST_INIT
	};
};

export const listSuccess = data => {
	return {
		type: TAXONOMY_LIST_SUCCESS,
		payload: { data }
	};
};

export const listError = error => {
	return {
		type: TAXONOMY_LIST_ERROR,
		payload: error
	};
};
