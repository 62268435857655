/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import {
  createInit,
  createSuccess,
  createError,
  updateInit,
  updateSuccess,
  updateError,
  closeInit,
  closeSuccess,
  closeError,
  searchInit,
  searchSuccess,
  searchError,
  deleteInit,
  deleteSuccess,
  deleteError,
  getbyidInit,
  getbyidSuccess,
  getbyidError,
  expedienteSelected,
  deleteContactExpediente,
  contactExpedienteError,
  contactExpedienteSearch,
  contactExpedienteSearchSuccess,
  contactsInit,
  contactsSuccess,
  contactsError,
  addContactToExpedienteInit,
  addContactToExpedienteSuccess,
  addContactToExpedienteError,
  cleanContactExpedienteSuccess,
  especimenesExpedienteInit,
  especimenesExpedienteSuccess,
  especimenesExpedienteError,
  getByIdEspecimenInit,
  getByIdEspecimenSuccess,
  getByIdEspecimenError,
  radicarInit,
  radicarSuccess,
  radicarError,
  addSameEspecimenSuccess,
  addSameEspecimenError,
  deleteEspecimenesExpedienteSuccess,
  deleteEspecimenesExpedienteError,
  selectedFileSuccess,
  selectedFileError,
  clearSelectedFileInit,
  selectedImageSuccess,
  selectedImageError,
  clearSelectedImageInit,
  clearSelectedInit,
  loadSelectedInit,
  loadSelectedSuccess,
  loadSelectedFailed,
  selectedFileCloseSuccess,
  selectedFileCloseError,
  clearSelectedFileCloseInit,
  especimenTaxonoInfoInit,
  especimenTaxonoInfoSuccess,
  especimenTaxonoInfoError,
  expedienteFilesAddSuccess,
  expedienteFilesAddError,
  expedienteFilesUpdateSuccess,
  expedienteFilesUpdateError,
  expedienteFilesDeleteSuccess,
  expedienteFilesDeleteError,
  expedienteFilesDownloadInit,
  expedienteFilesDownloadSuccess,
  expedienteFilesDownloadError,
  expedienteImagesAddSuccess,
  expedienteImagesAddError,
  expedienteImagesUpdateSuccess,
  expedienteImagesUpdateError,
  expedienteImagesDeleteSuccess,
  expedienteImagesDeleteError,
  expedienteImagesDownloadInit,
  expedienteImagesDownloadSuccess,
  expedienteImagesDownloadError,
  expedienteCerrarFilesDeleteSuccess,
  expedienteCerrarFilesDeleteError,
  expedienteFilesCloseAddSuccess,
  expedienteFilesCloseAddError,
  expedienteFilesCloseUpdateSuccess,
  expedienteFilesCloseUpdateError,
  expedienteFilesCloseDownloadInit,
  expedienteFilesCloseDownloadSuccess,
  expedienteFilesCloseDownloadError,
  expedienteEspecimenUpdateInit,
  expedienteEspecimenUpdateSuccess,
  expedienteEspecimenUpdateError
} from './actions';
import * as ExpedienteServices from "../../services";
import { domainTypesByKey, constantTypesByKey } from '../../config/const';
import { removeEmpty } from "../../utils";

export const closeExpedienteRequest = (expediente, files, token) => {
	return async (dispatch) => {
		dispatch(closeInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.close(expediente._id, expediente, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        // save data files close relationship with expediente close
        let responseFile = '';
        if(files.length > 0) {
          const anAsyncFunctionFile = async file => {
            let info = '';
            if(file.nuevo) {
              const { nuevo, _id, activo, memory, ...fileInfo } = file;
              const newFileInfo = { ...fileInfo, memory, expediente: expediente._id, tipo: Number(fileInfo.tipo._id) };
              console.log('archivo crear', newFileInfo, nuevo, activo);
              info = await ExpedienteServices.apiArchivos.uploadFileCreateToExpediente(newFileInfo, token);
            } else {
              const { nuevo, memory, ...fileInfo } = file;
              const updateFileInfo = { ...fileInfo, tipo: Number(fileInfo.tipo._id) };
              if((!updateFileInfo.activo) || (updateFileInfo.archivo && typeof updateFileInfo.archivo === 'string')) {
                const { archivo, ...newFileUpdated} = updateFileInfo;
                console.log('entro por aca archivo 1', nuevo);
                info = await ExpedienteServices.apiArchivos.uploadFileUpdateToExpediente(newFileUpdated, token);
              } else {
                console.log('entro por aca archivo 2', nuevo);
                info = await ExpedienteServices.apiArchivos.uploadFileUpdateToExpediente(updateFileInfo, token);
              }
            }
            if(typeof info === 'string') {
              return { msg: `${info}`, err: true };
            }
            return { msg: `${info.message}`, err: false };
          };

          const getDataFile = async () => {
            return Promise.all(files.map(file => anAsyncFunctionFile(file)))
          };

          responseFile = await getDataFile().then(newData => {
            console.log(newData);
            return newData;
            // dispatch(closeSuccess(data));
            // return { msg: `${data.message} `, err: false };
          });

          // dispatch(closeSuccess(data));
          // return { msg: `${data.message} `, err: false };
        }

        dispatch(closeSuccess(data));
        responseFile = (responseFile && responseFile[0].msg) || ('');
        return { msg: `${data.message} ${responseFile}`, err: false };
      }
      if(typeof data === 'string') {
        dispatch(closeError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
		} catch (error) {
      dispatch(closeError('Se genero un error al cerrar el expediente por favor consulte al administrador!'));
      return { msg: 'Se genero un error al cerrar el expediente por favor consulte al administrador!', err: true };
		}
	};
};

export const searchExpedienteRequest = (values = {}, from, to, token) => {
  console.log("Etro a dispather")
	return async (dispatch) => {
		dispatch(searchInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.search(values, from, to, token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async expediente => {
          const autoridad  = await ExpedienteServices.apiAutoridadAmbiental.get(expediente.autoridad_ambiental, token);
          const estadoData = await ExpedienteServices.apiUser.getByDomainIdsTypes(expediente.estado._id, domainTypesByKey.tiposExpedienteTipo, token);
          const nombreAutoridad = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const estadoNombre = (typeof estadoData === 'object' && Array.isArray(estadoData.data) && estadoData.data.length > 0) ? estadoData.data[0].domainName : '';

          return {
            ...expediente,
            autoridad_ambiental: { _id: (expediente.autoridad_ambiental || ''), nombre: nombreAutoridad },
            estado: { _id: expediente.estado ? expediente.estado._id : '', domainName: estadoNombre },
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(expediente => anAsyncFunction(expediente)))
        };

        await getData().then(newData => {
          const countValues = Object.keys(values).length;
          const total = ((data.metadata && (countValues === 0 || (values.pais_procedimiento && Number(values.pais_procedimiento) === 3000001))) && data.metadata.total) || data.data.length;
          const pageCount = Math.round(total / to) || Math.ceil(total / to);
          console.log("datos seteados",newData)
          dispatch(searchSuccess(newData, pageCount));
        });
      }
      if(typeof data === 'string') {
        dispatch(searchError(data));
      }
		} catch (error) {
			dispatch(searchError('Se genero un error al buscar los expedientes por favor consulte al administrador!'));
		}
	};
};

export const deleteExpedienteRequest = id => {
	return async (dispatch) => {
		dispatch(deleteInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.delete(id);
      if (typeof data._id !== 'undefined') {
        dispatch(deleteSuccess(data));
      } else {
        dispatch(deleteError(data));
      }
		} catch (error) {
			dispatch(deleteError('Se genero un error al borrar el expediente por favor consulte al administrador!'));
		}
	};
};

export const getbyidExpedienteRequest = expediente => {
	return async (dispatch) => {
		dispatch(getbyidInit());
		try {
      dispatch(getbyidSuccess(expediente));
		} catch (error) {
			dispatch(getbyidError('Se genero un error al seleccionar el expediente por favor consulte al administrador!'));
		}
	};
};

export const selectedExpediente = id => {
  return (dispatch) => {
    try {
      dispatch(expedienteSelected(id));
    } catch (error) {
			console.log(error);
		}
  };
};

export const contactDeleteExpediente = id => {
	return async (dispatch) => {
		try {
      if (id !== '') {
        dispatch(deleteContactExpediente(id));
      } else {
        dispatch(contactExpedienteError('El id del contacto no esta definido'));
      }
		} catch (error) {
			dispatch(contactExpedienteError('Se genero un error al desactivar el contacto del expediente por favor consulte al administrador!'));
		}
	};
};

// export const getContactsExpedienteRequest = funcionarios => {
// 	return async (dispatch) => {
// 		dispatch(contactExpedienteSearch());
// 		try {
//       if (Array.isArray(funcionarios)) {
//         const anAsyncFunction = async contact => {
//           const info          = await ExpedienteServices.apiContacts.get(contact);
//           const entidad       = (typeof info === 'object') ? await ExpedienteServices.apiUser.getByDomainIdsTypes(info.data.tipo_entidad_disposicion, domainTypesByKey.tiposEntidadDisposicion) : '';
//           const entidadNombre = (typeof entidad === 'object' && entidad.data.length === 1) ? entidad.data[0].domainName : '';
//           return {
//             _id: (typeof info === 'object') ? info.data._id : '',
//             nombre: (typeof info === 'object') ? info.data.nombre : '',
//             tipoEntidadNombre: entidadNombre,
//             cargo: (typeof info === 'object') ? info.data.cargo : '',
//             numero_documento: (typeof info === 'object') ? info.data.numero_documento : '',
//           };
//         }

//         const getData = async () => {
//           return Promise.all(funcionarios.map(contact => anAsyncFunction(contact)))
//         }

//         getData().then(data => {
//           dispatch(contactExpedienteSearchSuccess(data));
//         });
//       } else {
//         dispatch(contactExpedienteError('Se genero un error'));
//       }
// 		} catch (error) {
// 			dispatch(contactExpedienteError('Se genero un error al buscar los funcionarios responsables por favor consulte al administrador!'));
// 		}
// 	};
// };

export const getContactsRequest = values => {
	return async (dispatch) => {
		dispatch(contactsInit());
		try {
      const data = await ExpedienteServices.apiContacts.search(values);
      if (typeof data.data !== 'undefined' && Array.isArray(data.data)) {
        const anAsyncFunction = async contact => {
          const autoridad = await ExpedienteServices.apiAutoridadAmbiental.get(contact.autoridad_ambiental);
          // const entidad   = await ExpedienteServices.apiUser.getByDomainIdsTypes(contact.tipo_entidad_disposicion, domainTypesByKey.tiposEntidadDisposicion);
          console.log("autoridad", autoridad)
          const autoridadAmbientalNombre = (typeof autoridad === 'object') ? autoridad.data.nombre : '';
          console.log("autoridadAmbientalNombre", autoridadAmbientalNombre)
          const tipoEntidadNombre = contact.tipo_entidad_disposicion.domainName;// (typeof entidad.data !== 'undefined' && entidad.data.length > 0) ? entidad.data[0].domainName : '';
          console.log("tipoEntidadNombre", tipoEntidadNombre)

	        return {
            ...contact,
            autoridadAmbientalNombre,
            tipoEntidadNombre,
            correo_electronico: (typeof contact.correo_electronico !== 'undefined') ? contact.correo_electronico : '',
            numero_documento: (typeof contact.numero_documento !== 'undefined') ? contact.numero_documento : '',
          };
        }

        const getData = async () => {
          return Promise.all(data.data.map(contact => anAsyncFunction(contact)))
        }

        await getData().then(newData => {
          dispatch(contactsSuccess(newData, values));
        })
      } else {
        dispatch(contactsError(data));
      }
		} catch (error) {
			dispatch(contactsError('Se genero un error al buscar los contactos por favor consulte al administrador!'));
		}
	};
};

export const addContactToExpedienteRequest = contact => {
	return async (dispatch) => {
    dispatch(addContactToExpedienteInit());
		try {
      if (typeof contact._id !== 'undefined') {
        dispatch(addContactToExpedienteSuccess(contact));
      } else {
        dispatch(addContactToExpedienteError('El id del contacto no esta definido'));
      }
		} catch (error) {
			dispatch(addContactToExpedienteError('Se genero un error al agregar el contacto al listado de contactos del expediente por favor consulte al administrador!'));
		}
	};
};

export const cleanContactExpediente = () => {
	return (dispatch) => {
		try {
      dispatch(cleanContactExpedienteSuccess());
		} catch (error) {
			dispatch(contactExpedienteError('Se genero un error al limpiar los contactos del expediente por favor consulte al administrador!'));
		}
	};
};

export const getEspecimenesExpedienteRequest = (values, from, to, token) => {
	return async (dispatch) => {
		dispatch(especimenesExpedienteInit());
		try {
      const data = await ExpedienteServices.apiEspecimenes.search(values, from, to, token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async especimen => {
          const domainType = await ExpedienteServices.apiUser.getByDomainIdsTypes(especimen.estado_posesion_expediente._id, domainTypesByKey.tiposEstadoEspecimenExpediente, token);
          const estadoPosesion = (typeof domainType === 'object' && Array.isArray(domainType.data) && domainType.data.length > 0) ? domainType.data[0].domainName : '';
          const entidadDisposicionInicial = await ExpedienteServices.apiEntidadDisposicion.get(especimen.entidad_disposicion_inicial, token);
          const nombreDisposicionInicial = (typeof entidadDisposicionInicial === 'object' && typeof entidadDisposicionInicial.data  === 'object') ? entidadDisposicionInicial.data.nombre : '';
          const taxonomiaData = await ExpedienteServices.apiExpedientes.taxonGet(especimen.taxonomia._id, token);
          const taxonomiaGrupoBio = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].PIFS_grupoBio : '';
          const taxonomiaTaxonRank = (typeof taxonomiaData === 'object' && Array.isArray(taxonomiaData.data) && taxonomiaData.data.length > 0) ? taxonomiaData.data[0].taxonRank : '';
          const unidadMedidaData = await ExpedienteServices.apiUser.getByDomainIdsTypes(especimen.unidad_medida._id, domainTypesByKey.unidadesMedida, token);
          const unidadMedidaNombre = (typeof unidadMedidaData === 'object' && Array.isArray(unidadMedidaData.data) && unidadMedidaData.data.length > 0) ? unidadMedidaData.data[0].domainName : '';
          const clasificacionTaxonomiaData = await ExpedienteServices.apiUser.getByConstantIdsTypes('', constantTypesByKey.clasificacionTaxonomia, token);
          console.log('taxonomiaTaxonRank', taxonomiaTaxonRank);
          const categoriaId = (taxonomiaTaxonRank && typeof clasificacionTaxonomiaData === 'object' && Array.isArray(clasificacionTaxonomiaData.data) && clasificacionTaxonomiaData.data.length > 0) ? clasificacionTaxonomiaData.data.filter(({ constantName }) => constantName === taxonomiaTaxonRank)[0]._id : '';

          return {
            ...especimen,
            entidad_disposicion_inicial: { _id: especimen.entidad_disposicion_inicial, nombre: nombreDisposicionInicial },
            categoria_taxonomica: { _id: categoriaId, domainName: taxonomiaTaxonRank },
            taxonomia: { _id: especimen.taxonomia._id, canonicalName: especimen.taxonomia.canonicalName, PIFS_grupoBio: taxonomiaGrupoBio },
            unidad_medida: { _id: especimen.unidad_medida._id, domainName: unidadMedidaNombre },
            estado_posesion_expediente: { _id: especimen.estado_posesion_expediente._id, domainName: estadoPosesion },
            nuevo: false,
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(especimen => anAsyncFunction(especimen)))
        };

        getData().then(newData => {
          dispatch(especimenesExpedienteSuccess(newData));
        });

      }

      if(typeof data === 'string') {
        dispatch(especimenesExpedienteError(data));
      }
		} catch (error) {
			dispatch(especimenesExpedienteError(`Se genero un error al buscar los especimenes por favor consulte al administrador!: ${error.toString()}`));
		}
	};
};

export const getByIdEspecimenRequest = (id, mode) => {
	return (dispatch) => {
		dispatch(getByIdEspecimenInit());
		try {
      dispatch(getByIdEspecimenSuccess(id, mode));
		} catch (error) {
			dispatch(getByIdEspecimenError('Se genero un error al obtener el espécimen por favor consulte al administrador!'));
		}
	};
};

export const expedienteEspecimenUpdateRequest = (id, data) => {
	return (dispatch) => {
		dispatch(expedienteEspecimenUpdateInit());
		try {
      dispatch(expedienteEspecimenUpdateSuccess(id, data));
		} catch (error) {
			dispatch(expedienteEspecimenUpdateError('Se genero un error al actualizar el espécimen por favor consulte al administrador!'));
		}
	};
};

export const radicarExpedienteRequest = (id, token) => {
	return async (dispatch) => {
		dispatch(radicarInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.radicar(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        dispatch(radicarSuccess(data));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(radicarError(data));
        return { msg: `${data}`, err: true };
      }
      return { msg: '', err: false };
		} catch (error) {
      dispatch(radicarError('Se genero un error al radicar el expediente por favor consulte al administrador!'));
      return { msg: 'Se genero un error al radicar el expediente por favor consulte al administrador!', err: true };
		}
	};
};

export const addSameEspecimeneByCantidad = (especimen, cantidad) => {
  return (dispatch) => {
		try {
      if (cantidad > 0) {
        dispatch(addSameEspecimenSuccess(especimen, cantidad));
      } else {
        dispatch(addSameEspecimenError('Se genero un error la cantidad no esta especificada'));
      }
		} catch (error) {
			dispatch(addSameEspecimenError('Se genero un error al agregar el mismo expediente por cantidad ingresada por favor consulte al administrador!'));
		}
	};
};

export const deleteEspecimenesExpediente = selectedRowIds => {
  return (dispatch) => {
		try {
      if (Object.keys(selectedRowIds).length > 0) {
        dispatch(deleteEspecimenesExpedienteSuccess(selectedRowIds));
      } else {
        dispatch(deleteEspecimenesExpedienteError('Se genero un error no hay especimenes seleccionados'));
      }
		} catch (error) {
			dispatch(deleteEspecimenesExpedienteError('Se genero un error al borrar los especimenes seleccionados por favor consulte al administrador!'));
		}
	};
};

export const getByIdArchivoRequest = (id, mode) => {
  return async (dispatch) => {
		try {
      dispatch(selectedFileSuccess(id, mode));
      // if (Object.keys(file).length > 0) {
      //   const adjunto = await ExpedienteServices.apiUser.getByDomainIdsTypes(file.tipo, domainTypesByKey.tiposAdjunto);
      //   if(adjunto.data.length > 0 ) {
      //     const newFile = {
      //       ...file,
      //       nombre_tipo_documento: adjunto.data[0].domainName,
      //     };
      //     dispatch(selectedFileSuccess(newFile, mode));
      //   } else {
      //     dispatch(selectedFileError('Se genero un error el tipo de documento asociado al archivo no existe'));
      //   }
      // } else {
      //   dispatch(selectedFileError('Se genero un error no hay archivo seleccionado'));
      // }
		} catch (error) {
			dispatch(selectedFileError('Se genero un error al cargar el archivo seleccionado por favor consulte al administrador!'));
		}
	};
};

export const clearSelectedFile = () => {
  return async dispatch => {
    dispatch(clearSelectedFileInit());
  };
};

export const getByIdImagenRequest = (id, mode) => {
  return (dispatch) => {
		try {
      dispatch(selectedImageSuccess(id, mode));
      // if (Object.keys(image).length > 0) {
      //   dispatch(selectedImageSuccess(image, mode));
      // } else {
      //   dispatch(selectedImageError('Se genero un error no hay imagen seleccionado'));
      // }
		} catch (error) {
			dispatch(selectedImageError('Se genero un error al cargar la imagen seleccionada por favor consulte al administrador!'));
		}
	};
};

export const clearSelectedImage = () => {
  return async dispatch => {
    dispatch(clearSelectedImageInit());
  };
};

export const uploadFileRequest = file => {
  return async (dispatch) => {
		try {
      let data = {};
      if(file.nuevo) {
        const { nuevo, _id, activo, ...fileInfo } = file;
        console.log(nuevo, _id, activo);
        data = await ExpedienteServices.apiArchivos.uploadCreate(fileInfo);
      } else {
        const { nuevo, ...fileInfo } = file;
        console.log(nuevo);
        data = await ExpedienteServices.apiArchivos.uploadUpdate(fileInfo);
      }
      if (typeof data.data !== 'undefined') {
        console.log('archivo subido', data);
      } else {
        dispatch(selectedFileError('Se genero un error al guardar el archivo seleccionado por favor consulte al administrador!'));
      }
    } catch (error) {
      dispatch(selectedFileError('Se genero un error al guardar el archivo seleccionado por favor consulte al administrador!'));
		}
	};
};

export const uploadImageRequest = image => {
  return async (dispatch) => {
		try {
      let data = {};
      if(image.nuevo) {
        const { nuevo, _id, activo, ...imageInfo } = image;
        console.log(nuevo, _id, activo);
        data = await ExpedienteServices.apiImagenes.uploadCreate(imageInfo);
      } else {
        const { nuevo, ...imageInfo } = image;
        console.log(nuevo);
        data = await ExpedienteServices.apiImagenes.uploadUpdate(imageInfo);
      }
      if (typeof data.data !== 'undefined') {
        console.log('imagen subida', data);
      } else {
        dispatch(selectedFileError('Se genero un error al guardar la imagen seleccionada por favor consulte al administrador!'));
      }
    } catch (error) {
      dispatch(selectedFileError('Se genero un error al guardar la imagen seleccionada por favor consulte al administrador!'));
		}
	};
};

export const putEspecimenesExpedienteRequest = (id, especimenes) => {
  return async (dispatch) => {
		try {
      if (especimenes.length > 0) {
        const data = await ExpedienteServices.apiEspecimenes.put(id, especimenes);
        console.log('especimenes creados/actualizados', data);
      }
		} catch (error) {
			dispatch(especimenesExpedienteError('Se genero un error al crear/actualizar los especimenes del expediente por favor consulte al administrador!'));
		}
	};
};

export const createExpedienteRequest = (expediente, files, images, especimenes, token) => {
	return async (dispatch) => {
		dispatch(createInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.create(expediente, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        // save data files relationship with expediente
        if(files.length > 0) {
          const anAsyncFunctionFile = async file => {
            let info = {};
            if(file.nuevo) {
              const { nuevo, _id, activo, memory, ...fileInfo } = file;
              const newFileInfo = { ...fileInfo, expediente: data.data._id, tipo: Number(fileInfo.tipo._id) };
              console.log('archivo crear', newFileInfo, nuevo, activo);
              info = await ExpedienteServices.apiArchivos.uploadFileCreateToExpediente(newFileInfo, token);
            } else {
              const { nuevo, memory, ...fileInfo } = file;
              const updateFileInfo = { ...fileInfo, tipo: Number(fileInfo.tipo._id) };
              console.log('archivo actualizar', fileInfo, nuevo, updateFileInfo);
              info = await ExpedienteServices.apiArchivos.uploadFileUpdateToExpediente(updateFileInfo, token);
            }
            return info;
          };

          const getDataFile = async () => {
            return Promise.all(files.map(file => anAsyncFunctionFile(file)))
          };

          await getDataFile().then(newData => {
            console.log(newData);
          });
        }

        // save data images relationship with expediente
        if(images.length > 0) {
          const anAsyncFunctionImage = async image => {
            let info = {};
            if(image.nuevo) {
              const { nuevo, _id, activo, memory, ...imageInfo } = image;
              const newImageInfo = { ...imageInfo, expediente: data.data._id };
              console.log('imagen crear', newImageInfo, nuevo, _id, activo);
              info = await ExpedienteServices.apiImagenes.uploadImageCreateToExpediente(newImageInfo, token);
            } else {
              const { nuevo, memory, ...imageInfo } = image;
              console.log('imagen actualizar', imageInfo, nuevo);
              info = await ExpedienteServices.apiImagenes.uploadImageUpdateToExpediente(imageInfo, token);
            }
            return info;
          };

          const getDataImage = async () => {
            return Promise.all(images.map(image => anAsyncFunctionImage(image)))
          };

          await getDataImage().then(newData => {
            console.log(newData);
          });
        }

        // save data especimenes relationship with expediente
        if(especimenes.length > 0) {
          const anAsyncFunctionEspecimen = especimen => {
            return removeEmpty({
              clase_recurso: Number(especimen.clase_recurso._id),
              estado: Number(especimen.estado._id),
              parte_producto: especimen.parte_producto._id ? Number(especimen.parte_producto._id) : '',
              taxonomia: Number(especimen.taxonomia._id),
              nombre_comun: especimen.nombre_comun,
              descripcion: especimen.descripcion,
              hongos_enfermedades: especimen.hongos_enfermedades,
              marcador_genetico: especimen.marcador_genetico,
              sexo: Number(especimen.sexo._id),
              tiempo_cautiverio: especimen.tiempo_cautiverio ? Number(especimen.tiempo_cautiverio) : 0,
              unidad_medida: Number(especimen.unidad_medida._id),
              condicion: Number(especimen.condicion._id),
              estado_desarrollo: Number(especimen.estado_desarrollo._id),
              pais_procedencia_declarado: Number(especimen.pais_procedencia_declarado._id),
              departamento_procedencia_declarado: especimen.departamento_procedencia_declarado._id ? Number(especimen.departamento_procedencia_declarado._id) : '',
              municipio_procedencia_declarado: especimen.municipio_procedencia_declarado._id ? Number(especimen.municipio_procedencia_declarado._id) : '',
              corregimiento_procedencia_declarado: especimen.corregimiento_procedencia_declarado,
              vereda_procedencia_declarado: especimen.vereda_procedencia_declarado,
              barrio_procedencia_declarado: especimen.barrio_procedencia_declarado,
              predio_procedencia_declarado: especimen.predio_procedencia_declarado,
              lugar_procedencia_declarado: especimen.lugar_procedencia_declarado,
              entidad_disposicion_inicial: especimen.entidad_disposicion_inicial._id,
              observaciones: especimen.observaciones_generales,
              castrado: especimen.castrado,
              prenado: especimen.prenado,
              dieta: especimen.dieta,
              disposicion_final: false,
              cantidad: especimen.cantidad ? Number(especimen.cantidad) : 1,
              _id: especimen.nuevo ? null : especimen._id,
            });
          };
          const newEspecimenes = especimenes.map(especimen => anAsyncFunctionEspecimen(especimen));
          const dataEspecimenes = await ExpedienteServices.apiEspecimenes.put(data.data._id, newEspecimenes, token);
          console.log(dataEspecimenes);
        }

        dispatch(createSuccess(data));
        return { msg: `${data.message} `, err: false, _id: data.data._id };
      }

      if(typeof data === 'string') {
        dispatch(createError(data));
        return { msg: `${data}`, err: true, _id: null };
      }
      return { msg: '', err: false, _id: null };
		} catch (error) {
      dispatch(createError('Se genero un error al guardar el expediente por favor consulte al administrador!'));
      return { msg: 'Se genero un error al guardar el expediente por favor consulte al administrador!', err: true, _id: null };
		}
	};
};

export const updateExpedienteRequest = (expediente, files, images, especimenes, token) => {
	return async (dispatch) => {
		dispatch(updateInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.update(expediente._id, expediente, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        // save data files relationship with expediente
        if(files.length > 0) {
          const anAsyncFunctionFile = async file => {
            let info = {};
            if(file.nuevo) {
              const { nuevo, _id, activo, memory, ...fileInfo } = file;
              const newFileInfo = { ...fileInfo, memory, expediente: data.data._id, tipo: Number(fileInfo.tipo._id) };
              console.log('archivo crear', newFileInfo, nuevo, activo);
              info = await ExpedienteServices.apiArchivos.uploadFileCreateToExpediente(newFileInfo, token);
            } else {
              const { nuevo, memory, ...fileInfo } = file;
              const updateFileInfo = { ...fileInfo, tipo: Number(fileInfo.tipo._id) };
              if((!updateFileInfo.activo) || (updateFileInfo.archivo && typeof updateFileInfo.archivo === 'string')) {
                const { archivo, ...newFileUpdated} = updateFileInfo;
                console.log('entro por aca archivo 1', nuevo);
                info = await ExpedienteServices.apiArchivos.uploadFileUpdateToExpediente(newFileUpdated, token);
              } else {
                console.log('entro por aca archivo 2', nuevo);
                info = await ExpedienteServices.apiArchivos.uploadFileUpdateToExpediente(updateFileInfo, token);
              }
            }
            return info;
          };

          const getDataFile = async () => {
            return Promise.all(files.map(file => anAsyncFunctionFile(file)))
          };

          await getDataFile().then(newData => {
            console.log(newData);
          });
        }

        // save data images relationship with expediente
        if(images.length > 0) {
          const anAsyncFunctionImage = async image => {
            let info = {};
            if(image.nuevo) {
              const { nuevo, _id, activo, memory, ...imageInfo } = image;
              const newImageInfo = { ...imageInfo, expediente: data.data._id };
              console.log(nuevo, _id, activo);
              info = await ExpedienteServices.apiImagenes.uploadImageCreateToExpediente(newImageInfo, token);
            } else {
              const { nuevo, memory, ...imageInfo } = image;
              console.log('imagen actualizar', imageInfo, nuevo);
              if((!imageInfo.activo) || (imageInfo.archivo && typeof imageInfo.archivo === 'string')) {
                const { archivo, ...newImageUpdated} = imageInfo;
                info = await ExpedienteServices.apiImagenes.uploadImageUpdateToExpediente(newImageUpdated, token);
              } else {
                info = await ExpedienteServices.apiImagenes.uploadImageUpdateToExpediente(imageInfo, token);
              }
            }
            return info;
          };

          const getDataImage = async () => {
            return Promise.all(images.map(image => anAsyncFunctionImage(image)))
          };

          await getDataImage().then(newData => {
            console.log(newData);
          });
        }

        // save data especimenes relationship with expediente
        if(especimenes.length > 0) {
          const anAsyncFunctionEspecimen = especimen => {
            return removeEmpty({
              clase_recurso: Number(especimen.clase_recurso._id),
              estado: Number(especimen.estado._id),
              parte_producto: especimen.parte_producto._id ? Number(especimen.parte_producto._id) : '',
              taxonomia: Number(especimen.taxonomia._id),
              nombre_comun: especimen.nombre_comun,
              descripcion: especimen.descripcion,
              hongos_enfermedades: especimen.hongos_enfermedades,
              marcador_genetico: especimen.marcador_genetico,
              sexo: Number(especimen.sexo._id),
              tiempo_cautiverio: Number(especimen.tiempo_cautiverio),
              unidad_medida: Number(especimen.unidad_medida._id),
              condicion: Number(especimen.condicion._id),
              estado_desarrollo: Number(especimen.estado_desarrollo._id),
              pais_procedencia_declarado: Number(especimen.pais_procedencia_declarado._id),
              departamento_procedencia_declarado: especimen.departamento_procedencia_declarado._id ? Number(especimen.departamento_procedencia_declarado._id) : '',
              municipio_procedencia_declarado: especimen.municipio_procedencia_declarado._id ? Number(especimen.municipio_procedencia_declarado._id) : '',
              corregimiento_procedencia_declarado: especimen.corregimiento_procedencia_declarado,
              vereda_procedencia_declarado: especimen.vereda_procedencia_declarado,
              barrio_procedencia_declarado: especimen.barrio_procedencia_declarado,
              predio_procedencia_declarado: especimen.predio_procedencia_declarado,
              lugar_procedencia_declarado: especimen.lugar_procedencia_declarado,
              entidad_disposicion_inicial: especimen.entidad_disposicion_inicial._id,
              observaciones: especimen.observaciones_generales,
              castrado: especimen.castrado,
              prenado: especimen.prenado,
              dieta: especimen.dieta,
              disposicion_final: especimen.disposicion_final ? especimen.disposicion_final : false,
              cantidad: especimen.cantidad ? Number(especimen.cantidad) : 1,
              _id: especimen.nuevo ? null : especimen._id,
            });
          };
          const newEspecimenes = especimenes.map(especimen => anAsyncFunctionEspecimen(especimen));
          const dataEspecimenes = await ExpedienteServices.apiEspecimenes.put(data.data._id, newEspecimenes, token);
          console.log(dataEspecimenes);
        }

        dispatch(updateSuccess(data));
        return { msg: `${data.message} `, err: false, _id: data.data._id };
      }

      if(typeof data === 'string') {
        dispatch(createError(data));
        return { msg: `${data}`, err: true, _id: null };
      }
      return { msg: '', err: false, _id: null };
		} catch (error) {
      dispatch(updateError('Se genero un error al actualizar el expediente por favor consulte al administrador!'));
      return { msg: 'Se genero un error al actualizar el expediente por favor consulte al administrador!', err: true, _id: null };
		}
	};
};

export const loadSelected = (id, mode, token) => {
  return async dispatch => {
    dispatch(loadSelectedInit());
    try {
      const data = await ExpedienteServices.apiExpedientes.get(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async contact => {
          const funcionario   = await ExpedienteServices.apiContacts.get(contact, token);
          if(typeof funcionario === 'object' && typeof funcionario.data === 'object') {
            const autoridad     = await ExpedienteServices.apiAutoridadAmbiental.get(funcionario.data.autoridad_ambiental, token);
            let idAutoridad     = '';
            let nombreAutoridad = '';
            let tipoAutoridad   = '';

            if(typeof autoridad === 'object' && typeof autoridad.data === 'object') {
              const autoridadTipo = await ExpedienteServices.apiUser.getByDomainIdsTypes(autoridad.data.tipo._id, domainTypesByKey.tiposAutoridadAmbiental, token);
              const autotidadTipoNombre = (typeof autoridadTipo === 'object' && autoridadTipo.data.length === 1) ? autoridadTipo.data[0].domainName : '';
              idAutoridad = autoridad.data._id;
              nombreAutoridad = autoridad.data.nombre;
              tipoAutoridad = autotidadTipoNombre;
            }

            return {
              ...funcionario.data,
              _id: funcionario.data._id ? funcionario.data._id : '',
              nombre: funcionario.data.nombre ? funcionario.data.nombre : '',
              autoridad_ambiental: { _id: idAutoridad, nombre: nombreAutoridad, tipo: tipoAutoridad},
              numero_documento: funcionario.data.numero_documento ? funcionario.data.numero_documento : '',
            };
          }

          return {
            _id: '',
            nombre: '',
            autoridad_ambiental: { _id: '', nombre: '', tipo: ''},
            numero_documento: '',
          };
        };

        const getData = async () => {
          return Promise.all(data.data.funcionarios.map(contact => anAsyncFunction(contact)))
        };

        getData().then(async newData => {

          const anAsyncFunctionData = async () => {
            const sitioProcedimiento = await ExpedienteServices.apiUser.getByDomainIdsTypes(data.data.tipo_sitio_procedimiento._id, domainTypesByKey.tiposProcedimientoSitio, token);
            const motivoCierreData = (data.data.motivo_cierre && await ExpedienteServices.apiUser.getByDomainIdsTypes(data.data.motivo_cierre, domainTypesByKey.cierreMotivo, token)) || ('');
            const documentoPersona = await ExpedienteServices.apiUser.getByConstantIdsTypes(data.data.personas[0].tipo_documento, domainTypesByKey.tiposDocumento);
            const paisPersona = await ExpedienteServices.apiUser.getByConstantIdsTypes(data.data.personas[0].pais, domainTypesByKey.paises);
            const departamentoPersona = await ExpedienteServices.apiUser.getByConstantIdsTypes(data.data.personas[0].departamento, domainTypesByKey.departamentos);
            const municipioPersona = await ExpedienteServices.apiUser.getByConstantIdsTypes(data.data.personas[0].municipio, domainTypesByKey.municipios);
            const domainName = (typeof sitioProcedimiento === 'object' && Array.isArray(sitioProcedimiento.data) && (sitioProcedimiento.data.length > 0)) ? sitioProcedimiento.data[0].domainName : '';
            const documentoNombre = (typeof documentoPersona === 'object' && Array.isArray(documentoPersona.data) && (documentoPersona.data.length > 0)) ? documentoPersona.data[0].constantName : '';
            const paisNombre = (typeof paisPersona === 'object' && Array.isArray(paisPersona.data) && (paisPersona.data.length > 0)) ? paisPersona.data[0].constantName : '';
            const departamentoNombre = (typeof departamentoPersona === 'object' && Array.isArray(departamentoPersona.data) && (departamentoPersona.data.length > 0)) ? departamentoPersona.data[0].constantName : '';
            const municipioNombre = (typeof municipioPersona === 'object' && Array.isArray(municipioPersona.data) && (municipioPersona.data.length > 0)) ? municipioPersona.data[0].constantName : '';
            const motivoCierreNombre = (typeof motivoCierreData === 'object' && Array.isArray(motivoCierreData.data) && (motivoCierreData.data.length > 0)) ? motivoCierreData.data[0].domainName : '';

            let archivos = [];
            let imagenes = [];
            if(data.data.archivos_adjuntos.length > 0) {
              const anAsyncFunctionFile = async archivo => {
                const archivoTipo = await ExpedienteServices.apiUser.getByDomainIdsTypes(archivo.tipo, domainTypesByKey.tiposAdjunto, token);
                const tipoNombre = (typeof archivoTipo === 'object' && Array.isArray(archivoTipo.data) && (archivoTipo.data.length > 0)) ? archivoTipo.data[0].domainName : '';
                const archivoInfo = await ExpedienteServices.apiArchivos.getFileInfoToExpediente(archivo._id, token);
                const detalles = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoTipo.data.length > 0)) ? (archivoInfo.data[0].detalles || '') : '';
                // eslint-disable-next-line camelcase
                const usuario_firma = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoTipo.data.length > 0)) ? (archivoInfo.data[0].usuario_firma || '') : '';
                const cargo = (typeof archivoInfo === 'object' && Array.isArray(archivoInfo.data) && (archivoTipo.data.length > 0)) ? (archivoInfo.data[0].cargo || '') : '';
                console.log('archivoInfo', archivoInfo);
                return {
                  ...archivo,
                  tipo: { _id: archivo.tipo, domainName: tipoNombre },
                  detalles,
                  usuario_firma,
                  cargo,
                  nuevo: false,
                  nombre_archivo: `${archivo.nombre}.${archivo.archivo.split('.')[1]}`,
                  archivo_cierre_expediente: archivo.archivo_cierre_expediente ? archivo.archivo_cierre_expediente : false,
                };
              };

              const getDataFiles = async () => {
                const archivosFiltrados = data.data.archivos_adjuntos.filter(({ activo }) => activo === true);
                console.log('archivosFiltrados', archivosFiltrados);
                return Promise.all(archivosFiltrados.map(archivo => anAsyncFunctionFile(archivo)))
              }

              archivos = await getDataFiles().then(newDataFile => {
                return newDataFile;
              });
            }

            if(data.data.imagenes_adjuntas.length > 0) {
              const anAsyncFunctionImagen = async imagen => {
                const imagenInfo = await ExpedienteServices.apiImagenes.getImageInfoToExpediente(imagen._id, token);
                const detalles = (typeof imagenInfo === 'object' && typeof imagenInfo.data === 'object' && imagenInfo.data.detalles) ? imagenInfo.data.detalles : '';
                console.log('imagenInfo', imagenInfo);
                return {
                  ...imagen,
                  detalles,
                  nuevo: false,
                  nombre_archivo: `${imagen.nombre}.${imagen.archivo.split('.')[1]}`,
                };
              };

              const getDataImages = async () => {
                const imagenesFiltrados = data.data.imagenes_adjuntas.filter(({ activo }) => activo === true);
                console.log('imagenesFiltrados', imagenesFiltrados);
                return Promise.all(imagenesFiltrados.map(imagen => anAsyncFunctionImagen(imagen)))
              }

              imagenes = await getDataImages().then(newDataImage => {
                return newDataImage;
              });
            }

            return {
              ...data.data,
              autoridad_ambiental: { ...data.data.autoridad_ambiental, _id: data.data.autoridad_ambiental ? data.data.autoridad_ambiental._id : '', nombre: data.data.autoridad_ambiental ? data.data.autoridad_ambiental.nombre : ''},
              tipo_sitio_procedimiento: { _id: data.data.tipo_sitio_procedimiento._id, domainName },
              motivo_cierre: (data.data.motivo_cierre && { _id: data.data.motivo_cierre, domainName: motivoCierreNombre }) || (''),
              funcionarios: newData,
              personas: {
                ...data.data.personas[0],
                tipo_documento: { _id: data.data.personas[0].tipo_documento, constantName: documentoNombre },
                pais: { _id: data.data.personas[0].pais, constantName: paisNombre },
                departamento: { _id: data.data.personas[0].departamento, constantName: departamentoNombre },
                municipio: { _id: data.data.personas[0].municipio, constantName: municipioNombre },
              },
              files: archivos,
              images: imagenes,
            };
          };

          const newObj = await anAsyncFunctionData();
          dispatch(loadSelectedSuccess(newObj, mode));
        });
      }

      if(typeof data === 'string') {
        dispatch(loadSelectedFailed(data));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(clearSelectedInit());
  };
};

export const clearSelectedFileClose = () => {
  return async dispatch => {
    dispatch(clearSelectedFileCloseInit());
  };
};

export const getByIdArchivoCloseRequest = (id, mode) => {
  return async (dispatch) => {
		try {
      dispatch(selectedFileCloseSuccess(id, mode));
      // if (Object.keys(file).length > 0) {
      //   const adjunto = await ExpedienteServices.apiUser.getByDomainIdsTypes(file.tipo, domainTypesByKey.tiposAdjunto, token);
      //   if(adjunto.data.length > 0 ) {
      //     const newFile = {
      //       ...file,
      //       nombre_tipo_documento: adjunto.data[0].domainName,
      //     };
      //     dispatch(selectedFileCloseSuccess(newFile, mode));
      //   } else {
      //     dispatch(selectedFileCloseError('Se genero un error el tipo de documento asociado al archivo no existe'));
      //   }
      // } else {
      //   dispatch(selectedFileCloseError('Se genero un error no hay archivo seleccionado'));
      // }
		} catch (error) {
			dispatch(selectedFileCloseError('Se genero un error al cargar el archivo seleccionado por favor consulte al administrador!'));
		}
	};
};

export const downloadFileCloseRequest = file => {
  return async (dispatch) => {
		try {
      const fileBlob = await ExpedienteServices.apiArchivos.download(file);
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${file.archivo}`;
      a.click();
      return true;
    } catch (error) {
      dispatch(selectedFileError('Se genero un error al descargar el archivo seleccionado por favor consulte al administrador!'));
      return false;
		}
	};
};

export const especimenTaxonomyInfoRequest = (values, token) => {
	return async (dispatch) => {
		dispatch(especimenTaxonoInfoInit());
		try {
      const data = await ExpedienteServices.apiExpedientes.taxonInfo(values, token);
      if (typeof data === 'object' && Array.isArray(data.data)) {
        dispatch(especimenTaxonoInfoSuccess(data.data));
        return { taxonomyInfo: data.data, msg: `${data.message}`, err: false };
      }
      if(typeof data === 'string') {
        dispatch(especimenTaxonoInfoError(data));
        return { taxonomyInfo: [], msg: `${data}`, err: true };
      }
      return { taxonomyInfo: [], msg: 'Se genero un error al consultar la taxonomía', err: true };
		} catch (error) {
      dispatch(especimenTaxonoInfoError(`Se genero un error al consultar la taxonomía ${error.toString()}`));
      return { taxonomyInfo: [], msg: `Se genero un error al consultar la taxonomía ${error.toString()}`, err: true };
		}
	};
};
// Dispatchers sobre archivos
export const expedienteFilesAdd = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteFilesAddSuccess(file));
    } catch (error) {
      dispatch(expedienteFilesAddError(error));
    }
  };
};

export const expedienteFilesUpdate = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteFilesUpdateSuccess(file));
    } catch (error) {
      dispatch(expedienteFilesUpdateError(error));
    }
  };
};

export const expedienteFilesDelete = (selectedRowIds, selectedFlatRows) => {
  return async (dispatch) => {
    try {
      dispatch(expedienteFilesDeleteSuccess(selectedRowIds, selectedFlatRows));
    } catch (error) {
      dispatch(expedienteFilesDeleteError(error));
    }
  };
};

export const expedienteFilesDownload = (file, token) => {
  return async (dispatch) => {
    dispatch(expedienteFilesDownloadInit());
		try {
      const fileBlob = await ExpedienteServices.apiArchivos.downloadFileToExpediente(file._id, token);
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${file.nombre}`;
      // a.target = '_blank'; // Optional config
      a.click();
      dispatch(expedienteFilesDownloadSuccess());
      a.remove();
      return true;
    } catch (error) {
      dispatch(expedienteFilesDownloadError('Se genero un error al descargar el archivo seleccionado por favor consulte al administrador!'));
      return false;
		}
	};
};

// Dispatchers sobre imágenes
export const expedienteImagesAdd = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteImagesAddSuccess(file));
    } catch (error) {
      dispatch(expedienteImagesAddError(error));
    }
  };
};

export const expedienteImagesUpdate = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteImagesUpdateSuccess(file));
    } catch (error) {
      dispatch(expedienteImagesUpdateError(error));
    }
  };
};

export const expedienteImagesDelete = (selectedRowIds, selectedFlatRows) => {
  return async (dispatch) => {
    try {
      dispatch(expedienteImagesDeleteSuccess(selectedRowIds, selectedFlatRows));
    } catch (error) {
      dispatch(expedienteImagesDeleteError(error));
    }
  };
};

export const expedienteImagesDownload = (image, token) => {
  return async (dispatch) => {
    dispatch(expedienteImagesDownloadInit());
		try {
      const fileBlob = await ExpedienteServices.apiImagenes.downloadImageToExpediente(image._id, token);
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${image.nombre}`;
      // a.target = '_blank'; // Optional config
      a.click();
      dispatch(expedienteImagesDownloadSuccess());
      a.remove();
      return true;
    } catch (error) {
      dispatch(expedienteImagesDownloadError('Se genero un error al descargar la imagen seleccionada por favor consulte al administrador!'));
      return false;
		}
	};
};

// Dispatchers sobre cerrar expediente - soportes
export const expedienteFilesCloseAdd = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteFilesCloseAddSuccess(file));
    } catch (error) {
      dispatch(expedienteFilesCloseAddError(error));
    }
  };
};

export const expedienteFilesCloseUpdate = file => {
  return async (dispatch) => {
    try {
      dispatch(expedienteFilesCloseUpdateSuccess(file));
    } catch (error) {
      dispatch(expedienteFilesCloseUpdateError(error));
    }
  };
};

export const expedienteCerrarFilesDelete = (selectedRowIds, selectedFlatRows) => {
  return async (dispatch) => {
    try {
      dispatch(expedienteCerrarFilesDeleteSuccess(selectedRowIds, selectedFlatRows));
    } catch (error) {
      dispatch(expedienteCerrarFilesDeleteError(error));
    }
  };
};

export const expedienteFilesCloseDownload = (file, token) => {
  return async (dispatch) => {
    dispatch(expedienteFilesCloseDownloadInit());
		try {
      const fileBlob = await ExpedienteServices.apiArchivos.downloadFileToExpediente(file._id, token);
      const blobUrl = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${file.nombre}`;
      // a.target = '_blank'; // Optional config
      a.click();
      dispatch(expedienteFilesCloseDownloadSuccess());
      a.remove();
      return true;
    } catch (error) {
      dispatch(expedienteFilesCloseDownloadError('Se genero un error al descargar el archivo seleccionado por favor consulte al administrador!'));
      return false;
		}
	};
};
