/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, {useCallback, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import useForm from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {withRouter} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";

import {removeEmpty} from "../utils";

import ButtonsActionRow from "../components/Table/ButtonsActionRow";
import {Button} from "../components/form-ui-components/Button";
import {Card} from "../components/Card";
import {CardTitleBox} from "../components/CardTitleBox";
import {H1} from "../components/H1";
import { H5 } from "../components/H5";
import {Input} from "../components/form-ui-components/Input";
import {Form} from "../components/form-ui-components/Form";
import {Label} from "../components/form-ui-components/Label";
import {Select} from "../components/form-ui-components/Select";
import {SelectOptions} from "../components/form-ui-components/SelectOptions";
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import ErrorBoundary from '../components/ErrorBoundary';
import PaginationTable from "../components/Table/PaginationTable";

import {
    useAutoridadAmbiental,
    useDomains,
    useConstants,
    useContact,
    useNotification,
    useLogin
} from "../hooks";
/* Style Components */
import { CardContainerBody } from './styled';
import {Col, Col1, Col2, Col3, Col4, GridContainer, Row} from "../components/Grid";

const Contacts = ({history}) => {
    const [loaded, setLoaded] = useState(false);
    const {register, handleSubmit, errors, reset} = useForm({});
    const {domainsByKey, getOptionsByDomainTypes} = useDomains();
    const {
        constants,
        constantsByKey,
        getConstantOptionsByTypes
    } = useConstants();
    const {
      isLoading,
      error,
      data: contactos,
      getAllContactsSearch,
      deleteContact
    } = useContact();
    const { isLoggedIn, token } = useLogin();
    const {createNotification} = useNotification();

    const { data: autoridadesAmbientales, searchAutoridadAmbiental } = useAutoridadAmbiental();

    const contactosEstados = [{ _id: true, nombre: 'Activo' }, { _id: false, nombre: 'Inactivo' }];

    const gotoLogin = useCallback(() => {
      history.push('/');
    }, [history]);

    const handleClearSearchFields = async e => {
      e.preventDefault();
      reset();
      await getAllContactsSearch({}, token);
    };

    const load = useCallback(async () => {
      await Promise.all([getAllContactsSearch({}, token), searchAutoridadAmbiental({}, token), getOptionsByDomainTypes(["tiposAutoridadAmbiental", "tiposEntidadDisposicion"]), getConstantOptionsByTypes(["paises", "departamentos", "municipios", "tiposDocumento"])]);
    }, [
        getAllContactsSearch,
        getConstantOptionsByTypes,
        getOptionsByDomainTypes,
        searchAutoridadAmbiental
    ]);

    const AlertThenDelete = async original => {
      confirmAlert({
        title: 'DESACTIVAR CONTACTO',
        message: `¿Esta seguro que desea desactivar el contacto: ${original.nombre}`,
        buttons: [
          {
            label: "ACEPTAR",
            onClick: async () => {
              const newData = {
                ...original,
                observaciones: original.observaciones.length > 0 ? original.observaciones : "no hay observaciones",
                autoridad_ambiental: original.autoridad_ambiental ? original.autoridad_ambiental._id : null,
                tipo_entidad_disposicion: original.tipo_entidad_disposicion && original.tipo_entidad_disposicion != undefined? Number(original.tipo_entidad_disposicion._id) : null,
                tipo_documento: original.tipo_documento ? Number(original.tipo_documento._id) : null,
                pais: original.pais ? Number(original.pais._id) : null,
                departamento: original.departamento ? Number(original.departamento._id) : null,
                municipio: original.municipio ? Number(original.municipio._id) : null,
                estado: false,
              };
              const {msg, err} = await deleteContact(newData, original._id, token);
              createNotification({message: msg, visible: true, error: err});
              load();
            }
          },
          {
            label: "CANCELAR",
            onClick: () => {
            }
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      });
    };

    const columns = [
        {
          Header: 'Autoridad Ambiental',
          accessor: d => {
            return ((d.autoridad_ambiental.nombre !== '') && (d.autoridad_ambiental.nombre !== ' ') && (d.autoridad_ambiental.nombre !== undefined)) ? d.autoridad_ambiental.nombre : '---';
          }
        },
        {
          Header: 'Tipo de entidad',
          accessor: d => {
            return ((d.tipo_entidad_disposicion.domainName) && (d.tipo_entidad_disposicion.domainName !== undefined))? d.tipo_entidad_disposicion.domainName : '---';
          }
        },
        {
          Header: 'Nombre',
          accessor: d => {
            return ((d.nombre !== '') && (d.nombre !== ' ') && (d.nombre !== undefined)) ? d.nombre : '---';
          }
        },
        {
          Header: 'Correo',
          accessor: d => {
            return ((d.correo_electronico !== '') && (d.correo_electronico !== ' ') && (d.correo_electronico !== undefined)) ? d.correo_electronico : '---';
          }
        },
        {
          Header: 'Estado',
          accessor: d => {
              return ((d.estado !== '') && (d.estado !== ' ') && (d.estado !== undefined) && (d.estado)) ? 'Activo' : 'Inactivo';
          }
        },
        {
          Header: "Acciones",
          Cell: ({row}) => {
            const {original} = row;
            const buttons = [
              {
                id: original._id,
                iconName: 'remove_red_eye',
                title: 'Ver Contacto',
                visible: true,
                handleAction: async () => {
                  history.push(`/contacts-form/view/${original._id}`);
                }
              },
              {
                id: original._id,
                iconName: "edit",
                title: 'Actualizar Contacto',
                visible: true,
                handleAction: () => {
                  history.push(`/contacts-form/edit/${original._id}`);
                }
              },
              {
                id: original._id,
                iconName: 'visibility_off',
                title: 'Desactivar Contacto',
                visible: true,
                handleAction: async () => AlertThenDelete(original)
              },
            ];
            return <ButtonsActionRow buttons={buttons}/>;
          }
        }
    ];

    useEffect(() => {
      if ((isLoggedIn && !loaded)) {
        load();
        setLoaded(true);
      } else if (!isLoggedIn) {
        gotoLogin();
      }
    }, [isLoggedIn, loaded, load, gotoLogin]);


    const onSubmit = async (data, e) => {
      e.preventDefault();
      try {
        data.estado = data.estado ? (String(true) === data.estado) : null;
        await getAllContactsSearch(removeEmpty(data), token);
      } catch (err) {
        console.log(err);
      }
      e.stopPropagation();
    };

    return (
      <ErrorBoundary>
        <Page useSideBarSpace>
          {isLoggedIn && (
            <>
              <PageHeader>
                <H1>Ver contactos.</H1>
              </PageHeader>
              <Card title=" ">
                <CardTitleBox>
                  <H5>Datos de búsqueda</H5>
                </CardTitleBox>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <GridContainer>
                      <Row>
                        <Col3>
                          <Label>
                            NOMBRE
                            <Input
                              name="nombre"
                              type="text"
                              ref={register}
                              placeholder="Escriba el nombre del contacto"
                            />
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO DE CONTACTO<br />
                            <Select name="estado" ref={register}>
                              <SelectOptions selectOptions={contactosEstados}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CORREO
                            <Input
                              name="correo_electronico"
                              type="email"
                              ref={register}
                              placeholder="ingrese el correo del contacto"
                            />
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col4>
                          <Label>
                            AUTORIDAD AMBIENTAL
                            <Select name="autoridad_ambiental" ref={register}>
                              <SelectOptions selectOptions={autoridadesAmbientales}/>
                            </Select>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            TIPO DE ENTIDAD
                            <Select name="tipo_entidad_disposicion" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposEntidadDisposicion} />
                            </Select>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                              LIMPIAR FORMULARIO
                            </Button>
                          </Label>
                        </Col4>
                        <Col4>
                          <Label>
                            <Button type="submit">
                              BUSCAR
                            </Button>
                          </Label>
                        </Col4>
                      </Row>
                    </GridContainer>
                  </Form>
              </Card>
              <Card container>
                {isLoading ? (
                  <div style={{justifyContent: 'flex-end', width: '100%'}}>
                    <div>Cargando información, un momento por favor...</div>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
                ) : (
                  <CardContainerBody>
                    <div className="card-body-row">
                      <PaginationTable columns={columns} data={contactos}/>
                    </div>
                  </CardContainerBody>
                )}
                {error && (
                  <CardContainerBody>
                    <CardTitleBox className="error">
                      <H5> Ups, hubo un error {error}</H5>
                    </CardTitleBox>
                  </CardContainerBody>
                )}
              </Card>
            </>
          )}
        </Page>
      </ErrorBoundary>
    );
};

Contacts.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

Contacts.defaultProps = {
  history: {},
};

export default withRouter(Contacts);
