import {
  createNotificationA,
  deleteNotificationA
} from './actions';

export const createNotification = data => {
	return async (dispatch) => {
    dispatch(createNotificationA(data));
	};
};

export const deleteNotification = () => {
	return async (dispatch) => {
    dispatch(deleteNotificationA());
	};
};
