import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useLogin, useTaxonomy } from '../hooks';

import Page from "../components/Page";
import {Card} from "../components/Card";
import {H1} from "../components/H1";
import {PageHeader} from "../components/PageHeader";
import PaginationTable from "../components/Table/PaginationTable";
import {FileWidget} from "../components/FileWidget";
import { CardTitleBox } from "../components/CardTitleBox";
import {GridContainer, Row} from "../components/Grid";
import { H5 } from "../components/H5";
import ErrorBoundary from "../components/ErrorBoundary";

/* Style Components */
import { CardContainerBody } from './styled';

const TaxonomyPage = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const { isLoggedIn, token } = useLogin();
  const { data: taxonomy, getAllTaxonomy, error, isLoading } = useTaxonomy();

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const load = useCallback(async () => {
    await getAllTaxonomy(0, 30086, token);
  }, [ getAllTaxonomy ]);

  useEffect(() => {
    if(isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if(!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, gotoLogin, load]);

  const columns = [
    {Header: "Id", accessor: "_id"},
    {Header: "Nombre canónico", accessor: "canonicalName"},
    {Header: "Reino", accessor: "kingdom"},
    {Header: "Filo", accessor: "phylum"},
    {Header: "Clase", accessor: "class"},
    {Header: "Orden", accessor: "order"},
    {Header: "Familia", accessor: "family"},
    {Header: "Género", accessor: "genus"},
    {Header: "Epíteto específico", accessor: "specificEpithet"},
    {Header: "Epíteto infraespecífico", accessor: "infraspecificEpithet"},
    {Header: "Grupo biológico", accessor: "PIFS_grupoBio"},
    {Header: "Estado", accessor: d => (d.state ? "activo" : "inactivo")}
  ];

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        <PageHeader>
            <H1>Lista Taxonómica</H1>
        </PageHeader>
        <Card container>
          <GridContainer>
            <Row>
              <FileWidget fileName="taxonomy_file" downloadFileName="taxonomía"/>
            </Row>
            <Row>
              {isLoading ? (
                  <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
                    <div>Cargando lista, un momento por favor...</div>
                    <SkeletonTheme color="#f42f6391" highlightColor="#444">
                      <Skeleton height={34}/>
                    </SkeletonTheme>
                  </div>
              ) : (
                <div style={{alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                  <CardContainerBody>
                    <div className="card-body-row">
                      <PaginationTable columns={columns} data={taxonomy}/>
                    </div>
                  </CardContainerBody>
                  {error && (
                    <CardContainerBody>
                      <CardTitleBox className="error" error>
                        <H5>Ups, hubo un error: {error}</H5>
                      </CardTitleBox>
                    </CardContainerBody>
                  )}
                </div>
              )}
            </Row>
          </GridContainer>
        </Card>
      </Page>
    </ErrorBoundary>
  );
};

TaxonomyPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

TaxonomyPage.defaultProps = {
  history: {},
};

export default withRouter(TaxonomyPage);
