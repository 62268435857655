import styled from "styled-components";
import { pxToRem } from "../utils";

export const Card = styled.div`
  display: flex;
  padding: ${pxToRem(16)};
  padding-top: ${props => props.title ? pxToRem(56) : pxToRem(16)};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.cardShadow};
  margin: ${pxToRem(16)} auto;
  position: relative;
  box-sizing: border-box;
`;

export default Card;
