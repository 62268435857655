/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { useContext } from 'react';
import { useGlobalStore } from '../index';
import {
  listUsers,
  listSuccess,
  listFailed,
  userMe,
  userMeSuccess,
  userMeFailed,
  createUser,
  createSuccess,
  createFailed,
  updateUserMe,
  updateMeSuccess,
  updateMeFailed,
  updateUser,
  updateSuccess,
  updateFailed,
  userSelected,
  userClear,
  deleteUser,
  deleteSuccess,
  deleteFailed,
  rolesList,
  rolesSuccess,
  rolesFailed,
  tiposEntidadList,
  tiposEntidadSuccess,
  tiposEntidadFailed,
  tiposDocumentoList,
  tiposDocumentoSuccess,
  tiposDocumentoFailed,
  domainsByType,
  loadSelectedInit,
  loadSelectedSuccess,
  loadSelectedFailed
} from './actions';
import * as UsersServices from "../../services";
import { domainTypesByKey, constantTypesByKey } from '../../config/const';

export const getUsersRequest = (values, token) => {
  return async (dispatch) => {
    dispatch(listUsers());
    try {
      const data = await UsersServices.apiUser.getUsers(null,token);
      if (Array.isArray(data)) {
        const objSystem = {
          system: 'PIFS'
        };
        const usersSystem = await UsersServices.apiUser.getUsersBySystem(objSystem, token);
        if (typeof usersSystem.users !== 'undefined') {
          const filterUsersFunction = async user => {
            const result = data.filter(({ usuario }) => usuario === user.username);
            const docuemntoTipo      = await UsersServices.apiUser.getByConstantIdsTypes(user.documentType, constantTypesByKey.tiposDocumento);
            const constantName       = (typeof docuemntoTipo === 'object' && Array.isArray(docuemntoTipo.data) && docuemntoTipo.data.length > 0) ? docuemntoTipo.data[0].constantName : '';
            return {
              _id: user._id,
              usuario: user.username,
              apellidos: user.lastname || '',
              nombres: user.name || '',
              tipo_documento: { _id: user.documentType || '', nombre: constantName},
              numeroDocumento: user.document || '',
              correo: user.email || '',
              telefono: user.telephone || '',
              direccion: user.address || '',
              estado: user.status,
              entidad: (result.length === 1 && result[0].autoridad_ambiental && 'Autoridad') || (result.length === 1 && result[0].entidad_disposicion && 'Entidad') || (''),
              autoridad_ambiental: { _id: (result.length === 1 && result[0].autoridad_ambiental && result[0].autoridad_ambiental._id) || (''), nombre: (result.length === 1 && result[0].autoridad_ambiental && result[0].autoridad_ambiental.nombre) || ('') },
              entidad_disposicion: { _id: (result.length === 1 && result[0].entidad_disposicion && result[0].entidad_disposicion._id) || (''), nombre: (result.length === 1 && result[0].entidad_disposicion && result[0].entidad_disposicion.nombre) || ('') },
              rol: { _id: user.roles[0]._id, nombre: user.roles[0].name },
            };
          }

          const getData = async () => {
            return Promise.all(usersSystem.users.map(user => filterUsersFunction(user)))
          }

          await getData().then(newData => {
            dispatch(listSuccess(newData, values));
          })
        } else {
          dispatch(listFailed(usersSystem));
        }
      } else {
        dispatch(listFailed(data));
      }
    } catch (error) {
      dispatch(listFailed('Se genero un error cargando los usuarios por favor contacte al administrador!'));
    }
  };
};

export const getUserMeRequest = (username, token) => {
  return async (dispatch) => {
    dispatch(userMe());
    try {
      const data = await UsersServices.apiUser.getUserMe(username, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const {provider, roles, status, _id, date, __v, lastLogin, ...newData} = data.data;
        dispatch(userMeSuccess(newData));
        return { msg: 'Información cargada', err: false };
      }
      if(typeof data === 'string') {
        dispatch(userMeFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(userMeFailed('Se genero un error cargando mi información favor contacte al administrador!'));
      return { msg: 'Se genero un error cargando mi información por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(userMeFailed('Se genero un error cargando mi información por favor contacte al administrador!'));
      return { msg: 'Se genero un error cargando mi información por favor contacte al administrador!', err: true };
    }
  };
};

export const updateUserMeRequest = (formData, token) => {
  return async (dispatch) => {
    dispatch(updateUserMe());
    try {
      const data = await UsersServices.apiUser.updateUserMe(formData, token);
      if(typeof data === 'object' && typeof data.message !== 'undefined') {
        dispatch(updateMeSuccess(data.message));
        return { msg: data.message, err: false };
      }
      if(typeof data === 'string') {
        dispatch(userMeFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(userMeFailed('Se genero un error actualizando mi información favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando mi información por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(updateMeFailed('Se genero un error actualizando mi información por favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando mi información por favor contacte al administrador!', err: true };
    }
  };
};

export const userCreateRequest = (inputData, token) => {
  return async (dispatch) => {
    dispatch(createUser());
    try {
      const data = await UsersServices.apiUser.create(inputData, token);
      if(typeof data === 'object' && (typeof data.data === 'object' || typeof data._id !== 'undefined')) {
        const usuario  = (typeof data.data !== 'undefined') ? data.data.usuario : data.usuario;
        const tipoEntidad = (typeof data.data !== 'undefined') ? { autoridad_ambiental: data.data.autoridad_ambiental } : { entidad_disposicion: data.entidad_disposicion };
        const infoUser = await UsersServices.apiUser.getUserGeneral(usuario, token);

        const anAsyncFunction = async user => {
          const autoridadAmbiental = (user.autoridad_ambiental) ? user.autoridad_ambiental : '';
          const entidadDispocision = (user.entidad_disposicion) ? user.entidad_disposicion : '';
          const autoridad          = autoridadAmbiental ? await UsersServices.apiAutoridadAmbiental.get(autoridadAmbiental, token) : '';
          const entidad            = entidadDispocision ? await UsersServices.apiEntidadDisposicion.get(entidadDispocision, token) : ''; // await UsersServices.apiUser.getByDomainIdsTypes(entidadDispocision, domainTypesByKey.tiposEntidadDisposicion)
          const autoridadName      = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const entidadName        = (typeof entidad === 'object' && typeof entidad.data === 'object') ? entidad.data.nombre : '';
          const docuemntoTipo      = await UsersServices.apiUser.getByConstantIdsTypes(user.documentType, constantTypesByKey.tiposDocumento);
          const constantName       = (typeof docuemntoTipo === 'object' && Array.isArray(docuemntoTipo.data) && docuemntoTipo.data.length > 0) ? docuemntoTipo.data[0].constantName : '';
          return {
            _id: user._id,
            usuario: user.username,
            apellidos: user.lastname,
            nombres: user.name,
            tipo_documento: { _id: user.documentType, nombre: constantName},
            numeroDocumento: user.document,
            correo: user.email,
            telefono: user.telephone,
            direccion: user.address,
            estado: user.status,
            autoridad_ambiental: { _id: autoridadAmbiental, nombre: autoridadName },
            entidad_disposicion: { _id: entidadDispocision, nombre: entidadName },
            rol: { _id: user.roles[0]._id, nombre: user.roles[0].name },
          };
        }

        const getData = async () => {
          return Promise.all([{ ...infoUser.data, ...tipoEntidad }].map(user => anAsyncFunction(user)))
        }

        await getData().then(newData => {
          dispatch(createSuccess(newData[0]));
          return { msg: 'El usuario se guardó exitósamente.', err: false };
        });
        return { msg: 'El usuario se guardó exitósamente.', err: false };
      }
      if(typeof data === 'string') {
        dispatch(createFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(createFailed('Se genero un error creando el usuario favor contacte al administrador!'));
      return { msg: 'Se genero un error creando el usuario por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(createFailed(error));
      return { msg: 'no se pudo crear el usuario', err: true };
    }
  };
};

export const userUpdateRequest = (inputData, idUser, username, token) => {
  return async (dispatch) => {
    dispatch(updateUser());
    try {
      const data = await UsersServices.apiUser.update(inputData, idUser, username, token);
      if (typeof data.data !== 'undefined') {
        // dispatch(updateSuccess(data.data));
        return { msg: 'Se actualizó el usuario', err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(updateFailed('Se genero un error actualizando el usuario por favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando el usuario por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(updateFailed(error));
      return { msg: 'no se pudo actualizar el usuario', err: true };
    }
  };
};

export const userSelectedChange = id => {
  return (dispatch) => {
    try {
      dispatch(userSelected(id));
    } catch (error) {
      console.log(error);
    }
  };
};

export const userSelectedClear = () => {
  return (dispatch) => {
    try {
      dispatch(userClear());
    } catch (error) {
      console.log(error);
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(userClear());
  };
};

export const userDeleteRequest = (user,  username, token) => {
  return async (dispatch) => {
    dispatch(deleteUser());
    try {
      const data = await UsersServices.apiUser.delete(user, username, token);
      if (typeof data.data !== 'undefined') {
        // dispatch(deleteSuccess(data.data));
        return { msg: 'Usuario desactivado', err: false };
      }
      if(typeof data === 'string') {
        dispatch(deleteFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(deleteFailed('Se genero un error desactivando el usuario por favor contacte al administrador!'));
      return { msg: 'Se genero un error desactivando el usuario por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(deleteFailed('Se genero un error al desactivar por favor consulte al administrador!'));
      return { msg: "Se genero un error al desactivar por favor consulte al administrador!r", err: true };
    }
  };
};

export const getRolesRequest = () => {
  return async (dispatch) => {
    dispatch(rolesList());
    try {
      const data = await UsersServices.apiUser.getRoles();
      if (Array.isArray(data)) {
        const newData = data.filter((item) => item.system.name === 'PIFS');
        dispatch(rolesSuccess(newData));
      } else {
        dispatch(rolesFailed(data));
      }
    } catch (error) {
      dispatch(rolesFailed(error));
    }
  };
};

export const getTiposEntidadRequest = () => {
  return async (dispatch) => {
    dispatch(tiposEntidadList());
    try {
      const data = await UsersServices.apiUser.getEntidades();
      if (typeof data.status !== 'undefined') {
        dispatch(tiposEntidadSuccess(data.data));
      } else if (typeof data.error !== 'undefined') {
        dispatch(tiposEntidadFailed(data.error));
      }
    } catch (error) {
      dispatch(tiposEntidadFailed(error));
    }
  };
};

export const getTiposDocumentoRequest = () => {
  return async (dispatch) => {
    dispatch(tiposDocumentoList());
    try {
      const data = await UsersServices.apiUser.getDocumentos();
      if (typeof data.status !== 'undefined') {
        dispatch(tiposDocumentoSuccess(data.data));
      } else if (typeof data.error !== 'undefined') {
        dispatch(tiposDocumentoFailed(data.error));
      }
    } catch (error) {
      dispatch(tiposDocumentoFailed(error));
    }
  };
};

export const getDomainsByTypeRequest = type => {
  return async (dispatch) => {
    try {
      let data = {};
      const values = {};
      switch (type) {
        case 'autoridad':
          data = await UsersServices.apiUser.searchAutoridad(values);
          if (Array.isArray(data)) {
            const newData = data.map((item) => {
              return {
                _id: item._id,
                domainName: (item.nombre === '') ? item.nombre : '(empty)'
              }
            });
            dispatch(domainsByType(newData));
          }
          break;

        case 'disposicion':
          data = await UsersServices.apiUser.searchEntidad();
          if (Array.isArray(data)) {
            const newData = data.map((item) => {
              return {
                _id: item._id,
                domainName: (item.Nombre_disposicion === '') ? item.Nombre_disposicion : '(empty)'
              }
            });
            dispatch(domainsByType(newData));
          }
          break;

        default:
          data = await UsersServices.apiUser.getDomainsByType(type);
          if (Array.isArray(data.data)) {
            dispatch(domainsByType(data.data));
          }
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const loadSelected = (usuario, mode, token) => {
  return async (dispatch) => {
    dispatch(loadSelectedInit());
    try {
      const data = await UsersServices.apiUser.getUsers(usuario, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const autoridadAmbiental = (data.data.autoridad_ambiental) ? data.data.autoridad_ambiental : '';
          const entidadDispocision = (data.data.entidad_disposicion) ? data.data.entidad_disposicion : '';
          const autoridad          = autoridadAmbiental ? await UsersServices.apiAutoridadAmbiental.get(autoridadAmbiental, token) : '';
          const entidad            = entidadDispocision ? await UsersServices.apiEntidadDisposicion.get(entidadDispocision, token) : ''; // await UsersServices.apiUser.getByDomainIdsTypes(entidadDispocision, domainTypesByKey.tiposEntidadDisposicion)
          const docuemntoTipo      = await UsersServices.apiUser.getByConstantIdsTypes(data.data.documentType, constantTypesByKey.tiposDocumento);
          const constantName       = (typeof docuemntoTipo === 'object' && Array.isArray(docuemntoTipo.data) && docuemntoTipo.data.length > 0) ? docuemntoTipo.data[0].constantName : '';

          return {
            _id: data.data._id,
            usuario: data.data.username,
            apellidos: data.data.lastname,
            nombres: data.data.name,
            tipo_documento: { _id: data.data.documentType, nombre: constantName},
            numeroDocumento: data.data.document,
            correo: data.data.email,
            telefono: data.data.telephone,
            direccion: data.data.address,
            estado: data.data.status,
            autoridad_ambiental: { _id: autoridad != '' ? autoridad.data._id : '', nombre: autoridad != '' ? autoridad.data.nombre : '' },
            entidad_disposicion: { _id: entidad != '' ? entidad.data._id : '', nombre: entidad != '' ? entidad.data.nombre : '' },
            rol: { _id: data.data.roles[0]._id, nombre: data.data.roles[0].name },
          };
        };
        const newData = await anAsyncFunction();
        dispatch(loadSelectedSuccess(newData, mode));
      } else {
        dispatch(loadSelectedFailed('Se genero un error cargando el usuario por favor contacte al administrador!'));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};
