import { useGlobalStore } from '../../store';
import bindActions from '../../store/bindActions';
import constantsReducer from '../../store/constants';

const { dispatchers } = constantsReducer;

const useConstants = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { constants } = state;

  // List of Dispatchers
	const {
    getAllConstants,
    getConstantOptionsByTypes
  } = dispatchers;

  // Bind Actions
	const constantsActions = bindActions({
    getAllConstants,
    getConstantOptionsByTypes
  }, dispatch);

  return { ...constants, ...constantsActions };
};

export default useConstants;
