/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import styled from "styled-components";
import { Icon } from '../Icon';

const StyledButtons = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  margin-left: 5px;

  .button-item {
    color: #459d8e;
    &:hover {
      cursor: pointer;
      color: #CCCCCC;
    }
  }
`;

const ButtonsActionRow = ({ buttons }) => {
  return (
    <StyledButtons>
      {buttons.map(({ iconName, handleAction, id, title, visible }) => (
        (typeof visible === 'undefined' || visible) && (
          <span key={nanoid()} className="button-item" onClick={(e) => { e.preventDefault(); handleAction(id); e.stopPropagation(); }} title={title}>
            <Icon>{iconName}</Icon>
          </span>
        )
      ))}
    </StyledButtons>
  );
};

ButtonsActionRow.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number,  PropTypes.string]),
      iconName: PropTypes.string,
      title: PropTypes.string,
      handleAction: PropTypes.func,
    })
  ),
};

ButtonsActionRow.defaultProps = {
  buttons: [{}],
};

export default ButtonsActionRow;
