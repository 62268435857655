/* eslint-disable no-underscore-dangle */
import {
  CONSULTA_POR_TABLA_SEARCH_INIT,
  CONSULTA_POR_TABLA_SEARCH_SUCCESS,
  CONSULTA_POR_TABLA_SEARCH_ERROR,
  CONSULTA_POR_GEOVISOR_SEARCH_INIT,
  CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS,
  CONSULTA_POR_GEOVISOR_SEARCH_ERROR,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS,
  CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR,
  CONSULTA_POR_ESTADISTICAS_SEARCH_INIT,
  CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS,
  CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR
} from "./types";

const initialState = {
  tabla: [],
  est: {},
  pageCount: 0,
  from: 0,
  to: 0,
  geovisor: [],
  selected: null,
  isLoading: false,
  isLoadingFile: false,
  error: '',
};

const reportes = (state = initialState, { type, payload }) => {
  switch (type) {
    case CONSULTA_POR_TABLA_SEARCH_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONSULTA_POR_TABLA_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tabla: payload.data,
        pageCount: payload.pageCount,
        from: payload.from,
        to: payload.to,
        est: payload.est,
        error: ""
      };
    }

    case CONSULTA_POR_TABLA_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONSULTA_POR_GEOVISOR_SEARCH_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONSULTA_POR_GEOVISOR_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        geovisor: payload.data,
        error: ""
      };
    }

    case CONSULTA_POR_GEOVISOR_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONSULTA_POR_TABLA_DESCARGAR_REPORTE_INIT: {
      return {
        ...state,
        error: '',
        isLoadingFile: true,
      };
    }

    case CONSULTA_POR_TABLA_DESCARGAR_REPORTE_SUCCESS: {
      return {
        ...state,
        error: '',
        isLoadingFile: false,
      };
    }

    case CONSULTA_POR_TABLA_DESCARGAR_REPORTE_ERROR: {
      return {
        ...state,
        isLoadingFile: false,
        error: payload
      };
    }

    case CONSULTA_POR_ESTADISTICAS_SEARCH_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }

    case CONSULTA_POR_ESTADISTICAS_SEARCH_SUCCESS: {
      return{
        ...state,
        est: payload.data,
        isLoading: false,
      };
    }

    case CONSULTA_POR_ESTADISTICAS_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { reportes as default, initialState };
