/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
import {
  TAXONOMY_LIST_INIT,
  TAXONOMY_LIST_SUCCESS,
  TAXONOMY_LIST_ERROR,
} from "./types";

const initialState = {
  data: [],
  isLoading: false,
  error: ''
};

const taxonomy = (state = initialState, { type, payload }) => {
  switch (type) {
    case TAXONOMY_LIST_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true
      };
    }

    case TAXONOMY_LIST_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        isLoading: false
      };
    }

    case TAXONOMY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { taxonomy as default, initialState };
