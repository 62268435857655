import styled from "styled-components";
import { Link } from "react-router-dom";
import { pxToRem } from "../utils";

export const StyledLink = styled(Link)`
  display: inline-grid;
  padding: ${pxToRem(8)} ${pxToRem(8)};
  color: ${props => props.theme.primaryColor};
  font-weight: 300;
  font-size: ${pxToRem(12)};
  text-decoration: underline;
  text-align: ${props => props.center ? "center" : "initial"};
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;
