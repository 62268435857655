import {
  CONSTANTS_BY_TYPES_INIT,
  CONSTANTS_BY_TYPES_SUCCESS,
  CONSTANTS_BY_TYPES_ERROR,
  CONSTANTS_LIST_INIT,
  CONSTANTS_LIST_SUCCESS,
  CONSTANTS_LIST_ERROR
} from "./types";

export const optionsByTypes = () => {
  return {
    type: CONSTANTS_BY_TYPES_INIT
  };
};

export const optionsByTypesSuccess = (data, constantTypes) => {
  return {
    type: CONSTANTS_BY_TYPES_SUCCESS,
    payload: { data, constantTypes }
  };
};

export const optionsByTypesFailed = error => {
  return {
    type: CONSTANTS_BY_TYPES_ERROR,
    payload: error
  };
};

export const listInit = () => {
	return {
		type: CONSTANTS_LIST_INIT
	};
};

export const listSuccess = data => {
	return {
		type: CONSTANTS_LIST_SUCCESS,
		payload: { data }
	};
};

export const listError = error => {
	return {
		type: CONSTANTS_LIST_ERROR,
		payload: error
	};
};
