/* eslint-disable react/no-array-index-key */
import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { REPORTES_MENU_ID } from "../config/const";
/* Hooks */
import styled from "styled-components";
import {
  useLogin
} from '../hooks';
/* Components */
import Page from "../components/Page";
import { PageHeader } from "../components/PageHeader";
import { Card } from "../components/Card";
import { H5 } from "../components/H5";
import H3 from "../components/H3";
import {Col4, GridContainer, Row} from "../components/Grid";

const LinkCard = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  overflow: hidden;
  div {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h3 {
    color: ${props => props.theme.sideBarBackground};
    text-align: center;
  }
  &:hover {
    h3 {
      color: ${props => props.theme.sideBarBackgroundHover};;
    }
  }
`;

const ConsultaTraficoEspecies = ({ history }) => {
  const {isLoggedIn, menuUserLogin} = useLogin();

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (!isLoggedIn ) {
      gotoLogin();
    }
  }, [isLoggedIn, gotoLogin]);

  const reportsMenuItems = menuUserLogin.filter(function(item){
    if (item.id == REPORTES_MENU_ID){
      return true;
    } else {
      return false;
    }
  })[0].submenus

  return (
    <Page useSideBarSpace>
      <PageHeader>
        <H5>Reportes</H5>
      </PageHeader>
      <GridContainer>
        <Row >
          {reportsMenuItems.map((item, index) => (
            <Col4 center key={index}>
              <LinkCard to={item.path}>
                <Card>
                  <H3>{item.title}</H3>
                </Card>
              </LinkCard>
            </Col4>
          ))}
        </Row>
      </GridContainer>
    </Page>
  )
};

ConsultaTraficoEspecies.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConsultaTraficoEspecies.defaultProps = {
  history: {},
};

export default withRouter(ConsultaTraficoEspecies);
