import React, {useState, useEffect} from "react";
import Page from "../components/Page";
import {PageHeader} from "../components/PageHeader";
import {H1} from "../components/H1";
import {H3} from "../components/H3";
import {P} from "../components/P";
import {Card} from "../components/Card";

import {Col, Col1, Col12, Col3, GridContainer, Row} from "../components/Grid";

const AboutPage = () => {

    return (
        <Page>
            <PageHeader>
                <H1>Sobre el portal</H1>
            </PageHeader>

            <Card>
                <GridContainer>
                    <Row>
                        <Col12>
                            <H3>Portal de Tráfico de Fauna Silvestre.</H3>
                        </Col12>
                        <Col12>
                            <P>
                                El Ministerio de Medio Ambiente y Desarrollo Sostenible, en cumplimiento de sus
                                funciones,
                                ha
                                venido consolidando las medidas necesarias para asegurar la protección de las especies
                                de
                                flora
                                y fauna silvestre de Colombia. Como parte de este proyecto, han aparecido iniciativas
                                como
                                la
                                Política Nacional para la Gestión Integral de la Biodiversidad y sus Servicios
                                Ecosistémicos
                                (PNGIBSE); la Estrategia Nacional para la Prevención y el Control del Tráfico Ilegal de
                                Especies
                                Silvestres; y la Ley 1233 de 2009, “Por la cual se establece el procedimiento
                                sancionatorio
                                ambiental y se dictan otras disposiciones”. Esta última, establece la creación del
                                Portal de
                                Información sobre Flora y Fauna Silvestre no Maderable -PIFFS-, que tiene como objetivo
                                consolidar toda la información relativa al decomiso y manejo pos-decomiso de especies de
                                fauna
                                silvestre sujetas a tráfico ilegal.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                Actualmente existen ocho módulos que agrupan las acciones básicas de administración que
                                consisten en crear, consultar, modificar y desactivar información en el portal. Estos
                                mòdulos
                                son:
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Dominios:</b> Agrupa los casos de uso que cuyas funcionalidades aplican a la
                                administración de usuarios, roles y permisos sobre estos.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Usuarios:</b> agrupa los requerimientos funcionales asociados a la administración de
                                catálogos o listas de valores, constantes; en términos generales, toda aquella
                                funcionalidad
                                parametrizable.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Autoridad ambiental:</b> Administra la información de las autoridades ambientales
                                asociadas al portal.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Entidades de disposición:</b>Administra la información de las entidades en los cuales
                                los
                                especímenes son puestos a disposición.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Contactos:</b> Administra la información de los contactos asociados a una autoridad
                                ambiental.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Expedientes:</b> Administra la información del procesos de registro de incautación.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Traslados:</b> Administra los traslados y aprobaciones de traslados de los
                                especìmenes
                                entre autoridades y entidades.
                            </P>
                        </Col12>
                        <Col12>
                            <P>
                                <b>Reportes:</b> Genera los reportes que visualizan la información interpretada de
                                especìmenes y expedientes almacenados en el sistema.
                            </P>
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                            <H3>El SiB Colombia.</H3>
                        </Col12>
                        <Col12>
                            <P>
                                El SiB Colombia es la red nacional de datos abiertos sobre biodiversidad. Esta
                                iniciativa de
                                país nace con el Decreto 1603 de 1994 como parte del proceso de creación del Sistema
                                Nacional
                                Ambiental (Sina), establecido en la Ley 99 de 1993. Es el nodo oficial del país en la
                                Infraestructura Mundial de Información en Biodiversidad (GBIF) y es el sistema de
                                Información
                                Biogeográfica del Océano (OBIS), a través del SiB Marino. Su principal propósito es
                                brindar
                                acceso abierto a información sobre la diversidad biológica del país para la construcción
                                de
                                una
                                sociedad sostenible. Además, facilita la publicación en línea de datos e información
                                sobre
                                biodiversidad, y promueve su uso por parte de una amplia variedad de audiencias,
                                apoyando de
                                forma oportuna y eficiente la gestión integral de la biodiversidad. Es una realidad
                                gracias
                                a la
                                participación de cientos de organizaciones y personas que comparten datos e información
                                bajo
                                los
                                principios de libre acceso, transparencia, cooperación, reconocimiento y responsabilidad
                                compartida. Promueve la participación activa del gobierno, la academia, el sector
                                productivo
                                y
                                la sociedad civil para lograr la consolidación de información confiable y oportuna que
                                apoye
                                la
                                toma de decisiones a nivel nacional e internacional.
                            </P>
                        </Col12>
                    </Row>
                </GridContainer>
            </Card>
        </Page>
    );
};

export default AboutPage;
