import {
CREATE_NOTIFICATION,
DELETE_NOTIFICATION
} from './types';

export const createNotificationA = (data) => {
	return {
		type: CREATE_NOTIFICATION,
    payload: { data }
	};
};

export const deleteNotificationA = () => {
	return {
		type: DELETE_NOTIFICATION,
	};
};
