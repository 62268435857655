/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import nanoid from 'nanoid';
import {
  EXPEDIENTE_CREATE_INIT,
  EXPEDIENTE_CREATE_SUCCESS,
  EXPEDIENTE_CREATE_ERROR,
  EXPEDIENTE_UPDATE_INIT,
  EXPEDIENTE_UPDATE_SUCCESS,
  EXPEDIENTE_UPDATE_ERROR,
  EXPEDIENTE_CLOSE_INIT,
  EXPEDIENTE_CLOSE_SUCCESS,
  EXPEDIENTE_CLOSE_ERROR,
  EXPEDIENTE_SEARCH_INIT,
  EXPEDIENTE_SEARCH_SUCCESS,
  EXPEDIENTE_SEARCH_ERROR,
  EXPEDIENTE_DELETE_INIT,
  EXPEDIENTE_DELETE_SUCCESS,
  EXPEDIENTE_DELETE_ERROR,
  EXPEDIENTE_GETBYID_INIT,
  EXPEDIENTE_GETBYID_SUCCESS,
  EXPEDIENTE_GETBYID_ERROR,
  EXPEDIENTE_SELECTED,
  EXPEDIENTE_CONTACT_DELETE_SUCCESS,
  EXPEDIENTE_CONTACT_DELETE_ERROR,
  EXPEDIENTE_CONTACT_SEARCH_INIT,
  EXPEDIENTE_CONTACT_SEARCH_SUCCESS,
  CONTACTS_SEARCH_INIT,
  CONTACTS_SEARCH_SUCCESS,
  CONTACTS_SEARCH_ERROR,
  ADD_CONTACT_TO_EXPEDIENTE_INIT,
  ADD_CONTACT_TO_EXPEDIENTE_SUCCESS,
  ADD_CONTACT_TO_EXPEDIENTE_ERROR,
  CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS,
  ESPECIMENES_EXPEDIENTE_SEARCH_INIT,
  ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS,
  ESPECIMENES_EXPEDIENTE_SEARCH_ERROR,
  ESPECIMEN_GETBYID_INIT,
  ESPECIMEN_GETBYID_SUCCESS,
  ESPECIMEN_GETBYID_ERROR,
  EXPEDIENTE_RADICAR_INIT,
  EXPEDIENTE_RADICAR_SUCCESS,
  EXPEDIENTE_RADICAR_ERROR,
  ADD_SAME_ESPECIMEN_SUCCESS,
  ADD_SAME_ESPECIMEN_ERROR,
  DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS,
  DELETE_ESPECIMEN_EXPEDIENTE_ERROR,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_ERROR,
  FILE_CLEAR_SELECTED,
  SELECTED_IMAGE_SUCCESS,
  SELECTED_IMAGE_ERROR,
  IMAGE_CLEAR_SELECTED,
  EXPEDIENTE_CLEAR_SELECTED,
  EXPEDIENTE_LOAD_SELECTED_INIT,
  EXPEDIENTE_LOAD_SELECTED_SUCCESS,
  EXPEDIENTE_LOAD_SELECTED_ERROR,
  SELECTED_FILE_CLOSE_SUCCESS,
  SELECTED_FILE_CLOSE_ERROR,
  FILE_CLOSE_CLEAR_SELECTED,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR,
  EXPEDIENTE_FILES_ADD_SUCCESS,
  EXPEDIENTE_FILES_ADD_ERROR,
  EXPEDIENTE_FILES_UPDATE_SUCCESS,
  EXPEDIENTE_FILES_UPDATE_ERROR,
  EXPEDIENTE_FILES_DELETE_SUCCESS,
  EXPEDIENTE_FILES_DELETE_ERROR,
  EXPEDIENTE_FILES_DOWNLOAD_INIT,
  EXPEDIENTE_FILES_DOWNLOAD_SUCCESS,
  EXPEDIENTE_FILES_DOWNLOAD_ERROR,
  EXPEDIENTE_IMAGES_ADD_SUCCESS,
  EXPEDIENTE_IMAGES_ADD_ERROR,
  EXPEDIENTE_IMAGES_UPDATE_SUCCESS,
  EXPEDIENTE_IMAGES_UPDATE_ERROR,
  EXPEDIENTE_IMAGES_DELETE_SUCCESS,
  EXPEDIENTE_IMAGES_DELETE_ERROR,
  EXPEDIENTE_IMAGES_DOWNLOAD_INIT,
  EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS,
  EXPEDIENTE_IMAGES_DOWNLOAD_ERROR,
  EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_ADD_ERROR,
  EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR,
  EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_DELETE_ERROR,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR,
  EXPEDIENTE_ESPECIMEN_UPDATE_INIT,
  EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS,
  EXPEDIENTE_ESPECIMEN_UPDATE_ERROR
} from './types';
import { isPresent } from "../../utils";

const initialState = {
  data: [],
  pageCount: 0,
  contacts: [],
  files: [],
  filesAux: [],
  images: [],
  imagesAux: [],
  filesClose: [],
  filesAuxClose: [],
  especimenesExpediente: [],
  taxonomyInformation: [],
  selected: null,
  mode: null,
  isLoading: false,
  error: '',
  response: '',
  especimenSelected: null,
  especimenMode: null,
  filesMode: null,
  fileSelected: null,
  errorFile: '',
  imageMode: null,
  imageSelected: null,
  errorImage: '',
  filesCloseMode: null,
  fileCloseSelected: null,
  errorFileClose: '',
  errorContacts: '',
  isLoadingContacts: '',
  errorEspecimenes: '',
  isLoadingEspecimenes: false,
  isLoadingFile: false,
  isLoadingImage: false,
  isLoadingFileClose: false,
  isLoadingRadicar: false,
  isLoadingCerrar: false,
};

const expediente = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXPEDIENTE_CREATE_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
        selected: null,
        mode: null,
      };
    }

    case EXPEDIENTE_CREATE_SUCCESS: {
      return {
        ...state,
        data: [payload.data, ...state.data],
        isLoading: false,
      };
    }

    case EXPEDIENTE_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case EXPEDIENTE_UPDATE_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
        mode: null,
      };
    }

    case EXPEDIENTE_UPDATE_SUCCESS: {
      return {
        ...state,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        isLoading: false,
        selected: null,
      };
    }

    case EXPEDIENTE_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case EXPEDIENTE_CLOSE_INIT: {
      return {
        ...state,
        error: '',
        isLoadingCerrar: true,
      };
    }

    case EXPEDIENTE_CLOSE_SUCCESS: {
      return {
        ...state,
        // data: state.data.map((item) => (item._id === payload.data._id) ? { ...item, estado: } : item),
        isLoadingCerrar: false,
        error: '',
      };
    }

    case EXPEDIENTE_CLOSE_ERROR: {
      return {
        ...state,
        isLoadingCerrar: false,
        error: payload,
      };
    }

    case EXPEDIENTE_SEARCH_INIT: {
			return {
				...state,
				error: '',
        isLoading: true,
        selected: null,
        mode: null,
        files: [],
        filesAux: [],
        images: [],
        imagesAux: [],
        filesClose: [],
        filesAuxClose: [],
        especimenesExpediente: [],
        filesMode: null,
        fileSelected: null,
        errorFile: '',
        imageMode: null,
        imageSelected: null,
        errorImage: '',
        filesCloseMode: null,
        fileCloseSelected: null,
        errorFileClose: '',
      };
    }

		case EXPEDIENTE_SEARCH_SUCCESS: {
			return {
				...state,
        data: payload.data,
        pageCount: payload.pageCount,
        isLoading: false,
        error: ''
			};
    }

		case EXPEDIENTE_SEARCH_ERROR: {
			return {
				...state,
				isLoading: false,
				error: payload,
      };
    }

    case EXPEDIENTE_DELETE_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
        selected: null,
      };
    }

    case EXPEDIENTE_DELETE_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((item) => item._id !== payload),
        isLoading: false,
      };
    }

    case EXPEDIENTE_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case EXPEDIENTE_GETBYID_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true,
        selected: null,
      };
    }

    case EXPEDIENTE_GETBYID_SUCCESS: {
      return {
        ...state,
        selected: payload.data,
        mode: (payload.data.estado === 22000003) ? 'view' : null,
        isLoading: false,
      };
    }

    case EXPEDIENTE_GETBYID_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case EXPEDIENTE_SELECTED: {
			return {
				...state,
				selected: {...state.data.filter((item) => item._id === payload)[0]},
      };
    }

    case EXPEDIENTE_CONTACT_DELETE_SUCCESS: {
      return {
        ...state,
        contacts: state.contacts.filter((item) => item._id !== payload),
      };
    }

    case EXPEDIENTE_CONTACT_DELETE_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }

    case EXPEDIENTE_CONTACT_SEARCH_INIT: {
			return {
				...state,
				error: '',
        isLoading: true,
      };
    }

		case EXPEDIENTE_CONTACT_SEARCH_SUCCESS: {
			return {
				...state,
				contacts: payload.data,
        isLoading: false,
			};
    }

    case CONTACTS_SEARCH_INIT: {
			return {
				...state,
				errorContacts: '',
        isLoadingContacts: true,
      };
    }

		case CONTACTS_SEARCH_SUCCESS: {
      // const {
      //   nombre: nombreSearch,
      //   estado: estadoSearch,
      //   correo_electronico: correoSearch,
      //   autoridad_ambiental_consulta: autoridadSearch,
      //   tipo_entidad_disposicion_consulta: entidadSearch,
      // } = payload.values;

      // const contactsFiltered = payload.data
      //   .filter(({ nombre: name }) => {
      //     if(nombreSearch === '') return true;
      //     if(name.includes(nombreSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ estado: status }) => {
      //     console.log('estadoSearch', status, estadoSearch);
      //     if(estadoSearch === '') return true;
      //     if(String(status) === estadoSearch) return true;

      //     return false;
      //   })
      //   .filter(({ correo_electronico: correo }) => {
      //     if(correoSearch === '') return true;
      //     if(correo.includes(correoSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ autoridad_ambiental: autoridad }) => {
      //     if(autoridadSearch === '') return true;
      //     if(autoridad.includes(autoridadSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ tipo_entidad_disposicion: entidad }) => {
      //     if(entidadSearch === '') return true;
      //     if(entidad.includes(entidadSearch)) return true;

      //     return false;
      //   });

			return {
				...state,
        contacts: payload.data,
        isLoadingContacts: false,
			};
    }

		case CONTACTS_SEARCH_ERROR: {
			return {
				...state,
				isLoadingContacts: false,
				errorContacts: payload,
      };
    }

    case ADD_CONTACT_TO_EXPEDIENTE_INIT: {
			return {
				...state,
				errorContacts: '',
        isLoadingContacts: false,
      };
    }

		case ADD_CONTACT_TO_EXPEDIENTE_SUCCESS: {
			return {
				...state,
				contacts: [ ...state.contacts, payload.data ],
        isLoadingContacts: false,
			};
    }

		case ADD_CONTACT_TO_EXPEDIENTE_ERROR: {
			return {
				...state,
				isLoadingContacts: false,
				errorContacts: payload,
      };
    }

    case CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS: {
      return {
				...state,
				contacts: [],
      };
    }

    case ESPECIMENES_EXPEDIENTE_SEARCH_INIT: {
			return {
				...state,
				errorEspecimenes: '',
        isLoadingEspecimenes: true,
      };
    }

		case ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS: {
      // const {
      //   clase_recurso: claseRecursoSearch,
      //   parte_producto: parteProductoSearch,
      //   estado: estadoSearch,
      //   nombre_cientifico: nombreCientificoSearch,
      //   nombre_comun: nombreComunSearch,
      //   sexo: sexoSearch,
      //   condicion: condicionSearch,
      //   estado_desarrollo: estadoDesarrolloSearch,
      // } = payload.values;

      // const especimenesExpedienteFiltered = payload.data
      //   .filter(({ clase_recurso: claseRecurso }) => {
      //     if(claseRecursoSearch === '') return true;
      //     if(claseRecurso.includes(claseRecursoSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ parte_producto: parteProducto }) => {
      //     if(parteProductoSearch === '') return true;
      //     if(parteProducto.includes(parteProductoSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ estado: status }) => {
      //     if(estadoSearch === '') return true;
      //     if(String(status) === estadoSearch) return true;

      //     return false;
      //   })
      //   .filter(({ nombre_cientifico: nombreCientifico }) => {
      //     if(nombreCientificoSearch === '') return true;
      //     if(nombreCientifico.includes(nombreCientificoSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ nombre_comun: nombreComun }) => {
      //     if(nombreComunSearch === '') return true;
      //     if(nombreComun.includes(nombreComunSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ sexo }) => {
      //     if(sexoSearch === '') return true;
      //     if(sexo.includes(sexoSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ condicion }) => {
      //     if(condicionSearch === '') return true;
      //     if(condicion.includes(condicionSearch)) return true;

      //     return false;
      //   })
      //   .filter(({ estado_desarrollo: estadoDesarrollo }) => {
      //     if(estadoDesarrolloSearch === '') return true;
      //     if(estadoDesarrollo.includes(estadoDesarrolloSearch)) return true;

      //     return false;
      //   });

			return {
				...state,
        especimenesExpediente: payload.data,
        errorEspecimenes: '',
        isLoadingEspecimenes: false,
			};
    }

		case ESPECIMENES_EXPEDIENTE_SEARCH_ERROR: {
			return {
				...state,
				isLoadingEspecimenes: false,
				errorEspecimenes: payload,
      };
    }

    case ESPECIMEN_GETBYID_INIT: {
      return {
        ...state,
        errorEspecimenes: '',
        isLoadingEspecimenes: true,
        especimenSelected: null,
        especimenMode: null,
      };
    }

    case ESPECIMEN_GETBYID_SUCCESS: {
      return {
        ...state,
        especimenSelected: {...state.especimenesExpediente.filter((item) => item._id === payload.id)[0]},
        especimenMode: payload.mode,
        isLoadingEspecimenes: false,
      };
    }

    case ESPECIMEN_GETBYID_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimenes: payload,
      };
    }

    case EXPEDIENTE_ESPECIMEN_UPDATE_INIT: {
      return {
        ...state,
        errorEspecimenes: '',
        isLoadingEspecimenes: true,
      };
    }

    case EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS: {
      return {
        ...state,
        especimenesExpediente: state.especimenesExpediente.map((item) => (item._id === payload.id) ? { ...payload.data } : item),
        isLoadingEspecimenes: false,
      };
    }

    case EXPEDIENTE_ESPECIMEN_UPDATE_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimenes: payload,
      };
    }

    case EXPEDIENTE_RADICAR_INIT: {
      return {
        ...state,
        error: '',
        isLoadingRadicar: true,
      };
    }

    case EXPEDIENTE_RADICAR_SUCCESS: {
      return {
        ...state,
        // data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        isLoadingRadicar: false,
      };
    }

    case EXPEDIENTE_RADICAR_ERROR: {
      return {
        ...state,
        isLoadingRadicar: false,
        error: payload,
      };
    }

    case ADD_SAME_ESPECIMEN_SUCCESS: {
      const { data, cantidad } = payload;
      return {
        ...state,
        especimenesExpediente: [ ...state.especimenesExpediente, ...Array.from({ length: cantidad }, (_) => { return { ...data, _id: nanoid() } })],
        isLoadingEspecimenes: false,
      };
    }

    case ADD_SAME_ESPECIMEN_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimenes: payload,
      };
    }

    case DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS: {
      const { selectedRowIds } = payload;

      // const isPresent = (rows, index) => {
      //   let esta = false;
      //   Object.keys(rows).map(key => {
      //     if(key === index.toString()){
      //       esta = true;
      //     }
      //   });
      //   return esta;
      // };

      return {
        ...state,
        especimenesExpediente: state.especimenesExpediente.filter((item, index) => !isPresent(selectedRowIds, index)),
        isLoadingEspecimenes: false,
      };
    }

    case DELETE_ESPECIMEN_EXPEDIENTE_ERROR: {
      return {
        ...state,
        isLoadingEspecimenes: false,
        errorEspecimenes: payload,
      };
    }

    case SELECTED_FILE_SUCCESS: {
      const files1 = state.filesAux.filter((item) => item._id === payload.id)[0];
      return {
        ...state,
        fileSelected: files1 ? {...files1} : {...state.filesAuxClose.filter((item) => item._id === payload.id)[0]},
        filesMode: payload.mode,
        errorFile: '',
      };
    }

    case SELECTED_FILE_ERROR: {
      return {
        ...state,
        errorFile: payload,
      };
    }

    case FILE_CLEAR_SELECTED: {
      return {
        ...state,
        filesMode: null,
        fileSelected: null,
        errorFile: '',
      }
    }

    case SELECTED_IMAGE_SUCCESS: {
      return {
        ...state,
        imageSelected: {...state.imagesAux.filter((item) => item._id === payload.id)[0]},
        imageMode: payload.mode,
        errorImage: '',
      };
    }

    case SELECTED_IMAGE_ERROR: {
      return {
        ...state,
        errorImage: payload,
      };
    }

    case IMAGE_CLEAR_SELECTED: {
      return {
        ...state,
        imageMode: null,
        imageSelected: null,
        errorImage: '',
      }
    }

    case EXPEDIENTE_CLEAR_SELECTED: {
      return {
        ...state,
        error: "",
        selected: null,
        mode: null,
        isLoading: false,
        files: [],
        filesAux: [],
        images: [],
        imagesAux: [],
        filesClose: [],
        filesAuxClose: [],
        especimenesExpediente: [],
        contacts: [],
        filesMode: null,
        fileSelected: null,
        errorFile: '',
        imageMode: null,
        imageSelected: null,
        errorImage: '',
        filesCloseMode: null,
        fileCloseSelected: null,
        errorFileClose: '',
      }
    }

    case EXPEDIENTE_LOAD_SELECTED_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true,
        files: [],
        filesAux: [],
        images: [],
        imagesAux: [],
        filesClose: [],
        filesAuxClose: [],
        especimenesExpediente: [],
        contacts: [],
        filesMode: null,
        fileSelected: null,
        errorFile: '',
        imageMode: null,
        imageSelected: null,
        errorImage: '',
        filesCloseMode: null,
        fileCloseSelected: null,
        errorFileClose: '',
      };
    }

    case EXPEDIENTE_LOAD_SELECTED_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selected: payload.data,
        contacts: payload.data.funcionarios.filter(({ estado }) => estado === true),
        files: payload.data.files.filter(({ archivo_cierre_expediente }) => !archivo_cierre_expediente),
        filesAux: payload.data.files.filter(({ archivo_cierre_expediente }) => !archivo_cierre_expediente),
        images: payload.data.images,
        imagesAux: payload.data.images,
        filesClose: payload.data.files.filter(({ archivo_cierre_expediente }) => archivo_cierre_expediente),
        filesAuxClose: payload.data.files.filter(({ archivo_cierre_expediente }) => archivo_cierre_expediente),
        mode: payload.mode,
        especimenesExpediente: [],
        error: ""
      };
    }

    case EXPEDIENTE_LOAD_SELECTED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case SELECTED_FILE_CLOSE_SUCCESS: {
      return {
        ...state,
        fileCloseSelected: {...state.filesAuxClose.filter((item) => item._id === payload.id)[0]},
        filesCloseMode: payload.mode,
        errorFileClose: '',
      };
    }

    case SELECTED_FILE_CLOSE_ERROR: {
      return {
        ...state,
        errorFileClose: payload,
      };
    }

    case FILE_CLOSE_CLEAR_SELECTED: {
      return {
        ...state,
        filesCloseMode: null,
        fileCloseSelected: null,
        errorFileClose: '',
      }
    }

    case EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT: {
      return {
        ...state,
        error: '',
        // isLoading: true,
      };
    }

    case EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS: {
      return {
        ...state,
        taxonomyInformation: payload.data,
        // isLoading: false,
      };
    }

    case EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR: {
      return {
        ...state,
        // isLoading: false,
        error: payload,
      };
    }

    case EXPEDIENTE_FILES_ADD_SUCCESS: {
      return {
        ...state,
        files: [ ...state.files, payload.data ],
        filesAux: [ ...state.filesAux, payload.data ],
        errorFile: '',
      };
    }

    case EXPEDIENTE_FILES_ADD_ERROR: {
      return {
        ...state,
        errorFile: payload,
      };
    }

    case EXPEDIENTE_FILES_UPDATE_SUCCESS: {
      return {
        ...state,
        files: state.files.map((item) => (item._id === payload.data._id) ? payload.data : item),
        filesAux: state.filesAux.map((item) => (item._id === payload.data._id) ? payload.data : item),
        errorFile: '',
      };
    }

    case EXPEDIENTE_FILES_UPDATE_ERROR: {
      return {
        ...state,
        errorFile: payload,
      };
    }

    case EXPEDIENTE_FILES_DELETE_SUCCESS: {
      return {
        ...state,
        files: state.files.map((item, index) => isPresent(payload.selectedRowIds, index) ? { ...item, activo: false } : item),
        filesAux: state.filesAux.filter((_, index) => !isPresent(payload.selectedRowIds, index)),
        errorFile: '',
      };
    }

    case EXPEDIENTE_FILES_DELETE_ERROR: {
      return {
        ...state,
        errorFile: payload,
      };
    }

    case EXPEDIENTE_FILES_DOWNLOAD_INIT: {
      return {
        ...state,
        errorFile: '',
        isLoadingFile: true,
      };
    }

    case EXPEDIENTE_FILES_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        errorFile: '',
        isLoadingFile: false,
      };
    }

    case EXPEDIENTE_FILES_DOWNLOAD_ERROR: {
      return {
        ...state,
        errorFile: payload,
        isLoadingFile: false,
      };
    }

    case EXPEDIENTE_IMAGES_ADD_SUCCESS: {
      return {
        ...state,
        images: [ ...state.images, payload.data ],
        imagesAux: [ ...state.imagesAux, payload.data ],
        errorImage: '',
      };
    }

    case EXPEDIENTE_IMAGES_ADD_ERROR: {
      return {
        ...state,
        errorImage: payload,
      };
    }

    case EXPEDIENTE_IMAGES_UPDATE_SUCCESS: {
      return {
        ...state,
        images: state.images.map((item) => (item._id === payload.data._id) ? payload.data : item),
        imagesAux: state.imagesAux.map((item) => (item._id === payload.data._id) ? payload.data : item),
        errorImage: '',
      };
    }

    case EXPEDIENTE_IMAGES_UPDATE_ERROR: {
      return {
        ...state,
        errorImage: payload,
      };
    }

    case EXPEDIENTE_IMAGES_DELETE_SUCCESS: {
      return {
        ...state,
        images: state.images.map((item, index) => isPresent(payload.selectedRowIds, index) ? { ...item, activo: false } : item),
        imagesAux: state.imagesAux.filter((_, index) => !isPresent(payload.selectedRowIds, index)),
        errorImage: '',
      };
    }

    case EXPEDIENTE_IMAGES_DELETE_ERROR: {
      return {
        ...state,
        errorImage: payload,
      };
    }

    case EXPEDIENTE_IMAGES_DOWNLOAD_INIT: {
      return {
        ...state,
        errorImage: '',
        isLoadingImage: true,
      };
    }

    case EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        errorImage: '',
        isLoadingImage: false,
      };
    }

    case EXPEDIENTE_IMAGES_DOWNLOAD_ERROR: {
      return {
        ...state,
        errorImage: payload,
        isLoadingImage: false,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS: {
      return {
        ...state,
        filesClose: [ ...state.filesClose, payload.data ],
        filesAuxClose: [ ...state.filesAuxClose, payload.data ],
        errorFileClose: '',
      };
    }

    case EXPEDIENTE_FILES_CLOSE_ADD_ERROR: {
      return {
        ...state,
        errorFileClose: payload,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS: {
      return {
        ...state,
        filesClose: state.filesClose.map((item) => (item._id === payload.data._id) ? payload.data : item),
        filesAuxClose: state.filesAuxClose.map((item) => (item._id === payload.data._id) ? payload.data : item),
        errorFileClose: '',
      };
    }

    case EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR: {
      return {
        ...state,
        errorFileClose: payload,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS: {
      return {
        ...state,
        filesClose: state.filesClose.map((item, index) => isPresent(payload.selectedRowIds, index) ? { ...item, activo: false } : item),
        filesAuxClose: state.filesAuxClose.filter((_, index) => !isPresent(payload.selectedRowIds, index)),
        errorFileClose: '',
      };
    }

    case EXPEDIENTE_FILES_CLOSE_DELETE_ERROR: {
      return {
        ...state,
        errorFileClose: payload,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT: {
      return {
        ...state,
        errorFileClose: '',
        isLoadingFileClose: true,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        errorFileClose: '',
        isLoadingFileClose: false,
      };
    }

    case EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR: {
      return {
        ...state,
        errorFileClose: payload,
        isLoadingFileClose: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { expediente as default, initialState };
