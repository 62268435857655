import React from 'react';
import L from 'leaflet';
import styled from "styled-components";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import 'leaflet-easyprint'

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
`



function Geovisor({data}) {
  const clusterLayerRef = React.useRef(null);
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    const osm = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png'),
      GoogleMaps = L.tileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}'),
      GoogleSatellite = L.tileLayer('http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'),
      GoogleHybrid = L.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'),
      GoogleTraffic = L.tileLayer('https://mt1.google.com/vt?lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}'),
      CartoPositron = L.tileLayer('https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'),
      CartoDark = L.tileLayer('http://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png')

    const container = L.DomUtil.get('map');
    if (container != null) {
      container._leaflet_id = null;
    }

    // create map
    mapRef.current = L.map('map', {
      center: [4.10, -73.18],
      zoom: 5.5,
      zoomControl: true,
      layers: [osm]
    });

    clusterLayerRef.current = L.markerClusterGroup();

    const DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize: [24, 36],
      iconAnchor: [12, 36]
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    const baseLayers1 = {
      "osm": osm,
      "Google maps": GoogleMaps,
      "GoogleSatellite": GoogleSatellite,
      "GoogleHybrid": GoogleHybrid,
      "Carto Positron": CartoPositron,
      "Carto Dark": CartoDark
    };

    const baseLayers2 = {
      "GoogleTraffic": GoogleTraffic
    };

    const car = L.tileLayer.wms("http://mapas.parquesnacionales.gov.co/services/pnn/wms?service=WMS", {
        layers: "car",//nombre de la capa (ver get capabilities)
        format: 'image/png',
        transparent: true,
        version: '1.3.0',//wms version (ver get capabilities)
        attribution: "Parques Nacionales Naturales de Colombia"
      },
      [
        {header: 'Set-Cookie', value: 'HttpOnly;Secure;SameSite=Strict'}
      ]);

    const departamentos = L.tileLayer.wms("http://mapas.parquesnacionales.gov.co/services/pnn/wms?service=WMS", {
      layers: "departamentos",//nombre de la capa (ver get capabilities)
      format: 'image/png',
      transparent: true,
      version: '1.3.0',//wms version (ver get capabilities)
      attribution: "Parques Nacionales Naturales de Colombia"
    });

    const municipios = L.tileLayer.wms("http://mapas.parquesnacionales.gov.co/services/pnn/wms?service=WMS", {
      layers: "municipios",//nombre de la capa (ver get capabilities)
      format: 'image/png',
      transparent: true,
      version: '1.3.0',//wms version (ver get capabilities)
      attribution: "Parques Nacionales Naturales de Colombia"
    });

    const runap = L.tileLayer.wms("http://mapas.parquesnacionales.gov.co/services/pnn/wms?service=WMS", {
      layers: "runap2",//nombre de la capa (ver get capabilities)
      format: 'image/png',
      transparent: true,
      version: '1.3.0',//wms version (ver get capabilities)
      attribution: "Parques Nacionales Naturales de Colombia"
    });

    const wmslayers = {
      "Autoridades ambientales": car,
      "Departamentos": departamentos,
      "Municipios": municipios,
      "Áreas protegidas": runap
    };
    L.control.layers(baseLayers1, baseLayers2).addTo(mapRef.current);
    L.control.layers(wmslayers).addTo(mapRef.current);
    L.easyPrint({
      title: 'Descargar vista',
      position: 'topleft',
      sizeModes: ['A4Portrait', 'A4Landscape'],
      defaultSizeTitles: {Current: 'Tamaño actual', A4Landscape: 'A4 Horizontal', A4Portrait: 'A4 Vertical'}
    }).addTo(mapRef.current);
  }, []);

  var layerGeoRef = React.useRef(null);

  React.useEffect(() => {

    if (clusterLayerRef.current!== null) {
      clusterLayerRef.current.clearLayers();
    }

    var container = L.DomUtil.get('map');
    if (container != null) {
      container._leaflet_id = null;
    }

    function onEachFeature(feature, layer) {
      // does this feature have a property named popupContent?
      if (feature.properties && feature.properties.acta) {
        layer.bindPopup(
          '<b>Acta única: ' + feature.properties.acta + '</b>'
          + '<p><b> Código Expediente: </b>' + feature.properties.codigo_expediente
          + '<p><b> Tipo: </b>' + feature.properties.tipo
          + '<p><b> Autoridad: </b>' + feature.properties.autoridad
          + '<p><b> Nombre científico: </b>' + feature.properties.nombre
          + '<p><b> Nombre común: </b>' + feature.properties.nombreComun
          + '<p><b> Fecha procedimiento: </b>' + feature.properties.fechaProcedimiento
          + '<p><b> Ubicación: </b>' + feature.properties.pais_procedimiento + (feature.properties.depto_procedimiento ? ' | ' + feature.properties.depto_procedimiento : "") +  (feature.properties.municipio_procedimineto ? ' | ' + feature.properties.municipio_procedimineto : "")
          + '<p><b> Procedencia especimen: </b>' + feature.properties.pais_procedencia_declarado + (feature.properties.depto_procedencia_declarado ? ' | ' + feature.properties.depto_procedencia_declarado : "") +  (feature.properties.municipio_procedencia_declarado ? ' | ' + feature.properties.municipio_procedencia_declarado : "")
          + '<p><b> Coordenadas: </b>' + feature.geometry.coordinates[0] + " , " + feature.geometry.coordinates[1]
        );
      }
    }

    function validarCoordenadas(especimen) {
      if (especimen.view_expediente[0].coordenadas !== undefined) {
        if (especimen.view_expediente[0].coordenadas.latitud !== undefined && especimen.view_expediente[0].coordenadas.longitud !== undefined) {
          if (especimen.view_expediente[0].coordenadas.latitud.decimal !== undefined && especimen.view_expediente[0].coordenadas.longitud.decimal !== undefined) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }

    var jsonFeatures = [];

    data.filter(especimen => validarCoordenadas(especimen)).map(function (point) {
      var lat = point.view_expediente[0].coordenadas.latitud.decimal;
      var lon = point.view_expediente[0].coordenadas.longitud.decimal;
      var acta = point.view_expediente[0].acta;
      var codigo_expediente = point.view_expediente[0].codigo_expediente;
      var tipo = point.view_expediente[0].tipo_procedimiento.domainName;
      var nombre_cientifico = point.taxonomia.canonicalName;
      var nombre_comun = point.nombre_comun;
      var fecha = point.view_expediente[0].fecha_procedimiento;
      var pais_procedimiento = point.view_expediente[0].pais_procedimiento? point.view_expediente[0].pais_procedimiento.constantName : undefined;
      var departamento_procedimiento = point.view_expediente[0].departamento_procedimiento? point.view_expediente[0].departamento_procedimiento.constantName : undefined;
      var municipio_prodecimiento = point.view_expediente[0].municipio_procedimiento? point.view_expediente[0].municipio_procedimiento.constantName : undefined;
      var autoridad = point.view_expediente[0].view_autoridad_ambiental[0].nombre;
      var pais_procedencia_declarado = point.pais_procedencia_declarado.constantName;
      var depto_procedencia_declarado = point.departamento_procedencia_declarado.constantName;
      var municipio_procedencia_declarado = point.municipio_procedencia_declarado.constantName;

      var feature = {
        type: 'Feature',
        properties: {
          "acta": acta,
          "codigo_expediente": codigo_expediente,
          "tipo": tipo,
          "nombre": nombre_cientifico,
          "nombreComun": nombre_comun,
          "fechaProcedimiento": fecha,
          "pais_procedimiento": pais_procedimiento,
          "depto_procedimiento": departamento_procedimiento,
          "municipio_procedimineto": municipio_prodecimiento,
          "autoridad": autoridad,
          "pais_procedencia_declarado": pais_procedencia_declarado,
          "depto_procedencia_declarado": depto_procedencia_declarado,
          "municipio_procedencia_declarado": municipio_procedencia_declarado
        },
        geometry: {
          type: 'Point',
          coordinates: [lon, lat]
        }
      };
      jsonFeatures.push(feature);
    });

    var geoJson = {type: 'FeatureCollection', features: jsonFeatures};
    layerGeoRef.current = L.geoJson(geoJson, {
      onEachFeature: onEachFeature
    })
    clusterLayerRef.current.addLayer(layerGeoRef.current );
    clusterLayerRef.current.addTo(mapRef.current);
  }, [data]);

  return <Wrapper width="1280px" height="720px" id="map"/>
}

export default Geovisor;
