/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import {withRouter} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
/* Hooks */
import {
  useLogin,
  useDomains,
  useConstants,
  useNotification,
  useExpedientes,
  useAutoridadAmbiental,
  useEntidadDisposicion,
  useReportes
} from '../hooks';
/* Components */
import ErrorBoundary from '../components/ErrorBoundary';
import Page from '../components/Page';
import {PageHeader} from '../components/PageHeader';
import PaginationTable from '../components/Table/PaginationTable';
import PaginationTableServerSide from "../components/Table/PaginationTableServerSide";
import ButtonsActionRow from '../components/Table/ButtonsActionRow';
import {CardTitleBox} from '../components/CardTitleBox';
import {Col, Col1, Col2, Col3, GridContainer, Row} from "../components/Grid";
import {Card} from '../components/Card';
import {H1} from '../components/H1';
import {H5} from '../components/H5';
import {Input} from '../components/form-ui-components/Input';
import {Form} from '../components/form-ui-components/Form';
import {Label} from '../components/form-ui-components/Label';
import {Select} from '../components/form-ui-components/Select';
import {SelectOptions} from '../components/form-ui-components/SelectOptions';
import {Button} from '../components/form-ui-components/Button';
import Autocomplete from '../components/Autocomplete';
import Loader from '../components/Loader';
import {Icon} from '../components/Icon';

/* Style Components */
import {CardContainerBody, SpanDivide, SpanContact} from './styled';
/* Utils */
import {removeEmpty} from '../utils';

dayjs.locale('es');

const ConsultaPorTabla = ({history}) => {
  const [loaded, setLoaded] = useState(false);
  const [verMas, setVerMas] = useState(false);
  const [loadedInfo, setLoadedInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [municipiosFiltradosProcEspe, setMunicipiosFiltradosProcEspe] = useState([]);
  const [municipiosFiltradosDispo, setMunicipiosFiltradosDispo] = useState([]);
  const fetchIdRef = useRef(0);
  // Auto Complete
  const [suggestions, setSuggestions] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  // Fin Auto Complete

  const {setValue, register, handleSubmit, errors, reset, watch, getValues} = useForm({});

  const {isLoggedIn, token} = useLogin();
  const {domainsByKey, getOptionsByDomainTypes} = useDomains();
  const {createNotification} = useNotification();
  const {taxonomyInformation, especimenTaxonomyInfoRequest} = useExpedientes();
  const {data: autoridadesAmbientales, searchAutoridadAmbiental} = useAutoridadAmbiental();
  const {data: entidadesDisposicion, searchEntidadDisposicion} = useEntidadDisposicion();
  const {constantsByKey, getConstantOptionsByTypes} = useConstants();
  const {
    searchTablaRequest,
    descargarReporteRequest,
    tabla,
    error,
    from: currentFrom,
    to: currentTo,
    isLoading,
    isLoadingFile,
    pageCount
  } = useReportes();

  const handleChangeDptoProceEspe = e => {
    const {value} = e.target;
    const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(value));
    setMunicipiosFiltradosProcEspe(newData);
  };

  const handleChangeDptoDispo = e => {
    const {value} = e.target;
    const newData = constantsByKey.municipios.filter(({constantParent}) => constantParent === Number(value));
    setMunicipiosFiltradosDispo(newData);
  };

  const handleAutoCompleteChange = async e => {
    if (document.querySelector("select[name='taxonRank']").value !== '') {
      const newUserInput = e.currentTarget.value;
      const search = {
        taxonRank: document.querySelector("select[name='taxonRank']")[document.querySelector("select[name='taxonRank']").selectedIndex].innerHTML,
        canonicalName: newUserInput
      };
      setLoadingAutoComplete(true);
      const {taxonomyInfo, msg, err} = await especimenTaxonomyInfoRequest(search, token);
      setLoadingAutoComplete(false);
      setUserInput(newUserInput);
      setSuggestions(taxonomyInformation);
      setActiveSuggestion(0);
      setShowSuggestions(true);
    } else {
      Swal.fire({
        title: 'CATEGORÍA TAXONÓMICA!',
        text: 'Debe seleccionar una categoría taxonómica!',
        icon: 'error',
        confirmButtonText: 'ACEPTAR'
      })
    }
  };

  const handleAutoCompleteKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(suggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // User pressed the up arrow
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) { // User pressed the down arrow
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleAutoCompleteClick = e => {
    const newUserInput = e.currentTarget.innerText.trim();
    const info = e.currentTarget.id.split('-');
    const taxonomiaId = Number(info[0]);
    setActiveSuggestion(0);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserInput(newUserInput);
    document.getElementById('canonicalName').value = newUserInput;
    setValue('taxonomia', taxonomiaId);
    setValue('PIFS_grupoBio', info[1]);
  };
  // Fin Auto Complete

  const parseDate = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DD');
  const parseDateTime = timestamp => dayjs(timestamp).format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const getActualDate = () => dayjs().format('YYYY[-]MM[-]DD');
  const getActualDateTime = () => dayjs().format('YYYY[-]MM[-]DDTHH:mm:ss.SSS[Z]');
  const splitDate = timestamp => timestamp.split('T')[0];

  const handleDownloadReport = async () => {
    const values = getValues();
    const search = {
      autoridad_ambiental: values.autoridad_ambiental || null,
      acta: values.acta || null,
      codigo_expediente: values.codigo_expediente || null,
      tipo_procedimiento: Number(values.tipo_procedimiento) || null,
      clase_recurso: Number(values.clase_recurso) || null,
      estado: Number(values.estado) || null,
      parte_producto: Number(values.parte_producto) || null,
      taxonomia: Number(values.taxonomia) || null,
      sexo: Number(values.sexo) || null,
      estado_desarrollo: Number(values.estado_desarrollo) || null,
      departamento_procedimiento: Number(values.departamento_procedimiento) || null,
      municipio_procedimiento: Number(values.municipio_procedimiento) || null,
      entidad_disposicion_inicial: values.entidad_disposicion_inicial || null,
      fecha_procedimiento_inicial: values.fecha_procedimiento_inicial && parseDate(values.fecha_procedimiento_inicial) || null,
      fecha_procedimiento_final: values.fecha_procedimiento_final && parseDate(values.fecha_procedimiento_final) || null,
      departamento_procedencia_declarado: Number(values.departamento_procedencia_declarado) || null,
      municipio_procedencia_declarado: Number(values.municipio_procedencia_declarado) || null,
      disposicion_final: Number(values.disposicion_final) || null
    };
    const {msg, err} = await descargarReporteRequest(removeEmpty(search), token);
    createNotification({
      message: msg,
      error: err,
      visible: true
    });
  };

  const handleOpenVerMas = e => {
    e.preventDefault();
    setVerMas(!verMas);
  };

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleChangeType = e => {
    const {value} = e.target;
  };



  const handleClearSearchFields = async e => {
    e.preventDefault();
    setValue('taxonomia', null);
    setValue('PIFS_grupoBio', null);
    reset();

    await fetchData({pageSize: 10, pageIndex: 0, loading, isLoading});
  };


  const fetchData = useCallback(async ({pageSize, pageIndex, loading: loaderFecth, isLoading: loarderSearch}) => {
    setLoading(true);
    console.log('fetchData 1', fetchIdRef.current, pageSize, pageIndex, loaderFecth, loarderSearch, loading, isLoading);
    const fetchId = ++fetchIdRef.current;
    if ((fetchId === fetchIdRef.current) && !loading) {
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;
      var values = getValues();
      const search = {
        autoridad_ambiental: values.autoridad_ambiental || null,
        acta: values.acta || null,
        codigo_expediente: values.codigo_expediente || null,
        tipo_procedimiento: Number(values.tipo_procedimiento) || null,
        clase_recurso: Number(values.clase_recurso) || null,
        estado: Number(values.estado) || null,
        parte_producto: Number(values.parte_producto) || null,
        taxonomia: Number(values.taxonomia) || null,
        sexo: Number(values.sexo) || null,
        estado_desarrollo: Number(values.estado_desarrollo) || null,
        departamento_procedimiento: Number(values.departamento_procedimiento) || null,
        municipio_procedimiento: Number(values.municipio_procedimiento) || null,
        entidad_disposicion_inicial: values.entidad_disposicion_inicial || null,
        fecha_procedimiento_inicial: values.fecha_procedimiento_inicial && parseDate(values.fecha_procedimiento_inicial) || null,
        fecha_procedimiento_final: values.fecha_procedimiento_final && parseDate(values.fecha_procedimiento_final) || null,
        departamento_procedencia_declarado: Number(values.departamento_procedencia_declarado) || null,
        municipio_procedencia_declarado: Number(values.municipio_procedencia_declarado) || null,
        disposicion_final: Number(values.disposicion_final) || null
      };
      await searchTablaRequest(removeEmpty(search), startRow, pageSize, token);
      setLoading(false);
      console.log('search', search);
      console.log('fetchData 3-', loaderFecth, loarderSearch);
    }
  }, []);

  const load = useCallback(async () => {
    setLoadedInfo(false);
    await Promise.all([searchAutoridadAmbiental({}, token), searchEntidadDisposicion({}, token), getOptionsByDomainTypes(['tiposEvento', 'tiposRecurso', 'tiposEstado', 'tiposParte', 'tiposSexo', 'estadosDesarrollo', 'tiposDisposicion', 'tiposEstadoEspecimen']), getConstantOptionsByTypes(['departamentos', 'municipios', 'tiposDocumento', 'clasificacionTaxonomia'])]);
    setLoadedInfo(true);
  }, [
    searchAutoridadAmbiental,
    searchEntidadDisposicion,
    getOptionsByDomainTypes
  ]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await fetchData({pageSize: 100, pageIndex: 0, loading, isLoading});
    } catch (err) {
      console.log(err);
    }
    e.stopPropagation();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Autoridad Ambiental',
        accessor: d => {
          return ((d.view_expediente[0]) && (d.view_expediente[0].view_autoridad_ambiental[0]) && (d.view_expediente[0].view_autoridad_ambiental[0].nombre !== '') && (d.view_expediente[0].view_autoridad_ambiental[0].nombre !== ' ')) ? d.view_expediente[0].view_autoridad_ambiental[0].nombre : '---';
        }
      },
      {
        Header: 'Número de acta',
        accessor: d => {
          return ((d.view_expediente[0]) && (d.view_expediente[0].acta) && (d.view_expediente[0].acta !== '') && (d.view_expediente[0].acta !== ' ')) ? d.view_expediente[0].acta : '---';
        }
      },
      {
        Header: 'Código expediente',
        accessor: d => {
          return ((d.view_expediente[0]) && (d.view_expediente[0].codigo_expediente !== '') && (d.view_expediente[0].codigo_expediente !== ' ')) ? d.view_expediente[0].codigo_expediente : '---';
        }
      },
      {
        Header: 'Tipo de evento',
        accessor: d => {
          return ((d.view_expediente[0]) && (d.view_expediente[0].tipo_procedimiento) && (d.view_expediente[0].tipo_procedimiento.domainName) && (d.view_expediente[0].tipo_procedimiento.domainName !== '')) ? d.view_expediente[0].tipo_procedimiento.domainName : '---';
        }
      },
      {
        Header: 'Clase de recurso',
        accessor: d => {
          return ((d.clase_recurso.domainName) && (d.clase_recurso.domainName !== '') && (d.clase_recurso.domainName !== ' ')) ? d.clase_recurso.domainName : '---';
        }
      },
      {
        Header: 'Estado',
        accessor: d => {
          return ((d.estado.domainName) && (d.estado.domainName !== '') && (d.estado.domainName !== ' ')) ? d.estado.domainName : '---';
        }
      },
      {
        Header: 'Parte, producto o subproducto',
        accessor: d => {
          return ((d.parte_producto) && (d.parte_producto.domainName !== '') && (d.parte_producto.domainName !== ' ')) ? d.parte_producto.domainName : '---';
        }
      },
      {
        Header: 'Nombre Científico',
        accessor: d => {
          return ((d.taxonomia) && (d.taxonomia.canonicalName !== '') && (d.taxonomia.canonicalName !== ' ')) ? d.taxonomia.canonicalName : '---';
        }
      },
      {
        Header: 'Nombre Común',
        accessor: d => {
          return ((d.nombre_comun) && (d.nombre_comun !== '') && (d.nombre_comun !== ' ')) ? d.nombre_comun : '---';
        }
      },
      {
        Header: 'Sexo',
        accessor: d => {
          return ((d.sexo) && (d.sexo.domainName !== '') && (d.sexo.domainName !== ' ')) ? d.sexo.domainName : '---';
        }
      },
      {
        Header: 'Estado de desarrollo',
        accessor: d => {
          return ((d.estado_desarrollo) && (d.estado_desarrollo.domainName !== '') && (d.estado_desarrollo.domainName !== ' ')) ? d.estado_desarrollo.domainName : '---';
        }
      },
      {
        Header: 'Departamento procedimiento',
        accessor: d => {
          return ((d.view_expediente[0].departamento_procedimiento) && (d.view_expediente[0].departamento_procedimiento.constantName !== '') && (d.view_expediente[0].departamento_procedimiento.constantName !== ' ')) ? d.view_expediente[0].departamento_procedimiento.constantName : '---';
        }
      },
      {
        Header: 'Municipio procedimiento',
        accessor: d => {
          return ((d.view_expediente[0].municipio_procedimiento) && (d.view_expediente[0].municipio_procedimiento.constantName !== '') && (d.view_expediente[0].municipio_procedimiento.constantName !== ' ')) ? d.view_expediente[0].municipio_procedimiento.constantName : '---';
        }
      },
    ],
    []
  );

  useEffect(() => {
    if (isLoggedIn && !loaded) {
      load();
      setLoaded(true);
    } else if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, loaded, load, gotoLogin]);

  return (
    <ErrorBoundary>
      <Page useSideBarSpace>
        {isLoggedIn && (
          <>
            <PageHeader>
              <H1>Vista de resultados en tabla.</H1>
            </PageHeader>
            <Card title=" ">
              <CardTitleBox>
                <H5>Datos de búsqueda</H5>
              </CardTitleBox>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                  <Row>
                    <Col1>
                      <SpanDivide>
                        <br/>Información del expediente
                      </SpanDivide>
                    </Col1>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        AUTORIDAD AMBIENTAL
                        <Select style={{width: '100%'}} name="autoridad_ambiental" ref={register}>
                          <SelectOptions selectOptions={autoridadesAmbientales}/>
                        </Select>
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        NÚMERO DE ACTA ÚNICA
                        <Input
                          name="acta"
                          type="text"
                          placeholder="Escriba el número de acta única"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                    <Col3>
                      <Label>
                        CÓDIGO DEL EXPEDIENTE
                        <Input
                          name="codigo_expediente"
                          type="text"
                          placeholder="Escriba el código del expediente"
                          ref={register}
                        />
                      </Label>
                    </Col3>
                  </Row>
                  <Row>
                    <Col3>
                      <Label>
                        TIPO DE EVENTO
                        <Select name="tipo_procedimiento" ref={register}>
                          <SelectOptions selectOptions={domainsByKey.tiposEvento}/>
                        </Select>
                      </Label>
                    </Col3>
                    {!verMas && (
                      <Col style={{justifyContent: 'flex-end', width: '100%'}} right>
                        <Label style={{width: 'auto'}}>
                          <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                            LIMPIAR CAMPOS
                          </Button>
                        </Label>
                        <Label style={{width: 'auto'}}>
                          <Button type="submit">
                            BUSCAR
                          </Button>
                        </Label>
                      </Col>
                    )}
                  </Row>
                  {!verMas && (
                    <Row>
                      <Col1>
                        <Label>
                          <SpanContact onClick={(e) => handleOpenVerMas(e)}>VER
                            MAS <Icon>arrow_downward</Icon></SpanContact>
                        </Label>
                      </Col1>
                    </Row>
                  )}
                  {verMas && (
                    <>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br/>Información del espécimen
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            CLASE DE RECURSO
                            <Select style={{width: '100%'}} name="clase_recurso" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposRecurso}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO
                            <Select style={{width: '100%'}} name="estado" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposEstadoEspecimen}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            PARTE, PRODUCTO O SUBPRODUCTO
                            <Select style={{width: '100%'}} name="parte_producto" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposParte}/>
                            </Select>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            CATEGORÍA TAXONÓMICA
                            <Select style={{width: '100%'}} name="taxonRank" ref={register}>
                              <SelectOptions selectOptions={constantsByKey.clasificacionTaxonomia}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            NOMBRE CIENTÍFICO
                            <Autocomplete
                              name="canonicalName"
                              placeholder="Indique nombre científico"
                              suggestions={suggestions}
                              loading={loadingAutoComplete}
                              activeSuggestion={activeSuggestion}
                              showSuggestions={showSuggestions}
                              onChange={handleAutoCompleteChange}
                              onKeyDown={handleAutoCompleteKeyDown}
                              onClick={handleAutoCompleteClick}
                              userInput={userInput}
                              ref={register}
                            />
                            <Input
                              style={{width: '100%'}}
                              name="taxonomia"
                              type="hidden"
                              ref={register}
                            />
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            GRUPO BIOLÓGICO
                            <>
                              <Input
                                style={{width: '100%'}}
                                name="PIFS_grupoBio"
                                type="text"
                                placeholder="Grupo biológico"
                                ref={register}
                                readOnly={true}
                              />
                            </>
                          </Label>
                        </Col3>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            SEXO
                            <Select style={{width: '100%'}} name="sexo" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposSexo}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            ESTADO DE DESARROLLO
                            <Select style={{width: '100%'}} name="estado_desarrollo" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.estadosDesarrollo}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label/>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <SpanDivide>
                            <br/>Información de ubicación y fecha
                          </SpanDivide>
                        </Col1>
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            DEPARTAMENTO DE PROCEDIMIENTO
                            <Select style={{width: '100%'}} onChange={handleChangeDptoProceEspe}
                                    name="departamento_procedimiento" ref={register}>
                              <SelectOptions selectOptions={constantsByKey.departamentos}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            CIUDAD O MUNICIPIO DE PROCEDIMIENTO
                            <Select style={{width: '100%'}} name="municipio_procedimiento" ref={register}>
                              <SelectOptions selectOptions={municipiosFiltradosProcEspe}/>
                            </Select>
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            FECHA DEL PROCEDIMIENTO INICIAL
                            <Input
                              style={{width: '100%'}}
                              name="fecha_procedimiento_inicial"
                              type="date"
                              placeholder="dd/mm/yyyy"
                              ref={register}/>
                          </Label>
                        </Col3>
                        {/*<Col3>*/}
                        {/*  <Label>*/}
                        {/*    ENTIDAD DE DISPOSICIÓN ACTUAL*/}
                        {/*    <Select name="entidad_disposicion_inicial" ref={register}>*/}
                        {/*      <SelectOptions selectOptions={entidadesDisposicion}/>*/}
                        {/*    </Select>*/}
                        {/*  </Label>*/}
                        {/*</Col3>*/}
                      </Row>
                      <Row>
                        <Col3>
                          <Label>
                            FECHA DEL PROCEDIMIENTO FINAL
                            <Input
                              style={{width: '100%'}}
                              name="fecha_procedimiento_final"
                              type="date"
                              placeholder="dd/mm/yyyy"
                              ref={register}
                            />
                          </Label>
                        </Col3>
                        <Col3>
                          <Label>
                            TIPO DE DISPOSICIÓN
                            <Select style={{width: '100%'}} name="disposicion_final" ref={register}>
                              <SelectOptions selectOptions={domainsByKey.tiposDisposicion}/>
                            </Select>
                          </Label>
                        </Col3>
                        {/*<Col3>*/}
                        {/*  <Label>*/}
                        {/*    DEPARTAMENTO DE DISPOSICIÓN ACTUAL*/}
                        {/*    <Select style={{width: '100%'}} onChange={handleChangeDptoDispo}*/}
                        {/*            name="departamento_procedencia_declarado" ref={register}>*/}
                        {/*      <SelectOptions selectOptions={constantsByKey.departamentos}/>*/}
                        {/*    </Select>*/}
                        {/*  </Label>*/}
                        {/*</Col3>*/}
                      </Row>
                      {/*<Row>*/}
                      {/*  <Col3>*/}
                      {/*    <Label>*/}
                      {/*      MUNICIPIO DE DISPOSICIÓN ACTUAL*/}
                      {/*      <Select style={{width: '100%'}} name="municipio_procedencia_declarado" ref={register}>*/}
                      {/*        <SelectOptions selectOptions={municipiosFiltradosDispo}/>*/}
                      {/*      </Select>*/}
                      {/*    </Label>*/}
                      {/*  </Col3>*/}
                      {/*</Row>*/}
                      <Row>
                        <Col3>
                          <Label>
                            <SpanContact onClick={(e) => handleOpenVerMas(e)}>VER MENOS <Icon>arrow_upward</Icon></SpanContact>
                          </Label>
                        </Col3>
                        <Col style={{justifyContent: 'flex-end', width: '100%'}} right>
                          <Label style={{width: 'auto'}}>
                            <Button type="button" onClick={(e) => handleClearSearchFields(e)}>
                              LIMPIAR CAMPOS
                            </Button>
                          </Label>
                          <Label style={{width: 'auto'}}>
                            <Button type="submit">
                              BUSCAR
                            </Button>
                          </Label>
                        </Col>
                      </Row>
                    </>
                  )}
                </GridContainer>
              </Form>
            </Card>
            {(loadedInfo) && (
              <Card container>
                <GridContainer>
                  <Row>
                    <Col>
                      <CardContainerBody>
                        <div className="card-body-row">
                          <PaginationTableServerSide columns={columns} data={tabla} fetchData={fetchData}
                                                     loading={loading} isLoading={isLoading}
                                                     pageCount={pageCount}/>
                        </div>
                      </CardContainerBody>
                      {error && (
                        <CardContainerBody>
                          <CardTitleBox className="error" error>
                            <H5> Ups, hubo un error {error}</H5>
                          </CardTitleBox>
                        </CardContainerBody>
                      )}
                    </Col>
                  </Row>
                  <Row style={{justifyContent: 'flex-end', width: 'auto'}} right>
                    <Col right>
                      {isLoadingFile ? (
                        <Label style={{justifyContent: 'flex-end', width: '20vw'}}>
                          <Loader message="Enviando petición de la descarga."/>
                        </Label>
                      ) : (
                        <Label style={{justifyContent: 'flex-end', width: 'auto'}}>
                          <Button type="button" onClick={handleDownloadReport}>
                            DESCARGAR REPORTE
                          </Button>
                        </Label>
                      )}
                    </Col>
                  </Row>
                </GridContainer>
              </Card>
            )}
          </>
        )}
      </Page>
    </ErrorBoundary>
  )
};

ConsultaPorTabla.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
};

ConsultaPorTabla.defaultProps = {
  history: {},
};

export default withRouter(ConsultaPorTabla);
