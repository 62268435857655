/* eslint-disable no-underscore-dangle */
import {
  AUTORIDAD_AMBIENTAL_CREATE_INIT,
  AUTORIDAD_AMBIENTAL_CREATE_SUCCESS,
  AUTORIDAD_AMBIENTAL_CREATE_ERROR,
  AUTORIDAD_AMBIENTAL_UPDATE_INIT,
  AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS,
  AUTORIDAD_AMBIENTAL_UPDATE_ERROR,
  AUTORIDAD_AMBIENTAL_SEARCH_INIT,
  AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS,
  AUTORIDAD_AMBIENTAL_SEARCH_ERROR,
  AUTORIDAD_AMBIENTAL_DELETE_INIT,
  AUTORIDAD_AMBIENTAL_DELETE_SUCCESS,
  AUTORIDAD_AMBIENTAL_DELETE_ERROR,
  AUTORIDAD_AMBIENTAL_CLEAR_SELECTED,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS,
  AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR,
  AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS
} from "./types";

const formData = {
  nombre: null,
  sigla: null,
  tipo: null,
  pais: null,
  departamento: null,
  municipio: null,
  direccion: null,
  telefono_movil: null,
  telefono_fijo: null,
  sitio_web: null,
  coordenadas: {
    latitud: {
      grados: null,
      minutos: null,
      segundos: null,
      hemisferio: null,
      decimal: null
    },
    longitud: {
      grados: null,
      minutos: null,
      segundos: null,
      hemisferio: null,
      decimal: null
    }
  },
  estado: null,
  responsable: {
    nombre: null,
    cargo: null,
    email: null
  },
  usuario_creacion: null,
  fecha_creacion: null,
  usuario_modificacion: null,
  fecha_modificacion: null
};

const initialState = {
  data: [],
  selected: null,
  mode: null,
  isLoading: false,
  error: ""
};

const autoridadAmbientalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTORIDAD_AMBIENTAL_CREATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case AUTORIDAD_AMBIENTAL_CREATE_SUCCESS: {
      return {
        ...state,
        data: [payload.data, ...state.data],
        isLoading: false,
        error: ""
      };
    }

    case AUTORIDAD_AMBIENTAL_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case AUTORIDAD_AMBIENTAL_UPDATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        error: ""
      };
    }

    case AUTORIDAD_AMBIENTAL_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case AUTORIDAD_AMBIENTAL_SEARCH_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: ""
      };
    }

    case AUTORIDAD_AMBIENTAL_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case AUTORIDAD_AMBIENTAL_DELETE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case AUTORIDAD_AMBIENTAL_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        error: ""
      };
    }

    case AUTORIDAD_AMBIENTAL_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case AUTORIDAD_AMBIENTAL_CLEAR_SELECTED: {
      return {
        ...state,
        error: "",
        selected: null,
        mode: null,
        isLoading: false
      }
    }

    case AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        selected: payload.data,
        mode: payload.mode,
        error: ""
      };
    }

    case AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS: {
      return {
        ...state,
        mode: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { autoridadAmbientalReducer as default, initialState };
