export const AUTORIDAD_AMBIENTAL_SEARCH_INIT = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_SEARCH_INIT';
export const AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_SEARCH_SUCCESS';
export const AUTORIDAD_AMBIENTAL_SEARCH_ERROR = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_SEARCH_ERROR';
export const AUTORIDAD_AMBIENTAL_CREATE_INIT = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_CREATE_INIT';
export const AUTORIDAD_AMBIENTAL_CREATE_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_CREATE_SUCCESS';
export const AUTORIDAD_AMBIENTAL_CREATE_ERROR = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_CREATE_ERROR';
export const AUTORIDAD_AMBIENTAL_UPDATE_INIT = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_UPDATE_INIT';
export const AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_UPDATE_SUCCESS';
export const AUTORIDAD_AMBIENTAL_UPDATE_ERROR = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_UPDATE_ERROR';
export const AUTORIDAD_AMBIENTAL_DELETE_INIT = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_DELETE_INIT';
export const AUTORIDAD_AMBIENTAL_DELETE_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_DELETE_SUCCESS';
export const AUTORIDAD_AMBIENTAL_DELETE_ERROR = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_DELETE_ERROR';
export const AUTORIDAD_AMBIENTAL_CLEAR_SELECTED = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_CLEAR_SELECTED';
export const AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_LOAD_SELECTED_INIT';
export const AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_LOAD_SELECTED_SUCCESS';
export const AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_LOAD_SELECTED_ERROR';
export const AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS = '[AUTORIDAD_AMBIENTAL] AUTORIDAD_AMBIENTAL_CHANGE_MODE_SUCCESS';
