import Cookies from "js-cookie";
import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_TERMINATE,
  RECOVER_INIT,
  RECOVER_SUCCESS,
  RECOVER_ERROR,
  RESET_INIT,
  RESET_SUCCESS,
  RESET_ERROR
} from './types';

const isCookie = (typeof Cookies.get('pifs') !== 'undefined');

const initialState = {
  userLogin: isCookie ? JSON.parse(Cookies.get('pifs')).userLogin : '',
  token: isCookie ? JSON.parse(Cookies.get('pifs')).token : '',
	isRolAdministradorSistema: false,
	isRolCoordinadorExpedientes: false,
	isRolCoordinadorAutoridades: false,
	isRolCoordinadorEntidades: false,
	isLoading: false,
	isLoggedIn: isCookie,
  error: '',
  response: '',
	menuUserLogin: [],
  permissions: [],
	validRoutes: [],
  roles: [],
};

const login = (state = initialState, { type, payload }) => {
	switch (type) {
		case LOGIN_INIT: {
			return {
				...state,
				error: '',
				isLoading: true,
      };
    }

		case LOGIN_SUCCESS: {
      const { isRolAdministradorSistema, isRolCoordinadorExpedientes, isRolCoordinadorAutoridades, isRolCoordinadorEntidades,userMenu, username, token, permissions, roles, validRoutes } = payload.data;
			return {
				...state,
				isRolAdministradorSistema: isRolAdministradorSistema,
				isRolCoordinadorExpedientes: isRolCoordinadorExpedientes,
				isRolCoordinadorAutoridades: isRolCoordinadorAutoridades,
				isRolCoordinadorEntidades: isRolCoordinadorEntidades,
				menuUserLogin: userMenu,
				userLogin: username,
				token,
				isLoading: false,
        isLoggedIn: true,
        permissions,
				validRoutes,
        roles,
			};
    }

		case LOGIN_ERROR: {
			return {
				...state,
				isLoading: false,
				isLoggedIn: false,
				error: payload,
      };
    }

		case LOGIN_TERMINATE: {
			return {
				...state,
				userLogin: '',
				token: '',
				isLoggedIn: false
      };
    }

    case RECOVER_INIT: {
			return {
				...state,
				error: '',
				isLoading: true,
      };
    }

		case RECOVER_SUCCESS: {
      const { data } = payload;
			return {
				...state,
				isLoading: false,
        isLoggedIn: false,
        response: data
			};
    }

		case RECOVER_ERROR: {
			return {
				...state,
				isLoading: false,
				isLoggedIn: false,
				error: payload,
      };
    }

    case RESET_INIT: {
			return {
				...state,
				error: '',
				isLoading: true,
      };
    }

		case RESET_SUCCESS: {
      const { data } = payload;
			return {
				...state,
				isLoading: false,
        isLoggedIn: false,
        response: data
			};
    }

		case RESET_ERROR: {
			return {
				...state,
				isLoading: false,
				isLoggedIn: false,
				error: payload,
      };
    }

		default: {
			return state;
		}
	}
};

export { login as default, initialState };
