/* eslint-disable no-underscore-dangle */
import {
  deleteEntidadDisposicionA,
  deleteSuccess,
  deleteFailed,
  createEntidadDisposicionA,
  createSuccess,
  createFailed,
  clearSelectedA,
  loadSelectedA,
  loadSelectedSuccess,
  loadSelectedFailed,
  searchEntidadDisposicionA,
  searchSuccess,
  searchFailed,
  changeModeSuccess,
  updateInit,
  updateSuccess,
  updateFailed
} from "./actions";
import * as EntidadDisposicionServices from "../../services";
import { domainTypesByKey } from '../../config/const';

export const createEntidadDisposicion = (inputData, token) => {
  return async dispatch => {
    dispatch(createEntidadDisposicionA());
    try {
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.create(inputData, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const autoridad = data.data.autoridad_ambiental ? await EntidadDisposicionServices.apiAutoridadAmbiental.get(data.data.autoridad_ambiental, token) : '';
          const tipoEntidad = data.data.tipo_disposicion ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.data.tipo_disposicion, domainTypesByKey.tiposEntidadDisposicion, token) : '';
          const tipoDisposicion = data.data.tipo ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.data.tipo, domainTypesByKey.tiposEntidad, token) : '';
          const nombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const domainName = (typeof tipoEntidad === 'object' && Array.isArray(tipoEntidad.data) && tipoEntidad.data.length > 0) ? tipoEntidad.data[0].domainName : '';
          const domainNameTipo = (typeof tipoDisposicion === 'object' && Array.isArray(tipoDisposicion.data) && tipoDisposicion.data.length > 0) ? tipoDisposicion.data[0].domainName : '';

          return {
            ...data.data,
            autoridad_ambiental: { _id: data.data.autoridad_ambiental, nombre },
            tipo_entidad_disposicion: { _id: data.data.tipo_disposicion, domainName },
            tipo_naturaleza: { _id: 15000001, domainName: 'Provisional'},
            tipo: { _id: data.data.tipo, domainName: domainNameTipo}
          };
        };

        const newData = await anAsyncFunction();
        dispatch(createSuccess(newData));
        return { msg: `${data.message} `, err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(createFailed('Se genero un error creando la entidad de disposición por favor contacte al administrador!'));
      return { msg: 'Se genero un error creando la entidad de disposición por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(createFailed(error));
      return { msg: 'no se pudo crear la entidad de disposición', err: true };
    }
  };
};

export const updateEntidadDisposicion = (inputData, idEntidad, token) => {
  return async dispatch => {
    dispatch(updateInit());
    try {
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.update(inputData, idEntidad, token);
      if(typeof data.response === 'object') {
        const anAsyncFunction = async () => {
          const autoridad = data.response.autoridad_ambiental ? await EntidadDisposicionServices.apiAutoridadAmbiental.get(data.response.autoridad_ambiental, token) : '';
          const tipoDisposicion = data.response.tipo_disposicion ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo_disposicion, domainTypesByKey.tiposDisposicion, token) : ''; // Tipo Disposición
          const tipoEntidad = data.response.tipo ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo, domainTypesByKey.tiposEntidadDisposicion, token) : ''; // Tipo
          const tipoNaturaleza  = data.response.tipo_naturaleza ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo_naturaleza, domainTypesByKey.tiposNaturalezaEntidad, token) : ''; // Tipo Naturaleza
          const nombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const domainNameDisposicion = (typeof tipoDisposicion === 'object' && Array.isArray(tipoDisposicion.data) && tipoDisposicion.data.length > 0) ? tipoDisposicion.data[0].domainName : '';
          const domainNameTipo = (typeof tipoEntidad === 'object' && Array.isArray(tipoEntidad.data) && tipoEntidad.data.length > 0) ? tipoEntidad.data[0].domainName : '';
          const domainNameNaturaleza = (typeof tipoNaturaleza === 'object' && Array.isArray(tipoNaturaleza.data) && tipoNaturaleza.data.length > 0) ? tipoNaturaleza.data[0].domainName : '';

          return {
            ...data.response,
            autoridad_ambiental: { _id: data.response.autoridad_ambiental, nombre },
            tipo_entidad_disposicion: { _id: data.response.tipo_disposicion, domainName: domainNameDisposicion }, // Tipo Disposición
            tipo_naturaleza: { _id: data.response.tipo_naturaleza, domainName: domainNameNaturaleza }, // Tipo Naturaleza
            tipo: { _id: data.response.tipo, domainName: domainNameTipo }, // Tipo
          };
        };

        const newData = await anAsyncFunction();
        dispatch(updateSuccess(newData));
        return { msg: 'Se actualizó la entidad de disposición', err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(updateFailed('Se genero un error actualizando la entidad de disposición por favor contacte al administrador!'));
      return { msg: 'Se genero un error actualizando la entidad de disposición por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(updateFailed(error));
      return { msg: 'no se pudo actualizar la entidad de disposición', err: true };
    }
  };
};

export const deleteEntidadDisposicion = (entidad, id, token) => {
  return async dispatch => {
    dispatch(deleteEntidadDisposicionA());
    try {
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.delete(entidad, id, token);
      if (typeof data.response === "object") {
        const anAsyncFunction = async () => {
          const autoridad = data.response.autoridad_ambiental ? await EntidadDisposicionServices.apiAutoridadAmbiental.get(data.response.autoridad_ambiental, token) : '';
          const tipoDisposicion = data.response.tipo_disposicion ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo_disposicion, domainTypesByKey.tiposDisposicion, token) : ''; // Tipo Disposición
          const tipoEntidad = data.response.tipo ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo, domainTypesByKey.tiposEntidadDisposicion, token) : ''; // Tipo
          const tipoNaturaleza  = data.response.tipo_naturaleza ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.response.tipo_naturaleza, domainTypesByKey.tiposNaturalezaEntidad, token) : ''; // Tipo Naturaleza
          const nombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const domainNameDisposicion = (typeof tipoDisposicion === 'object' && Array.isArray(tipoDisposicion.data) && tipoDisposicion.data.length > 0) ? tipoDisposicion.data[0].domainName : '';
          const domainNameTipo = (typeof tipoEntidad === 'object' && Array.isArray(tipoEntidad.data) && tipoEntidad.data.length > 0) ? tipoEntidad.data[0].domainName : '';
          const domainNameNaturaleza = (typeof tipoNaturaleza === 'object' && Array.isArray(tipoNaturaleza.data) && tipoNaturaleza.data.length > 0) ? tipoNaturaleza.data[0].domainName : '';

          return {
            ...data.response,
            autoridad_ambiental: { _id: data.response.autoridad_ambiental, nombre },
            tipo_entidad_disposicion: { _id: data.response.tipo_disposicion, domainName: domainNameDisposicion }, // Tipo Disposición
            tipo_naturaleza: { _id: data.response.tipo_naturaleza, domainName: domainNameNaturaleza }, // Tipo Naturaleza
            tipo: { _id: data.response.tipo, domainName: domainNameTipo }, // Tipo
          };
        };

        const newData = await anAsyncFunction();
        dispatch(deleteSuccess(newData));
        return { msg: 'entidad de disposición desactivada', err: false };
      }
      if(typeof data === 'string') {
        dispatch(updateFailed(data));
        return { msg: `${data}`, err: true };
      }
      dispatch(deleteFailed('Se genero un error desactivando la entidad de disposición por favor contacte al administrador!'));
      return { msg: 'Se genero un error desactivando la entidad de disposición por favor contacte al administrador!', err: true };
    } catch (error) {
      dispatch(deleteFailed(error));
      return { msg: "no se pudo desactivar", err: true };
    }
  };
};

export const getAllEntidadesDisposicion = (token) => {
  return async dispatch => {
    dispatch(searchEntidadDisposicionA());
    try{
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.search({}, token);
      dispatch(searchSuccess(data.data));
    } catch (error) {
      dispatch(searchFailed(error));
    }
  }
}

export const searchEntidadDisposicion = (inputData, token) => {
  return async dispatch => {
    dispatch(searchEntidadDisposicionA());
    try {
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.search(inputData, token);
      if(typeof data === 'object' && Array.isArray(data.data)) {
        const anAsyncFunction = async entidad => {
          // const autoridad = entidad.autoridad_ambiental ? await EntidadDisposicionServices.apiAutoridadAmbiental.get(entidad.autoridad_ambiental._id, token) : '';
          const tipoDisposicion = entidad.tipo_disposicion ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(entidad.tipo_disposicion._id, domainTypesByKey.tiposDisposicion, token) : ''; // Tipo Disposición
          const tipoEntidad = entidad.tipo ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(entidad.tipo._id, domainTypesByKey.tiposEntidadDisposicion, token) : ''; // Tipo
          const tipoNaturaleza  = entidad.tipo_naturaleza ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(entidad.tipo_naturaleza._id, domainTypesByKey.tiposNaturalezaEntidad, token) : ''; // Tipo Naturaleza
          // const nombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const domainNameDisposicion = (typeof tipoDisposicion === 'object' && Array.isArray(tipoDisposicion.data) && tipoDisposicion.data.length > 0) ? tipoDisposicion.data[0].domainName : '';
          const domainNameTipo = (typeof tipoEntidad === 'object' && Array.isArray(tipoEntidad.data) && tipoEntidad.data.length > 0) ? tipoEntidad.data[0].domainName : '';
          const domainNameNaturaleza = (typeof tipoNaturaleza === 'object' && Array.isArray(tipoNaturaleza.data) && tipoNaturaleza.data.length > 0) ? tipoNaturaleza.data[0].domainName : '';
          return {
            ...entidad,
            autoridad_ambiental: { _id: entidad.autoridad_ambiental._id, nombre: entidad.autoridad_ambiental.nombre },
            tipo_entidad_disposicion: { _id: entidad.tipo_disposicion, domainName: domainNameDisposicion }, // Tipo Disposición
            tipo_naturaleza: { _id: entidad.tipo_naturaleza._id, domainName: domainNameNaturaleza }, // Tipo Naturaleza
            tipo: { _id: entidad.tipo, domainName: domainNameTipo },  // Tipo
          };
        };

        const getData = async () => {
          return Promise.all(data.data.map(entidad => anAsyncFunction(entidad)))
        }

        getData().then(newData => {
          dispatch(searchSuccess(newData));
        })
      } else if(typeof data === 'string') {
        dispatch(searchFailed(data));
        return { msg: `${data}`, err: true };
      }
      // dispatch(searchFailed('Se genero un error cargando las entidades de disposición por favor contacte al administrador!'));
    } catch (error) {
      dispatch(searchFailed(error));
      return { msg: "no se puedo cargar", err: true };
    }
  };
};

export const changeMode = mode => {
  return async dispatch => {
    try {
      dispatch(changeModeSuccess(mode));
    } catch (error) {
      dispatch(searchFailed('Se genero un error al cargar la información por favor consulte al administrador!'));
    }
  };
};

export const loadSelected = (id, mode, token) => {
  return async dispatch => {
    dispatch(loadSelectedA());
    try {
      const data = await EntidadDisposicionServices.apiEntidadDisposicion.get(id, token);
      if(typeof data === 'object' && typeof data.data === 'object') {
        const anAsyncFunction = async () => {
          const autoridad = data.data.autoridad_ambiental ? await EntidadDisposicionServices.apiAutoridadAmbiental.get(data.data.autoridad_ambiental._id, token) : '';
          const tipoDisposicion = data.data.tipo_disposicion ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.data.tipo_disposicion, domainTypesByKey.tiposDisposicion, token) : ''; // Tipo Disposición
          const tipoEntidad = data.data.tipo ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.data.tipo, domainTypesByKey.tiposEntidadDisposicion, token) : ''; // Tipo
          const tipoNaturaleza  = data.data.tipo_naturaleza ? await EntidadDisposicionServices.apiUser.getByDomainIdsTypes(data.data.tipo_naturaleza, domainTypesByKey.tiposNaturalezaEntidad, token) : ''; // Tipo Naturaleza
          const nombre = (typeof autoridad === 'object' && typeof autoridad.data === 'object') ? autoridad.data.nombre : '';
          const domainNameDisposicion = (typeof tipoDisposicion === 'object' && Array.isArray(tipoDisposicion.data) && tipoDisposicion.data.length > 0) ? tipoDisposicion.data[0].domainName : '';
          const domainNameTipo = (typeof tipoEntidad === 'object' && Array.isArray(tipoEntidad.data) && tipoEntidad.data.length > 0) ? tipoEntidad.data[0].domainName : '';
          const domainNameNaturaleza = (typeof tipoNaturaleza === 'object' && Array.isArray(tipoNaturaleza.data) && tipoNaturaleza.data.length > 0) ? tipoNaturaleza.data[0].domainName : '';

          return {
            ...data.data,
            autoridad_ambiental: { _id: data.data.autoridad_ambiental._id, nombre },
            tipo_entidad_disposicion: { _id: data.data.tipo_disposicion, domainName: domainNameDisposicion }, // Tipo Disposición
            tipo_naturaleza: { _id: data.data.tipo_naturaleza, domainName: domainNameNaturaleza }, // Tipo Naturaleza
            tipo: { _id: data.data.tipo, domainName: domainNameTipo }, // Tipo
          };
        };
        const newData = await anAsyncFunction();
        dispatch(loadSelectedSuccess(newData, mode));
      } else {
        dispatch(loadSelectedFailed('Se genero un error cargando la entidad de disposición por favor contacte al administrador!'));
      }
    } catch (error) {
      dispatch(loadSelectedFailed(error));
    }
  };
};

export const clearSelected = () => {
  return async dispatch => {
    dispatch(clearSelectedA());
  };
};
