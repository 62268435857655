import React, {useCallback, useEffect} from "react";

import Page from "../components/Page";
import {Card} from "../components/Card";
import {H1} from "../components/H1";
import {PageHeader} from "../components/PageHeader";
import {Col4, GridContainer, Row} from "../components/Grid";
import {Link} from "react-router-dom";
import H3 from "../components/H3";

import styled from "styled-components";
import {useLogin} from "../hooks";

const LinkCard = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  overflow: hidden;
  div {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h3 {
    color: ${props => props.theme.sideBarBackground};
    text-align: center;
  }
  &:hover {
    h3 {
      color: ${props => props.theme.sideBarBackgroundHover};;
    }
  }
`;


const Dashboard = ({history}) => {
  const {isLoggedIn, menuUserLogin} = useLogin();

  const gotoLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn, gotoLogin]);

  return (
    <Page useSideBarSpace>
      <PageHeader>
        <H1>Bienvenido</H1>
      </PageHeader>
      <GridContainer>
        <Row>
          {menuUserLogin.map((item, index) => (
            <Col4 center key={index}>
              <LinkCard to={item.path}>
                <Card>
                  <H3>{item.title}</H3>
                </Card>
              </LinkCard>
            </Col4>
          ))}
        </Row>
      </GridContainer>
    </Page>
  );
};

export default Dashboard;
