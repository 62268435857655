import styled from "styled-components";
import { pxToRem } from "../utils";

export const PageHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pxToRem(32)} ${pxToRem(16)} ${pxToRem(8)};
  border-bottom: ${pxToRem(1)} solid ${props => props.theme.primaryColor};
  width: 100%;
  h1, h2, h3, h4, h5, h6, p, span {
    font-weight: 400;
  }
  
`;
