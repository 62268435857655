/* eslint-disable no-underscore-dangle */
import {
  CONSTANTS_BY_TYPES_INIT,
  CONSTANTS_BY_TYPES_SUCCESS,
  CONSTANTS_BY_TYPES_ERROR,
  CONSTANTS_LIST_INIT,
  CONSTANTS_LIST_SUCCESS,
  CONSTANTS_LIST_ERROR
} from "./types";

import { constantTypesByKey } from "../../config/const";

const initialState = {
  allconstants: [],
  constantsByKey: [],
  isLoading: false,
  error: ''
};

const constants = (state = initialState, { type, payload }) => {
  switch (type) {
    case CONSTANTS_BY_TYPES_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case CONSTANTS_BY_TYPES_SUCCESS: {
      const constantsByKey = {};
      let constantKey;
        let constantType = null;
      const constantKeys = payload.constantTypes;
      for (let i = 0; i < constantKeys.length; i++) {
        constantKey = constantKeys[i];
        constantType = constantTypesByKey[constantKey];
        constantsByKey[constantKey] = payload.data.filter(
          c => c.constantType === constantType
        ).sort((a, b) => {
          return a.constantName.localeCompare(b.constantName)
        })
      }
      return { ...state, isLoading: false, constantsByKey };
    }

    case CONSTANTS_BY_TYPES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case CONSTANTS_LIST_INIT: {
      return {
        ...state,
        error: '',
        isLoading: true
      };
    }

    case CONSTANTS_LIST_SUCCESS: {
      return {
        ...state,
        allconstants: payload.data,
        isLoading: false
      };
    }

    case CONSTANTS_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { constants as default, initialState };
