/* eslint-disable no-underscore-dangle */
import {
  ENTIDAD_DISPOSICION_CREATE_INIT,
  ENTIDAD_DISPOSICION_CREATE_SUCCESS,
  ENTIDAD_DISPOSICION_CREATE_ERROR,
  ENTIDAD_DISPOSICION_UPDATE_INIT,
  ENTIDAD_DISPOSICION_UPDATE_SUCCESS,
  ENTIDAD_DISPOSICION_UPDATE_ERROR,
  ENTIDAD_DISPOSICION_SEARCH_INIT,
  ENTIDAD_DISPOSICION_SEARCH_SUCCESS,
  ENTIDAD_DISPOSICION_SEARCH_ERROR,
  ENTIDAD_DISPOSICION_DELETE_INIT,
  ENTIDAD_DISPOSICION_DELETE_SUCCESS,
  ENTIDAD_DISPOSICION_DELETE_ERROR,
  ENTIDAD_DISPOSICION_CLEAR_SELECTED,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS,
  ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR,
  ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS
} from "./types";

const formData = {
  autoridadAmbiental: null,
  nombre: null,
  tipo: null,
  naturaleza_juridica: null,
  aplica_disposicion_provisional: null,
  aplica_disposicion_final: null,
  responsable: {
    nombre: null,
    cargo: null,
    email: null
  },
  ano_fundacion: null,
  estado: null,
  observaciones: null,

  pais: null,
  departamento: null,
  municipio: null,
  direccion: null,
  telefono_movil: null,
  telefono_fijo: null,
  sitio_web: null,
  coordenadas: {
    latitud: null,
    longitud: null
  }
};

const initialState = {
  data: [],
  selected: null,
  mode: null,
  isLoading: false,
  error: ""
};

const entidadDisposicionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ENTIDAD_DISPOSICION_CREATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case ENTIDAD_DISPOSICION_CREATE_SUCCESS: {
      return {
        ...state,
        data: [payload.data, ...state.data],
        isLoading: false
      };
    }

    case ENTIDAD_DISPOSICION_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case ENTIDAD_DISPOSICION_UPDATE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case ENTIDAD_DISPOSICION_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item),
        error: ""
      };
    }

    case ENTIDAD_DISPOSICION_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case ENTIDAD_DISPOSICION_SEARCH_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case ENTIDAD_DISPOSICION_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.data
      };
    }

    case ENTIDAD_DISPOSICION_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case ENTIDAD_DISPOSICION_DELETE_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case ENTIDAD_DISPOSICION_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        data: state.data.map((item) => (item._id === payload.data._id) ? payload.data : item)
      };
    }

    case ENTIDAD_DISPOSICION_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case ENTIDAD_DISPOSICION_CLEAR_SELECTED: {
      return {
        ...state,
        error: "",
        selected: null,
        mode: null,
        isLoading: false
      }
    }

    case ENTIDAD_DISPOSICION_LOAD_SELECTED_INIT: {
      return {
        ...state,
        error: "",
        isLoading: true
      };
    }

    case ENTIDAD_DISPOSICION_LOAD_SELECTED_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        selected: payload.data,
        mode: payload.mode,
        error: ""
      };
    }

    case ENTIDAD_DISPOSICION_LOAD_SELECTED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    }

    case ENTIDAD_DISPOSICION_CHANGE_MODE_SUCCESS: {
      return {
        ...state,
        mode: payload
      };
    }

    default: {
      return state;
    }
  }
};

export { entidadDisposicionReducer as default, initialState };
