/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {pxToRem} from "../utils";
import {Icon} from "./Icon";

const StyledLink = styled(Link)`
  display: flex;
  padding: ${pxToRem(8)} ${pxToRem(12)};
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme.sideBarBackgroundHover};
  }
`;

const Text = styled.span`
  display: none;
  @media (min-width: 600px) {
    display: inline-flex;
    padding: 0 ${pxToRem(12)};
    text-align: left;
    margin: auto 0;
  }
`;

const Submenu = styled.div`
  padding-left: ${pxToRem(18)};
  background-color: ${props => props.theme.sideBarBackground};
`;

const SideBarItem = ({title, iconName, path, submenus}) => {
    const [submenuVisible, setSubmenuVisible] = useState(false);

    return (
      <>
        <StyledLink
         to={path}
         onClick={() => setSubmenuVisible(!submenuVisible)}
         >
          <Icon>{iconName}</Icon>
          <Text> {title} </Text>
        </StyledLink>
        {submenuVisible && submenus ?
          <Submenu>
              {submenus.map((item, index) => (
                  <SideBarItem {...item} key={index}/>
              ))}
          </Submenu>
          :
          ''
        }
      </>
    );
};

SideBarItem.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  path: PropTypes.string,
  submenus: PropTypes.arrayOf(PropTypes.object),
};

SideBarItem.defaultProps = {
  title: '',
  iconName: '',
  path: '',
  submenus: [],
};

export default SideBarItem;
