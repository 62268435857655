export const TRASLADO_SEARCH_INIT = '[TRASLADO] TRASLADO_SEARCH_INIT';
export const TRASLADO_SEARCH_SUCCESS = '[TRASLADO] TRASLADO_SEARCH_SUCCESS';
export const TRASLADO_SEARCH_ERROR = '[TRASLADO] TRASLADO_SEARCH_ERROR';
export const TRASLADO_CREATE_INIT = '[TRASLADO] TRASLADO_CREATE_INIT';
export const TRASLADO_CREATE_SUCCESS = '[TRASLADO] TRASLADO_CREATE_SUCCESS';
export const TRASLADO_CREATE_ERROR = '[TRASLADO] TRASLADO_CREATE_ERROR';
export const TRASLADO_CANCEL_INIT = '[TRASLADO] TRASLADO_CANCEL_INIT';
export const TRASLADO_CANCEL_SUCCESS = '[TRASLADO] TRASLADO_CANCEL_SUCCESS';
export const TRASLADO_CANCEL_ERROR = '[TRASLADO] TRASLADO_CANCEL_ERROR';
export const TRASLADO_CLEAR_SELECTED = '[TRASLADO] TRASLADO_CLEAR_SELECTED';
export const TRASLADO_LOAD_SELECTED_INIT = '[TRASLADO] TRASLADO_LOAD_SELECTED_INIT';
export const TRASLADO_LOAD_SELECTED_SUCCESS = '[TRASLADO] TRASLADO_LOAD_SELECTED_SUCCESS';
export const TRASLADO_LOAD_SELECTED_ERROR = '[TRASLADO] TRASLADO_LOAD_SELECTED_ERROR';
export const TRASLADO_CHANGE_MODE_SUCCESS = '[TRASLADO] TRASLADO_CHANGE_MODE_SUCCESS';
export const TRASLADO_CONTACTS_SEARCH_INIT = '[MODAL_CONTACTS_TRASLADO] TRASLADO_CONTACTS_SEARCH_INIT';
export const TRASLADO_CONTACTS_SEARCH_SUCCESS = 'MODAL_CONTACTS_TRASLADO] TRASLADO_CONTACTS_SEARCH_SUCCESS';
export const TRASLADO_CONTACTS_SEARCH_ERROR = '[MODAL_CONTACTS_TRASLADO] TRASLADO_CONTACTS_SEARCH_ERROR';
export const TRASLADO_CONTACT_DELETE_SUCCESS = '[TRASLADO] TRASLADO_CONTACT_DELETE_SUCCESS';
export const TRASLADO_CONTACT_DELETE_ERROR = '[TRASLADO] TRASLADO_CONTACT_DELETE_ERROR';
export const TRASLADO_CONTACT_ADD_SUCCESS = '[TRASLADO] TRASLADO_CONTACT_ADD_SUCCESS';
export const TRASLADO_CONTACT_ADD_ERROR = '[TRASLADO] TRASLADO_CONTACT_ADD_ERROR';
export const TRASLADO_SOPORTE_SELECTED_SUCCESS = '[TRASLADO] TRASLADO_SOPORTE_SELECTED_SUCCESS';
export const TRASLADO_SOPORTE_SELECTED_ERROR = '[TRASLADO] TRASLADO_SOPORTE_SELECTED_ERROR';
export const TRASLADO_ESPECIMENES_SEARCH_INIT = '[TRASLADO] TRASLADO_ESPECIMENES_SEARCH_INIT';
export const TRASLADO_ESPECIMENES_SEARCH_SUCCESS = '[TRASLADO] TRASLADO_ESPECIMENES_SEARCH_SUCCESS';
export const TRASLADO_ESPECIMENES_SEARCH_ERROR = '[TRASLADO] TRASLADO_ESPECIMENES_SEARCH_ERROR';
export const TRASLADO_ESPECIMENES_ADD_SUCCESS = '[TRASLADO] TRASLADO_ESPECIMENES_ADD_SUCCESS';
export const TRASLADO_ESPECIMENES_ADD_ERROR = '[TRASLADO] TRASLADO_ESPECIMENES_ADD_ERROR';
export const TRASLADO_ESPECIMENES_DELETE_SUCCESS = '[TRASLADO] TRASLADO_ESPECIMENES_DELETE_SUCCESS';
export const TRASLADO_ESPECIMENES_DELETE_ERROR = '[TRASLADO] TRASLADO_ESPECIMENES_DELETE_ERROR';
export const TRASLADO_ESPECIMEN_SELECTED_SUCCESS = '[MODAL_ESPECIMEN_TRASLADO] TRASLADO_ESPECIMEN_SELECTED_SUCCESS';
export const TRASLADO_ESPECIMEN_SELECTED_ERROR = '[MODAL_ESPECIMEN_TRASLADO] TRASLADO_ESPECIMEN_SELECTED_ERROR';
export const TRASLADO_USUARIO_ENTIDAD_SUCCESS = '[TRASLADO] TRASLADO_USUARIO_ENTIDAD_SUCCESS';
export const TRASLADO_USUARIO_ENTIDAD_ERROR = '[TRASLADO] TRASLADO_USUARIO_ENTIDAD_ERROR';
export const TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT = '[TRASLADO] TRASLADO_ENTIDAD_DESTINO_SEARCH_INIT';
export const TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS = '[TRASLADO] TRASLADO_ENTIDAD_DESTINO_SEARCH_SUCCESS';
export const TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR = '[TRASLADO] TRASLADO_ENTIDAD_DESTINO_SEARCH_ERROR';
export const TRASLADO_LOAD_SOPORTE_SUCCESS = '[TRASLADO] TRASLADO_LOAD_SOPORTE_SUCCESS';
export const TRASLADO_LOAD_SOPORTE_ERROR = '[TRASLADO] TRASLADO_LOAD_SOPORTE_ERROR';
export const TRASLADO_CANCELAR_INIT = '[TRASLADO] TRASLADO_CANCELAR_INIT';
export const TRASLADO_CANCELAR_SUCCESS = '[TRASLADO] TRASLADO_CANCELAR_SUCCESS';
export const TRASLADO_CANCELAR_ERROR = '[TRASLADO] TRASLADO_CANCELAR_ERROR';
export const TRASLADO_RESPUESTA_SUCCESS = '[TRASLADO] TRASLADO_RESPUESTA_SUCCESS';
export const TRASLADO_RESPUESTA_ERROR = '[TRASLADO] TRASLADO_RESPUESTA_ERROR';
export const TRASLADO_SOPORTES_ADD_SUCCESS = '[TRASLADO] TRASLADO_SOPORTES_ADD_SUCCESS';
export const TRASLADO_SOPORTES_ADD_ERROR = '[TRASLADO] TRASLADO_SOPORTES_ADD_ERROR';
export const TRASLADO_SOPORTES_UPDATE_SUCCESS = '[TRASLADO] TRASLADO_SOPORTES_UPDATE_SUCCESS';
export const TRASLADO_SOPORTES_UPDATE_ERROR = '[TRASLADO] TRASLADO_SOPORTES_UPDATE_ERROR';
export const TRASLADO_SOPORTES_DELETE_SUCCESS = '[TRASLADO] TRASLADO_SOPORTES_DELETE_ERROR';
export const TRASLADO_SOPORTES_DELETE_ERROR = '[TRASLADO] TRASLADO_SOPORTES_DELETE_ERROR';
export const TRASLADO_SOPORTES_DOWNLOAD_INIT = '[TRASLADO] TRASLADO_SOPORTES_DOWNLOAD_INIT';
export const TRASLADO_SOPORTES_DOWNLOAD_SUCCESS = '[TRASLADO] TRASLADO_SOPORTES_DOWNLOAD_SUCCESS';
export const TRASLADO_SOPORTES_DOWNLOAD_ERROR = '[TRASLADO] TRASLADO_SOPORTES_DOWNLOAD_ERROR';
export const CLEAN_CONTACTS_TO_TRASLADO_SUCCESS = '[TRASLADO] CLEAN_CONTACTS_TO_TRASLADO_SUCCESS';
export const CLEAN_CONTACTS_TO_TRASLADO_ERROR = '[TRASLADO] CLEAN_CONTACTS_TO_TRASLADO_ERROR';
export const SELECTED_FILE_SUCCESS = '[MODAL_FILE] SELECTED_FILE_SUCCESS';
export const SELECTED_FILE_ERROR = '[MODAL_FILE] SELECTED_FILE_ERROR';
export const FILE_CLEAR_SELECTED = '[MODAL_FILE] FILE_CLEAR_SELECTED';
export const SEARCH_ESPECIMENES_SIN_TRASLADO_INIT = '[TRASLADO] SEARCH_ESPECIMENES_SIN_TRASLADO_INIT';
export const SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS = '[TRASLADO] SEARCH_ESPECIMENES_SIN_TRASLADO_SUCCESS';
export const SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR = '[TRASLADO] SEARCH_ESPECIMENES_SIN_TRASLADO_ERROR';
export const SEARCH_ESPECIMENES_CON_TRASLADO_INIT = '[TRASLADO] SEARCH_ESPECIMENES_CON_TRASLADO_INIT';
export const SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS = '[TRASLADO] SEARCH_ESPECIMENES_CON_TRASLADO_SUCCESS';
export const SEARCH_ESPECIMENES_CON_TRASLADO_ERROR = '[TRASLADO] SEARCH_ESPECIMENES_CON_TRASLADO_ERROR';
