import {
  EXPEDIENTE_CREATE_INIT,
  EXPEDIENTE_CREATE_SUCCESS,
  EXPEDIENTE_CREATE_ERROR,
  EXPEDIENTE_UPDATE_INIT,
  EXPEDIENTE_UPDATE_SUCCESS,
  EXPEDIENTE_UPDATE_ERROR,
  EXPEDIENTE_CLOSE_INIT,
  EXPEDIENTE_CLOSE_SUCCESS,
  EXPEDIENTE_CLOSE_ERROR,
  EXPEDIENTE_SEARCH_INIT,
  EXPEDIENTE_SEARCH_SUCCESS,
  EXPEDIENTE_SEARCH_ERROR,
  EXPEDIENTE_DELETE_INIT,
  EXPEDIENTE_DELETE_SUCCESS,
  EXPEDIENTE_DELETE_ERROR,
  EXPEDIENTE_GETBYID_INIT,
  EXPEDIENTE_GETBYID_SUCCESS,
  EXPEDIENTE_GETBYID_ERROR,
  EXPEDIENTE_SELECTED,
  DOMAINS_BY_TYPE,
  EXPEDIENTE_CONTACT_DELETE_SUCCESS,
  EXPEDIENTE_CONTACT_DELETE_ERROR,
  EXPEDIENTE_CONTACT_SEARCH_INIT,
  EXPEDIENTE_CONTACT_SEARCH_SUCCESS,
  CONTACTS_SEARCH_INIT,
  CONTACTS_SEARCH_SUCCESS,
  CONTACTS_SEARCH_ERROR,
  ADD_CONTACT_TO_EXPEDIENTE_INIT,
  ADD_CONTACT_TO_EXPEDIENTE_SUCCESS,
  ADD_CONTACT_TO_EXPEDIENTE_ERROR,
  CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS,
  ESPECIMENES_EXPEDIENTE_SEARCH_INIT,
  ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS,
  ESPECIMENES_EXPEDIENTE_SEARCH_ERROR,
  ESPECIMEN_GETBYID_INIT,
  ESPECIMEN_GETBYID_SUCCESS,
  ESPECIMEN_GETBYID_ERROR,
  EXPEDIENTE_RADICAR_INIT,
  EXPEDIENTE_RADICAR_SUCCESS,
  EXPEDIENTE_RADICAR_ERROR,
  ADD_SAME_ESPECIMEN_SUCCESS,
  ADD_SAME_ESPECIMEN_ERROR,
  DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS,
  DELETE_ESPECIMEN_EXPEDIENTE_ERROR,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_ERROR,
  FILE_CLEAR_SELECTED,
  SELECTED_IMAGE_SUCCESS,
  SELECTED_IMAGE_ERROR,
  IMAGE_CLEAR_SELECTED,
  EXPEDIENTE_CLEAR_SELECTED,
  EXPEDIENTE_LOAD_SELECTED_INIT,
  EXPEDIENTE_LOAD_SELECTED_SUCCESS,
  EXPEDIENTE_LOAD_SELECTED_ERROR,
  SELECTED_FILE_CLOSE_SUCCESS,
  SELECTED_FILE_CLOSE_ERROR,
  FILE_CLOSE_CLEAR_SELECTED,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS,
  EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR,
  EXPEDIENTE_FILES_ADD_SUCCESS,
  EXPEDIENTE_FILES_ADD_ERROR,
  EXPEDIENTE_FILES_UPDATE_SUCCESS,
  EXPEDIENTE_FILES_UPDATE_ERROR,
  EXPEDIENTE_FILES_DELETE_SUCCESS,
  EXPEDIENTE_FILES_DELETE_ERROR,
  EXPEDIENTE_FILES_DOWNLOAD_INIT,
  EXPEDIENTE_FILES_DOWNLOAD_SUCCESS,
  EXPEDIENTE_FILES_DOWNLOAD_ERROR,
  EXPEDIENTE_IMAGES_ADD_SUCCESS,
  EXPEDIENTE_IMAGES_ADD_ERROR,
  EXPEDIENTE_IMAGES_UPDATE_SUCCESS,
  EXPEDIENTE_IMAGES_UPDATE_ERROR,
  EXPEDIENTE_IMAGES_DELETE_SUCCESS,
  EXPEDIENTE_IMAGES_DELETE_ERROR,
  EXPEDIENTE_IMAGES_DOWNLOAD_INIT,
  EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS,
  EXPEDIENTE_IMAGES_DOWNLOAD_ERROR,
  EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_ADD_ERROR,
  EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR,
  EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_DELETE_ERROR,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS,
  EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR,
  EXPEDIENTE_ESPECIMEN_UPDATE_INIT,
  EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS,
  EXPEDIENTE_ESPECIMEN_UPDATE_ERROR
} from './types';

export const createInit = () => {
	return {
		type: EXPEDIENTE_CREATE_INIT
	};
};

export const createSuccess = data => {
	return {
		type: EXPEDIENTE_CREATE_SUCCESS,
		payload: { data }
	};
};

export const createError = error => {
	return {
		type: EXPEDIENTE_CREATE_ERROR,
		payload: error
	};
};

export const updateInit = () => {
	return {
		type: EXPEDIENTE_UPDATE_INIT
	};
};

export const updateSuccess = data => {
	return {
		type: EXPEDIENTE_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const updateError = error => {
	return {
		type: EXPEDIENTE_UPDATE_ERROR,
		payload: error
	};
};

export const closeInit = () => {
	return {
		type: EXPEDIENTE_CLOSE_INIT
	};
};

export const closeSuccess = data => {
	return {
		type: EXPEDIENTE_CLOSE_SUCCESS,
		payload: { data }
	};
};

export const closeError = error => {
	return {
		type: EXPEDIENTE_CLOSE_ERROR,
		payload: error
	};
};

export const searchInit = () => {
	return {
		type: EXPEDIENTE_SEARCH_INIT
	};
};

export const searchSuccess = (data, pageCount) => {
	return {
		type: EXPEDIENTE_SEARCH_SUCCESS,
		payload: { data, pageCount }
	};
};

export const searchError = error => {
	return {
		type: EXPEDIENTE_SEARCH_ERROR,
		payload: error
	};
};

export const deleteInit = () => {
	return {
		type: EXPEDIENTE_DELETE_INIT
	};
};

export const deleteSuccess = data => {
	return {
		type: EXPEDIENTE_DELETE_SUCCESS,
		payload: { data }
	};
};

export const deleteError = error => {
	return {
		type: EXPEDIENTE_DELETE_ERROR,
		payload: error
	};
};

export const getbyidInit = () => {
	return {
		type: EXPEDIENTE_GETBYID_INIT
	};
};

export const getbyidSuccess = data => {
	return {
		type: EXPEDIENTE_GETBYID_SUCCESS,
		payload: { data }
	};
};

export const getbyidError = error => {
	return {
		type: EXPEDIENTE_GETBYID_ERROR,
		payload: error
	};
};

export const expedienteSelected = id => {
	return {
		type: EXPEDIENTE_SELECTED,
		payload: id
	};
};

export const domainsByType = () => {
	return {
		type: DOMAINS_BY_TYPE
	};
};

export const deleteContactExpediente = id => {
	return {
		type: EXPEDIENTE_CONTACT_DELETE_SUCCESS,
		payload: id
	};
};

export const contactExpedienteError = error => {
	return {
		type: EXPEDIENTE_CONTACT_DELETE_ERROR,
		payload: error
	};
};

export const contactExpedienteSearch = () => {
	return {
		type: EXPEDIENTE_CONTACT_SEARCH_INIT
	};
};

export const contactExpedienteSearchSuccess = data => {
	return {
		type: EXPEDIENTE_CONTACT_SEARCH_SUCCESS,
		payload: { data }
	};
};

export const contactsInit = () => {
	return {
		type: CONTACTS_SEARCH_INIT
	};
};

export const contactsSuccess = (data, values) => {
	return {
		type: CONTACTS_SEARCH_SUCCESS,
		payload: { data, values }
	};
};

export const contactsError = error => {
	return {
		type: CONTACTS_SEARCH_ERROR,
		payload: error
	};
};

export const addContactToExpedienteInit = () => {
	return {
		type: ADD_CONTACT_TO_EXPEDIENTE_INIT
	};
};

export const addContactToExpedienteSuccess = data => {
	return {
		type: ADD_CONTACT_TO_EXPEDIENTE_SUCCESS,
		payload: { data }
	};
};

export const addContactToExpedienteError = error => {
	return {
		type: ADD_CONTACT_TO_EXPEDIENTE_ERROR,
		payload: error
	};
};

export const cleanContactExpedienteSuccess = () => {
	return {
		type: CLEAN_CONTACTS_TO_EXPEDIENTE_SUCCESS
	};
};

export const especimenesExpedienteInit = () => {
	return {
		type: ESPECIMENES_EXPEDIENTE_SEARCH_INIT
	};
};

export const especimenesExpedienteSuccess = data => {
	return {
		type: ESPECIMENES_EXPEDIENTE_SEARCH_SUCCESS,
		payload: { data }
	};
};

export const especimenesExpedienteError = error => {
	return {
		type: ESPECIMENES_EXPEDIENTE_SEARCH_ERROR,
		payload: error
	};
};

export const getByIdEspecimenInit = () => {
	return {
		type: ESPECIMEN_GETBYID_INIT
	};
};

export const getByIdEspecimenSuccess = (id, mode) => {
	return {
		type: ESPECIMEN_GETBYID_SUCCESS,
		payload: { id, mode }
	};
};

export const getByIdEspecimenError = error => {
	return {
		type: ESPECIMEN_GETBYID_ERROR,
		payload: error
	};
};

export const expedienteEspecimenUpdateInit = () => {
	return {
		type: EXPEDIENTE_ESPECIMEN_UPDATE_INIT
	};
};

export const expedienteEspecimenUpdateSuccess = (id, data) => {
	return {
		type: EXPEDIENTE_ESPECIMEN_UPDATE_SUCCESS,
		payload: { id, data }
	};
};

export const expedienteEspecimenUpdateError = error => {
	return {
		type: EXPEDIENTE_ESPECIMEN_UPDATE_ERROR,
		payload: error
	};
};

export const radicarInit = () => {
	return {
		type: EXPEDIENTE_RADICAR_INIT
	};
};

export const radicarSuccess = data => {
	return {
		type: EXPEDIENTE_RADICAR_SUCCESS,
		payload: { data }
	};
};

export const radicarError = error => {
	return {
		type: EXPEDIENTE_RADICAR_ERROR,
		payload: error
	};
};

export const addSameEspecimenSuccess = (data, cantidad) => {
	return {
		type: ADD_SAME_ESPECIMEN_SUCCESS,
		payload: { data, cantidad }
	};
};

export const addSameEspecimenError = error => {
	return {
		type: ADD_SAME_ESPECIMEN_ERROR,
		payload: error
	};
};

export const deleteEspecimenesExpedienteSuccess = (selectedRowIds, selectedFlatRows) => {
	return {
		type: DELETE_ESPECIMEN_EXPEDIENTE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const deleteEspecimenesExpedienteError = error => {
	return {
		type: DELETE_ESPECIMEN_EXPEDIENTE_ERROR,
		payload: error
	};
};

export const selectedFileSuccess = (id, mode) => {
	return {
		type: SELECTED_FILE_SUCCESS,
		payload: { id, mode }
	};
};

export const selectedFileError = error => {
	return {
		type: SELECTED_FILE_ERROR,
		payload: error
	};
};

export const clearSelectedFileInit = () => {
  return {
    type: FILE_CLEAR_SELECTED
  }
};

export const selectedImageSuccess = (id, mode) => {
	return {
		type: SELECTED_IMAGE_SUCCESS,
		payload: { id, mode }
	};
};

export const selectedImageError = error => {
	return {
		type: SELECTED_IMAGE_ERROR,
		payload: error
	};
};

export const clearSelectedImageInit = () => {
  return {
    type: IMAGE_CLEAR_SELECTED
  };
};

export const clearSelectedInit = () => {
  return {
    type: EXPEDIENTE_CLEAR_SELECTED,
  };
};

export const loadSelectedInit = data => {
  return {
    type: EXPEDIENTE_LOAD_SELECTED_INIT,
    payload: { data }
  };
};

export const loadSelectedSuccess = (data, mode) => {
  return {
    type: EXPEDIENTE_LOAD_SELECTED_SUCCESS,
    payload: { data, mode }
  };
};

export const loadSelectedFailed = error => {
  return {
    type: EXPEDIENTE_LOAD_SELECTED_ERROR,
    payload: error
  };
};

export const selectedFileCloseSuccess = (id, mode) => {
	return {
		type: SELECTED_FILE_CLOSE_SUCCESS,
		payload: { id, mode }
	};
};

export const selectedFileCloseError = error => {
	return {
		type: SELECTED_FILE_CLOSE_ERROR,
		payload: error
	};
};

export const clearSelectedFileCloseInit = () => {
  return {
    type: FILE_CLOSE_CLEAR_SELECTED
  }
};

export const especimenTaxonoInfoInit = () => {
  return {
    type: EXPEDIENTE_ESPECIMEN_TAXONOINFO_INIT,
  };
};

export const especimenTaxonoInfoSuccess = data => {
  return {
		type: EXPEDIENTE_ESPECIMEN_TAXONOINFO_SUCCESS,
		payload: { data }
	};
};

export const especimenTaxonoInfoError = error => {
  return {
		type: EXPEDIENTE_ESPECIMEN_TAXONOINFO_ERROR,
		payload: error
	};
};
// Acciones sobre los archivos
export const expedienteFilesAddSuccess = data => {
  return {
		type: EXPEDIENTE_FILES_ADD_SUCCESS,
		payload: { data }
	};
};

export const expedienteFilesAddError = error => {
  return {
		type: EXPEDIENTE_FILES_ADD_ERROR,
		payload: error
	};
};

export const expedienteFilesUpdateSuccess = data => {
  return {
		type: EXPEDIENTE_FILES_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const expedienteFilesUpdateError = error => {
  return {
		type: EXPEDIENTE_FILES_UPDATE_ERROR,
		payload: error
	};
};

export const expedienteFilesDeleteSuccess = (selectedRowIds, selectedFlatRows) => {
  return {
		type: EXPEDIENTE_FILES_DELETE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const expedienteFilesDeleteError = error => {
  return {
		type: EXPEDIENTE_FILES_DELETE_ERROR,
		payload: error
	};
};

export const expedienteFilesDownloadInit = () => {
  return {
		type: EXPEDIENTE_FILES_DOWNLOAD_INIT,
	};
};

export const expedienteFilesDownloadSuccess = () => {
  return {
		type: EXPEDIENTE_FILES_DOWNLOAD_SUCCESS,
	};
};

export const expedienteFilesDownloadError = error => {
  return {
		type: EXPEDIENTE_FILES_DOWNLOAD_ERROR,
		payload: error
	};
};

// Acciones sobre las imagenes
export const expedienteImagesAddSuccess = data => {
  return {
		type: EXPEDIENTE_IMAGES_ADD_SUCCESS,
		payload: { data }
	};
};

export const expedienteImagesAddError = error => {
  return {
		type: EXPEDIENTE_IMAGES_ADD_ERROR,
		payload: error
	};
};

export const expedienteImagesUpdateSuccess = data => {
  return {
		type: EXPEDIENTE_IMAGES_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const expedienteImagesUpdateError = error => {
  return {
		type: EXPEDIENTE_IMAGES_UPDATE_ERROR,
		payload: error
	};
};

export const expedienteImagesDeleteSuccess = (selectedRowIds, selectedFlatRows) => {
  return {
		type: EXPEDIENTE_IMAGES_DELETE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const expedienteImagesDeleteError = error => {
  return {
		type: EXPEDIENTE_IMAGES_DELETE_ERROR,
		payload: error
	};
};

export const expedienteImagesDownloadInit = () => {
  return {
		type: EXPEDIENTE_IMAGES_DOWNLOAD_INIT,
	};
};

export const expedienteImagesDownloadSuccess = () => {
  return {
		type: EXPEDIENTE_IMAGES_DOWNLOAD_SUCCESS,
	};
};

export const expedienteImagesDownloadError = error => {
  return {
		type: EXPEDIENTE_IMAGES_DOWNLOAD_ERROR,
		payload: error
	};
};

// Acciones sobre cerrar expedientes - soportes
export const expedienteFilesCloseAddSuccess = data => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_ADD_SUCCESS,
		payload: { data }
	};
};

export const expedienteFilesCloseAddError = error => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_ADD_ERROR,
		payload: error
	};
};

export const expedienteFilesCloseUpdateSuccess = data => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_UPDATE_SUCCESS,
		payload: { data }
	};
};

export const expedienteFilesCloseUpdateError = error => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_UPDATE_ERROR,
		payload: error
	};
};

export const expedienteCerrarFilesDeleteSuccess = (selectedRowIds, selectedFlatRows) => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_DELETE_SUCCESS,
		payload: { selectedRowIds, selectedFlatRows }
	};
};

export const expedienteCerrarFilesDeleteError = error => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_DELETE_ERROR,
		payload: error
	};
};

export const expedienteFilesCloseDownloadInit = () => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_DOWNLOAD_INIT,
	};
};

export const expedienteFilesCloseDownloadSuccess = () => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_DOWNLOAD_SUCCESS,
	};
};

export const expedienteFilesCloseDownloadError = error => {
  return {
		type: EXPEDIENTE_FILES_CLOSE_DOWNLOAD_ERROR,
		payload: error
	};
};
