import nanoid from 'nanoid';

export const wait = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

// TODO: generar archivos .env para manejo de rutas en desarrollo, pruebas y producción
const API_USERS_DEV = "http://186.84.94.219:8006";
const API_USERS_TEST = "http://186.155.244.60::550";
const API_USERS_PROD = "http://167.114.129.146:8002";

const API_PIFS_DEV = "http://186.84.94.219:8004";
const API_PIFS_TEST = "http://186.155.244.60:520";
const API_PIFS_PROD = "http://51.178.43.74:8000";

const API_PIFS = API_PIFS_DEV;
const API_USERS = API_USERS_DEV;

export const AUTORIDAD_AMBIENTAL_URL_API = API_PIFS;
export const DOMAINS_URL_API = "http://186.84.94.219:8002";
export const CONSTANTS_URL_API = "http://186.84.94.219:8002";
export const CONTACTS_URL_API = API_PIFS;
export const ENTIDAD_DISPOSICION_URL_API = API_PIFS;
export const USERS_URL_API = API_USERS;
export const USERS_URL_API_PIFS = API_PIFS;
export const REPORTS_URL_API_PIFS = API_PIFS;
export const FAKE_JSON_API = 'https://app.fakejson.com/q';
export const FAKE_JSON_TOKEN = '79k3Z1p34SBDDzpsBxW9RA';

export const BASE_FONT_SIZE = 14;

export const domainTypesByKey = {
  tiposAutoridadAmbiental: "AUTORIDAD.TIPO",
  tiposEntidadDisposicion: 'ENTIDAD_DISPOSICION.TIPO',
  tiposEntidad: 'ENTIDAD.TIPO',
  tiposEvento: 'EVENTO.TIPO',
  tiposProcedimientoSitio: 'PROCEDIMIENTO.SITIO',
  tiposProcedimientoOrigen: 'PROCEDIMIENTO.ORIGEN',
  tiposRecurso: 'RECURSO.TIPO',
  tiposParte: 'PARTE.TIPO',
  tiposSexo: 'SEXO.TIPO',
  estadosDesarrollo: 'DESARROLLO.ESTADO',
  estadosCondicion: 'CONDICION.ESTADO',
  unidadesMedida: 'UNIDAD_MEDIDA.UNIDAD',
  tiposEstado: 'ESTADO.TIPO',
  tiposAdjunto: 'ADJUNTO.TIPO',
  cierreMotivo: 'CIERRE.MOTIVO',
  tiposEstadoEspecimen: 'ESTADO.ESPECIMEN.TIPO',
  tiposEstadoTraslado: 'ESTADO.TRASLADO.TIPO',
  tiposDisposicion: 'DISPOSICION.TIPO',
  tiposRespuestaTraslado: 'RESPUESTA.TRASLADO.TIPO',
  motivosCierre: 'CIERRE.MOTIVO',
  tiposEstadoEspecimenExpediente: 'ESTADO.ESPECIMEN.EXPEDIENTE.TIPO',
  tiposEstadoEspecimenTraslado: 'ESTADO.ESPECIMEN.TRASLADO.TIPO',
  tiposNaturalezaEntidad: 'ENTIDAD_DISPOSICION.NATURALEZA.TIPO',
  tiposExpedienteTipo: 'ESTADO.EXPEDIENTE.TIPO',
};

export const constantTypesByKey = {
  paises: 'PAIS.NOMBRE',
  departamentos: 'DEPARTAMENTO.NOMBRE',
  municipios: 'MUNICIPIO.NOMBRE',
  tiposDocumento: 'DOCUMENTO.TIPO',
  clasificacionTaxonomia: 'TAXONOMIA.CLASIFICACION',
  hemisferioLatitud: 'HEMISFERIO.LATITUD',
  hemisferioLongitud: 'HEMISFERIO.LONGITUD',
};

export const REPORTES_MENU_ID = 8

export const SUPERUSUARIO = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  },
];

export const ADMINISTRADOR_SISTEMA = [
  {
    id: 1,
    title: "Listas de Dominios",
    iconName: "table_chart",
    path: "/domains",
    component: "domains",
    submenus: [
      {
        id: 10,
        title: "Dominios",
        iconName: "view_column",
        path: "/domains",
        component: "domains"
      },
      {
        id: 11,
        title: "Constantes",
        iconName: "view_agenda",
        path: "/constants",
        component: "constants"
      },
      {
        id: 12,
        title: "Listas Taxonómicas",
        iconName: "toc",
        path: "/taxonomy",
        component: "taxonomy"
      }
    ]
  },
  {
    id: 2,
    title: "Usuarios",
    iconName: "supervised_user_circle",
    path: "/users",
    component: "users",
    submenus: [
      {
        id: 20,
        title: "Listar usuarios",
        iconName: "people",
        path: "/users",
        component: "users"
      },
      {
        id: 21,
        title: "Crear usuario",
        iconName: "person_add",
        path: "/user-form",
        component: "user-form"
      }
    ]
  },
  {
    id: 3,
    title: "Autoridades Ambientales",
    iconName: "apartment",
    path: "/autoridad-ambiental-search",
    component: "autoridad-ambiental-search",
    submenus: [
      {
        id: 30,
        title: "Listar autoridades",
        iconName: "list",
        path: "/autoridad-ambiental-search",
        component: "autoridad-ambiental-search"
      },
      {
        id: 31,
        title: "Crear autoridad",
        iconName: "add",
        path: "/autoridad-ambiental-form",
        component: "autoridad-ambiental-form\""
      }
    ]
  },
  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      }
    ]
  },
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
export const COORDINADOR_AUTORIDAD = [

  {
    id: 4,
    title: "Entidad Disposicion",
    iconName: "save",
    path: "/entidad-disposicion-search",
    component: "entidad-disposicion-search",
    submenus: [
      {
        id: 40,
        title: "Listar entidades",
        iconName: "list",
        path: "/entidad-disposicion-search",
        component: "entidad-disposicion-search"
      },
      {
        id: 41,
        title: "Crear entidad",
        iconName: "add",
        path: "/entidad-disposicion-form",
        component: "entidad-disposicion-form"
      }
    ]
  },
  {
    id: 5,
    title: "Contactos",
    iconName: "contacts",
    path: "/contacts-search",
    component: "contacts-search",
    submenus: [
      {
        id: 50,
        title: "Listar contactos",
        iconName: "list",
        path: "/contacts-search",
        component: "contacts-search"
      },
      {
        id: 51,
        title: "Crear contacto",
        iconName: "add",
        path: "/contacts-form",
        component: "contacts-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
export const COORDINADOR_EXPEDIENTE = [
  {
    id: 6,
    title: "Expedientes",
    iconName: "pets",
    path: "/expedientes-search",
    component: "expedientes-search",
    submenus: [
      {
        id: 60,
        title: "Listar expedientes",
        iconName: "list",
        path: "/expedientes-search",
        component: "expedientes-search"
      },
      {
        id: 61,
        title: "Crear expediente",
        iconName: "add",
        path: "/expediente-form",
        component: "expediente-form"
      }
    ]
  },
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      },
      {
        id: 71,
        title: "Crear traslado",
        iconName: "post_add",
        path: "/traslados-form",
        component: "traslados-form"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 80,
        title: "Tabla",
        iconName: "table_chart",
        path: "/consulta-por-tabla-search",
        component: "consulta-por-tabla-search"
      },
      {
        id: 81,
        title: "Geovisor",
        iconName: "map",
        path: "/consulta-por-geovisor-search",
        component: "consulta-por-geovisor-search"
      },
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]
export const COORDINADOR_ENTIDADES = [
  {
    id: 7,
    title: "Traslados",
    iconName: "assessment",
    path: "/traslados-search",
    component: "traslados-search",
    submenus: [
      {
        id: 70,
        title: "Ver mis traslados",
        iconName: "assignment",
        path: "/traslados-search",
        component: "traslados-search"
      }
    ]
  },
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]

export const USUARIO_CONSULTA = [
  {
    id: 8,
    title: "Reportes",
    iconName: "table_chart",
    path: "/consulta-trafico-especies",
    component: "consulta-trafico-especies",
    submenus: [
      {
        id: 82,
        title: "Estadísticas",
        iconName: "pie_chart",
        path: "/consulta-por-estadisticas-search",
        component: "consulta-por-estadisticas-search"
      }
    ]
  }
]



